<template>
  <div>
    <div class="hidden pc:block bg-wrapper">
      <div class="flex items-center justify-center bg py-[76px] max-w-[1920px] mx-auto">
        <p class="text-[40px] font-bold text-neutral-950">성인 과정</p>
      </div>
    </div>

    <section class="pt-[55px] pb-[70px] pc:pt-[70px] pc:pb-[140px]">
      <div>
        <div class="flex items-center justify-between p-2 bg-[#323740] pc:hidden show-list">
          <div class="w-3"></div>
          <p class="text-sm font-bold text-white">과정 리스트 보기</p>
          <div class="w-3">
            <img src="@lifeeduWeb/assets/images/courseIntroduce/adult/arrow.png" alt="icon" class="transition-all show-arrow" />
          </div>
        </div>
        <ul class="flex pc:flex-col pc:max-w-[1200px] pc:mx-auto lang-list">
          <li class="w-[40%] bg-[#ececec] pc:w-full pc:bg-white">
            <ul class="gap-10 pc:flex pc:border-b border-solid border-[#f1f1f1]">
              <li class="py-3.5 font-medium text-center cursor-pointer" @click="goPage('/courseIntroduce/adultEng/PageAll')">
                영어
              </li>
              <li class="py-3.5 font-medium text-center cursor-pointer" @click="goPage('/courseIntroduce/adultChi/pageAll')">
                중국어
              </li>
              <li class="py-3.5 font-medium text-center cursor-pointer" @click="goPage('/courseIntroduce/adultJap/pageAll')">
                일본어
              </li>
              <li
                class="py-3.5 font-medium text-center text-[#fb6930] cursor-pointer"
                @click="goPage('/courseIntroduce/adultEtc')"
              >
                제2외국어
              </li>
            </ul>
          </li>
          <li class="w-[60%] pc:w-full">
            <ul class="divide-y divide-solid divide-[#e9e9e9] pc:flex pc:divide-y-0 gap-9 country-list">
              <li class="py-3.5 font-medium text-center cursor-pointer text-[#666666]">
                <a href="#lang1">독일어</a>
              </li>
              <li class="py-3.5 font-medium text-center cursor-pointer text-[#666666]">
                <a href="#lang2">러시아어</a>
              </li>
              <li class="py-3.5 font-medium text-center cursor-pointer text-[#666666]">
                <a href="#lang3">베트남어</a>
              </li>
              <li class="py-3.5 font-medium text-center cursor-pointer text-[#666666]">
                <a href="#lang4">스페인어</a>
              </li>
              <li class="py-3.5 font-medium text-center cursor-pointer text-[#666666]">
                <a href="#lang5">아랍어</a>
              </li>
              <li class="py-3.5 font-medium text-center cursor-pointer text-[#666666]">
                <a href="#lang6">인도네시아어</a>
              </li>
              <li class="py-3.5 font-medium text-center cursor-pointer text-[#666666]">
                <a href="#lang7">태국어</a>
              </li>
              <li class="py-3.5 font-medium text-center cursor-pointer text-[#666666]">
                <a href="#lang8">포르투갈어</a>
              </li>
              <li class="py-3.5 font-medium text-center cursor-pointer text-[#666666]">
                <a href="#lang9">프랑스어</a>
              </li>
              <li class="py-3.5 font-medium text-center cursor-pointer text-[#666666]">
                <a href="#lang10">이탈리아어</a>
              </li>
              <li class="py-3.5 font-medium text-center cursor-pointer text-[#666666]">
                <a href="#lang11">한국어</a>
              </li>
            </ul>
          </li>
        </ul>
        <div class="pc-con-wrapper5">
          <div class="flex flex-col items-center bg1 py-10 pc:py-[90px] px-5 mb-7 pc:mb-11">
            <p class="text-white font-bold text-center text-[35px] mb-2">제2외국어</p>
            <div class="w-[72px] h-[2px] bg-[#fb6930] mb-6"></div>
            <p class="text-center text-white pc:text-2xl">
              20개 언어, 922명의 강사진과 함께하는 리얼 원어민 수업!<br />언어별 원어민 강사와 직접 얼굴을 보며 진행되는 수준별
              맞춤학습을 통해<br />글로벌 커뮤니케이션 능력을 가장 빠르게 향상시켜 드립니다.
            </p>
          </div>
          <div class="px-5 pc-con-wrapper4">
            <!-- 독일어 -->
            <div id="lang1" class="pb-10 mb-10 border-b border-solid border-[#ebebeb]">
              <div class="flex items-center gap-2 mb-5">
                <div class="w-5">
                  <img src="@lifeeduWeb/assets/images/courseIntroduce/adult/etc/germany.png" alt="img" />
                </div>
                <p class="text-2xl text-[#111111] font-bold">독일어</p>
              </div>
              <ul class="grid grid-cols-2 gap-5 tablet:grid-cols-3 pc:grid-cols-4 pc:gap-10">
                <li>
                  <div class="relative mb-2.5 overflow-hidden rounded-xl">
                    <img src="@lifeeduWeb/assets/images/courseIntroduce/adult/etc/germany01.png" alt="img" />
                    <p class="absolute top-0 left-0 px-3 py-1 text-white bg-black/60">입문~초급</p>
                    <p class="absolute bottom-0 w-full pb-3.5 text-center text-white custom-shadow">입문초급회화</p>
                  </div>
                  <p
                    class="inline-block cursor-pointer text-sm px-3.5 font-medium text-[#fb6930] mb-1 border border-solid border-[fb6930] rounded-2xl"
                    @click="goSite('https://kr.object.ncloudstorage.com/mailpad/b2b_pdf/ETC01.pdf')"
                  >
                    미리보기
                  </p>
                  <p class="text-[#111111] font-medium">가장 쉬운 독일어 첫걸음의 모든 것</p>
                </li>
                <li>
                  <div class="relative mb-2.5 overflow-hidden rounded-xl">
                    <img src="@lifeeduWeb/assets/images/courseIntroduce/adult/etc/germany02.png" alt="img" />
                    <p class="absolute top-0 left-0 px-3 py-1 text-white bg-black/60">초급</p>
                    <p class="absolute bottom-0 w-full pb-3.5 text-center text-white custom-shadow">일반회화</p>
                  </div>
                  <p
                    class="inline-block text-sm px-3.5 font-medium text-[#8b8b8b] mb-1 border border-solid border-[8b8b8b] rounded-2xl"
                  >
                    외부교재
                  </p>
                  <p class="text-[#111111] font-medium">Tangram1_lektion 1-4</p>
                </li>
                <li>
                  <div class="relative mb-2.5 overflow-hidden rounded-xl">
                    <img src="@lifeeduWeb/assets/images/courseIntroduce/adult/etc/germany03.png" alt="img" />
                    <p class="absolute top-0 left-0 px-3 py-1 text-white bg-black/60">초급</p>
                    <p class="absolute bottom-0 w-full pb-3.5 text-center text-white custom-shadow">일반회화</p>
                  </div>
                  <p
                    class="inline-block text-sm px-3.5 font-medium text-[#8b8b8b] mb-1 border border-solid border-[8b8b8b] rounded-2xl"
                  >
                    외부교재
                  </p>
                  <p class="text-[#111111] font-medium">Tangram1_lektion 5-8</p>
                </li>
                <li>
                  <div class="relative mb-2.5 overflow-hidden rounded-xl">
                    <img src="@lifeeduWeb/assets/images/courseIntroduce/adult/etc/germany04.png" alt="img" />
                    <p class="absolute top-0 left-0 px-3 py-1 text-white bg-black/60">중급</p>
                    <p class="absolute bottom-0 w-full pb-3.5 text-center text-white custom-shadow">일반회화</p>
                  </div>
                  <p
                    class="inline-block text-sm px-3.5 font-medium text-[#8b8b8b] mb-1 border border-solid border-[8b8b8b] rounded-2xl"
                  >
                    외부교재
                  </p>
                  <p class="text-[#111111] font-medium">Tangram2_lektion 1-4</p>
                </li>
                <li>
                  <div class="relative mb-2.5 overflow-hidden rounded-xl">
                    <img src="@lifeeduWeb/assets/images/courseIntroduce/adult/etc/germany05.png" alt="img" />
                    <p class="absolute top-0 left-0 px-3 py-1 text-white bg-black/60">중급</p>
                    <p class="absolute bottom-0 w-full pb-3.5 text-center text-white custom-shadow">일반회화</p>
                  </div>
                  <p
                    class="inline-block text-sm px-3.5 font-medium text-[#8b8b8b] mb-1 border border-solid border-[8b8b8b] rounded-2xl"
                  >
                    외부교재
                  </p>
                  <p class="text-[#111111] font-medium">Tangram2_lektion 5-8</p>
                </li>
                <li>
                  <div class="relative mb-2.5 overflow-hidden rounded-xl">
                    <img src="@lifeeduWeb/assets/images/courseIntroduce/adult/etc/germany06.png" alt="img" />
                    <p class="absolute top-0 left-0 px-3 py-1 text-white bg-black/60">고급</p>
                    <p class="absolute bottom-0 w-full pb-3.5 text-center text-white custom-shadow">일반회화</p>
                  </div>
                  <p
                    class="inline-block text-sm px-3.5 font-medium text-[#8b8b8b] mb-1 border border-solid border-[8b8b8b] rounded-2xl"
                  >
                    외부교재
                  </p>
                  <p class="text-[#111111] font-medium">Tangram3_lektion 1-4</p>
                </li>
                <li>
                  <div class="relative mb-2.5 overflow-hidden rounded-xl">
                    <img src="@lifeeduWeb/assets/images/courseIntroduce/adult/etc/germany07.png" alt="img" />
                    <p class="absolute top-0 left-0 px-3 py-1 text-white bg-black/60">초급</p>
                    <p class="absolute bottom-0 w-full pb-3.5 text-center text-white custom-shadow">
                      Zertifikat Deutsch<br />독일어 능력시험
                    </p>
                  </div>
                  <p
                    class="inline-block text-sm px-3.5 font-medium text-[#8b8b8b] mb-1 border border-solid border-[8b8b8b] rounded-2xl"
                  >
                    외부교재
                  </p>
                  <p class="text-[#111111] font-medium">Zertifikat Deutsch 독일어 능력시험 A1</p>
                </li>
                <li>
                  <div class="relative mb-2.5 overflow-hidden rounded-xl">
                    <img src="@lifeeduWeb/assets/images/courseIntroduce/adult/etc/germany08.png" alt="img" />
                    <p class="absolute top-0 left-0 px-3 py-1 text-white bg-black/60">초급</p>
                    <p class="absolute bottom-0 w-full pb-3.5 text-center text-white custom-shadow">
                      Zertifikat Deutsch<br />독일어 능력시험
                    </p>
                  </div>
                  <p
                    class="inline-block text-sm px-3.5 font-medium text-[#8b8b8b] mb-1 border border-solid border-[8b8b8b] rounded-2xl"
                  >
                    외부교재
                  </p>
                  <p class="text-[#111111] font-medium">Zertifikat Deutsch 독일어 능력시험 A2</p>
                </li>
                <li>
                  <div class="relative mb-2.5 overflow-hidden rounded-xl">
                    <img src="@lifeeduWeb/assets/images/courseIntroduce/adult/etc/germany09.png" alt="img" />
                    <p class="absolute top-0 left-0 px-3 py-1 text-white bg-black/60">중급이상</p>
                    <p class="absolute bottom-0 w-full pb-3.5 text-center text-white custom-shadow">
                      Zertifikat Deutsch<br />독일어 능력시험
                    </p>
                  </div>
                  <p
                    class="inline-block text-sm px-3.5 font-medium text-[#8b8b8b] mb-1 border border-solid border-[8b8b8b] rounded-2xl"
                  >
                    외부교재
                  </p>
                  <p class="text-[#111111] font-medium">Zertifikat Deutsch 독일어 능력시험 B1</p>
                </li>
                <li>
                  <div class="relative mb-2.5 overflow-hidden rounded-xl">
                    <img src="@lifeeduWeb/assets/images/courseIntroduce/adult/etc/germany10.png" alt="img" />
                    <p class="absolute top-0 left-0 px-3 py-1 text-white bg-black/60">중급이상</p>
                    <p class="absolute bottom-0 w-full pb-3.5 text-center text-white custom-shadow">
                      Zertifikat Deutsch<br />독일어 능력시험
                    </p>
                  </div>
                  <p
                    class="inline-block text-sm px-3.5 font-medium text-[#8b8b8b] mb-1 border border-solid border-[8b8b8b] rounded-2xl"
                  >
                    외부교재
                  </p>
                  <p class="text-[#111111] font-medium">Zertifikat Deutsch 독일어 능력시험 B2</p>
                </li>
              </ul>
            </div>

            <!-- 러시아어 -->
            <div id="lang2" class="pb-10 mb-10 border-b border-solid border-[#ebebeb]">
              <div class="flex items-center gap-2 mb-5">
                <div class="w-5">
                  <img src="@lifeeduWeb/assets/images/courseIntroduce/adult/etc/russia.png" alt="img" />
                </div>
                <p class="text-2xl text-[#111111] font-bold">러시아어</p>
              </div>
              <ul class="grid grid-cols-2 gap-5 tablet:grid-cols-3 pc:grid-cols-4 pc:gap-10">
                <li>
                  <div class="relative mb-2.5 overflow-hidden rounded-xl">
                    <img src="@lifeeduWeb/assets/images/courseIntroduce/adult/etc/russia01.png" alt="img" />
                    <p class="absolute top-0 left-0 px-3 py-1 text-white bg-black/60">입문~초급</p>
                    <p class="absolute bottom-0 w-full pb-3.5 text-center text-white custom-shadow">입문초급회화</p>
                  </div>
                  <p
                    class="inline-block cursor-pointer text-sm px-3.5 font-medium text-[#fb6930] mb-1 border border-solid border-[fb6930] rounded-2xl"
                    @click="goSite('https://kr.object.ncloudstorage.com/mailpad/b2b_pdf/ETC02.pdf')"
                  >
                    미리보기
                  </p>
                  <p class="text-[#111111] font-medium">가장 쉬운 러시아어 첫걸음의 모든 것</p>
                </li>
                <li>
                  <div class="relative mb-2.5 overflow-hidden rounded-xl">
                    <img src="@lifeeduWeb/assets/images/courseIntroduce/adult/etc/russia02.png" alt="img" />
                    <p class="absolute top-0 left-0 px-3 py-1 text-white bg-black/60">초급</p>
                    <p class="absolute bottom-0 w-full pb-3.5 text-center text-white custom-shadow">일반회화</p>
                  </div>
                  <p
                    class="inline-block text-sm px-3.5 font-medium text-[#8b8b8b] mb-1 border border-solid border-[8b8b8b] rounded-2xl"
                  >
                    외부교재
                  </p>
                  <p class="text-[#111111] font-medium">러시아어 인텐시브 회화1</p>
                </li>
                <li>
                  <div class="relative mb-2.5 overflow-hidden rounded-xl">
                    <img src="@lifeeduWeb/assets/images/courseIntroduce/adult/etc/russia03.png" alt="img" />
                    <p class="absolute top-0 left-0 px-3 py-1 text-white bg-black/60">중급</p>
                    <p class="absolute bottom-0 w-full pb-3.5 text-center text-white custom-shadow">일반회화</p>
                  </div>
                  <p
                    class="inline-block text-sm px-3.5 font-medium text-[#8b8b8b] mb-1 border border-solid border-[8b8b8b] rounded-2xl"
                  >
                    외부교재
                  </p>
                  <p class="text-[#111111] font-medium">러시아어 인텐시브 회화2</p>
                </li>
                <li>
                  <div class="relative mb-2.5 overflow-hidden rounded-xl">
                    <img src="@lifeeduWeb/assets/images/courseIntroduce/adult/etc/russia04.png" alt="img" />
                    <p class="absolute top-0 left-0 px-3 py-1 text-white bg-black/60">중급</p>
                    <p class="absolute bottom-0 w-full pb-3.5 text-center text-white custom-shadow">일반회화</p>
                  </div>
                  <p
                    class="inline-block text-sm px-3.5 font-medium text-[#8b8b8b] mb-1 border border-solid border-[8b8b8b] rounded-2xl"
                  >
                    외부교재
                  </p>
                  <p class="text-[#111111] font-medium">러시아어 인텐시브 회화3</p>
                </li>
                <li>
                  <div class="relative mb-2.5 overflow-hidden rounded-xl">
                    <img src="@lifeeduWeb/assets/images/courseIntroduce/adult/etc/russia05.png" alt="img" />
                    <p class="absolute top-0 left-0 px-3 py-1 text-white bg-black/60">고급</p>
                    <p class="absolute bottom-0 w-full pb-3.5 text-center text-white custom-shadow">일반회화</p>
                  </div>
                  <p
                    class="inline-block text-sm px-3.5 font-medium text-[#8b8b8b] mb-1 border border-solid border-[8b8b8b] rounded-2xl"
                  >
                    외부교재
                  </p>
                  <p class="text-[#111111] font-medium">러시아어 인텐시브 회화4</p>
                </li>
              </ul>
            </div>

            <!-- 베트남어 -->
            <div id="lang3" class="pb-10 mb-10 border-b border-solid border-[#ebebeb]">
              <div class="flex items-center gap-2 mb-5">
                <div class="w-5">
                  <img src="@lifeeduWeb/assets/images/courseIntroduce/adult/etc/vietnam.png" alt="img" />
                </div>
                <p class="text-2xl text-[#111111] font-bold">베트남어</p>
              </div>
              <ul class="grid grid-cols-2 gap-5 tablet:grid-cols-3 pc:grid-cols-4 pc:gap-10">
                <li>
                  <div class="relative mb-2.5 overflow-hidden rounded-xl">
                    <img src="@lifeeduWeb/assets/images/courseIntroduce/adult/etc/vietnam01.png" alt="img" />
                    <p class="absolute top-0 left-0 px-3 py-1 text-white bg-black/60">입문~초급</p>
                    <p class="absolute bottom-0 w-full pb-3.5 text-center text-white custom-shadow">튼튼한 기초 베트남어</p>
                  </div>
                  <p
                    class="inline-block cursor-pointer text-sm px-3.5 font-medium text-[#fb6930] mb-1 border border-solid border-[fb6930] rounded-2xl"
                    @click="goSite('https://kr.object.ncloudstorage.com/mailpad/b2b_pdf/ETC03.pdf')"
                  >
                    미리보기
                  </p>
                  <p class="text-[#111111] font-medium">튼튼한 기초 베트남어</p>
                </li>
                <li>
                  <div class="relative mb-2.5 overflow-hidden rounded-xl">
                    <img src="@lifeeduWeb/assets/images/courseIntroduce/adult/etc/vietnam02.png" alt="img" />
                    <p class="absolute top-0 left-0 px-3 py-1 text-white bg-black/60">초급</p>
                    <p class="absolute bottom-0 w-full pb-3.5 text-center text-white custom-shadow">일반회화</p>
                  </div>
                  <p
                    class="inline-block cursor-pointer text-sm px-3.5 font-medium text-[#fb6930] mb-1 border border-solid border-[fb6930] rounded-2xl"
                    @click="goSite('https://kr.object.ncloudstorage.com/mailpad/b2b_pdf/ETC04.pdf')"
                  >
                    미리보기
                  </p>
                  <p class="text-[#111111] font-medium">버전업! 가장 쉬운 베트남어 첫걸음</p>
                </li>
                <li>
                  <div class="relative mb-2.5 overflow-hidden rounded-xl">
                    <img src="@lifeeduWeb/assets/images/courseIntroduce/adult/etc/vietnam03.png" alt="img" />
                    <p class="absolute top-0 left-0 px-3 py-1 text-white bg-black/60">중급</p>
                    <p class="absolute bottom-0 w-full pb-3.5 text-center text-white custom-shadow">일반회화</p>
                  </div>
                  <p
                    class="inline-block cursor-pointer text-sm px-3.5 font-medium text-[#fb6930] mb-1 border border-solid border-[fb6930] rounded-2xl"
                    @click="goSite('https://kr.object.ncloudstorage.com/mailpad/b2b_pdf/ETC05.pdf')"
                  >
                    미리보기
                  </p>
                  <p class="text-[#111111] font-medium">Vietnamese as a Second Language 1</p>
                </li>
                <li>
                  <div class="relative mb-2.5 overflow-hidden rounded-xl">
                    <img src="@lifeeduWeb/assets/images/courseIntroduce/adult/etc/vietnam04.png" alt="img" />
                    <p class="absolute top-0 left-0 px-3 py-1 text-white bg-black/60">중급</p>
                    <p class="absolute bottom-0 w-full pb-3.5 text-center text-white custom-shadow">일반회화</p>
                  </div>
                  <p
                    class="inline-block cursor-pointer text-sm px-3.5 font-medium text-[#fb6930] mb-1 border border-solid border-[fb6930] rounded-2xl"
                    @click="goSite('https://kr.object.ncloudstorage.com/mailpad/b2b_pdf/ETC06.pdf')"
                  >
                    미리보기
                  </p>
                  <p class="text-[#111111] font-medium">Vietnamese as a Second Language 2</p>
                </li>
                <li>
                  <div class="relative mb-2.5 overflow-hidden rounded-xl">
                    <img src="@lifeeduWeb/assets/images/courseIntroduce/adult/etc/vietnam05.png" alt="img" />
                    <p class="absolute top-0 left-0 px-3 py-1 text-white bg-black/60">고급</p>
                    <p class="absolute bottom-0 w-full pb-3.5 text-center text-white custom-shadow">OPI 시험대비</p>
                  </div>
                  <p
                    class="inline-block text-sm px-3.5 font-medium text-[#8b8b8b] mb-1 border border-solid border-[8b8b8b] rounded-2xl"
                  >
                    외부교재
                  </p>
                  <p class="text-[#111111] font-medium">한 번에 끝! OPI 베트남어</p>
                </li>
              </ul>
            </div>

            <!-- 스페인어 -->
            <div id="lang4" class="pb-10 mb-10 border-b border-solid border-[#ebebeb]">
              <div class="flex items-center gap-2 mb-5">
                <div class="w-5">
                  <img src="@lifeeduWeb/assets/images/courseIntroduce/adult/etc/spain.png" alt="img" />
                </div>
                <p class="text-2xl text-[#111111] font-bold">스페인어</p>
              </div>
              <ul class="grid grid-cols-2 gap-5 tablet:grid-cols-3 pc:grid-cols-4 pc:gap-10">
                <li>
                  <div class="relative mb-2.5 overflow-hidden rounded-xl">
                    <img src="@lifeeduWeb/assets/images/courseIntroduce/adult/etc/spain01.png" alt="img" />
                    <p class="absolute top-0 left-0 px-3 py-1 text-white bg-black/60">입문~중급</p>
                    <p class="absolute bottom-0 w-full pb-3.5 text-center text-white custom-shadow">입문초급회화</p>
                  </div>
                  <p
                    class="inline-block cursor-pointer text-sm px-3.5 font-medium text-[#fb6930] mb-1 border border-solid border-[fb6930] rounded-2xl"
                    @click="goSite('https://kr.object.ncloudstorage.com/mailpad/b2b_pdf/ETC07.pdf')"
                  >
                    미리보기
                  </p>
                  <p class="text-[#111111] font-medium">가장 쉬운 스페인어 첫걸음의 모든 것</p>
                </li>
                <li>
                  <div class="relative mb-2.5 overflow-hidden rounded-xl">
                    <img src="@lifeeduWeb/assets/images/courseIntroduce/adult/etc/spain02.png" alt="img" />
                    <p class="absolute top-0 left-0 px-3 py-1 text-white bg-black/60">입문~초급</p>
                    <p class="absolute bottom-0 w-full pb-3.5 text-center text-white custom-shadow">스페인어 첫걸음</p>
                  </div>
                  <p
                    class="inline-block text-sm px-3.5 font-medium text-[#8b8b8b] mb-1 border border-solid border-[8b8b8b] rounded-2xl"
                  >
                    외부교재
                  </p>
                  <p class="text-[#111111] font-medium">스페인어 첫걸음</p>
                </li>
                <li>
                  <div class="relative mb-2.5 overflow-hidden rounded-xl">
                    <img src="@lifeeduWeb/assets/images/courseIntroduce/adult/etc/spain03.png" alt="img" />
                    <p class="absolute top-0 left-0 px-3 py-1 text-white bg-black/60">입문~초급</p>
                    <p class="absolute bottom-0 w-full pb-3.5 text-center text-white custom-shadow">Espanol En Directo</p>
                  </div>
                  <p
                    class="inline-block text-sm px-3.5 font-medium text-[#8b8b8b] mb-1 border border-solid border-[8b8b8b] rounded-2xl"
                  >
                    외부교재
                  </p>
                  <p class="text-[#111111] font-medium">Espanol En Directo</p>
                </li>
                <li>
                  <div class="relative mb-2.5 overflow-hidden rounded-xl">
                    <img src="@lifeeduWeb/assets/images/courseIntroduce/adult/etc/spain04.png" alt="img" />
                    <p class="absolute top-0 left-0 px-3 py-1 text-white bg-black/60">초급</p>
                    <p class="absolute bottom-0 w-full pb-3.5 text-center text-white custom-shadow">일반회화</p>
                  </div>
                  <p
                    class="inline-block text-sm px-3.5 font-medium text-[#8b8b8b] mb-1 border border-solid border-[8b8b8b] rounded-2xl"
                  >
                    외부교재
                  </p>
                  <p class="text-[#111111] font-medium">Nuevo Espanol En Marcha 1</p>
                </li>
                <li>
                  <div class="relative mb-2.5 overflow-hidden rounded-xl">
                    <img src="@lifeeduWeb/assets/images/courseIntroduce/adult/etc/spain05.png" alt="img" />
                    <p class="absolute top-0 left-0 px-3 py-1 text-white bg-black/60">초중급</p>
                    <p class="absolute bottom-0 w-full pb-3.5 text-center text-white custom-shadow">일반회화</p>
                  </div>
                  <p
                    class="inline-block text-sm px-3.5 font-medium text-[#8b8b8b] mb-1 border border-solid border-[8b8b8b] rounded-2xl"
                  >
                    외부교재
                  </p>
                  <p class="text-[#111111] font-medium">Nuevo Espanol En Marcha 2</p>
                </li>
                <li>
                  <div class="relative mb-2.5 overflow-hidden rounded-xl">
                    <img src="@lifeeduWeb/assets/images/courseIntroduce/adult/etc/spain06.png" alt="img" />
                    <p class="absolute top-0 left-0 px-3 py-1 text-white bg-black/60">중급</p>
                    <p class="absolute bottom-0 w-full pb-3.5 text-center text-white custom-shadow">일반회화</p>
                  </div>
                  <p
                    class="inline-block text-sm px-3.5 font-medium text-[#8b8b8b] mb-1 border border-solid border-[8b8b8b] rounded-2xl"
                  >
                    외부교재
                  </p>
                  <p class="text-[#111111] font-medium">Nuevo Espanol En Marcha 3</p>
                </li>
                <li>
                  <div class="relative mb-2.5 overflow-hidden rounded-xl">
                    <img src="@lifeeduWeb/assets/images/courseIntroduce/adult/etc/spain07.png" alt="img" />
                    <p class="absolute top-0 left-0 px-3 py-1 text-white bg-black/60">중고급</p>
                    <p class="absolute bottom-0 w-full pb-3.5 text-center text-white custom-shadow">일반회화</p>
                  </div>
                  <p
                    class="inline-block text-sm px-3.5 font-medium text-[#8b8b8b] mb-1 border border-solid border-[8b8b8b] rounded-2xl"
                  >
                    외부교재
                  </p>
                  <p class="text-[#111111] font-medium">Nuevo Espanol En Marcha 4</p>
                </li>
                <li>
                  <div class="relative mb-2.5 overflow-hidden rounded-xl">
                    <img src="@lifeeduWeb/assets/images/courseIntroduce/adult/etc/spain08.png" alt="img" />
                    <p class="absolute top-0 left-0 px-3 py-1 text-white bg-black/60">중고급</p>
                    <p class="absolute bottom-0 w-full pb-3.5 text-center text-white custom-shadow">토론회화</p>
                  </div>
                  <p
                    class="inline-block cursor-pointer text-sm px-3.5 font-medium text-[#fb6930] mb-1 border border-solid border-[fb6930] rounded-2xl"
                    @click="goSite('https://kr.object.ncloudstorage.com/mailpad/b2b_pdf/ETC08.pdf')"
                  >
                    미리보기
                  </p>
                  <p class="text-[#111111] font-medium">Spanish topic discussion1</p>
                </li>
                <li>
                  <div class="relative mb-2.5 overflow-hidden rounded-xl">
                    <img src="@lifeeduWeb/assets/images/courseIntroduce/adult/etc/spain09.png" alt="img" />
                    <p class="absolute top-0 left-0 px-3 py-1 text-white bg-black/60">중고급</p>
                    <p class="absolute bottom-0 w-full pb-3.5 text-center text-white custom-shadow">토론회화</p>
                  </div>
                  <p
                    class="inline-block cursor-pointer text-sm px-3.5 font-medium text-[#fb6930] mb-1 border border-solid border-[fb6930] rounded-2xl"
                    @click="goSite('https://kr.object.ncloudstorage.com/mailpad/b2b_pdf/ETC09.pdf')"
                  >
                    미리보기
                  </p>
                  <p class="text-[#111111] font-medium">Spanish topic discussion2</p>
                </li>
                <li>
                  <div class="relative mb-2.5 overflow-hidden rounded-xl">
                    <img src="@lifeeduWeb/assets/images/courseIntroduce/adult/etc/spain10.png" alt="img" />
                    <p class="absolute top-0 left-0 px-3 py-1 text-white bg-black/60">중급</p>
                    <p class="absolute bottom-0 w-full pb-3.5 text-center text-white custom-shadow">DELE 시험대비</p>
                  </div>
                  <p
                    class="inline-block text-sm px-3.5 font-medium text-[#8b8b8b] mb-1 border border-solid border-[8b8b8b] rounded-2xl"
                  >
                    외부교재
                  </p>
                  <p class="text-[#111111] font-medium">Preparacion DELE B1</p>
                </li>
                <li>
                  <div class="relative mb-2.5 overflow-hidden rounded-xl">
                    <img src="@lifeeduWeb/assets/images/courseIntroduce/adult/etc/spain11.png" alt="img" />
                    <p class="absolute top-0 left-0 px-3 py-1 text-white bg-black/60">중고급</p>
                    <p class="absolute bottom-0 w-full pb-3.5 text-center text-white custom-shadow">DELE 시험대비</p>
                  </div>
                  <p
                    class="inline-block text-sm px-3.5 font-medium text-[#8b8b8b] mb-1 border border-solid border-[8b8b8b] rounded-2xl"
                  >
                    외부교재
                  </p>
                  <p class="text-[#111111] font-medium">Preparacion DELE B2</p>
                </li>
              </ul>
            </div>

            <!-- 아랍어 -->
            <div id="lang5" class="pb-10 mb-10 border-b border-solid border-[#ebebeb]">
              <div class="flex items-center gap-2 mb-5">
                <div class="w-5">
                  <img src="@lifeeduWeb/assets/images/courseIntroduce/adult/etc/arab.png" alt="img" />
                </div>
                <p class="text-2xl text-[#111111] font-bold">아랍어</p>
              </div>
              <ul class="grid grid-cols-2 gap-5 tablet:grid-cols-3 pc:grid-cols-4 pc:gap-10">
                <li>
                  <div class="relative mb-2.5 overflow-hidden rounded-xl">
                    <img src="@lifeeduWeb/assets/images/courseIntroduce/adult/etc/arab01.png" alt="img" />
                    <p class="absolute top-0 left-0 px-3 py-1 text-white bg-black/60">입문~중급</p>
                    <p class="absolute bottom-0 w-full pb-3.5 text-center text-white custom-shadow">입문초급회화</p>
                  </div>
                  <p
                    class="inline-block cursor-pointer text-sm px-3.5 font-medium text-[#fb6930] mb-1 border border-solid border-[fb6930] rounded-2xl"
                    @click="goSite('https://kr.object.ncloudstorage.com/mailpad/b2b_pdf/ETC10.pdf')"
                  >
                    미리보기
                  </p>
                  <p class="text-[#111111] font-medium">버전업! 가장 쉬운 아랍어 첫걸음</p>
                </li>
                <li>
                  <div class="relative mb-2.5 overflow-hidden rounded-xl">
                    <img src="@lifeeduWeb/assets/images/courseIntroduce/adult/etc/arab02.png" alt="img" />
                    <p class="absolute top-0 left-0 px-3 py-1 text-white bg-black/60">입문~중급</p>
                    <p class="absolute bottom-0 w-full pb-3.5 text-center text-white custom-shadow">일반회화</p>
                  </div>
                  <p
                    class="inline-block text-sm px-3.5 font-medium text-[#8b8b8b] mb-1 border border-solid border-[8b8b8b] rounded-2xl"
                  >
                    외부교재
                  </p>
                  <p class="text-[#111111] font-medium">종합 아랍어</p>
                </li>
              </ul>
            </div>

            <!-- 인도네시아어 -->
            <div id="lang6" class="pb-10 mb-10 border-b border-solid border-[#ebebeb]">
              <div class="flex items-center gap-2 mb-5">
                <div class="w-5">
                  <img src="@lifeeduWeb/assets/images/courseIntroduce/adult/etc/indonesia.png" alt="img" />
                </div>
                <p class="text-2xl text-[#111111] font-bold">인도네시아어</p>
              </div>
              <ul class="grid grid-cols-2 gap-5 tablet:grid-cols-3 pc:grid-cols-4 pc:gap-10">
                <li>
                  <div class="relative mb-2.5 overflow-hidden rounded-xl">
                    <img src="@lifeeduWeb/assets/images/courseIntroduce/adult/etc/indonesia01.png" alt="img" />
                    <p class="absolute top-0 left-0 px-3 py-1 text-white bg-black/60">입문~중급</p>
                    <p class="absolute bottom-0 w-full pb-3.5 text-center text-white custom-shadow">입문초급회화</p>
                  </div>
                  <p
                    class="inline-block cursor-pointer text-sm px-3.5 font-medium text-[#fb6930] mb-1 border border-solid border-[fb6930] rounded-2xl"
                    @click="goSite('https://kr.object.ncloudstorage.com/mailpad/b2b_pdf/Indonesia.pdf')"
                  >
                    미리보기
                  </p>
                  <p class="text-[#111111] font-medium">가장 쉬운 인도네시아어 첫걸음의 모든 것</p>
                </li>
                <li>
                  <div class="relative mb-2.5 overflow-hidden rounded-xl">
                    <img src="@lifeeduWeb/assets/images/courseIntroduce/adult/etc/indonesia02.png" alt="img" />
                    <p class="absolute top-0 left-0 px-3 py-1 text-white bg-black/60">입문~초급</p>
                    <p class="absolute bottom-0 w-full pb-3.5 text-center text-white custom-shadow">일반회화</p>
                  </div>
                  <p
                    class="inline-block text-sm px-3.5 font-medium text-[#8b8b8b] mb-1 border border-solid border-[8b8b8b] rounded-2xl"
                  >
                    외부교재
                  </p>
                  <p class="text-[#111111] font-medium">THE 바른 인도네시아어 STEP1</p>
                </li>
                <li>
                  <div class="relative mb-2.5 overflow-hidden rounded-xl">
                    <img src="@lifeeduWeb/assets/images/courseIntroduce/adult/etc/indonesia03.png" alt="img" />
                    <p class="absolute top-0 left-0 px-3 py-1 text-white bg-black/60">초중급~중급</p>
                    <p class="absolute bottom-0 w-full pb-3.5 text-center text-white custom-shadow">일반회화</p>
                  </div>
                  <p
                    class="inline-block text-sm px-3.5 font-medium text-[#8b8b8b] mb-1 border border-solid border-[8b8b8b] rounded-2xl"
                  >
                    외부교재
                  </p>
                  <p class="text-[#111111] font-medium">THE 바른 인도네시아어 STEP2</p>
                </li>
                <li>
                  <div class="relative mb-2.5 overflow-hidden rounded-xl">
                    <img src="@lifeeduWeb/assets/images/courseIntroduce/adult/etc/indonesia04.png" alt="img" />
                    <p class="absolute top-0 left-0 px-3 py-1 text-white bg-black/60">중급</p>
                    <p class="absolute bottom-0 w-full pb-3.5 text-center text-white custom-shadow">일반회화</p>
                  </div>
                  <p
                    class="inline-block text-sm px-3.5 font-medium text-[#8b8b8b] mb-1 border border-solid border-[8b8b8b] rounded-2xl"
                  >
                    외부교재
                  </p>
                  <p class="text-[#111111] font-medium">인도네시아어 대화 읽기 중급</p>
                </li>
                <li>
                  <div class="relative mb-2.5 overflow-hidden rounded-xl">
                    <img src="@lifeeduWeb/assets/images/courseIntroduce/adult/etc/indonesia05.png" alt="img" />
                    <p class="absolute top-0 left-0 px-3 py-1 text-white bg-black/60">중급~고급</p>
                    <p class="absolute bottom-0 w-full pb-3.5 text-center text-white custom-shadow">Biz. Speaking</p>
                  </div>
                  <p
                    class="inline-block text-sm px-3.5 font-medium text-[#8b8b8b] mb-1 border border-solid border-[8b8b8b] rounded-2xl"
                  >
                    외부교재
                  </p>
                  <p class="text-[#111111] font-medium">비즈니스 인도네시아어 한국어</p>
                </li>
              </ul>
            </div>

            <!-- 태국어 -->
            <div id="lang7" class="pb-10 mb-10 border-b border-solid border-[#ebebeb]">
              <div class="flex items-center gap-2 mb-5">
                <div class="w-5">
                  <img src="@lifeeduWeb/assets/images/courseIntroduce/adult/etc/thailand.png" alt="img" />
                </div>
                <p class="text-2xl text-[#111111] font-bold">태국어</p>
              </div>
              <ul class="grid grid-cols-2 gap-5 tablet:grid-cols-3 pc:grid-cols-4 pc:gap-10">
                <li>
                  <div class="relative mb-2.5 overflow-hidden rounded-xl">
                    <img src="@lifeeduWeb/assets/images/courseIntroduce/adult/etc/thailand01.png" alt="img" />
                    <p class="absolute top-0 left-0 px-3 py-1 text-white bg-black/60">입문~중급</p>
                    <p class="absolute bottom-0 w-full pb-3.5 text-center text-white custom-shadow">입문초급회화</p>
                  </div>
                  <p
                    class="inline-block cursor-pointer text-sm px-3.5 font-medium text-[#fb6930] mb-1 border border-solid border-[fb6930] rounded-2xl"
                    @click="goSite('https://kr.object.ncloudstorage.com/mailpad/b2b_pdf/ETC11.pdf')"
                  >
                    미리보기
                  </p>
                  <p class="text-[#111111] font-medium">버전업! 가장 쉬운 태국어 첫걸음</p>
                </li>
                <li>
                  <div class="relative mb-2.5 overflow-hidden rounded-xl">
                    <img src="@lifeeduWeb/assets/images/courseIntroduce/adult/etc/thailand02.png" alt="img" />
                    <p class="absolute top-0 left-0 px-3 py-1 text-white bg-black/60">중~고급</p>
                    <p class="absolute bottom-0 w-full pb-3.5 text-center text-white custom-shadow">심화</p>
                  </div>
                  <p
                    class="inline-block cursor-pointer text-sm px-3.5 font-medium text-[#fb6930] mb-1 border border-solid border-[fb6930] rounded-2xl"
                    @click="goSite('https://kr.object.ncloudstorage.com/mailpad/b2b_pdf/ETC12.pdf')"
                  >
                    미리보기
                  </p>
                  <p class="text-[#111111] font-medium">첫걸음 끝내고 보는 태국어 중고급의 모든 것</p>
                </li>
              </ul>
            </div>

            <!-- 포르투갈어 -->
            <div id="lang8" class="pb-10 mb-10 border-b border-solid border-[#ebebeb]">
              <div class="flex items-center gap-2 mb-5">
                <div class="w-5">
                  <img src="@lifeeduWeb/assets/images/courseIntroduce/adult/etc/portugal.png" alt="img" />
                </div>
                <p class="text-2xl text-[#111111] font-bold">포르투갈어</p>
              </div>
              <ul class="grid grid-cols-2 gap-5 tablet:grid-cols-3 pc:grid-cols-4 pc:gap-10">
                <li>
                  <div class="relative mb-2.5 overflow-hidden rounded-xl">
                    <img src="@lifeeduWeb/assets/images/courseIntroduce/adult/etc/portugal01.png" alt="img" />
                    <p class="absolute top-0 left-0 px-3 py-1 text-white bg-black/60">입문~초급</p>
                    <p class="absolute bottom-0 w-full pb-3.5 text-center text-white custom-shadow">입문초급회화</p>
                  </div>
                  <p
                    class="inline-block cursor-pointer text-sm px-3.5 font-medium text-[#fb6930] mb-1 border border-solid border-[fb6930] rounded-2xl"
                    @click="goSite('https://kr.object.ncloudstorage.com/mailpad/b2b_pdf/ETC13.pdf')"
                  >
                    미리보기
                  </p>
                  <p class="text-[#111111] font-medium">가장 쉬운 포르투갈어 첫걸음의 모든 것</p>
                </li>
              </ul>
            </div>

            <!-- 프랑스어 -->
            <div id="lang9" class="pb-10 mb-10 border-b border-solid border-[#ebebeb]">
              <div class="flex items-center gap-2 mb-5">
                <div class="w-5">
                  <img src="@lifeeduWeb/assets/images/courseIntroduce/adult/etc/france.png" alt="img" />
                </div>
                <p class="text-2xl text-[#111111] font-bold">프랑스어</p>
              </div>
              <ul class="grid grid-cols-2 gap-5 tablet:grid-cols-3 pc:grid-cols-4 pc:gap-10">
                <li>
                  <div class="relative mb-2.5 overflow-hidden rounded-xl">
                    <img src="@lifeeduWeb/assets/images/courseIntroduce/adult/etc/france01.png" alt="img" />
                    <p class="absolute top-0 left-0 px-3 py-1 text-white bg-black/60">입문~초급</p>
                    <p class="absolute bottom-0 w-full pb-3.5 text-center text-white custom-shadow">입문초급회화</p>
                  </div>
                  <p
                    class="inline-block cursor-pointer text-sm px-3.5 font-medium text-[#fb6930] mb-1 border border-solid border-[fb6930] rounded-2xl"
                    @click="goSite('https://kr.object.ncloudstorage.com/mailpad/b2b_pdf/ETC13.pdf')"
                  >
                    미리보기
                  </p>
                  <p class="text-[#111111] font-medium">가장 쉬운 프랑스어 첫걸음의 모든것(Basic French)</p>
                </li>
                <li>
                  <div class="relative mb-2.5 overflow-hidden rounded-xl">
                    <img src="@lifeeduWeb/assets/images/courseIntroduce/adult/etc/france02.png" alt="img" />
                    <p class="absolute top-0 left-0 px-3 py-1 text-white bg-black/60">초급</p>
                    <p class="absolute bottom-0 w-full pb-3.5 text-center text-white custom-shadow">일반회화</p>
                  </div>
                  <p
                    class="inline-block text-sm px-3.5 font-medium text-[#8b8b8b] mb-1 border border-solid border-[8b8b8b] rounded-2xl"
                  >
                    외부교재
                  </p>
                  <p class="text-[#111111] font-medium">Festival1</p>
                </li>
                <li>
                  <div class="relative mb-2.5 overflow-hidden rounded-xl">
                    <img src="@lifeeduWeb/assets/images/courseIntroduce/adult/etc/france03.png" alt="img" />
                    <p class="absolute top-0 left-0 px-3 py-1 text-white bg-black/60">초급</p>
                    <p class="absolute bottom-0 w-full pb-3.5 text-center text-white custom-shadow">일반회화</p>
                  </div>
                  <p
                    class="inline-block text-sm px-3.5 font-medium text-[#8b8b8b] mb-1 border border-solid border-[8b8b8b] rounded-2xl"
                  >
                    외부교재
                  </p>
                  <p class="text-[#111111] font-medium">Festival2</p>
                </li>
                <li>
                  <div class="relative mb-2.5 overflow-hidden rounded-xl">
                    <img src="@lifeeduWeb/assets/images/courseIntroduce/adult/etc/france04.png" alt="img" />
                    <p class="absolute top-0 left-0 px-3 py-1 text-white bg-black/60">고급</p>
                    <p class="absolute bottom-0 w-full pb-3.5 text-center text-white custom-shadow">일반회화</p>
                  </div>
                  <p
                    class="inline-block text-sm px-3.5 font-medium text-[#8b8b8b] mb-1 border border-solid border-[8b8b8b] rounded-2xl"
                  >
                    외부교재
                  </p>
                  <p class="text-[#111111] font-medium">Festival3</p>
                </li>
                <li>
                  <div class="relative mb-2.5 overflow-hidden rounded-xl">
                    <img src="@lifeeduWeb/assets/images/courseIntroduce/adult/etc/france05.png" alt="img" />
                    <p class="absolute top-0 left-0 px-3 py-1 text-white bg-black/60">초급</p>
                    <p class="absolute bottom-0 w-full pb-3.5 text-center text-white custom-shadow">DELF 시험대비</p>
                  </div>
                  <p
                    class="inline-block text-sm px-3.5 font-medium text-[#8b8b8b] mb-1 border border-solid border-[8b8b8b] rounded-2xl"
                  >
                    외부교재
                  </p>
                  <p class="text-[#111111] font-medium">DELF A1</p>
                </li>
                <li>
                  <div class="relative mb-2.5 overflow-hidden rounded-xl">
                    <img src="@lifeeduWeb/assets/images/courseIntroduce/adult/etc/france06.png" alt="img" />
                    <p class="absolute top-0 left-0 px-3 py-1 text-white bg-black/60">초급</p>
                    <p class="absolute bottom-0 w-full pb-3.5 text-center text-white custom-shadow">DELF 시험대비</p>
                  </div>
                  <p
                    class="inline-block text-sm px-3.5 font-medium text-[#8b8b8b] mb-1 border border-solid border-[8b8b8b] rounded-2xl"
                  >
                    외부교재
                  </p>
                  <p class="text-[#111111] font-medium">DELF B1</p>
                </li>
              </ul>
            </div>

            <!-- 이탈리아어 -->
            <div id="lang10" class="pb-10 mb-10 border-b border-solid border-[#ebebeb]">
              <div class="flex items-center gap-2 mb-5">
                <div class="w-5">
                  <img src="@lifeeduWeb/assets/images/courseIntroduce/adult/etc/italy.png" alt="img" />
                </div>
                <p class="text-2xl text-[#111111] font-bold">이탈리아어</p>
              </div>
              <ul class="grid grid-cols-2 gap-5 tablet:grid-cols-3 pc:grid-cols-4 pc:gap-10">
                <li>
                  <div class="relative mb-2.5 overflow-hidden rounded-xl">
                    <img src="@lifeeduWeb/assets/images/courseIntroduce/adult/etc/italy01.png" alt="img" />
                    <p class="absolute top-0 left-0 px-3 py-1 text-white bg-black/60">입문~초급</p>
                    <p class="absolute bottom-0 w-full pb-3.5 text-center text-white custom-shadow">입문</p>
                  </div>
                  <p
                    class="inline-block cursor-pointer text-sm px-3.5 font-medium text-[#fb6930] mb-1 border border-solid border-[fb6930] rounded-2xl"
                    @click="goSite('https://kr.object.ncloudstorage.com/mailpad/b2b_pdf/ETC15.pdf')"
                  >
                    미리보기
                  </p>
                  <p class="text-[#111111] font-medium">가장 쉬운 이탈리아어 첫걸음의 모든 것</p>
                </li>
              </ul>
            </div>

            <!-- 한국어 -->
            <div id="lang11">
              <div class="flex items-center gap-2 mb-5">
                <div class="w-5">
                  <img src="@lifeeduWeb/assets/images/courseIntroduce/adult/etc/korea.png" alt="img" />
                </div>
                <p class="text-2xl text-[#111111] font-bold">한국어</p>
              </div>
              <ul class="grid grid-cols-2 gap-5 tablet:grid-cols-3 pc:grid-cols-4 pc:gap-10">
                <li>
                  <div class="relative mb-2.5 overflow-hidden rounded-xl">
                    <img src="@lifeeduWeb/assets/images/courseIntroduce/adult/etc/korea01.png" alt="img" />
                    <p class="absolute top-0 left-0 px-3 py-1 text-white bg-black/60">입문~초급</p>
                    <p class="absolute bottom-0 w-full pb-3.5 text-center text-white custom-shadow">일반회화</p>
                  </div>
                  <p
                    class="inline-block text-sm px-3.5 font-medium text-[#8b8b8b] mb-1 border border-solid border-[8b8b8b] rounded-2xl"
                  >
                    외부교재
                  </p>
                  <p class="text-[#111111] font-medium">한국어 일상회화1</p>
                </li>
                <li>
                  <div class="relative mb-2.5 overflow-hidden rounded-xl">
                    <img src="@lifeeduWeb/assets/images/courseIntroduce/adult/etc/korea02.png" alt="img" />
                    <p class="absolute top-0 left-0 px-3 py-1 text-white bg-black/60">초급~초중급</p>
                    <p class="absolute bottom-0 w-full pb-3.5 text-center text-white custom-shadow">일반회화</p>
                  </div>
                  <p
                    class="inline-block text-sm px-3.5 font-medium text-[#8b8b8b] mb-1 border border-solid border-[8b8b8b] rounded-2xl"
                  >
                    외부교재
                  </p>
                  <p class="text-[#111111] font-medium">한국어 일상회화2</p>
                </li>
                <li>
                  <div class="relative mb-2.5 overflow-hidden rounded-xl">
                    <img src="@lifeeduWeb/assets/images/courseIntroduce/adult/etc/korea03.png" alt="img" />
                    <p class="absolute top-0 left-0 px-3 py-1 text-white bg-black/60">초중급~중급</p>
                    <p class="absolute bottom-0 w-full pb-3.5 text-center text-white custom-shadow">일반회화</p>
                  </div>
                  <p
                    class="inline-block text-sm px-3.5 font-medium text-[#8b8b8b] mb-1 border border-solid border-[8b8b8b] rounded-2xl"
                  >
                    외부교재
                  </p>
                  <p class="text-[#111111] font-medium">한국어 일상회화3</p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import { ref, reactive, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import axios from "@lifeeduWeb/plugins/axios.js";
import { useStore } from "vuex";

export default {
  components: {},

  setup() {
    const router = useRouter();
    const route = useRoute();
    const store = useStore();

    const goPage = (url) => {
      location.href = url;
    };

    const goSite = (url) => {
      window.open(url, "_blank");
    };

    onMounted(() => {
      const showListButton = document.querySelector(".show-list");
      const arrowIcon = showListButton.querySelector(".show-arrow");
      const listToToggle = document.querySelector(".lang-list");
      const countryListItems = document.querySelectorAll(".country-list li a");

      showListButton.addEventListener("click", function () {
        if (listToToggle.style.display === "none") {
          listToToggle.style.display = "flex";
        } else {
          listToToggle.style.display = "none";
        }

        arrowIcon.classList.toggle("rotate-180");
      });

      if (window.innerWidth < 1200) {
        countryListItems.forEach(function (item) {
          item.addEventListener("click", function () {
            listToToggle.style.display = "none";
            arrowIcon.classList.remove("rotate-180");
          });
        });
      }
    });

    return {
      goPage,
      goSite,
    };
  },
};
</script>
<style lang="scss" scoped>
.bg1 {
  background: url(@lifeeduWeb/assets/images/courseIntroduce/adult/etc/bg.png) no-repeat center / 1920px;
}

.custom-shadow {
  background: linear-gradient(to bottom, rgba(20, 20, 20, 0.05) 0%, rgba(20, 20, 20, 1) 100%);
}
</style>
