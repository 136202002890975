<template>
    <div class="p-4 editor-wrapper">
        <div class="p-2 mb-5">
            <!-- 수정일 땐 공지사항 수정 으로 변경  -->
            <p class="text-xl font-semibold">공지사항 등록</p>
        </div>
        <div class="mb-5">
            <input
                v-model="noticePage.noticeList.title"
                type="text"
                maxlength="50"
                placeholder="제목을 입력하세요."
                class="w-full max-w-md border border-black border-solid rounded"
            />
        </div>
        <BagicEditor v-model="noticePage.noticeList.content"></BagicEditor>
        <div>
            <div class="flex items-center justify-end mb-4">
                <ul class="flex gap-4">
                    <li @click="noticePage.goBack">
                        <p
                            class="p-2 text-white bg-red-700 border border-solid rounded cursor-pointer"
                        >
                            돌아가기
                        </p>
                    </li>
                    <li
                        v-if="!noticePage.noticeUpdate"
                        @click="noticePage.registNotice"
                    >
                        <p
                            class="p-2 text-white bg-blue-700 border border-solid rounded cursor-pointer"
                        >
                            등록
                        </p>
                    </li>
                    <li
                        v-if="noticePage.noticeUpdate"
                        @click="noticePage.registNotice"
                    >
                        <p
                            class="p-2 text-white bg-blue-700 border border-solid rounded cursor-pointer"
                        >
                            수정
                        </p>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>
<script>
import { useRoute } from "vue-router";
import BagicEditor from "@/lifeeduAdmin/components/BagicEditor.vue";
import { reactive, onMounted } from "vue";
import axios from "@lifeeduAdmin/plugins/axios.js";

export default {
    components: {
        BagicEditor,
    },
    setup() {
        const route = useRoute();

        const noticePage = reactive({
            noticeList: {
                content: "",
                idxLeSiteMain: "",
                idxNotice: "",
                title: "",
                viewCount: 0,
            },

            noticeUpdate: false,

            // 공지사항 조회
            getRegistNotice() {
                noticePage.noticeList.idxNotice = route.query.idxNotice;
                if (
                    noticePage.noticeList.idxNotice &&
                    noticePage.noticeList.idxNotice !== "0"
                ) {
                    const params = {
                        idxNotice: noticePage.noticeList.idxNotice,
                    };
                    axios.get(`/admin/notice`, { params }).then(function (res) {
                        if (res.data.success) {
                            noticePage.noticeList = res.data.data;
                            noticePage.noticeUpdate = true;
                        } else {
                            // 처리 실패 시의 동작
                        }
                    });
                }
            },

            // 공지사항 등록
            registNotice() {
                noticePage.noticeList.idxLeSiteMain = route.query.idxLeSiteMain;
                noticePage.noticeList.idxNotice = route.query.idxNotice;
                let params = noticePage.noticeList;
                axios.post("/admin/notice", params, {}).then(function (res) {
                    if (res.data.success) {
                        if (noticePage.noticeUpdate) {
                            alert("수정되었습니다.");
                            window.history.back();
                        } else if (noticePage.noticeList.idxNotice !== "0") {
                            alert("등록되었습니다.");
                            window.history.back();
                        }
                    } else {
                        //alert(res.data.message);
                    }
                    // listPage.is_loader = false;
                });
            },

            goBack() {
                window.history.back();
            },
        });

        onMounted(() => {
            noticePage.getRegistNotice();
        });

        return {
            noticePage,
        };
    },
};
</script>
<style scoped>
.editor-wrapper >>> .tox {
    height: 1000px !important;
}
</style>
