<template>
    <div class="p-4">
        <div class="p-2 mb-5">
            <p class="text-xl font-semibold">공지사항 관리</p>
        </div>
        <div>
            <div class="flex items-center justify-end mb-5">
                <ul class="flex gap-4">
                    <li>
                        <p
                            class="p-2 text-white bg-blue-700 border border-solid rounded cursor-pointer"
                            @click="
                                notiListPage.registNoti(
                                    notiListPage.searchParams.idxLeSiteMain
                                )
                            "
                        >
                            등록
                        </p>
                    </li>

                    <li @click="notiListPage.deleteNotice">
                        <p
                            class="p-2 text-white bg-red-700 border border-solid rounded cursor-pointer"
                        >
                            삭제
                        </p>
                    </li>
                </ul>
            </div>
            <BagicTable
                :header="notiListPage.tableHeader"
                :list="notiListPage.noticeList"
                :pageSize="notiListPage.searchParams.pageSize"
                :totalRows="notiListPage.totalCnt"
                :enableCheckBox="true"
                :checkParamName="notiListPage.checkParamName"
                @paging-click="notiListPage.movePage"
                @check-click="notiListPage.checkBoxClick"
            >
            </BagicTable>
        </div>
    </div>
</template>
<script>
import { onMounted, reactive, watch } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import axios from "@lifeeduAdmin/plugins/axios.js";
import BagicTable from "@/lifeeduAdmin/components/BagicTable.vue";

export default {
    components: {
        BagicTable,
    },
    setup() {
        const router = useRouter();
        const route = useRoute();

        const notiListPage = reactive({
            searchParams: {
                content: "",
                createDate: "",
                idxLeSiteMain: "",
                rowNum: "",
                staffName: "",
                title: "",
                viewCount: "",
                pageNum: 1,
                pageSize: 50,
            },
            tableHeader: {
                rowNum: "No",
                createDate: "작성일자",
                title: "공지사항 제목",
                staffName: "작성자",
                viewCount: "조회수",
            },
            noticeList: [],
            totalCnt: 0,

            selectedCheckboxes: [],

            checkParamName: "rowNum",

            // 리스트 조회 및 검색
            getNoticeList() {
                notiListPage.searchParams.idxLeSiteMain = parseInt(
                    route.query.idxLeSiteMain
                );
                axios
                    .get("/admin/noticeList", {
                        params: notiListPage.searchParams,
                    })
                    .then(function (res) {
                        if (res.data.success) {
                            notiListPage.totalCnt = res.data.data.totalCnt;
                            notiListPage.noticeList = res.data.data.noticeList;
                            if (Array.isArray(notiListPage.noticeList)) {
                                notiListPage.noticeList.forEach(function (
                                    item
                                ) {
                                    // company
                                    item.title = `<u><a href="/noticeCreate?idxNotice=${item.idxNotice}">${item.title}</a></u>`;
                                });
                            }
                        } else {
                            //alert(res.data.message);
                        }
                        // notiListPage.is_loader = false;
                    });
            },

            // deleteNotice() {
            //     const idxNotice = notiListPage.noticeList[0].idxNotice;

            //     if (confirm("공지사항을 삭제하시겠습니까?")) {
            //         axios
            //             .delete(`/admin/notice?idxNotice=${idxNotice}`, {})
            //             .then((res) => {
            //                 if (res.data.success) {
            //                     notiListPage.getNoticeList();
            //                 }
            //             });
            //     } else {
            //         // 취소 얼럿
            //     }
            // },

            checkBoxClick(selectedCheckboxes) {
                // 선택된 체크박스 정보를 상태에 저장
                notiListPage.selectedCheckboxes = selectedCheckboxes;
            },

            deleteNotice() {
                const selectedNotices = notiListPage.noticeList.filter(
                    (notice) =>
                        notiListPage.selectedCheckboxes.includes(
                            notice[notiListPage.checkParamName]
                        )
                );

                if (selectedNotices.length === 0) {
                    alert("선택된 항목이 없습니다.");
                    return;
                }

                if (confirm("선택한 공지사항을 삭제하시겠습니까?")) {
                    const idxNoticeList = selectedNotices.map(
                        (notice) => notice.idxNotice
                    );
                    axios
                        .delete(
                            `/admin/notice?idxNotice=${idxNoticeList.join(
                                ","
                            )}`,
                            {}
                        )
                        .then((res) => {
                            if (res.data.success) {
                                notiListPage.getNoticeList();
                            }
                        });
                } else {
                    // 취소 얼럿
                }
            },

            movePage(value) {
                notiListPage.searchParams.pageNum = value;
                notiListPage.getNoticeList();
            },

            registNoti(value) {
                let params = { idxLeSiteMain: value };
                router.push({ path: "/noticeCreate", query: params });
            },
        });

        onMounted(() => {
            notiListPage.searchParams.idxLeSiteMain = parseInt(
                route.query.idxLeSiteMain
            );
            if (
                "idxLeSiteMain" in route.query &&
                route.query.idxLeSiteMain > 0
            ) {
                notiListPage.getNoticeList();
            } else {
                alert("잘못된 접근입니다.");
            }
        });

        return {
            notiListPage,
        };
    },
};
</script>
<style></style>
