<template>
    <div class="flex items-center w-64 gap-3">
        <vue-tailwind-datepicker
            i18n="ko"
            v-model="datePicker.dateValue"
            :formatter="formatter"
            :placeholder="datePicker.placeholder"
            input-classes="cursor-pointer "
            readonly
            overlay
            use-range
            separator=" ~ "
            class="rounded"
        >
        </vue-tailwind-datepicker>
    </div>
</template>
<script>
import { watch, reactive, onMounted, ref } from "vue";

export default {
    name: "BagicDatePickerRange",
    components: {},
    props: ["eduStartDate", "eduEndDate"],
    setup(props, { emit }) {
        const formatter = {
            date: "YYYY-MM-DD",
            month: "MM",
        };

        const datePicker = reactive({
            dateValue: ref([]),
            placeholder: "기간을 선택해주세요",

            setPlaceholderDate(eduStartDate, eduEndDate) {
                if (eduStartDate != "" && eduEndDate != "") {
                    datePicker.placeholder = eduStartDate + " ~ " + eduEndDate;
                } else {
                    datePicker.placeholder = "기간을 선택해주세요";
                }
            },
        });
        onMounted(() => {
            //placeholder 를 통해 초기값을 설정한다.
            datePicker.setPlaceholderDate(props.eduStartDate, props.eduEndDate);
        });
        watch(
            () => datePicker.dateValue,
            (nVal, oVal) => {
                if (nVal.length > 1) {
                    // 날짜값이 선택되었으므로 커스텀 이벤트를 발생시킨다.
                    emit("date-click", datePicker.dateValue);
                } else {
                }
            }
        );
        watch(
            () => props.eduStartDate,
            (nVal, oVal) => {
                //props.eduStartDate 값이 바뀌었다면 placeholder 값을 변경한다.
                console.log(props);
                datePicker.setPlaceholderDate(
                    props.eduStartDate,
                    props.eduEndDate
                );
            }
        );
        watch(
            () => props.eduEndDate,
            (nVal, oVal) => {
                //props.eduEndDate 값이 바뀌었다면 placeholder 값을 변경한다.
                console.log(props);
                datePicker.setPlaceholderDate(
                    props.eduStartDate,
                    props.eduEndDate
                );
            }
        );

        return {
            datePicker,
            formatter,
        };
    },
};
</script>
