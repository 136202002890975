<template>
  <div class="p-4">
    <div class="p-2 mb-5">
      <p class="mb-2 text-xl font-semibold">[{{ listPage.sugang.kname }} {{ listPage.sugang.sugangName }}] 취소자 리스트</p>
      <p class="text-lg">
        수강신청기간 : {{ listPage.sugang.startDate }} ~ {{ listPage.sugang.endDate }} | 진행상태 :
        {{ listPage.sugang.status }}
      </p>
    </div>
    <div>
      <div class="flex justify-end mb-4">
        <ul class="flex gap-4">
          <li>
            <p class="p-2 text-white bg-red-700 border border-solid rounded cursor-pointer" @click="listPage.delContents">삭제</p>
          </li>
          <li>
            <p class="p-2 text-white bg-green-700 border border-solid rounded cursor-pointer" @click="listPage.excelDownload">
              Excel 다운로드
            </p>
          </li>
        </ul>
      </div>
      <BagicTable
        :header="listPage.tableHeader"
        :list="listPage.sugangMemberList"
        :pageSize="listPage.searchParams.pageSize"
        :totalRows="listPage.totalCnt"
        :enableCheckBox="true"
        :checkParamName="listPage.checkParamName"
        @paging-click="listPage.movePage"
        :detailRowName="listPage.detailRowName"
        :detailParamName="listPage.detailParamName"
        @check-click="listPage.checkBoxClick"
      >
      </BagicTable>
    </div>
  </div>
</template>
<script>
import { onMounted, reactive } from "vue";
import { useRoute } from "vue-router";
import axios from "@lifeeduAdmin/plugins/axios.js";
import BagicTable from "@/lifeeduAdmin/components/BagicTable.vue";
export default {
  components: {
    BagicTable,
  },
  setup() {
    const route = useRoute();

    const listPage = reactive({
      searchParams: {
        idxLeSugang: "",
        isNewSugang: "",
        pageNum: 1,
        pageSize: 50,
        searchKeyword: "",
        searchValue: "0",
        status: "C",
        waitStatus: "",
      },
      tableHeader: {
        rowNum: "No",
        createDate: "신청일",
        isNewSugang: "신청구분",
        sugangSupType: "대상자구분",
        waitStatus: "상태",
        memberName: "이름",
        memberEmail: "이메일",
        memberMobile: "핸드폰번호",
        sugangLecType: "스케줄",
        sugangLang: "언어",
        sugangType: "수업유형",
        sugangName: "과정",
        sugangLevel: "레벨",
        learnTime: "수업기간/횟수",
        sugangHopeTime1: "희망시간1",
        sugangHopeTime2: "희망시간2",
        // classTeacher: "강사",
        status: "결제여부",
        updateDate: "수정/취소일",
      },

      sugang: {
        endDate: "",
        kname: "",
        startDate: "",
        status: "",
        sugangName: "",
      },

      sugangMemberList: [],

      selectedCheckboxes: [],

      checkParamName: "rowNum",

      totalCnt: 0,

      // 리스트 조회 및 검색
      getRegisterList() {
        listPage.searchParams.idxLeSugang = route.query.idxLeSugang;
        axios
          .get("/admin/registerList", {
            params: listPage.searchParams,
          })
          .then(function (res) {
            if (res.data.success) {
              listPage.totalCnt = res.data.data.totalCnt;
              listPage.sugang = res.data.data.sugang;
              listPage.sugangMemberList = res.data.data.sugangMemberList;
              if (Array.isArray(listPage.sugangMemberList)) {
                listPage.sugangMemberList.forEach(function (item) {
                  // company
                  item.name = `<u><a href="/enrolmentStep1?idxLeSiteMain=${item.idxLeSiteMain}">${item.name}</a></u>`;
                  item.email = `<u><a href="/enrolmentStep1?idxLeSiteMain=${item.idxLeSiteMain}">${item.email}</a></u>`;
                });
              }
            } else {
              //alert(res.data.message);
            }
            // listPage.is_loader = false;
          });
      },

      checkBoxClick(selectedCheckboxes) {
        // 선택된 체크박스 정보를 상태에 저장
        listPage.selectedCheckboxes = selectedCheckboxes;
      },

      delContents() {
        const idxLeSugang = listPage.searchParams.idxLeSugang;
        const selectedIdxLeSugangPayInfoList = listPage.selectedCheckboxes.map((checkboxId) => {
          const selectedItem = listPage.sugangMemberList.find((item) => item[listPage.checkParamName] === checkboxId);
          return selectedItem ? selectedItem.idxLeSugangPayInfo : null;
        });

        if (selectedIdxLeSugangPayInfoList.length === 0) {
          alert("선택된 수강신청자 정보가 없습니다.");
          return;
        }

        axios
          .post("/admin/registerDelete", {
            idxLeSugang: idxLeSugang,
            idxLeSugangPayInfoList: selectedIdxLeSugangPayInfoList,
          })
          .then((res) => {
            if (res.data.success) {
              alert("선택한 항목 삭제 완료");
              listPage.getRegisterList();
            } else {
              alert("삭제 실패");
            }
          })
          .catch((error) => {
            console.error("삭제 요청 중 오류 발생:", error);
            alert("삭제 요청 중 오류가 발생했습니다.");
          });
      },

      excelDownload() {
        if (listPage.selectedCheckboxes.length === 0) {
          // alert("선택된 항목이 없습니다.");
          const params = {
            idxLeSugang: listPage.searchParams.idxLeSugang,
            idxLeSugangOnePayInfoList: selectedData,
            status: listPage.searchParams.status,
          };

          axios({
            url: "/admin/registerListExcel",
            method: "GET",
            params: params,
            responseType: "blob",
          })
            .then((res) => {
              const url = window.URL.createObjectURL(new Blob([res.data]));
              const link = document.createElement("a");
              link.href = url;
              link.setAttribute("download", "lifeClassList.xlsx");
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
            })
            .catch((err) => {
              console.log(err);
              return false;
            });
          return;
        }

        const selectedData = listPage.selectedCheckboxes
          .map((checkboxId) => {
            const selectedItem = listPage.sugangMemberList.find((item) => item[listPage.checkParamName] === checkboxId);
            return selectedItem ? selectedItem.idxLeSugangPayInfo : null;
          })
          .filter((idx) => idx !== null);

        console.log("selectedData:", selectedData);

        const params = {
          idxLeSugang: listPage.searchParams.idxLeSugang,
          idxLeSugangOnePayInfoList: selectedData.join(),
          status: listPage.searchParams.status,
        };

        axios({
          url: "/admin/registerListExcel",
          method: "GET",
          params: params,
          responseType: "blob",
        })
          .then((res) => {
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "lifeClassList.xlsx");
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          })
          .catch((err) => {
            console.log(err);
            return false;
          });
      },

      movePage(value) {
        listPage.searchParams.pageNum = value;
        listPage.getRegisterList();
      },

      // goToDetailPage(value) {
      //     let params = { idxLeSiteMain: value };
      //     router.push({ path: "/noticeManagement", query: params });
      // },
    });

    onMounted(() => {
      listPage.getRegisterList();
    });

    return {
      listPage,
    };
  },
};
</script>
<style></style>
