<template>
  <div>
    <div class="hidden pc:block bg-wrapper">
      <div class="flex items-center justify-center bg py-[76px] max-w-[1920px] mx-auto">
        <p class="text-[40px] font-bold text-neutral-950">성인 과정</p>
      </div>
    </div>

    <section class="pt-[55px] pb-[70px] pc:pt-[70px] pc:pb-[140px]">
      <div>
        <div class="flex items-center justify-between p-2 bg-[#323740] pc:hidden show-list">
          <div class="w-3"></div>
          <p class="text-sm font-bold text-white">과정 리스트 보기</p>
          <div class="w-3">
            <img src="@lifeeduWeb/assets/images/courseIntroduce/adult/arrow.png" alt="icon" class="transition-all show-arrow" />
          </div>
        </div>
        <ul class="flex pc:flex-col pc:max-w-[1200px] pc:mx-auto lang-list">
          <li class="w-[40%] bg-[#ececec] pc:w-full pc:bg-white">
            <ul class="gap-10 pc:flex pc:border-b border-solid border-[#f1f1f1]">
              <li
                class="py-3.5 font-medium text-center text-[#fb6930] cursor-pointer"
                @click="goPage('/courseIntroduce/adultEng/PageAll')"
              >
                영어
              </li>
              <li class="py-3.5 font-medium text-center cursor-pointer" @click="goPage('/courseIntroduce/adultChi/pageAll')">
                중국어
              </li>
              <li class="py-3.5 font-medium text-center cursor-pointer" @click="goPage('/courseIntroduce/adultJap/pageAll')">
                일본어
              </li>
              <li class="py-3.5 font-medium text-center cursor-pointer" @click="goPage('/courseIntroduce/adultEtc')">
                제2외국어
              </li>
            </ul>
          </li>
          <li class="w-[60%] pc:w-full">
            <ul class="divide-y divide-solid divide-[#e9e9e9] pc:flex pc:divide-y-0 gap-9 sub-list">
              <li
                class="py-3.5 font-medium text-center cursor-pointer text-[#666666]"
                @click="goPage('/courseIntroduce/adultEng/PageAll')"
              >
                전체 커리큘럼 맵
              </li>
              <li
                class="py-3.5 font-medium text-center cursor-pointer text-[#666666]"
                @click="goPage('/courseIntroduce/adultEng/page1')"
              >
                일반회화
              </li>
              <li
                class="py-3.5 font-medium text-center cursor-pointer text-[#666666]"
                @click="goPage('/courseIntroduce/adultEng/page2')"
              >
                비즈니스회화
              </li>
              <li
                class="py-3.5 font-medium text-center cursor-pointer text-[#666666]"
                @click="goPage('/courseIntroduce/adultEng/page3')"
              >
                토론회화
              </li>
              <li
                class="py-3.5 font-medium text-center cursor-pointer text-[#666666]"
                @click="goPage('/courseIntroduce/adultEng/page4')"
              >
                시험·인터뷰대비
              </li>
              <li
                class="py-3.5 font-medium text-center cursor-pointer text-[#666666]"
                @click="goPage('/courseIntroduce/adultEng/page5')"
              >
                로이터뉴스
              </li>
              <li
                class="py-3.5 font-medium text-center text-[#fb6930] cursor-pointer"
                @click="goPage('/courseIntroduce/adultEng/page6')"
              >
                Global Topic
              </li>
            </ul>
          </li>
        </ul>
        <div class="pc-con-wrapper5">
          <div class="flex flex-col items-center bg6 py-10 pc:py-[90px] px-5 mb-7 pc:mb-11">
            <p class="text-white font-bold text-center text-[35px] mb-2">Global Topic</p>
            <div class="w-[72px] h-[2px] bg-[#fb6930] mb-6"></div>
            <p class="text-center text-white pc:text-2xl">
              글로벌 비즈니스 역량 향상과 관련된 기사 및 주제를 통해<br />비즈니스 과정에서 필요한 회화 표현을 쉽게 학습할 수
              있습니다.
            </p>
          </div>
          <div class="px-5 pc-con-wrapper4">
            <ul class="grid grid-cols-2 gap-5 tablet:grid-cols-3 pc:grid-cols-4 pc:gap-10">
              <li>
                <div class="relative mb-2.5">
                  <img src="@lifeeduWeb/assets/images/courseIntroduce/adult/eng/bg6-book01.png" alt="img" />
                  <p class="absolute right-0 px-3 py-1 text-white bottom-5 bg-black/60">전 레벨</p>
                </div>
                <p
                  class="inline-block cursor-pointer text-sm px-3.5 font-medium text-[#fb6930] mb-1 border border-solid border-[fb6930] rounded-2xl"
                  @click="goSite('https://kr.object.ncloudstorage.com/mailpad/b2b_pdf/EN17_Daily%20Topic.pdf')"
                >
                  미리보기
                </p>
                <p class="text-[#111111] font-medium mb-1.5">Daily 토픽회화</p>
                <p class="text-sm text-[#535353] mb-1">
                  일반적 토픽과 최신 이슈 학습을 통한 토론 과정으로, 다양한 토픽에 대한 영어표현 학습 및 논리적 스피치 연습하는
                  과정
                </p>
                <ul class="flex flex-wrap gap-1">
                  <li class="text-sm py-px px-2 bg-[#ececec] rounded-2xl">#초중급</li>
                  <li class="text-sm py-px px-2 bg-[#ececec] rounded-2xl">#다양한주제</li>
                  <li class="text-sm py-px px-2 bg-[#ececec] rounded-2xl">#업데이트</li>
                </ul>
              </li>
              <li>
                <div class="relative mb-2.5">
                  <img src="@lifeeduWeb/assets/images/courseIntroduce/adult/eng/bg6-book02.png" alt="img" />
                  <p class="absolute right-0 px-3 py-1 text-white bottom-5 bg-black/60">고급</p>
                </div>
                <p
                  class="inline-block cursor-pointer text-sm px-3.5 font-medium text-[#fb6930] mb-1 border border-solid border-[fb6930] rounded-2xl"
                  @click="goSite('https://kr.object.ncloudstorage.com/mailpad/b2b_pdf/EN19_Global%20Insight_Basic.pdf')"
                >
                  미리보기
                </p>
                <p class="text-[#111111] font-medium mb-1.5">글로벌 Insight 토론_Basic</p>
                <p class="text-sm text-[#535353] mb-1">
                  Biz Skills, Culture 등 다양한 분야에 대한 뉴스 기사, 영어 아티클, 칼럼 등을 기반으로 Current Global issue에 대한
                  인사이트를 얻고 심도 있는 토론을 할 수 있는 과정
                </p>
                <ul class="flex flex-wrap gap-1">
                  <li class="text-sm py-px px-2 bg-[#ececec] rounded-2xl">#중고급</li>
                  <li class="text-sm py-px px-2 bg-[#ececec] rounded-2xl">#글로벌이슈</li>
                  <li class="text-sm py-px px-2 bg-[#ececec] rounded-2xl">#토론</li>
                </ul>
              </li>
              <li>
                <div class="relative mb-2.5">
                  <img src="@lifeeduWeb/assets/images/courseIntroduce/adult/eng/bg6-book03.png" alt="img" />
                  <p class="absolute right-0 px-3 py-1 text-white bottom-5 bg-black/60">고급</p>
                </div>
                <p
                  class="inline-block cursor-pointer text-sm px-3.5 font-medium text-[#fb6930] mb-1 border border-solid border-[fb6930] rounded-2xl"
                  @click="
                    goSite('https://kr.object.ncloudstorage.com/mailpad/b2b_pdf/EN20_Global%20Insight_Intermediate%201.pdf')
                  "
                >
                  미리보기
                </p>
                <p class="text-[#111111] font-medium mb-1.5">글로벌 Insight 토론_Intermediate 1</p>
                <p class="text-sm text-[#535353] mb-1">
                  Biz Skills, Culture 등 다양한 분야에 대한 뉴스 기사, 영어 아티클, 칼럼 등을 기반으로 Current Global issue에 대한
                  인사이트를 얻고 심도 있는 토론을 할 수 있는 과정
                </p>
                <ul class="flex flex-wrap gap-1">
                  <li class="text-sm py-px px-2 bg-[#ececec] rounded-2xl">#중고급</li>
                  <li class="text-sm py-px px-2 bg-[#ececec] rounded-2xl">#글로벌이슈</li>
                  <li class="text-sm py-px px-2 bg-[#ececec] rounded-2xl">#토론</li>
                </ul>
              </li>
              <li>
                <div class="relative mb-2.5">
                  <img src="@lifeeduWeb/assets/images/courseIntroduce/adult/eng/bg6-book04.png" alt="img" />
                  <p class="absolute right-0 px-3 py-1 text-white bottom-5 bg-black/60">고급</p>
                </div>
                <p
                  class="inline-block cursor-pointer text-sm px-3.5 font-medium text-[#fb6930] mb-1 border border-solid border-[fb6930] rounded-2xl"
                  @click="
                    goSite('https://kr.object.ncloudstorage.com/mailpad/b2b_pdf/EN21_Global%20Insight_Intermediate%202.pdf')
                  "
                >
                  미리보기
                </p>
                <p class="text-[#111111] font-medium mb-1.5">글로벌 Insight 토론_Intermediate 2</p>
                <p class="text-sm text-[#535353] mb-1">
                  Biz Skills, Culture 등 다양한 분야에 대한 뉴스 기사, 영어 아티클, 칼럼 등을 기반으로 Current Global issue에 대한
                  인사이트를 얻고 심도 있는 토론을 할 수 있는 과정
                </p>
                <ul class="flex flex-wrap gap-1">
                  <li class="text-sm py-px px-2 bg-[#ececec] rounded-2xl">#중고급</li>
                  <li class="text-sm py-px px-2 bg-[#ececec] rounded-2xl">#글로벌이슈</li>
                  <li class="text-sm py-px px-2 bg-[#ececec] rounded-2xl">#토론</li>
                </ul>
              </li>
              <li>
                <div class="relative mb-2.5">
                  <img src="@lifeeduWeb/assets/images/courseIntroduce/adult/eng/bg6-book05.png" alt="img" />
                  <p class="absolute right-0 px-3 py-1 text-white bottom-5 bg-black/60">고급</p>
                </div>
                <p
                  class="inline-block cursor-pointer text-sm px-3.5 font-medium text-[#fb6930] mb-1 border border-solid border-[fb6930] rounded-2xl"
                  @click="goSite('https://kr.object.ncloudstorage.com/mailpad/b2b_pdf/EN22_Global%20Insight_Advanced.pdf')"
                >
                  미리보기
                </p>
                <p class="text-[#111111] font-medium mb-1.5">글로벌 Insight 토론_Advanced</p>
                <p class="text-sm text-[#535353] mb-1">
                  Biz Skills, Culture 등 다양한 분야에 대한 뉴스 기사, 영어 아티클, 칼럼 등을 기반으로 Current Global issue에 대한
                  인사이트를 얻고 심도 있는 토론을 할 수 있는 과정
                </p>
                <ul class="flex flex-wrap gap-1">
                  <li class="text-sm py-px px-2 bg-[#ececec] rounded-2xl">#중고급</li>
                  <li class="text-sm py-px px-2 bg-[#ececec] rounded-2xl">#글로벌이슈</li>
                  <li class="text-sm py-px px-2 bg-[#ececec] rounded-2xl">#토론</li>
                </ul>
              </li>
              <li>
                <div class="relative mb-2.5">
                  <img src="@lifeeduWeb/assets/images/courseIntroduce/adult/eng/bg6-book06.png" alt="img" />
                  <p class="absolute right-0 px-3 py-1 text-white bottom-5 bg-black/60">중급~고급</p>
                </div>
                <p
                  class="inline-block cursor-pointer text-sm px-3.5 font-medium text-[#fb6930] mb-1 border border-solid border-[fb6930] rounded-2xl"
                  @click="
                    goSite(
                      'https://carrotenglish.kr/guide/media_view/book_teded_study/73?_gl=1*1hz3pfe*_ga*MTMxNDQwMTgzMS4xNjk4MjIxMjM4*_ga_R79H2W39ZD*MTcwMjQ0MTk3NS4yOC4xLjE3MDI0NDY0NzUuMi4wLjA.'
                    )
                  "
                >
                  미리보기
                </p>
                <p class="text-[#111111] font-medium mb-1.5">Ted-ed 영어</p>
                <p class="text-sm text-[#535353] mb-1">
                  과학, 기술, 예술 등 다양한 분야의 글을 읽고 표현을 배우고 요약하며, 수업 후 해당 내용 영상 초과학습을 통해
                  전체적인 영어 실력을 향상시킬 수 있는 과정
                </p>
                <ul class="flex flex-wrap gap-1">
                  <li class="text-sm py-px px-2 bg-[#ececec] rounded-2xl">#중고급</li>
                  <li class="text-sm py-px px-2 bg-[#ececec] rounded-2xl">#글로벌이슈</li>
                  <li class="text-sm py-px px-2 bg-[#ececec] rounded-2xl">#토론</li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import { ref, reactive, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import axios from "@lifeeduWeb/plugins/axios.js";
import { useStore } from "vuex";

export default {
  components: {},

  setup() {
    const router = useRouter();
    const route = useRoute();
    const store = useStore();

    const goPage = (url) => {
      location.href = url;
    };

    const goSite = (url) => {
      window.open(url, "_blank");
    };

    onMounted(() => {
      const showListButton = document.querySelector(".show-list");
      const arrowIcon = showListButton.querySelector(".show-arrow");
      const listToToggle = document.querySelector(".lang-list");
      const countryListItems = document.querySelectorAll(".sub-list li");

      showListButton.addEventListener("click", function () {
        if (listToToggle.style.display === "none") {
          listToToggle.style.display = "flex";
        } else {
          listToToggle.style.display = "none";
        }

        arrowIcon.classList.toggle("rotate-180");
      });

      countryListItems.forEach(function (item) {
        item.addEventListener("click", function () {
          listToToggle.style.display = "none";
          arrowIcon.classList.remove("rotate-180");
        });
      });
    });

    return {
      goPage,
      goSite,
    };
  },
};
</script>
<style lang="scss" scoped>
.bg6 {
  background: url(@lifeeduWeb/assets/images/courseIntroduce/adult/eng/bg6.png) no-repeat center / 1920px;
}
</style>
