<template>
  <div>
    <div class="hidden pc:block bg-wrapper">
      <div class="flex items-center justify-center bg py-[76px] max-w-[1920px] mx-auto">
        <p class="text-[40px] font-bold text-neutral-950">성인 과정</p>
      </div>
    </div>

    <section class="pt-[55px] pb-[70px] pc:pt-[70px] pc:pb-[140px]">
      <div>
        <div class="flex items-center justify-between p-2 bg-[#323740] pc:hidden show-list">
          <div class="w-3"></div>
          <p class="text-sm font-bold text-white">과정 리스트 보기</p>
          <div class="w-3">
            <img src="@lifeeduWeb/assets/images/courseIntroduce/adult/arrow.png" alt="icon" class="transition-all show-arrow" />
          </div>
        </div>
        <ul class="flex pc:flex-col pc:max-w-[1200px] pc:mx-auto lang-list">
          <li class="w-[40%] bg-[#ececec] pc:w-full pc:bg-white">
            <ul class="gap-10 pc:flex pc:border-b border-solid border-[#f1f1f1]">
              <li class="py-3.5 font-medium text-center cursor-pointer" @click="goPage('/courseIntroduce/adultEng/PageAll')">
                영어
              </li>
              <li
                class="py-3.5 font-medium text-center text-[#fb6930] cursor-pointer"
                @click="goPage('/courseIntroduce/adultChi/pageAll')"
              >
                중국어
              </li>
              <li class="py-3.5 font-medium text-center cursor-pointer" @click="goPage('/courseIntroduce/adultJap/pageAll')">
                일본어
              </li>
              <li class="py-3.5 font-medium text-center cursor-pointer" @click="goPage('/courseIntroduce/adultEtc')">
                제2외국어
              </li>
            </ul>
          </li>
          <li class="w-[60%] pc:w-full">
            <ul class="divide-y divide-solid divide-[#e9e9e9] pc:flex pc:divide-y-0 gap-9 sub-list">
              <li
                class="py-3.5 font-medium text-center cursor-pointer text-[#666666]"
                @click="goPage('/courseIntroduce/adultChi/PageAll')"
              >
                전체 커리큘럼 맵
              </li>
              <li
                class="py-3.5 font-medium text-center cursor-pointer text-[#666666]"
                @click="goPage('/courseIntroduce/adultChi/page1')"
              >
                일반회화
              </li>
              <li
                class="py-3.5 font-medium text-center cursor-pointer text-[#666666]"
                @click="goPage('/courseIntroduce/adultChi/page2')"
              >
                비즈니스회화
              </li>
              <li
                class="py-3.5 font-medium text-center cursor-pointer text-[#666666]"
                @click="goPage('/courseIntroduce/adultChi/page3')"
              >
                토론회화
              </li>
              <li
                class="py-3.5 font-medium text-center text-[#fb6930] cursor-pointer"
                @click="goPage('/courseIntroduce/adultChi/page4')"
              >
                시험대비과정
              </li>
              <li
                class="py-3.5 font-medium text-center cursor-pointer text-[#666666]"
                @click="goPage('/courseIntroduce/adultChi/page5')"
              >
                테마과정
              </li>
            </ul>
          </li>
        </ul>
        <div class="pc-con-wrapper5">
          <div class="flex flex-col items-center bg4 py-10 pc:py-[90px] px-5 mb-7 pc:mb-11">
            <p class="text-white font-bold text-center text-[35px] mb-2">시험대비과정</p>
            <div class="w-[72px] h-[2px] bg-[#fb6930] mb-6"></div>
            <p class="text-center text-white pc:text-2xl">
              시험 관련 어휘와 핵심문장 패턴을 익히고 <br />1:1 수업을 통해 실전 감각과 시험 전략을 키웁니다.
            </p>
          </div>
          <div class="px-5 pc-con-wrapper4">
            <ul class="grid grid-cols-2 gap-5 tablet:grid-cols-3 pc:grid-cols-4 pc:gap-10">
              <li>
                <div class="relative mb-2.5">
                  <img src="@lifeeduWeb/assets/images/courseIntroduce/adult/chi/bg4-book01.png" alt="img" />
                  <p class="absolute right-0 px-3 py-1 text-white bottom-5 bg-black/60">초급~중급</p>
                </div>
                <p
                  class="inline-block cursor-pointer text-sm px-3.5 font-medium text-[#fb6930] mb-1 border border-solid border-[fb6930] rounded-2xl"
                  @click="goSite('https://kr.object.ncloudstorage.com/mailpad/b2b_pdf/CN13.pdf')"
                >
                  미리보기
                </p>
                <p class="text-[#111111] font-medium mb-1.5">TSC 가볍게 뛰어넘기 (초중급)</p>
                <p class="text-sm text-[#535353] mb-1">
                  중국어 말하기 시험 TSC 초중급 레벨 달성을 위해 기본 문장 구조 및 문법, 주요 어휘 학습 과정
                </p>
                <ul class="flex flex-wrap gap-1">
                  <li class="text-sm py-px px-2 bg-[#ececec] rounded-2xl">#중급</li>
                  <li class="text-sm py-px px-2 bg-[#ececec] rounded-2xl">#시험대비</li>
                  <li class="text-sm py-px px-2 bg-[#ececec] rounded-2xl">#TSC</li>
                </ul>
              </li>
              <li>
                <div class="relative mb-2.5">
                  <img src="@lifeeduWeb/assets/images/courseIntroduce/adult/chi/bg4-book02.png" alt="img" />
                  <p class="absolute right-0 px-3 py-1 text-white bottom-5 bg-black/60">중급~고급</p>
                </div>
                <p
                  class="inline-block cursor-pointer text-sm px-3.5 font-medium text-[#fb6930] mb-1 border border-solid border-[fb6930] rounded-2xl"
                  @click="goSite('https://kr.object.ncloudstorage.com/mailpad/b2b_pdf/CN14.pdf')"
                >
                  미리보기
                </p>
                <p class="text-[#111111] font-medium mb-1.5">TSC 한번에 달성하기 중고급</p>
                <p class="text-sm text-[#535353] mb-1">
                  중국어 말하기 시험 TSC 중고급 레벨 달성을 위해 심화 단어 및 핵심 어법 학습 과정
                </p>
                <ul class="flex flex-wrap gap-1">
                  <li class="text-sm py-px px-2 bg-[#ececec] rounded-2xl">#고급</li>
                  <li class="text-sm py-px px-2 bg-[#ececec] rounded-2xl">#시험대비</li>
                  <li class="text-sm py-px px-2 bg-[#ececec] rounded-2xl">#TSC</li>
                </ul>
              </li>
              <li>
                <div class="relative mb-2.5">
                  <img src="@lifeeduWeb/assets/images/courseIntroduce/adult/chi/bg4-book03.png" alt="img" />
                  <p class="absolute right-0 px-3 py-1 text-white bottom-5 bg-black/60">초급</p>
                </div>
                <p
                  class="inline-block cursor-pointer text-sm px-3.5 font-medium text-[#fb6930] mb-1 border border-solid border-[fb6930] rounded-2xl"
                  @click="goSite('https://kr.object.ncloudstorage.com/mailpad/b2b_pdf/CN15.pdf')"
                >
                  미리보기
                </p>
                <p class="text-[#111111] font-medium mb-1.5">초단기 BCT Speaking 공략</p>
                <p class="text-sm text-[#535353] mb-1">
                  BCT 중국어 말하기 평가 시험 대비 과정으로, 실제 비즈니스 표현을 학습하며 다양한 모범 답변을 학습하는 과정
                </p>
                <ul class="flex flex-wrap gap-1">
                  <li class="text-sm py-px px-2 bg-[#ececec] rounded-2xl">#고급</li>
                  <li class="text-sm py-px px-2 bg-[#ececec] rounded-2xl">#시험대비</li>
                  <li class="text-sm py-px px-2 bg-[#ececec] rounded-2xl">#BCT</li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import { ref, reactive, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import axios from "@lifeeduWeb/plugins/axios.js";
import { useStore } from "vuex";

export default {
  components: {},

  setup() {
    const router = useRouter();
    const route = useRoute();
    const store = useStore();

    const goPage = (url) => {
      location.href = url;
    };

    const goSite = (url) => {
      window.open(url, "_blank");
    };

    onMounted(() => {
      const showListButton = document.querySelector(".show-list");
      const arrowIcon = showListButton.querySelector(".show-arrow");
      const listToToggle = document.querySelector(".lang-list");
      const countryListItems = document.querySelectorAll(".sub-list li");

      showListButton.addEventListener("click", function () {
        if (listToToggle.style.display === "none") {
          listToToggle.style.display = "flex";
        } else {
          listToToggle.style.display = "none";
        }

        arrowIcon.classList.toggle("rotate-180");
      });

      countryListItems.forEach(function (item) {
        item.addEventListener("click", function () {
          listToToggle.style.display = "none";
          arrowIcon.classList.remove("rotate-180");
        });
      });
    });

    return {
      goPage,
      goSite,
    };
  },
};
</script>
<style lang="scss" scoped>
.bg4 {
  background: url(@lifeeduWeb/assets/images/courseIntroduce/adult/chi/bg4.png) no-repeat center / 1920px;
}
</style>
