<template>
    <div class="p-4">
        <div class="p-2 mb-5">
            <p class="text-xl font-semibold">
                꼼쓰빰쓰뻠스 (bumsteadyoon@carrotglobal.com) 님의 수강내역
            </p>
        </div>
        <div class="p-2 mb-5 rounded shadow-md">
            <p
                class="pb-2 mb-2 text-lg font-semibold border-b-2 border-red-400 border-solid"
            >
                회원정보
            </p>
            <ul class="flex flex-col gap-4">
                <li class="flex gap-2">
                    <p class="flex-none font-semibold w-36">회원이름(실명)</p>
                    <p>윤지훈</p>
                </li>
                <li class="flex gap-2">
                    <p class="flex-none font-semibold w-36">
                        영어이름(수업이름)
                    </p>
                    <p>bumstead</p>
                </li>
                <li class="flex gap-2">
                    <p class="flex-none font-semibold w-36">휴대전화</p>
                    <p>010-1234-5678</p>
                </li>
                <li class="flex gap-2">
                    <p class="flex-none font-semibold w-36">주소</p>
                    <p>
                        06541<br />서울 서초구 강남대로 479<br />신논현타워 6F
                    </p>
                </li>
            </ul>
        </div>
        <div class="p-2 mb-5 rounded shadow-md">
            <p
                class="pb-2 mb-2 text-lg font-semibold border-b-2 border-red-400 border-solid"
            >
                수강정보
            </p>
            <ul class="flex flex-col gap-4">
                <li class="flex gap-2">
                    <p class="flex-none font-semibold w-36">수업 스케줄</p>
                    <p>주니어 수강신청 / 1:1수업</p>
                </li>
                <li class="flex gap-2">
                    <p class="flex-none font-semibold w-36">수업유형</p>
                    <p>모바일 화상</p>
                </li>
                <li class="flex gap-2">
                    <p class="flex-none font-semibold w-36">언어</p>
                    <p>영어(북미)</p>
                </li>
                <li class="flex gap-2">
                    <p class="flex-none font-semibold w-36">레벨</p>
                    <p>초급</p>
                </li>
                <li class="flex gap-2">
                    <p class="flex-none font-semibold w-36">수업</p>
                    <p>주니어 일반</p>
                </li>
                <li class="flex gap-2">
                    <p class="flex-none font-semibold w-36">수업 기간 / 횟수</p>
                    <p>1개월 주 3회 10분</p>
                </li>
                <li class="flex gap-2">
                    <p class="flex-none font-semibold w-36">희망수업시간</p>
                    <p>06:00 ~ 06:30, 06:00 ~ 06:30</p>
                </li>
                <li class="flex gap-2">
                    <p class="flex-none font-semibold w-36">교육비</p>
                    <p>120,000원</p>
                </li>
                <li class="flex gap-2">
                    <p class="flex-none font-semibold w-36">교재비</p>
                    <p>10,000원</p>
                </li>
                <li class="flex gap-2">
                    <p class="flex-none font-semibold w-36">총 결제금액</p>
                    <p>130,000원</p>
                </li>
            </ul>
        </div>
        <div class="p-2 mb-5 rounded shadow-md">
            <p
                class="pb-2 mb-2 text-lg font-semibold border-b-2 border-red-400 border-solid"
            >
                교재 배송지 정보
            </p>
            <ul class="flex flex-col gap-4">
                <li class="flex gap-2">
                    <p class="flex-none font-semibold w-36">받는 사람</p>
                    <p>윤지훈</p>
                </li>
                <li class="flex gap-2">
                    <p class="flex-none font-semibold w-36">휴대전화</p>
                    <p>010-1234-5678</p>
                </li>
                <li class="flex gap-2">
                    <p class="flex-none font-semibold w-36">받을 주소</p>
                    <p>
                        06541<br />서울 서초구 강남대로 479<br />신논현타워 6F
                    </p>
                </li>
            </ul>
        </div>
    </div>
</template>
<script>
export default {};
</script>
<style></style>
