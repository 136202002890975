<template>
  <section class="pt-[55px] pb-[70px] pc:py-[140px] pc-con-wrapper1">
    <div>
      <p
        v-if="regDatailInfo.regDateInfoList.waitStatus == 'Y'"
        class="text-xl font-bold text-center text-orange-500 mb-7 pc:text-4xl pc:mb-11"
      >
        대기 신청이 정상적으로<br class="pc:hidden" />
        완료되었습니다.
      </p>
      <p v-else class="text-xl font-bold text-center text-orange-500 mb-7 pc:text-4xl pc:mb-11">
        수강 신청이 정상적으로<br class="pc:hidden" />
        완료되었습니다.
      </p>
      <ul class="flex flex-col gap-14 pc:gap-36 mb-14 pc:mb-24">
        <li>
          <p class="mb-5 text-lg font-bold pc:text-2xl pc:mb-4">회원 정보</p>
          <ul class="grid grid-cols-1 border-t border-b border-solid divide-y border-neutral-950 divide-solid divide-neutral-200">
            <!-- 1:1 -->
            <li v-if="regDatailInfo.regDateInfoList.sugangLecType === '1:1'" class="flex items-center">
              <div
                class="flex-none w-24 pc:w-60 px-2.5 py-3 pc:px-9 pc:py-4 self-stretch flex justify-center items-center bg-neutral-50"
              >
                <p class="text-sm font-bold text-center text-neutral-950 pc:text-lg">회원 이름</p>
              </div>
              <div class="grow pl-2.5 py-3 pc:pl-9 pc:py-4 flex flex-col gap-2.5 pc:flex-row pc:gap-9 pc:items-center">
                <p class="text-sm font-medium pc:text-lg text-neutral-500">
                  {{ regDatailInfo.regDateInfoList.memberName }}
                </p>
              </div>
            </li>

            <!-- 그룹 -->
            <li v-else class="flex items-center">
              <div
                class="flex-none w-24 pc:w-60 px-2.5 py-3 pc:px-9 pc:py-4 self-stretch flex justify-center items-center bg-neutral-50"
              >
                <p class="text-sm font-bold text-center text-neutral-950 pc:text-lg">회원 이름(실명)</p>
              </div>
              <div class="grow pl-2.5 py-3 pc:pl-9 pc:py-4 flex flex-col gap-2.5 pc:flex-row pc:gap-9 pc:items-center">
                <p class="text-sm font-medium pc:text-lg text-neutral-500">
                  {{ regDatailInfo.regDateInfoList.memberName }}
                </p>
              </div>
            </li>
            <li class="flex items-center">
              <div
                class="flex-none w-24 pc:w-60 px-2.5 py-3 pc:px-9 pc:py-4 self-stretch flex justify-center items-center bg-neutral-50"
              >
                <p class="text-sm font-bold text-center text-neutral-950 pc:text-lg">
                  영어이름<br class="pc:hidden" />(수업이름)
                </p>
              </div>
              <div class="grow pl-2.5 py-3 pc:pl-9 pc:py-4 flex flex-col gap-2.5 pc:flex-row pc:gap-9 pc:items-center">
                <p class="text-sm font-medium pc:text-lg text-neutral-500">
                  {{ regDatailInfo.regDateInfoList.memberEname }}
                </p>
              </div>
            </li>
            <li class="flex items-center">
              <div
                class="flex-none w-24 pc:w-60 px-2.5 py-3 pc:px-9 pc:py-4 self-stretch flex justify-center items-center bg-neutral-50"
              >
                <p class="text-sm font-bold text-center text-neutral-950 pc:text-lg">휴대전화</p>
              </div>
              <div class="grow pl-2.5 py-3 pc:pl-9 pc:py-4 flex flex-col gap-2.5 pc:flex-row pc:gap-9 pc:items-center">
                <p class="text-sm font-medium pc:text-lg text-neutral-500">
                  {{ regDatailInfo.regDateInfoList.memberMobile }}
                </p>
              </div>
            </li>
            <li class="flex items-center">
              <div
                class="flex-none w-24 pc:w-60 px-2.5 py-3 pc:px-9 pc:py-4 self-stretch flex justify-center items-center bg-neutral-50"
              >
                <p class="text-sm font-bold text-center text-neutral-950 pc:text-lg">주소</p>
              </div>
              <div class="grow pl-2.5 py-3 pc:pl-9 pc:py-4 flex flex-col gap-2.5 pc:flex-row pc:gap-9 pc:items-center">
                <p class="text-sm font-medium pc:text-lg text-neutral-500">
                  {{ regDatailInfo.regDateInfoList.memberPostCode }}<br />{{ regDatailInfo.regDateInfoList.memberAddress }}
                  {{ regDatailInfo.regDateInfoList.memberAddressSub }}
                </p>
              </div>
            </li>
          </ul>
        </li>
        <li>
          <p class="mb-5 text-lg font-bold pc:text-2xl pc:mb-4">수강 정보</p>
          <ul class="grid grid-cols-1 border-t border-b border-solid divide-y border-neutral-950 divide-solid divide-neutral-200">
            <!-- 1:1 -->
            <li v-if="regDatailInfo.regDateInfoList.sugangLecType === '1:1'" class="flex items-center">
              <div
                class="flex-none w-24 pc:w-60 px-2.5 py-3 pc:px-9 pc:py-4 self-stretch flex justify-center items-center bg-neutral-50"
              >
                <p class="text-sm font-bold text-center text-neutral-950 pc:text-lg">수업 스케줄</p>
              </div>
              <div class="grow pl-2.5 py-3 pc:pl-9 pc:py-4 flex flex-col gap-2.5 pc:flex-row pc:gap-9 pc:items-center">
                <p class="text-sm font-medium pc:text-lg text-neutral-500">
                  {{ regDatailInfo.regDateInfoList.sugangAdult }}
                  과정 /
                  {{ regDatailInfo.regDateInfoList.sugangLecType }}
                </p>
              </div>
            </li>

            <!-- 그룹 -->
            <li v-else class="flex items-center">
              <div
                class="flex-none w-24 pc:w-60 px-2.5 py-3 pc:px-9 pc:py-4 self-stretch flex justify-center items-center bg-neutral-50"
              >
                <p class="text-sm font-bold text-center text-neutral-950 pc:text-lg">수업 스케줄</p>
              </div>
              <div class="grow pl-2.5 py-3 pc:pl-9 pc:py-4 flex flex-col gap-2.5 pc:flex-row pc:gap-9 pc:items-center">
                <p class="text-sm font-medium pc:text-lg text-neutral-500">
                  {{ regDatailInfo.regDateInfoList.sugangAdult }}
                  과정 /
                  {{ regDatailInfo.regDateInfoList.sugangType }}
                </p>
              </div>
            </li>

            <!-- 1:1 -->
            <li v-if="regDatailInfo.regDateInfoList.sugangLecType === '1:1'" class="flex items-center">
              <div
                class="flex-none w-24 pc:w-60 px-2.5 py-3 pc:px-9 pc:py-4 self-stretch flex justify-center items-center bg-neutral-50"
              >
                <p class="text-sm font-bold text-center text-neutral-950 pc:text-lg">수업 유형</p>
              </div>
              <div class="grow pl-2.5 py-3 pc:pl-9 pc:py-4 flex flex-col gap-2.5 pc:flex-row pc:gap-9 pc:items-center">
                <p class="text-sm font-medium pc:text-lg text-neutral-500">
                  {{ regDatailInfo.regDateInfoList.sugangType }}
                </p>
              </div>
            </li>

            <!-- 그룹 -->
            <li v-else class="flex items-center">
              <div
                class="flex-none w-24 pc:w-60 px-2.5 py-3 pc:px-9 pc:py-4 self-stretch flex justify-center items-center bg-neutral-50"
              >
                <p class="text-sm font-bold text-center text-neutral-950 pc:text-lg">그룹</p>
              </div>
              <div class="grow pl-2.5 py-3 pc:pl-9 pc:py-4 flex flex-col gap-2.5 pc:flex-row pc:gap-9 pc:items-center">
                <p class="text-sm font-medium pc:text-lg text-neutral-500">
                  {{ regDatailInfo.regDateInfoList.sugangLecType }}
                </p>
              </div>
            </li>
            <li class="flex items-center">
              <div
                class="flex-none w-24 pc:w-60 px-2.5 py-3 pc:px-9 pc:py-4 self-stretch flex justify-center items-center bg-neutral-50"
              >
                <p class="text-sm font-bold text-center text-neutral-950 pc:text-lg">언어</p>
              </div>
              <div class="grow pl-2.5 py-3 pc:pl-9 pc:py-4 flex flex-col gap-2.5 pc:flex-row pc:gap-9 pc:items-center">
                <p class="text-sm font-medium pc:text-lg text-neutral-500">
                  {{ regDatailInfo.regDateInfoList.sugangLang }}
                </p>
              </div>
            </li>
            <li class="flex items-center">
              <div
                class="flex-none w-24 pc:w-60 px-2.5 py-3 pc:px-9 pc:py-4 self-stretch flex justify-center items-center bg-neutral-50"
              >
                <p class="text-sm font-bold text-center text-neutral-950 pc:text-lg">수업 기간/횟수</p>
              </div>
              <div class="grow pl-2.5 py-3 pc:pl-9 pc:py-4 flex flex-col gap-2.5 pc:flex-row pc:gap-9 pc:items-center">
                <p
                  v-if="regDatailInfo.regDateInfoList.sugangClassMonth === 0"
                  class="text-sm font-medium pc:text-lg text-neutral-500"
                >
                  주{{ regDatailInfo.regDateInfoList.sugangNumberOfClass }} 회
                  {{ regDatailInfo.regDateInfoList.sugangLessonTime }}분
                </p>

                <p v-else class="text-sm font-medium pc:text-lg text-neutral-500">
                  {{ regDatailInfo.regDateInfoList.sugangClassMonth }}개월 주{{
                    regDatailInfo.regDateInfoList.sugangNumberOfClass
                  }}
                  회 {{ regDatailInfo.regDateInfoList.sugangLessonTime }}분
                </p>
              </div>
            </li>
            <li class="flex items-center">
              <div
                class="flex-none w-24 pc:w-60 px-2.5 py-3 pc:px-9 pc:py-4 self-stretch flex justify-center items-center bg-neutral-50"
              >
                <p class="text-sm font-bold text-center text-neutral-950 pc:text-lg">레벨</p>
              </div>
              <div class="grow pl-2.5 py-3 pc:pl-9 pc:py-4 flex flex-col gap-2.5 pc:flex-row pc:gap-9 pc:items-center">
                <p class="text-sm font-medium pc:text-lg text-neutral-500">
                  {{ regDatailInfo.regDateInfoList.sugangLevel }}
                </p>
              </div>
            </li>
            <li class="flex items-center">
              <div
                class="flex-none w-24 pc:w-60 px-2.5 py-3 pc:px-9 pc:py-4 self-stretch flex justify-center items-center bg-neutral-50"
              >
                <p class="text-sm font-bold text-center text-neutral-950 pc:text-lg">수업</p>
              </div>
              <div class="grow pl-2.5 py-3 pc:pl-9 pc:py-4 flex flex-col gap-2.5 pc:flex-row pc:gap-9 pc:items-center">
                <p class="text-sm font-medium pc:text-lg text-neutral-500">
                  {{ regDatailInfo.regDateInfoList.sugangClassName }}
                </p>
              </div>
            </li>

            <!-- 1:1 -->
            <li v-if="regDatailInfo.regDateInfoList.sugangLecType === '1:1'" class="flex items-center">
              <div
                class="flex-none w-24 pc:w-60 px-2.5 py-3 pc:px-9 pc:py-4 self-stretch flex justify-center items-center bg-neutral-50"
              >
                <p class="text-sm font-bold text-center text-neutral-950 pc:text-lg">수업 횟수</p>
              </div>
              <div class="grow pl-2.5 py-3 pc:pl-9 pc:py-4 flex flex-col gap-2.5 pc:flex-row pc:gap-9 pc:items-center">
                <p class="text-sm font-medium pc:text-lg text-neutral-500">
                  주
                  {{ regDatailInfo.regDateInfoList.sugangNumberOfClass }}회 {{ regDatailInfo.regDateInfoList.sugangLessonTime }}분
                </p>
              </div>
            </li>

            <!-- 그룹 -->
            <li v-else class="flex items-center">
              <div
                class="flex-none w-24 pc:w-60 px-2.5 py-3 pc:px-9 pc:py-4 self-stretch flex justify-center items-center bg-neutral-50"
              >
                <p class="text-sm font-bold text-center text-neutral-950 pc:text-lg">수업시작시간</p>
              </div>
              <div class="grow pl-2.5 py-3 pc:pl-9 pc:py-4 flex flex-col gap-2.5 pc:flex-row pc:gap-9 pc:items-center">
                <p class="text-sm font-medium pc:text-lg text-neutral-500">
                  {{ regDatailInfo.regDateInfoList.sugangTime }}
                </p>
              </div>
            </li>
            <li v-if="regDatailInfo.regDateInfoList.sugangLecType === '1:1'" class="flex items-center">
              <div
                class="flex-none w-24 pc:w-60 px-2.5 py-3 pc:px-9 pc:py-4 self-stretch flex justify-center items-center bg-neutral-50"
              >
                <p class="text-sm font-bold text-center text-neutral-950 pc:text-lg">희망 수업시간</p>
              </div>
              <div class="grow pl-2.5 py-3 pc:pl-9 pc:py-4 flex flex-col gap-2.5 pc:flex-row pc:gap-9 pc:items-center">
                <p class="text-sm font-medium pc:text-lg text-neutral-500">
                  {{ regDatailInfo.regDateInfoList.sugangHopeTime1 }},
                  {{ regDatailInfo.regDateInfoList.sugangHopeTime2 }}
                </p>
              </div>
            </li>
            <li class="flex items-center">
              <div
                class="flex-none w-24 pc:w-60 px-2.5 py-3 pc:px-9 pc:py-4 self-stretch flex justify-center items-center bg-neutral-50"
              >
                <p class="text-sm font-bold text-center text-neutral-950 pc:text-lg">교육비</p>
              </div>
              <div class="grow pl-2.5 py-3 pc:pl-9 pc:py-4 flex flex-col gap-2.5 pc:flex-row pc:gap-9 pc:items-center">
                <p class="text-sm font-medium pc:text-lg text-neutral-500">{{ regDatailInfo.regDateInfoList.classPay }}원</p>
              </div>
            </li>
            <li class="flex items-center">
              <div
                class="flex-none w-24 pc:w-60 px-2.5 py-3 pc:px-9 pc:py-4 self-stretch flex justify-center items-center bg-neutral-50"
              >
                <p class="text-sm font-bold text-center text-neutral-950 pc:text-lg">교재비</p>
              </div>
              <div class="grow pl-2.5 py-3 pc:pl-9 pc:py-4 flex flex-col gap-2.5 pc:flex-row pc:gap-9 pc:items-center">
                <p class="text-sm font-medium pc:text-lg text-neutral-500">{{ regDatailInfo.regDateInfoList.bookPay }}원</p>
              </div>
            </li>
            <li class="flex items-center">
              <div
                class="flex-none w-24 pc:w-60 px-2.5 py-3 pc:px-9 pc:py-4 self-stretch flex justify-center items-center bg-neutral-50"
              >
                <p class="text-sm font-bold text-center text-neutral-950 pc:text-lg">총 결제금액</p>
              </div>
              <div class="grow pl-2.5 py-3 pc:pl-9 pc:py-4 flex flex-col gap-2.5 pc:flex-row pc:gap-9 pc:items-center">
                <p class="text-sm font-medium pc:text-lg text-neutral-500">{{ regDatailInfo.regDateInfoList.totalPay }}원</p>
              </div>
            </li>
          </ul>
        </li>
        <li>
          <p class="mb-5 text-lg font-bold pc:text-2xl pc:mb-4">교재 배송지 정보</p>
          <ul class="grid grid-cols-1 border-t border-b border-solid divide-y border-neutral-950 divide-solid divide-neutral-200">
            <li class="flex items-center">
              <div
                class="flex-none w-24 pc:w-60 px-2.5 py-3 pc:px-9 pc:py-4 self-stretch flex justify-center items-center bg-neutral-50"
              >
                <p class="text-sm font-bold text-center text-neutral-950 pc:text-lg">받는 사람</p>
              </div>
              <div class="grow pl-2.5 py-3 pc:pl-9 pc:py-4 flex flex-col gap-2.5 pc:flex-row pc:gap-9 pc:items-center">
                <p class="text-sm font-medium pc:text-lg text-neutral-500">
                  {{ regDatailInfo.regDateInfoList.bookMemberName }}
                </p>
              </div>
            </li>
            <li class="flex items-center">
              <div
                class="flex-none w-24 pc:w-60 px-2.5 py-3 pc:px-9 pc:py-4 self-stretch flex justify-center items-center bg-neutral-50"
              >
                <p class="text-sm font-bold text-center text-neutral-950 pc:text-lg">휴대전화</p>
              </div>
              <div class="grow pl-2.5 py-3 pc:pl-9 pc:py-4 flex flex-col gap-2.5 pc:flex-row pc:gap-9 pc:items-center">
                <p class="text-sm font-medium pc:text-lg text-neutral-500">
                  {{ regDatailInfo.regDateInfoList.bookMemberMobile }}
                </p>
              </div>
            </li>
            <li class="flex items-center">
              <div
                class="flex-none w-24 pc:w-60 px-2.5 py-3 pc:px-9 pc:py-4 self-stretch flex justify-center items-center bg-neutral-50"
              >
                <p class="text-sm font-bold text-center text-neutral-950 pc:text-lg">주소</p>
              </div>
              <div class="grow pl-2.5 py-3 pc:pl-9 pc:py-4 flex flex-col gap-2.5 pc:flex-row pc:gap-9 pc:items-center">
                <p class="text-sm font-medium pc:text-lg text-neutral-500">
                  {{ regDatailInfo.regDateInfoList.bookMemberPostCode }}<br />{{
                    regDatailInfo.regDateInfoList.bookMemberAddress
                  }}
                  {{ regDatailInfo.regDateInfoList.bookMemberAddressSub }}
                </p>
              </div>
            </li>
          </ul>
        </li>
      </ul>

      <p
        class="max-w-xs py-4 mx-auto text-sm font-bold text-center text-white bg-orange-500 rounded cursor-pointer pc:py-6 pc:text-xl"
        @click="regDatailInfo.goToMain()"
      >
        메인 페이지로 이동
      </p>
    </div>
  </section>
</template>
<script>
import { reactive, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import axios from "@lifeeduWeb/plugins/axios.js";
import { useStore } from "vuex";
export default {
  components: {},
  setup(props, { emit }) {
    const router = useRouter();
    const route = useRoute();
    const store = useStore();
    const idxLeSiteMain = store.getters.getIdxLeSiteMain; // store 에 저장된 idxLeSiteMain 값

    const regDatailInfo = reactive({
      regDateInfoList: [], // 수강신청 data info 리스트
      idxLeSugangPayInfo: route.query.idxLeSugangPayInfo, // 수강결제정보

      // 수업신청 내역 상세 보기
      getRegisterDetail() {
        const params = {
          idxLeSugangPayInfo: regDatailInfo.idxLeSugangPayInfo,
        };
        axios.get(`/user/sugang/class/registerDetail`, { params }).then(function (res) {
          if (res.data.success === true) {
            regDatailInfo.regDateInfoList = res.data.data;
          } else {
            alert(res.data.message);
          }
        });
      },

      // 메인페이지 이동, idxLeSiteMain값을 query로 물고 이동 해야함 (main page 에서 idxLeSiteMain 값을 필요로 하는 기능이 있음 로고 및 팝업 등등)
      goToMain() {
        router.push({
          path: "/home",
          query: {
            idxLeSiteMain: idxLeSiteMain,
          },
        });
      },
    });
    onMounted(() => {
      regDatailInfo.getRegisterDetail();
    });

    return {
      regDatailInfo,
    };
  },
};
</script>
<style></style>
