<template>
  <div>
    <div class="hidden pc:block bg-wrapper">
      <div class="flex items-center justify-center bg py-[76px] max-w-[1920px] mx-auto">
        <p class="text-[40px] font-bold text-neutral-950">성인 과정</p>
      </div>
    </div>

    <section class="pt-[55px] pb-[70px] pc:pt-[70px] pc:pb-[140px]">
      <div>
        <div class="flex items-center justify-between p-2 bg-[#323740] pc:hidden show-list">
          <div class="w-3"></div>
          <p class="text-sm font-bold text-white">과정 리스트 보기</p>
          <div class="w-3">
            <img src="@lifeeduWeb/assets/images/courseIntroduce/adult/arrow.png" alt="icon" class="transition-all show-arrow" />
          </div>
        </div>
        <ul class="flex pc:flex-col pc:max-w-[1200px] pc:mx-auto lang-list">
          <li class="w-[40%] bg-[#ececec] pc:w-full pc:bg-white">
            <ul class="gap-10 pc:flex pc:border-b border-solid border-[#f1f1f1]">
              <li class="py-3.5 font-medium text-center cursor-pointer" @click="goPage('/courseIntroduce/adultEng/PageAll')">
                영어
              </li>
              <li
                class="py-3.5 font-medium text-center text-[#fb6930] cursor-pointer"
                @click="goPage('/courseIntroduce/adultChi/pageAll')"
              >
                중국어
              </li>
              <li class="py-3.5 font-medium text-center cursor-pointer" @click="goPage('/courseIntroduce/adultJap/pageAll')">
                일본어
              </li>
              <li class="py-3.5 font-medium text-center cursor-pointer" @click="goPage('/courseIntroduce/adultEtc')">
                제2외국어
              </li>
            </ul>
          </li>
          <li class="w-[60%] pc:w-full">
            <ul class="divide-y divide-solid divide-[#e9e9e9] pc:flex pc:divide-y-0 gap-9 sub-list">
              <li
                class="py-3.5 font-medium text-center cursor-pointer text-[#666666]"
                @click="goPage('/courseIntroduce/adultChi/PageAll')"
              >
                전체 커리큘럼 맵
              </li>
              <li
                class="py-3.5 font-medium text-center cursor-pointer text-[#666666]"
                @click="goPage('/courseIntroduce/adultChi/page1')"
              >
                일반회화
              </li>
              <li
                class="py-3.5 font-medium text-center text-[#fb6930] cursor-pointer"
                @click="goPage('/courseIntroduce/adultChi/page2')"
              >
                비즈니스회화
              </li>
              <li
                class="py-3.5 font-medium text-center cursor-pointer text-[#666666]"
                @click="goPage('/courseIntroduce/adultChi/page3')"
              >
                토론회화
              </li>
              <li
                class="py-3.5 font-medium text-center cursor-pointer text-[#666666]"
                @click="goPage('/courseIntroduce/adultChi/page4')"
              >
                시험대비과정
              </li>
              <li
                class="py-3.5 font-medium text-center cursor-pointer text-[#666666]"
                @click="goPage('/courseIntroduce/adultChi/page5')"
              >
                테마과정
              </li>
            </ul>
          </li>
        </ul>
        <div class="pc-con-wrapper5">
          <div class="flex flex-col items-center bg2 py-10 pc:py-[90px] px-5 mb-7 pc:mb-11">
            <p class="text-white font-bold text-center text-[35px] mb-2">비즈니스회화</p>
            <div class="w-[72px] h-[2px] bg-[#fb6930] mb-6"></div>
            <p class="text-center text-white pc:text-2xl">
              비즈니스 업무 환경에서 필요한 커뮤니케이션 능력을 향상 시키고,<br />다양한 상황을 통해 비즈니스 실무 능력을 마스터
              합니다.
            </p>
          </div>
          <div class="px-5 pc-con-wrapper4">
            <ul class="grid grid-cols-2 gap-5 tablet:grid-cols-3 pc:grid-cols-4 pc:gap-10">
              <li>
                <div class="relative mb-2.5">
                  <img src="@lifeeduWeb/assets/images/courseIntroduce/adult/chi/bg2-book01.png" alt="img" />
                  <p class="absolute right-0 px-3 py-1 text-white bottom-5 bg-black/60">중급~고급</p>
                </div>
                <p
                  class="inline-block cursor-pointer text-sm px-3.5 font-medium text-[#fb6930] mb-1 border border-solid border-[fb6930] rounded-2xl"
                  @click="goSite('https://kr.object.ncloudstorage.com/mailpad/b2b_pdf/CN05.pdf')"
                >
                  미리보기
                </p>
                <p class="text-[#111111] font-medium mb-1.5">팔선생 중국어 비즈니스 베이직</p>
                <p class="text-sm text-[#535353] mb-1">
                  기업 교육에 최적화된 중국어 비즈니스 교재를 활용하여 실제 상황에서 활용되는 표현을 반복 연습, 체득화 하는 과정
                </p>
                <ul class="flex flex-wrap gap-1">
                  <li class="text-sm py-px px-2 bg-[#ececec] rounded-2xl">#중급</li>
                  <li class="text-sm py-px px-2 bg-[#ececec] rounded-2xl">#비즈니스회화</li>
                  <li class="text-sm py-px px-2 bg-[#ececec] rounded-2xl">#실용비즈니스</li>
                </ul>
              </li>
              <li>
                <div class="relative mb-2.5">
                  <img src="@lifeeduWeb/assets/images/courseIntroduce/adult/chi/bg2-book02.png" alt="img" />
                  <p class="absolute right-0 px-3 py-1 text-white bottom-5 bg-black/60">중급~고급</p>
                </div>
                <p
                  class="inline-block cursor-pointer text-sm px-3.5 font-medium text-[#fb6930] mb-1 border border-solid border-[fb6930] rounded-2xl"
                  @click="goSite('https://kr.object.ncloudstorage.com/mailpad/b2b_pdf/CN06.pdf')"
                >
                  미리보기
                </p>
                <p class="text-[#111111] font-medium mb-1.5">팔선생 중국어 비즈니스 프렉티스 심화</p>
                <p class="text-sm text-[#535353] mb-1">
                  기업 교육에 최적화된 중국어 비즈니스 교재를 활용하여 실제 상황에서 활용되는 표현을 반복 연습, 체득화 하는 과정
                </p>
                <ul class="flex flex-wrap gap-1">
                  <li class="text-sm py-px px-2 bg-[#ececec] rounded-2xl">#고급</li>
                  <li class="text-sm py-px px-2 bg-[#ececec] rounded-2xl">#비즈니스회화</li>
                  <li class="text-sm py-px px-2 bg-[#ececec] rounded-2xl">#실용비즈니스</li>
                </ul>
              </li>
              <li>
                <div class="relative mb-2.5">
                  <img src="@lifeeduWeb/assets/images/courseIntroduce/adult/chi/bg2-book03.png" alt="img" />
                  <p class="absolute right-0 px-3 py-1 text-white bottom-5 bg-black/60">중급~고급</p>
                </div>
                <p
                  class="inline-block cursor-pointer text-sm px-3.5 font-medium text-[#fb6930] mb-1 border border-solid border-[fb6930] rounded-2xl"
                  @click="goSite('https://kr.object.ncloudstorage.com/mailpad/b2b_pdf/CN07.pdf')"
                >
                  미리보기
                </p>
                <p class="text-[#111111] font-medium mb-1.5">팔선생 중국어 비즈니스 스킬 미팅</p>
                <p class="text-sm text-[#535353] mb-1">비즈니스 중국어 미팅을 위한 효과적인 미팅 프로세스 스킬 학습 과정</p>
                <ul class="flex flex-wrap gap-1">
                  <li class="text-sm py-px px-2 bg-[#ececec] rounded-2xl">#중고급</li>
                  <li class="text-sm py-px px-2 bg-[#ececec] rounded-2xl">#비즈니스회화</li>
                  <li class="text-sm py-px px-2 bg-[#ececec] rounded-2xl">#센스있는미팅대화</li>
                </ul>
              </li>
              <li>
                <div class="relative mb-2.5">
                  <img src="@lifeeduWeb/assets/images/courseIntroduce/adult/chi/bg2-book04.png" alt="img" />
                  <p class="absolute right-0 px-3 py-1 text-white bottom-5 bg-black/60">중급~고급</p>
                </div>
                <p
                  class="inline-block cursor-pointer text-sm px-3.5 font-medium text-[#fb6930] mb-1 border border-solid border-[fb6930] rounded-2xl"
                  @click="goSite('https://kr.object.ncloudstorage.com/mailpad/b2b_pdf/CN08.pdf')"
                >
                  미리보기
                </p>
                <p class="text-[#111111] font-medium mb-1.5">팔선생 중국어 비즈니스 스킬 이메일</p>
                <p class="text-sm text-[#535353] mb-1">비즈니스 이메일 작성을 위한 용어 및 다양한 표현들을 학습하는 과정</p>
                <ul class="flex flex-wrap gap-1">
                  <li class="text-sm py-px px-2 bg-[#ececec] rounded-2xl">#중고급</li>
                  <li class="text-sm py-px px-2 bg-[#ececec] rounded-2xl">#비즈니스회화</li>
                  <li class="text-sm py-px px-2 bg-[#ececec] rounded-2xl">#이메일작성법</li>
                </ul>
              </li>
              <li>
                <div class="relative mb-2.5">
                  <img src="@lifeeduWeb/assets/images/courseIntroduce/adult/chi/bg2-book05.png" alt="img" />
                  <p class="absolute right-0 px-3 py-1 text-white bottom-5 bg-black/60">중급~고급</p>
                </div>
                <p
                  class="inline-block cursor-pointer text-sm px-3.5 font-medium text-[#fb6930] mb-1 border border-solid border-[fb6930] rounded-2xl"
                  @click="goSite('https://kr.object.ncloudstorage.com/mailpad/b2b_pdf/CN09.pdf')"
                >
                  미리보기
                </p>
                <p class="text-[#111111] font-medium mb-1.5">팔선생 중국어 비즈니스 스킬 출장</p>
                <p class="text-sm text-[#535353] mb-1">출장 중 대면하는 상황 별 패턴 및 유용한 표현법 학습 과정</p>
                <ul class="flex flex-wrap gap-1">
                  <li class="text-sm py-px px-2 bg-[#ececec] rounded-2xl">#중고급</li>
                  <li class="text-sm py-px px-2 bg-[#ececec] rounded-2xl">#비즈니스회화</li>
                  <li class="text-sm py-px px-2 bg-[#ececec] rounded-2xl">#중국출장</li>
                </ul>
              </li>
              <li>
                <div class="relative mb-2.5">
                  <img src="@lifeeduWeb/assets/images/courseIntroduce/adult/chi/bg2-book06.png" alt="img" />
                  <p class="absolute right-0 px-3 py-1 text-white bottom-5 bg-black/60">중급~고급</p>
                </div>
                <p
                  class="inline-block cursor-pointer text-sm px-3.5 font-medium text-[#fb6930] mb-1 border border-solid border-[fb6930] rounded-2xl"
                  @click="goSite('https://kr.object.ncloudstorage.com/mailpad/b2b_pdf/CN10.pdf')"
                >
                  미리보기
                </p>
                <p class="text-[#111111] font-medium mb-1.5">팔선생 중국어 비즈니스 스킬 프레젠테이션</p>
                <p class="text-sm text-[#535353] mb-1">
                  중국어 프레젠테이션을 준비하는 단계별 배경지식, 주요표현 및 필수 규칙 학습 과정
                </p>
                <ul class="flex flex-wrap gap-1">
                  <li class="text-sm py-px px-2 bg-[#ececec] rounded-2xl">#중고급</li>
                  <li class="text-sm py-px px-2 bg-[#ececec] rounded-2xl">#비즈니스회화</li>
                  <li class="text-sm py-px px-2 bg-[#ececec] rounded-2xl">#프레젠테이션</li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import { ref, reactive, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import axios from "@lifeeduWeb/plugins/axios.js";
import { useStore } from "vuex";

export default {
  components: {},

  setup() {
    const router = useRouter();
    const route = useRoute();
    const store = useStore();

    const goPage = (url) => {
      location.href = url;
    };

    const goSite = (url) => {
      window.open(url, "_blank");
    };

    onMounted(() => {
      const showListButton = document.querySelector(".show-list");
      const arrowIcon = showListButton.querySelector(".show-arrow");
      const listToToggle = document.querySelector(".lang-list");
      const countryListItems = document.querySelectorAll(".sub-list li");

      showListButton.addEventListener("click", function () {
        if (listToToggle.style.display === "none") {
          listToToggle.style.display = "flex";
        } else {
          listToToggle.style.display = "none";
        }

        arrowIcon.classList.toggle("rotate-180");
      });

      countryListItems.forEach(function (item) {
        item.addEventListener("click", function () {
          listToToggle.style.display = "none";
          arrowIcon.classList.remove("rotate-180");
        });
      });
    });

    return {
      goPage,
      goSite,
    };
  },
};
</script>
<style lang="scss" scoped>
.bg2 {
  background: url(@lifeeduWeb/assets/images/courseIntroduce/adult/chi/bg2.png) no-repeat center / 1920px;
}
</style>
