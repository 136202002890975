<template>
  <section class="pt-[55px] pb-[70px] pc:py-[140px] pc-con-wrapper1">
    <div>
      <p class="text-xl font-bold text-center mb-7 pc:text-4xl pc:mb-11">
        <span v-if="classPayment.applicationDetails.classPaymentInfoList.waitStatus == 'Y'">대기</span>신청내역 확인
      </p>
      <ul class="flex flex-col gap-14 pc:gap-36 mb-14 pc:mb-24">
        <li>
          <p class="mb-5 text-lg font-bold pc:text-2xl pc:mb-4">회원정보</p>
          <ul class="grid grid-cols-1 border-t border-b border-solid divide-y border-neutral-950 divide-solid divide-neutral-200">
            <!-- 1:1 -->
            <li v-if="classPayment.applicationDetails.classPaymentInfoList.sugangLecType === '1:1'" class="flex items-center">
              <div
                class="flex-none w-24 pc:w-60 px-2.5 py-3 pc:px-9 pc:py-4 self-stretch flex justify-center items-center bg-neutral-50"
              >
                <p class="text-sm font-bold text-center text-neutral-950 pc:text-lg">회원 이름</p>
              </div>
              <div class="grow pl-2.5 py-3 pc:pl-9 pc:py-4 flex flex-col gap-2.5 pc:flex-row pc:gap-9 pc:items-center">
                <input
                  type="text"
                  placeholder="회원 이름"
                  class="p-4 text-xs border border-solid rounded pc:text-base border-neutral-200 placeholder:text-neutral-200 pc:w-80 text-neutral-500"
                  v-model="classPayment.applicationDetails.classPaymentInfoList.memberName"
                />
              </div>
            </li>

            <!-- 그룹 -->
            <li v-else class="flex items-center">
              <div
                class="flex-none w-24 pc:w-60 px-2.5 py-3 pc:px-9 pc:py-4 self-stretch flex justify-center items-center bg-neutral-50"
              >
                <p class="text-sm font-bold text-center text-neutral-950 pc:text-lg">회원 이름(실명)</p>
              </div>
              <div class="grow pl-2.5 py-3 pc:pl-9 pc:py-4 flex flex-col gap-2.5 pc:flex-row pc:gap-9 pc:items-center">
                <input
                  type="text"
                  placeholder="회원 이름"
                  class="p-4 text-xs border border-solid rounded pc:text-base border-neutral-200 placeholder:text-neutral-200 pc:w-80 text-neutral-500"
                  v-model="classPayment.applicationDetails.classPaymentInfoList.memberName"
                />
              </div>
            </li>
            <li class="flex items-center">
              <div
                class="flex-none w-24 pc:w-60 px-2.5 py-3 pc:px-9 pc:py-4 self-stretch flex justify-center items-center bg-neutral-50"
              >
                <p class="text-sm font-bold text-center text-neutral-950 pc:text-lg">
                  영어 이름<br class="pc:hidden" />(수업이름)
                </p>
              </div>
              <div class="grow pl-2.5 py-3 pc:pl-9 pc:py-4 flex flex-col gap-2.5 pc:flex-row pc:gap-9 pc:items-center">
                <input
                  type="text"
                  placeholder="영어 이름"
                  class="p-4 text-xs border border-solid rounded pc:text-base border-neutral-200 placeholder:text-neutral-200 pc:w-80 text-neutral-500"
                  v-model="classPayment.applicationDetails.classPaymentInfoList.memberEname"
                />
              </div>
            </li>
            <li class="flex items-center">
              <div
                class="flex-none w-24 pc:w-60 px-2.5 py-3 pc:px-9 pc:py-4 self-stretch flex justify-center items-center bg-neutral-50"
              >
                <p class="text-sm font-bold text-center text-neutral-950 pc:text-lg">휴대전화</p>
              </div>
              <div class="grow pl-2.5 py-3 pc:pl-9 pc:py-4 flex flex-col gap-2.5 pc:flex-row pc:gap-9 pc:items-center">
                <input
                  type="tel"
                  placeholder="010-1234-5678"
                  class="p-4 text-xs border border-solid rounded pc:text-base border-neutral-200 placeholder:text-neutral-200 pc:w-80 text-neutral-500"
                  v-model="classPayment.applicationDetails.classPaymentInfoList.memberMobile"
                  @input="classPayment.formatMobile()"
                />
              </div>
            </li>
            <li class="flex items-center">
              <div
                class="flex-none w-24 pc:w-60 px-2.5 py-3 pc:px-9 pc:py-4 self-stretch flex justify-center items-center bg-neutral-50"
              >
                <p class="text-sm font-bold text-center text-neutral-950 pc:text-lg">주소</p>
              </div>
              <div class="grow pl-2.5 py-3 pc:pl-9 pc:py-4 flex flex-col gap-2.5 pc:flex-row pc:gap-9 pc:items-center">
                <ul class="flex flex-col gap-2">
                  <li class="flex gap-2">
                    <input
                      type="text"
                      class="p-4 text-xs border border-solid rounded pc:text-base border-neutral-200 placeholder:text-neutral-200 pc:w-80"
                      v-model="classPayment.applicationDetails.classPaymentInfoList.memberPostCode"
                      readonly
                      @click="classPayment.execDaumMemberPostcode"
                    />
                    <p
                      class="flex-none p-4 text-xs border border-solid rounded cursor-pointer pc:text-base text-neutral-500 bg-neutral-50 border-neutral-200"
                      @click="classPayment.execDaumMemberPostcode"
                    >
                      우편번호 찾기
                    </p>
                  </li>
                  <li>
                    <input
                      type="text"
                      class="p-4 text-xs border border-solid rounded pc:text-base border-neutral-200 placeholder:text-neutral-200 pc:w-80"
                      v-model="classPayment.applicationDetails.classPaymentInfoList.memberAddress"
                      readonly
                      @click="classPayment.execDaumMemberPostcode"
                    />
                  </li>
                  <li>
                    <input
                      type="text"
                      class="p-4 text-xs border border-solid rounded pc:text-base border-neutral-200 placeholder:text-neutral-200 pc:w-80"
                      v-model="classPayment.applicationDetails.classPaymentInfoList.memberAddressSub"
                    />
                  </li>
                </ul>
              </div>
            </li>
            <li v-if="classPayment.applicationDetails.classPaymentInfoList.sugangSupType == '지원'" class="flex items-center">
              <div
                class="flex-none w-24 pc:w-60 px-2.5 py-3 pc:px-9 pc:py-4 self-stretch flex justify-center items-center bg-neutral-50"
              >
                <p class="text-sm font-bold text-center text-neutral-950 pc:text-lg">지원대상자<br />서류 첨부</p>
              </div>
              <div class="grow pl-2.5 py-3 pc:pl-9 pc:py-4 flex flex-col gap-2.5 pc:flex-row pc:gap-9 pc:items-center">
                <div class="flex-grow">
                  <input type="file" name="companyLogo" id="" class="mb-2" @change="classPayment.fileChange()" />
                  <div class="flex items-center gap-3">
                    <input
                      class="text-blue-500 cursor-pointer"
                      readonly
                      type="text"
                      :value="classPayment.companyLogoFileName()"
                      @click="imgDownload(classPayment.fileName)"
                    />
                  </div>
                  <button
                    class="block p-2 border rounded text-slate-500 border-slate-300 hover:bg-slate-100"
                    @click="classPayment.deleteImage('companyLogo')"
                  >
                    Delete
                  </button>
                </div>
              </div>
            </li>
            <li v-if="classPayment.applicationDetails.classPaymentInfoList.sugangSupType == '지원'" class="space-y-4">
              <p><span class="font-bold text-red-500">*</span> 지원대상자 별 제출 필요 서류는 공지사항을 참고해주세요.</p>
              <p>
                <span class="font-bold text-red-500">*</span> 지금 제출하지 않으셔도, 차후에 담당매니저 이메일로도 제출이 가능하니
                공지사항을 참고해주세요.
              </p>
              <p>
                <span class="font-bold text-red-500">*</span> 서류를 여러 개 제출하는 경우 알집으로 압축하여 1개의 파일로
                제출해주세요
              </p>
            </li>
          </ul>
        </li>
        <li>
          <p class="mb-5 text-lg font-bold pc:text-2xl pc:mb-4">수강 정보</p>
          <ul class="grid grid-cols-1 border-t border-b border-solid divide-y border-neutral-950 divide-solid divide-neutral-200">
            <!-- 1:1 -->
            <li v-if="classPayment.applicationDetails.classPaymentInfoList.sugangLecType === '1:1'" class="flex items-center">
              <div
                class="flex-none w-24 pc:w-60 px-2.5 py-3 pc:px-9 pc:py-4 self-stretch flex justify-center items-center bg-neutral-50"
              >
                <p class="text-sm font-bold text-center text-neutral-950 pc:text-lg">수업 스케줄</p>
              </div>
              <div class="grow pl-2.5 py-3 pc:pl-9 pc:py-4 flex flex-col gap-2.5 pc:flex-row pc:gap-9 pc:items-center">
                <p class="text-sm font-medium pc:text-lg text-neutral-500">
                  {{ classPayment.applicationDetails.classPaymentInfoList.sugangAdult }}
                  /
                  {{ classPayment.applicationDetails.classPaymentInfoList.sugangLecType }}
                  수업
                </p>
              </div>
            </li>

            <!-- 그룹 -->
            <li v-else class="flex items-center">
              <div
                class="flex-none w-24 pc:w-60 px-2.5 py-3 pc:px-9 pc:py-4 self-stretch flex justify-center items-center bg-neutral-50"
              >
                <p class="text-sm font-bold text-center text-neutral-950 pc:text-lg">수업 스케줄</p>
              </div>
              <div class="grow pl-2.5 py-3 pc:pl-9 pc:py-4 flex flex-col gap-2.5 pc:flex-row pc:gap-9 pc:items-center">
                <p class="text-sm font-medium pc:text-lg text-neutral-500">
                  {{ classPayment.applicationDetails.classPaymentInfoList.sugangAdult }}
                  /
                  {{ classPayment.applicationDetails.classPaymentInfoList.sugangType }}
                </p>
              </div>
            </li>

            <!-- 1:1 -->
            <li v-if="classPayment.applicationDetails.classPaymentInfoList.sugangLecType === '1:1'" class="flex items-center">
              <div
                class="flex-none w-24 pc:w-60 px-2.5 py-3 pc:px-9 pc:py-4 self-stretch flex justify-center items-center bg-neutral-50"
              >
                <p class="text-sm font-bold text-center text-neutral-950 pc:text-lg">수업 유형</p>
              </div>
              <div class="grow pl-2.5 py-3 pc:pl-9 pc:py-4 flex flex-col gap-2.5 pc:flex-row pc:gap-9 pc:items-center">
                <p class="text-sm font-medium pc:text-lg text-neutral-500">
                  {{ classPayment.applicationDetails.classPaymentInfoList.sugangType }}
                </p>
              </div>
            </li>

            <!-- 그룹 -->
            <li v-else class="flex items-center">
              <div
                class="flex-none w-24 pc:w-60 px-2.5 py-3 pc:px-9 pc:py-4 self-stretch flex justify-center items-center bg-neutral-50"
              >
                <p class="text-sm font-bold text-center text-neutral-950 pc:text-lg">그룹</p>
              </div>
              <div class="grow pl-2.5 py-3 pc:pl-9 pc:py-4 flex flex-col gap-2.5 pc:flex-row pc:gap-9 pc:items-center">
                <p class="text-sm font-medium pc:text-lg text-neutral-500">
                  {{ classPayment.applicationDetails.classPaymentInfoList.sugangLecType }}
                </p>
              </div>
            </li>
            <li class="flex items-center">
              <div
                class="flex-none w-24 pc:w-60 px-2.5 py-3 pc:px-9 pc:py-4 self-stretch flex justify-center items-center bg-neutral-50"
              >
                <p class="text-sm font-bold text-center text-neutral-950 pc:text-lg">언어</p>
              </div>
              <div class="grow pl-2.5 py-3 pc:pl-9 pc:py-4 flex flex-col gap-2.5 pc:flex-row pc:gap-9 pc:items-center">
                <p class="text-sm font-medium pc:text-lg text-neutral-500">
                  {{ classPayment.applicationDetails.classPaymentInfoList.sugangLang }}
                </p>
              </div>
            </li>
            <li class="flex items-center">
              <div
                class="flex-none w-24 pc:w-60 px-2.5 py-3 pc:px-9 pc:py-4 self-stretch flex justify-center items-center bg-neutral-50"
              >
                <p class="text-sm font-bold text-center text-neutral-950 pc:text-lg">수업 기간/횟수</p>
              </div>
              <div class="grow pl-2.5 py-3 pc:pl-9 pc:py-4 flex flex-col gap-2.5 pc:flex-row pc:gap-9 pc:items-center">
                <p
                  v-if="classPayment.applicationDetails.classPaymentInfoList.sugangClassMonth === 0"
                  class="text-sm font-medium pc:text-lg text-neutral-500"
                >
                  주{{ classPayment.applicationDetails.classPaymentInfoList.sugangNumberOfClass }} 회
                  {{ classPayment.applicationDetails.classPaymentInfoList.sugangLessonTime }}분
                </p>

                <p v-else class="text-sm font-medium pc:text-lg text-neutral-500">
                  {{ classPayment.applicationDetails.classPaymentInfoList.sugangClassMonth }}개월 주{{
                    classPayment.applicationDetails.classPaymentInfoList.sugangNumberOfClass
                  }}
                  회 {{ classPayment.applicationDetails.classPaymentInfoList.sugangLessonTime }}분
                </p>
              </div>
            </li>
            <li class="flex items-center">
              <div
                class="flex-none w-24 pc:w-60 px-2.5 py-3 pc:px-9 pc:py-4 self-stretch flex justify-center items-center bg-neutral-50"
              >
                <p class="text-sm font-bold text-center text-neutral-950 pc:text-lg">레벨</p>
              </div>
              <div class="grow pl-2.5 py-3 pc:pl-9 pc:py-4 flex flex-col gap-2.5 pc:flex-row pc:gap-9 pc:items-center">
                <p class="text-sm font-medium pc:text-lg text-neutral-500">
                  {{ classPayment.applicationDetails.classPaymentInfoList.sugangLevel }}
                </p>
              </div>
            </li>
            <li class="flex items-center">
              <div
                class="flex-none w-24 pc:w-60 px-2.5 py-3 pc:px-9 pc:py-4 self-stretch flex justify-center items-center bg-neutral-50"
              >
                <p class="text-sm font-bold text-center text-neutral-950 pc:text-lg">수업</p>
              </div>
              <div class="grow pl-2.5 py-3 pc:pl-9 pc:py-4 flex flex-col gap-2.5 pc:flex-row pc:gap-9 pc:items-center">
                <p class="text-sm font-medium pc:text-lg text-neutral-500">
                  {{ classPayment.applicationDetails.classPaymentInfoList.sugangClassName }}
                </p>
              </div>
            </li>

            <!-- 1:1 -->
            <li v-if="classPayment.applicationDetails.classPaymentInfoList.sugangLecType === '1:1'" class="flex items-center">
              <div
                class="flex-none w-24 pc:w-60 px-2.5 py-3 pc:px-9 pc:py-4 self-stretch flex justify-center items-center bg-neutral-50"
              >
                <p class="text-sm font-bold text-center text-neutral-950 pc:text-lg">수업 횟수</p>
              </div>
              <div class="grow pl-2.5 py-3 pc:pl-9 pc:py-4 flex flex-col gap-2.5 pc:flex-row pc:gap-9 pc:items-center">
                <p class="text-sm font-medium pc:text-lg text-neutral-500">
                  주
                  {{ classPayment.applicationDetails.classPaymentInfoList.sugangNumberOfClass }}회
                  {{ classPayment.applicationDetails.classPaymentInfoList.sugangLessonTime }}분
                </p>
              </div>
            </li>

            <!-- 그룹 -->
            <li v-else class="flex items-center">
              <div
                class="flex-none w-24 pc:w-60 px-2.5 py-3 pc:px-9 pc:py-4 self-stretch flex justify-center items-center bg-neutral-50"
              >
                <p class="text-sm font-bold text-center text-neutral-950 pc:text-lg">수업시작시간</p>
              </div>
              <div class="grow pl-2.5 py-3 pc:pl-9 pc:py-4 flex flex-col gap-2.5 pc:flex-row pc:gap-9 pc:items-center">
                <p class="text-sm font-medium pc:text-lg text-neutral-500">
                  {{ classPayment.applicationDetails.classPaymentInfoList.sugangTime }}
                </p>
              </div>
            </li>

            <!-- 1:1 -->
            <li v-if="classPayment.applicationDetails.classPaymentInfoList.sugangLecType === '1:1'" class="flex items-center">
              <div
                class="flex-none w-24 pc:w-60 px-2.5 py-3 pc:px-9 pc:py-4 self-stretch flex justify-center items-center bg-neutral-50"
              >
                <p class="text-sm font-bold text-center text-neutral-950 pc:text-lg">희망 수업시간</p>
              </div>
              <div class="grow pl-2.5 py-3 pc:pl-9 pc:py-4 flex flex-col gap-2.5 pc:flex-row pc:gap-9 pc:items-center">
                <p class="text-sm font-medium pc:text-lg text-neutral-500">
                  {{ classPayment.applicationDetails.classPaymentInfoList.sugangHopeTime1 }},
                  {{ classPayment.applicationDetails.classPaymentInfoList.sugangHopeTime2 }}
                </p>
              </div>
            </li>
            <li class="flex items-center">
              <div
                class="flex-none w-24 pc:w-60 px-2.5 py-3 pc:px-9 pc:py-4 self-stretch flex justify-center items-center bg-neutral-50"
              >
                <p class="text-sm font-bold text-center text-neutral-950 pc:text-lg">교육비</p>
              </div>
              <div class="grow pl-2.5 py-3 pc:pl-9 pc:py-4 flex flex-col gap-2.5 pc:flex-row pc:gap-9 pc:items-center">
                <p class="text-sm font-medium pc:text-lg text-neutral-500">
                  {{ classPayment.applicationDetails.classPaymentInfoList.classPay }}원
                </p>
              </div>
            </li>
            <li class="flex items-center">
              <div
                class="flex-none w-24 pc:w-60 px-2.5 py-3 pc:px-9 pc:py-4 self-stretch flex justify-center items-center bg-neutral-50"
              >
                <p class="text-sm font-bold text-center text-neutral-950 pc:text-lg">교재비</p>
              </div>
              <div class="grow pl-2.5 py-3 pc:pl-9 pc:py-4 flex flex-col gap-2.5 pc:flex-row pc:gap-9 pc:items-center">
                <p class="text-sm font-medium pc:text-lg text-neutral-500">
                  {{ classPayment.applicationDetails.classPaymentInfoList.bookPay }}원
                </p>
              </div>
            </li>
            <li class="flex items-center">
              <div
                class="flex-none w-24 pc:w-60 px-2.5 py-3 pc:px-9 pc:py-4 self-stretch flex justify-center items-center bg-neutral-50"
              >
                <p class="text-sm font-bold text-center text-neutral-950 pc:text-lg">총 결제금액</p>
              </div>
              <div class="grow pl-2.5 py-3 pc:pl-9 pc:py-4 flex flex-col gap-2.5 pc:flex-row pc:gap-9 pc:items-center">
                <p class="text-sm font-medium pc:text-lg text-neutral-500">
                  {{ classPayment.applicationDetails.classPaymentInfoList.totalPay }}원
                </p>
              </div>
            </li>
          </ul>
        </li>
        <li>
          <p class="mb-5 text-lg font-bold pc:text-2xl pc:mb-4">교재 배송지 정보</p>
          <ul class="grid grid-cols-1 border-t border-b border-solid divide-y border-neutral-950 divide-solid divide-neutral-200">
            <li class="flex items-center">
              <div
                class="flex-none w-24 pc:w-60 px-2.5 py-3 pc:px-9 pc:py-4 self-stretch flex justify-center items-center bg-neutral-50"
              >
                <p class="text-sm font-bold text-center text-neutral-950 pc:text-lg">받는사람</p>
              </div>
              <div class="grow pl-2.5 py-3 pc:pl-9 pc:py-4 flex flex-col gap-2.5 pc:flex-row pc:gap-9 pc:items-center">
                <input
                  type="text"
                  placeholder="받는사람"
                  class="p-4 text-xs border border-solid rounded pc:text-base border-neutral-200 placeholder:text-neutral-200 pc:w-80 text-neutral-500"
                  v-model="classPayment.applicationDetails.classPaymentInfoList.bookMemberName"
                />
              </div>
            </li>
            <li class="flex items-center">
              <div
                class="flex-none w-24 pc:w-60 px-2.5 py-3 pc:px-9 pc:py-4 self-stretch flex justify-center items-center bg-neutral-50"
              >
                <p class="text-sm font-bold text-center text-neutral-950 pc:text-lg">휴대전화</p>
              </div>
              <div class="grow pl-2.5 py-3 pc:pl-9 pc:py-4 flex flex-col gap-2.5 pc:flex-row pc:gap-9 pc:items-center">
                <input
                  type="tel"
                  placeholder="010-1234-5678"
                  class="p-4 text-xs border border-solid rounded pc:text-base border-neutral-200 placeholder:text-neutral-200 pc:w-80 text-neutral-500"
                  v-model="classPayment.applicationDetails.classPaymentInfoList.bookMemberMobile"
                  @input="classPayment.parentFormatMobile()"
                />
              </div>
            </li>
            <li class="flex items-center">
              <div
                class="flex-none w-24 pc:w-60 px-2.5 py-3 pc:px-9 pc:py-4 self-stretch flex justify-center items-center bg-neutral-50"
              >
                <p class="text-sm font-bold text-center text-neutral-950 pc:text-lg">주소</p>
              </div>
              <div class="grow pl-2.5 py-3 pc:pl-9 pc:py-4 flex flex-col gap-2.5 pc:flex-row pc:gap-9 pc:items-center">
                <ul class="flex flex-col gap-2">
                  <li class="flex gap-2">
                    <input
                      type="text"
                      class="p-4 text-xs border border-solid rounded pc:text-base border-neutral-200 placeholder:text-neutral-200 pc:w-80"
                      v-model="classPayment.applicationDetails.classPaymentInfoList.bookMemberPostCode"
                      readonly
                      @click="classPayment.execDaumBookPostcode"
                    />
                    <p
                      class="flex-none p-4 text-xs border border-solid rounded cursor-pointer pc:text-base text-neutral-500 bg-neutral-50 border-neutral-200"
                      @click="classPayment.execDaumBookPostcode"
                    >
                      우편번호 찾기
                    </p>
                  </li>
                  <li>
                    <input
                      type="text"
                      class="p-4 text-xs border border-solid rounded pc:text-base border-neutral-200 placeholder:text-neutral-200 pc:w-80"
                      v-model="classPayment.applicationDetails.classPaymentInfoList.bookMemberAddress"
                      readonly
                      @click="classPayment.execDaumBookPostcode"
                    />
                  </li>
                  <li>
                    <input
                      type="text"
                      class="p-4 text-xs border border-solid rounded pc:text-base border-neutral-200 placeholder:text-neutral-200 pc:w-80"
                      v-model="classPayment.applicationDetails.classPaymentInfoList.bookMemberAddressSub"
                    />
                  </li>
                </ul>
              </div>
            </li>
          </ul>
        </li>
      </ul>

      <!-- 결제할 금액이 있을 경우 -->
      <!-- 네이버, 카카오 페이 추가 되면 pc:grid-cols-2 값을 2에서 4로 변경 해주면됨 -->
      <ul
        v-if="classPayment.applicationDetails.classPaymentInfoList.totalPay"
        class="grid max-w-xs grid-cols-1 mx-auto pc:max-w-4xl gap-2.5 pc:gap-5 pc:grid-cols-1"
      >
        <!-- <li
        class="py-4 text-sm font-bold text-center text-orange-500 border border-orange-500 border-solid rounded-sm cursor-pointer pc:py-6 pc:rounded pc:text-xl"
        @click="classPayment.requestPayTrans"
      >
        실시간 계좌이체
      </li> -->
        <li
          v-if="classPayment.applicationDetails.classPaymentInfoList.waitStatus == 'Y'"
          class="py-4 text-sm font-bold text-center text-orange-500 border border-orange-500 border-solid rounded-sm cursor-pointer pc:py-6 pc:rounded pc:text-xl"
          @click="classPayment.confirmPayZero()"
        >
          대기 신청 하기
        </li>
        <li
          v-else
          class="py-4 text-sm font-bold text-center text-orange-500 border border-orange-500 border-solid rounded-sm cursor-pointer pc:py-6 pc:rounded pc:text-xl"
          @click="classPayment.requestPay()"
        >
          카드 결제
        </li>
        <!-- 네이버 페이 추후 개발 예정 -->
        <!-- <li
                class="py-4 bg-[#00C73C] rounded-sm cursor-pointer pc:py-6 pc:rounded"
            >
                <div class="flex items-center justify-center gap-1 pc:gap-1.5">
                    <img
                        src="@/lifeeduWeb/assets/images/enrolment/enrolment/pay-naver.png"
                        alt="icon"
                        class="w-10 pc:w-16"
                    />
                    <p class="text-sm font-bold text-white pc:text-xl">결제</p>
                </div>
            </li> -->

        <!-- 카카오 페이 추후 개발 예정 -->
        <!-- <li
                class="py-4 bg-[#FFEB00] rounded-sm cursor-pointer pc:py-6 pc:rounded"
            >
                <div class="flex items-center justify-center gap-1 pc:gap-1.5">
                    <img
                        src="@/lifeeduWeb/assets/images/enrolment/enrolment/pay-kakao.png"
                        alt="icon"
                        class="w-10 pc:w-16"
                    />
                    <p class="text-sm font-bold pc:text-xl">결제</p>
                </div>
            </li> -->
      </ul>

      <!-- 결제할 금액이 없을 경우 -->
      <p
        v-else
        class="max-w-xs py-4 mx-auto text-sm font-bold text-center text-white bg-orange-500 rounded cursor-pointer pc:py-6 pc:text-xl"
        @click="classPayment.confirmPayZero()"
      >
        {{ classPayment.applicationDetails.classPaymentInfoList.waitStatus === "Y" ? "대기 신청 하기" : "확인" }}
      </p>
    </div>
  </section>
</template>
<script>
import { reactive, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import axios from "@lifeeduWeb/plugins/axios.js";
import { useStore } from "vuex";
export default {
  components: {},
  setup(props, { emit }) {
    const router = useRouter();
    const route = useRoute();
    const store = useStore();
    const idxLeSiteMain = store.getters.getIdxLeSiteMain; // store에 저장된 idxLeSiteMain 값

    const IMP = window.IMP;
    IMP.init("imp28895051");

    const classPayment = reactive({
      applicationDetails: {
        idxLeSugangPayInfo: "",
        classPaymentInfoList: [], // paymentinfo 값을 담을 리스트
        payWay: {
          trans: "A", // 계좌이체
          card: "C", // 카드
        },
        merchantUid: "", // 주문번호
      },

      // 신청자 우편번호 찾는 함수
      execDaumMemberPostcode() {
        new window.daum.Postcode({
          oncomplete: (data) => {
            classPayment.applicationDetails.classPaymentInfoList.memberPostCode = data.zonecode;
            let buildingName = data.buildingName ? ` (${data.buildingName})` : "";
            classPayment.applicationDetails.classPaymentInfoList.memberAddress = data.roadAddress + buildingName;
          },
        }).open();
      },

      // 구매자 우편번호 찾는 함수
      execDaumBookPostcode() {
        new window.daum.Postcode({
          oncomplete: (data) => {
            classPayment.applicationDetails.classPaymentInfoList.bookMemberPostCode = data.zonecode;
            let buildingName = data.buildingName ? ` (${data.buildingName})` : "";
            classPayment.applicationDetails.classPaymentInfoList.bookMemberAddress = data.roadAddress + buildingName;
          },
        }).open();
      },

      // 수업신청내역 상세보기 API 호출 함수
      getRegisterDetail() {
        const params = {
          idxLeSugangPayInfo: classPayment.applicationDetails.idxLeSugangPayInfo,
        };
        axios.get(`/user/sugang/class/registerDetail`, { params }).then(function (res) {
          if (res.data.success === true) {
            classPayment.applicationDetails.classPaymentInfoList = res.data.data;
          } else {
            if (res.data.code === 400) {
              alert(res.data.message);
              router.push({
                path: "/home",
                query: {
                  idxLeSiteMain: idxLeSiteMain,
                },
              });
            } else {
              alert(res.data.message);
            }
          }
        });
      },

      // 신청자 관련 정규식 함수
      formatMobile() {
        let input = classPayment.applicationDetails.classPaymentInfoList.memberMobile;

        // 정규식을 사용하여 숫자만 추출
        let numericValue = input.replace(/\D/g, "");

        // 하이픈 추가
        let formattedValue = numericValue.replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3");

        // 최대 길이를 초과하지 않도록 조정
        classPayment.applicationDetails.classPaymentInfoList.memberMobile = formattedValue.slice(0, 13);
      },

      // 부모님 관련 정규식 함수
      parentFormatMobile() {
        let input = classPayment.applicationDetails.classPaymentInfoList.bookMemberMobile;

        // 정규식을 사용하여 숫자만 추출
        let numericValue = input.replace(/\D/g, "");

        // 하이픈 추가
        let formattedValue = numericValue.replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3");

        // 최대 길이를 초과하지 않도록 조정
        classPayment.applicationDetails.classPaymentInfoList.bookMemberMobile = formattedValue.slice(0, 13);
      },

      // 계좌이체 버튼
      requestPayTrans() {
        const params = {
          idxLeSugangPayInfo: classPayment.applicationDetails.idxLeSugangPayInfo,
          totalPay: classPayment.applicationDetails.classPaymentInfoList.totalPay,
          payWay: classPayment.applicationDetails.payWay.trans,
          memberName: classPayment.applicationDetails.classPaymentInfoList.memberName, //회원 이름 (문자열)
          memberEname: classPayment.applicationDetails.classPaymentInfoList.memberEname, //회원 영어 이름 (문자열)
          memberMobile: classPayment.applicationDetails.classPaymentInfoList.memberMobile, //회원 휴대전화 (문자열)
          memberPostCode: classPayment.applicationDetails.classPaymentInfoList.memberPostCode, //회원 우편번호 (문자열)
          memberAddress: classPayment.applicationDetails.classPaymentInfoList.memberAddress, //회원 주소 (문자열)
          memberAddressSub: classPayment.applicationDetails.classPaymentInfoList.memberAddressSub, //회원 추가 주소 (문자열)
          bookMemberName: classPayment.applicationDetails.classPaymentInfoList.bookMemberName, //배송지 받는사람 이름 (문자열)
          bookMemberMobile: classPayment.applicationDetails.classPaymentInfoList.bookMemberMobile, //배송지 받는사람 휴대전화 (문자열)
          bookMemberPostCode: classPayment.applicationDetails.classPaymentInfoList.bookMemberPostCode, //배송지 우편번호 (문자열)
          bookMemberAddress: classPayment.applicationDetails.classPaymentInfoList.bookMemberAddress, //배송지 주소 (문자열)
          bookMemberAddressSub: classPayment.applicationDetails.classPaymentInfoList.bookMemberAddressSub, //배송지 추가 주소 (문자열)
        };

        axios.post(`/user/sugang/pay/check`, params).then(function (res) {
          if (res.data.success === true) {
            classPayment.applicationDetails.merchantUid = res.data.data.merchantUid;

            IMP.request_pay(
              {
                // pg: "kicc.T5102001", // 고정값 (테스트용)
                pg: "kicc.IM000106", // 고정값 (실결제용)
                pay_method: "trans", // 고정값
                merchant_uid: classPayment.applicationDetails.merchantUid,
                name:
                  classPayment.applicationDetails.classPaymentInfoList.sugangAdult +
                  "/" +
                  classPayment.applicationDetails.classPaymentInfoList.sugangLang, // 결제 정보
                amount: classPayment.applicationDetails.classPaymentInfoList.totalPay, // 총 결제 금액
                buyer_email: classPayment.applicationDetails.classPaymentInfoList.memberEmail, // 결제자 이메일
                buyer_name: classPayment.applicationDetails.classPaymentInfoList.memberName, // 결제자 이름
                notice_url: "https://api-life.carrotapply.com/user/sugang/pay/webhook", // 웹훅 주소
                buyer_tel: classPayment.applicationDetails.classPaymentInfoList.memberMobile, // 결제자 번호
                // notice_url: "https://stage-life-api.carrotenglish.net/user/sugang/pay/webhook", // 스테이지 웹훅 주소
              },
              function (res) {
                if (res.success) {
                  const impUid = res.imp_uid;
                  const merchantUid = classPayment.applicationDetails.merchantUid;
                  const params = {
                    payWay: classPayment.applicationDetails.payWay.trans, // 결제방법(계좌이체)
                    memberEmail: classPayment.applicationDetails.classPaymentInfoList.memberEmail, // 신청자 이메일
                    merchantUid: merchantUid, // 주문번호
                    impUid: impUid, //결제 idx (정수)
                    totalPay: classPayment.applicationDetails.classPaymentInfoList.totalPay, //최종 금액 (정수)
                    memberName: classPayment.applicationDetails.classPaymentInfoList.memberName, //회원 이름 (문자열)
                    memberEname: classPayment.applicationDetails.classPaymentInfoList.memberEname, //회원 영어 이름 (문자열)
                    memberMobile: classPayment.applicationDetails.classPaymentInfoList.memberMobile, //회원 휴대전화 (문자열)
                    memberPostCode: classPayment.applicationDetails.classPaymentInfoList.memberPostCode, //회원 우편번호 (문자열)
                    memberAddress: classPayment.applicationDetails.classPaymentInfoList.memberAddress, //회원 주소 (문자열)
                    memberAddressSub: classPayment.applicationDetails.classPaymentInfoList.memberAddressSub, //회원 추가 주소 (문자열)
                    bookMemberName: classPayment.applicationDetails.classPaymentInfoList.bookMemberName, //배송지 받는사람 이름 (문자열)
                    bookMemberMobile: classPayment.applicationDetails.classPaymentInfoList.bookMemberMobile, //배송지 받는사람 휴대전화 (문자열)
                    bookMemberPostCode: classPayment.applicationDetails.classPaymentInfoList.bookMemberPostCode, //배송지 우편번호 (문자열)
                    bookMemberAddress: classPayment.applicationDetails.classPaymentInfoList.bookMemberAddress, //배송지 주소 (문자열)
                    bookMemberAddressSub: classPayment.applicationDetails.classPaymentInfoList.bookMemberAddressSub, //배송지 추가 주소 (문자열)
                  };
                  axios.post(`/user/sugang/pay/confirm`, params).then(function (res) {
                    if (res.data.success === true) {
                      router.push({
                        path: "/enrolment/classOk",
                        query: {
                          idxLeSugangPayInfo: classPayment.applicationDetails.idxLeSugangPayInfo,
                        },
                      });
                    } else {
                      alert(res.data.message);
                    }
                  });

                  console.log(res);
                } else {
                  console.log(res);
                }
              }
            );
          } else {
            alert(res.data.message);
          }
        });
      },

      // 카드결제
      requestPay() {
        const applicationDetails = classPayment.applicationDetails;
        const paymentInfo = applicationDetails.classPaymentInfoList;

        // 주소 유효성 검사
        if (!paymentInfo.memberPostCode) {
          return alert("주소를 입력해 주세요.");
        }
        if (!paymentInfo.bookMemberPostCode) {
          return alert("배송지 주소를 입력해 주세요.");
        }

        // 공통 결제 데이터
        const commonParams = {
          memberName: paymentInfo.memberName,
          memberEname: paymentInfo.memberEname,
          memberMobile: paymentInfo.memberMobile,
          memberPostCode: paymentInfo.memberPostCode,
          memberAddress: paymentInfo.memberAddress,
          memberAddressSub: paymentInfo.memberAddressSub,
          bookMemberName: paymentInfo.bookMemberName,
          bookMemberMobile: paymentInfo.bookMemberMobile,
          bookMemberPostCode: paymentInfo.bookMemberPostCode,
          bookMemberAddress: paymentInfo.bookMemberAddress,
          bookMemberAddressSub: paymentInfo.bookMemberAddressSub,
        };

        const params = {
          idxLeSugangPayInfo: applicationDetails.idxLeSugangPayInfo,
          totalPay: paymentInfo.totalPay,
          payWay: applicationDetails.payWay.card,
          ...commonParams,
        };

        // 결제 요청 함수
        const requestPayment = (redirectUrl) => {
          axios.post(`/user/sugang/pay/check`, params).then((res) => {
            if (res.data.success) {
              applicationDetails.merchantUid = res.data.data.merchantUid;
              IMP.request_pay(
                {
                  pg: "kicc.IM000106", // 실결제용 고정값
                  pay_method: "card", // 고정값
                  merchant_uid: applicationDetails.merchantUid,
                  name: `${paymentInfo.sugangAdult}/${paymentInfo.sugangLang}`, // 결제 정보
                  amount: paymentInfo.totalPay,
                  buyer_email: paymentInfo.memberEmail,
                  buyer_name: paymentInfo.memberName,
                  buyer_tel: paymentInfo.memberMobile,
                  notice_url: "https://api-life.carrotapply.com/user/sugang/pay/webhook",
                  m_redirect_url: redirectUrl,
                },
                function (res) {
                  if (res.success) {
                    confirmPayment(res.imp_uid);
                  } else {
                    console.log(res);
                  }
                }
              );
            } else {
              alert(res.data.message);
            }
          });
        };

        // 결제 확인 함수
        const confirmPayment = (impUid) => {
          const confirmParams = {
            payWay: applicationDetails.payWay.card,
            memberEmail: paymentInfo.memberEmail,
            merchantUid: applicationDetails.merchantUid,
            impUid,
            totalPay: paymentInfo.totalPay,
            ...commonParams,
          };

          axios.post(`/user/sugang/pay/confirm`, confirmParams).then((res) => {
            if (res.data.success) {
              router.push({
                path: "/enrolment/classOk",
                query: { idxLeSugangPayInfo: applicationDetails.idxLeSugangPayInfo },
              });
              window.scrollTo({ top: 0, behavior: "smooth" });
            } else {
              alert(res.data.message);
            }
          });
        };

        // 결제 요청 처리 (모바일/데스크탑)
        if (window.innerWidth < 1200) {
          const redirectUrl = `${location.origin}/enrolment/classOkMobile?idxLeSugangPayInfo=${applicationDetails.idxLeSugangPayInfo}&imp_uid={imp_uid}&merchant_uid={merchant_uid}`;

          for (const element of Object.entries(commonParams)) {
            sessionStorage.setItem(element[0], element[1]);
          }

          sessionStorage.setItem("memberEmail", paymentInfo.memberEmail);
          sessionStorage.setItem("totalPay", paymentInfo.totalPay);

          requestPayment(redirectUrl);
        } else {
          requestPayment(null); // 데스크탑의 경우 redirectUrl 필요 없음
        }
      },

      // 결제 금액이 없을 경우 (무료) 호출 하는 함수
      confirmPayZero() {
        const merchantUid = classPayment.applicationDetails.merchantUid;
        const params = {
          idxLeSugangPayInfo: classPayment.applicationDetails.idxLeSugangPayInfo, // 수강결제정보 idx
          memberEmail: classPayment.applicationDetails.classPaymentInfoList.memberEmail, // 신청자 이름
          merchantUid: merchantUid,
          supDocument: classPayment.fileName,
          totalPay: classPayment.applicationDetails.classPaymentInfoList.totalPay, //최종 금액 (정수)
          memberName: classPayment.applicationDetails.classPaymentInfoList.memberName, //회원 이름 (문자열)
          memberEname: classPayment.applicationDetails.classPaymentInfoList.memberEname, //회원 영어 이름 (문자열)
          memberMobile: classPayment.applicationDetails.classPaymentInfoList.memberMobile, //회원 휴대전화 (문자열)
          memberPostCode: classPayment.applicationDetails.classPaymentInfoList.memberPostCode, //회원 우편번호 (문자열)
          memberAddress: classPayment.applicationDetails.classPaymentInfoList.memberAddress, //회원 주소 (문자열)
          memberAddressSub: classPayment.applicationDetails.classPaymentInfoList.memberAddressSub, //회원 추가 주소 (문자열)
          bookMemberName: classPayment.applicationDetails.classPaymentInfoList.bookMemberName, //배송지 받는사람 이름 (문자열)
          bookMemberMobile: classPayment.applicationDetails.classPaymentInfoList.bookMemberMobile, //배송지 받는사람 휴대전화 (문자열)
          bookMemberPostCode: classPayment.applicationDetails.classPaymentInfoList.bookMemberPostCode, //배송지 우편번호 (문자열)
          bookMemberAddress: classPayment.applicationDetails.classPaymentInfoList.bookMemberAddress, //배송지 주소 (문자열)
          bookMemberAddressSub: classPayment.applicationDetails.classPaymentInfoList.bookMemberAddressSub, //배송지 추가 주소 (문자열)
        };
        axios.post(`/user/sugang/pay/confirmPayZero`, params).then(function (res) {
          if (res.data.success === true) {
            document.body.scrollTop = 0;
            document.documentElement.scrollTop = 0;
            if (classPayment.applicationDetails.classPaymentInfoList.waitStatus == "Y") {
              alert(`
              대기 신청이 완료되었습니다.
              신청 확정되면 순차적으로 담당매니저가
              개별 안내 드리겠습니다.
              `);
              router.push({
                path: "/enrolment/classOk",
                query: {
                  idxLeSugangPayInfo: classPayment.applicationDetails.idxLeSugangPayInfo,
                },
              });
            } else {
              router.push({
                path: "/enrolment/classOk",
                query: {
                  idxLeSugangPayInfo: classPayment.applicationDetails.idxLeSugangPayInfo,
                },
              });
            }
          } else {
            alert(res.data.message);
          }
        });
      },

      fileChange: async () => {
        const inputField = event.target;
        const file = inputField.files[0]; // 선택된 파일
        if (file) {
          try {
            const res = await classPayment.uploadFile(file);
            if (res.data.success) {
              classPayment.fileName = res.data.data.uploadUrl;
            } else {
              const inputField = document.querySelector(`input[name="companyLogo"]`);
              inputField.value = "";
              alert(res.data.message);
            }
          } catch (err) {
            console.log(err);
          }
        }
      },

      uploadFile: async (file) => {
        const formData = new FormData();
        formData.append("file", file);

        try {
          const res = await axios.post(`/user/upload`, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          });
          return res;
        } catch (err) {
          throw err;
        }
      },

      companyLogoFileName(url) {
        if (url) {
          const parts = url.split("/");
          return parts[parts.length - 1];
        }
        return "";
      },

      deleteImage(fieldName) {
        classPayment[fieldName] = "";
        const inputField = document.querySelector(`input[name="${fieldName}"]`);
        inputField.value = "";
      },
    });

    const imgDownload = (url) => {
      window.open(url, "_blank");
    };

    onMounted(() => {
      classPayment.applicationDetails.idxLeSugangPayInfo = route.query.idxLeSugangPayInfo;
      classPayment.getRegisterDetail();
    });

    return {
      classPayment,
      imgDownload,
    };
  },
};
</script>
<style></style>
