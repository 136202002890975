<template>
  <section class="pt-[55px] pb-[70px] pc:py-[140px] pc-con-wrapper1">
    <div>
      <p class="pc:pt-[70px] mb-[70px] text-lg font-bold text-center pc:mb-[150px] pc:text-2xl">
        이전에 수강 신청한 내역이 있습니다.<br />이어서 신청 하시겠습니까?
      </p>
      <ul class="grid grid-cols-2 gap-2.5 pc:gap-5 pc:max-w-md mx-auto max-w-xs pb-[124px] pc:pb-0">
        <li
          class="py-4 text-sm font-bold text-center border border-solid rounded cursor-pointer text-neutral-500 pc:py-6 pc:text-xl border-neutral-500 flex justify-center items-center"
          @click="classApplyFirstNext.getRegisterCheckCencle"
        >
          처음부터 신청하기
        </li>
        <li
          class="py-4 text-sm font-bold text-center text-white bg-orange-500 rounded cursor-pointer pc:py-6 pc:text-xl flex justify-center items-center"
          @click="classApplyFirstNext.onClassApplyNext"
        >
          이어서 신청하기
        </li>
      </ul>
    </div>
  </section>
</template>
<script>
import { reactive, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import axios from "@lifeeduWeb/plugins/axios.js";
import { useStore } from "vuex";
export default {
  components: {},
  setup(props, { emit }) {
    const router = useRouter();
    const route = useRoute();
    const store = useStore();

    const idxLeSiteMain = store.getters.getIdxLeSiteMain;

    const classApplyFirstNext = reactive({
      idxLeSiteMain: idxLeSiteMain, // store 에 저장된 idxLeSiteMain 값
      idxLeSugangPayInfo: "", // 기존 수강내역 조회 & 동일수업 신청에 필요한 idxLeSugangPayInfo 값

      // 기존에 수강신청 내역이 있는지 getRegisterCheck 함수 호출 한 다음에 있을때 getRegisterCheckCencle 함수 호출 하면 기존에 입력 했던 data 값을 가진 page로 이동 하는 함수
      getRegisterCheckCencle() {
        const params = {
          idxLeSugangPayInfo: classApplyFirstNext.idxLeSugangPayInfo,
        };

        axios.post(`/user/sugang/class/registerCheckCencle/${params.idxLeSugangPayInfo}`).then(function (res) {
          if (res.data.success === true) {
            router.push({
              path: `/enrolment/newEnrolmentClass`,
              query: {
                idxLeSugangPayInfo: classApplyFirstNext.idxLeSugangPayInfo,
              },
            });
          } else {
            alert(res.data.message);
          }
        });
      },

      // 기존 수강신천 내역을 초기화 하고 처음부터 수강 신청 하는 함수
      onClassApplyNext() {
        router.push({
          path: `/enrolment/checkDetail`,
          query: {
            idxLeSugangPayInfo: classApplyFirstNext.idxLeSugangPayInfo,
          },
        });
      },

      // 기존 수강신청 내역 확인 API 호출 함수
      getRegisterCheck() {
        const params = {
          idxLeSiteMain: idxLeSiteMain,
        };

        axios.get(`/user/sugang/class/registerCheck`, { params }).then(function (res) {
          if (res.data.success === true) {
            classApplyFirstNext.idxLeSugangPayInfo = res.data.data.idxLeSugangPayInfo;
          } else {
            alert(res.data.message);
          }
        });
      },
    });

    onMounted(() => {
      classApplyFirstNext.getRegisterCheck();
    });

    return {
      classApplyFirstNext,
      idxLeSiteMain,
    };
  },
};
</script>
<style></style>
