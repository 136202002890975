<template>
    <div>
        <header class="p-4 bg-slate-800">
            <div class="flex items-center justify-between inner">
                <p
                    class="text-2xl text-white cursor-pointer"
                    @click="goPage('home')"
                >
                    평생교육 글로벌역량 플랫폼 관리자
                </p>
                <ul class="flex gap-4">
                    <li
                        :class="{ 'font-bold': currentPage === 'home' }"
                        class="pr-4 text-white border-r border-white border-solid cursor-pointer drop-shadow-md"
                        @click="goPage('home')"
                    >
                        수강신청 관리
                    </li>
                    <li
                        :class="{
                            'font-bold': currentPage === 'enrolmentStep1',
                        }"
                        class="text-white cursor-pointer drop-shadow-md"
                        @click="goPage('enrolmentStep1')"
                    >
                        수강신청 등록
                    </li>
                    <li
                        :class="{ 'font-bold': currentPage === 'busanSubmission' }"
                        class="pl-4 text-white border-l border-white border-solid cursor-pointer drop-shadow-md"
                        @click="goPage('busanSubmission')"
                    >
                        부산광역시교육청 과제제출
                    </li>
                </ul>
            </div>
        </header>
    </div>
</template>
<script>
import { ref } from "vue";
import { useRouter } from "vue-router";

export default {
    setup() {
        const router = useRouter();

        const goPage = (path) => {
            currentPage.value = path;
            router.push({ name: path });
        };

        const classAdd = () => {
            router.push({ name: "/enrolmentStep1" });
        };

        const currentPage = ref("");

        return {
            goPage,
            classAdd,
            currentPage,
        };
    },
};
</script>
<style></style>
