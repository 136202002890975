import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";

export default createStore({
  state: {
    accessToken: "",
    companyLogo: "",
    idxLeSiteMain: null,
    idxCompany: null,
    isCancleFunction: null,
    userId: "",
  },
  getters: {
    getAccessToken(state) {
      return state.accessToken;
    },
    getCompanyLogo(state) {
      return state.companyLogo;
    },
    getIdxLeSiteMain(state) {
      return state.idxLeSiteMain;
    },
    getIdxCompany(state) {
      return state.idxCompany;
    },
  },
  mutations: {
    setAccessToken(state, accessToken) {
      state.accessToken = accessToken;
    },
    setCompanyLogo(state, logo) {
      state.companyLogo = logo;
    },
    setIdxLeSiteMain(state, idxLeSiteMain) {
      state.idxLeSiteMain = idxLeSiteMain;
    },
    setIdxCompany(state, idxCompany) {
      state.idxCompany = idxCompany;
    },
    setIsCancleFunction(state, value) {
      state.isCancleFunction = value;
    },
    setUserId(state, id) {
      state.userId = id;
    },
  },
  actions: {
    setCompanyLogo({ commit }, logo) {
      commit("setCompanyLogo", logo);
    },
  },
  plugins: [
    createPersistedState({
      key: "LifeEduUserVuex",
    }),
  ],
});
