<template>
  <div>
    <section class="pt-[55px] pb-[70px] pc:py-[140px] pc-con-wrapper1">
      <p class="hidden text-lg font-bold text-center pc:text-5xl text-neutral-950 mb-11 pc:block">회원가입</p>
      <div class="px-5 border border-solid py-7 pc:py-11 border-neutral-200 rounded-2xl">
        <div>
          <p class="text-lg font-bold text-center text-neutral-950 mb-7 pc:text-2xl pc:mb-16">
            화상외국어과정 참여를 위한 만 14세 미만 법정 대리인 동의서
          </p>
          <div class="mb-14 pc:mb-11">
            <div
              class="p-5 text-xs border border-solid rounded pc:text-base border-neutral-200 text-neutral-500 mb-2.5 whitespace-pre-line"
            >
              {{ signUser.agentAgreeText }}
              <div
                class="text-xs font-bold cursor-pointer pc:text-xl text-neutral-950 mt-[24px] w-full flex items-center justify-center"
              >
                위 사항에 동의하실 경우 아래 '동의함' 버튼을 눌러주시기 바랍니다.
              </div>
            </div>
            <div>
              <label class="text-xs font-bold cursor-pointer pc:text-base check-label text-neutral-950"
                >위 이용약관의 내용에 동의합니다.
                <input type="checkbox" v-model="signUser.agentAgreeCheckbox" />
                <div class="checkmark"></div>
              </label>
            </div>
          </div>

          <ul class="grid grid-cols-2 gap-2.5 pc:gap-5 pc:max-w-md mx-auto max-w-xs">
            <li
              class="py-4 text-sm font-bold text-center border border-solid rounded cursor-pointer text-neutral-500 pc:py-6 pc:text-xl border-neutral-500"
              @click="signUser.backSignup1()"
            >
              돌아가기
            </li>
            <li
              class="py-4 text-sm font-bold text-center text-white bg-orange-500 rounded cursor-pointer pc:py-6 pc:text-xl"
              @click="signUser.nextSignPage"
            >
              다음
            </li>
          </ul>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import { reactive } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

export default {
  components: {},
  setup() {
    const store = useStore();
    const router = useRouter();
    const idxLeSiteMain = store.getters.getIdxLeSiteMain;

    const signUser = reactive({
      agentAgreeCheckbox: false,
      agentAgreeText: `본 동의서는 귀하(부모님 또는 보호자)의 자녀(만 14세 미만)가 ㈜캐럿솔루션즈에서 진행하는 화상외국어과정 참여를 위하여 필요한 최소한의 자녀(만 14세 미만)의 개인정보를 제공하는 것에 귀하의 동의 여부를 확인하는 내용의 동의서입니다.

개인정보 보호법 제22조 에 의해서 만 14세 미만의 아동으로부터 개인정보 수집, 이용 제공 등의 동의를 받으려면 법정 대리인(부모님 또는 보호자)의 동의를 받아야 합니다.

이 경우에 당 사는 자녀에게 법정 대리인(부모님 또는 보호자)의 동의를 받기 위하여 필요한 법정 대리인의 성명 등 최소한의 정보를 요구할 수 있습니다. 

동의하실 경우 아래 내용을 자세히 읽어보시고 동의 여부를 확인해 주시길 바랍니다.

귀하(부모님 또는 보호자)의 자녀(만 14세 미만)가 개인정보 수집, 이용, 제공 등에 동의하는 것에 대한 안내 사항입니다.

=======================================================================

1) ㈜캐럿솔루션즈는 개인정보 보호법 22조에 따라 귀하의 동의를 확인한 후 자녀의 프로그램 참여 절차를 처리합니다.

2) 귀하는 자녀의 개인정보에 대하여 수집, 이용, 제공 등의 동의를 언제든지 철회할 수 있습니다.

3) 당 사는 교육 시 필요한 개인정보의 열람, 제공을 요청할 수 있으며, 오류가 있는 경우에는 그 정정을 요구할 수 있습니다.

4) 귀하가 자녀에 대한 개인정보 수집, 이용, 제공 등에 대한 동의 확인을 위해 제공한 개인정보는 이용목적을 다한 경우에는 재생이 불가능한 방법으로 즉시 파기합니다.

5) 기타 개인정보 침해에 대한 신고나 상담, 분쟁조정이 필요하신 경우에는 개인정보침해신고센터(http://1336.or.kr 또는 전화 국번 없이 1336)로 문의하시기 바랍니다.
            `,

      backSignup1() {
        router.push({ name: "signUp1" });
      },

      nextSignPage() {
        if (signUser.agentAgreeCheckbox) {
          router.push({ name: "signUp3" });
        } else {
          alert("화상외국어과정 참여를 위한 만 14세 미만 법정 대리인 동의서에 대해 동의 해주셔야 합니다.");
        }
      },
    });

    return {
      signUser,
    };
  },
};
</script>
<style scoped></style>
