import { createRouter, createWebHistory } from "vue-router";
import routes from "vue-auto-routing";
import { createRouterLayout } from "vue-router-layout";

import connectionHQ from "@lifeeduAdmin/pages/connectionHQ.vue";
import index from "@lifeeduAdmin/pages/index.vue";
import home from "@lifeeduAdmin/pages/home.vue";
import applicantList from "@lifeeduAdmin/pages/applicantList.vue";
import currentSituationBoard from "@lifeeduAdmin/pages/currentSituationBoard.vue";
import cancelerList from "@lifeeduAdmin/pages/cancelerList.vue";
import courseHistory from "@lifeeduAdmin/pages/courseHistory.vue";
import noticeManagement from "@lifeeduAdmin/pages/noticeManagement.vue";
import noticeCreate from "@lifeeduAdmin/pages/noticeCreate.vue";
import enrolmentStep1 from "@lifeeduAdmin/pages/enrolmentStep1.vue";
import enrolmentStep2 from "@lifeeduAdmin/pages/enrolmentStep2.vue";
import enrolmentStep3_1 from "@lifeeduAdmin/pages/enrolmentStep3_1.vue";
import enrolmentStep3_2 from "@lifeeduAdmin/pages/enrolmentStep3_2.vue";
import busanSubmission from "@lifeeduAdmin/pages/busanSubmission.vue";
import questionPage from "@lifeeduAdmin/pages/questionPage.vue";

const RouterLayout = createRouterLayout((layout) => {
  return import("@lifeeduAdmin/pages/" + layout + ".vue");
});

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: [
    // { path: "/", redirect: "/home" },
    {
      path: "/",
      component: index,
      children: routes,
    },
    {
      path: "/connectionHQ",
      component: connectionHQ,
      children: routes,
    },
    {
      // 메인
      path: "/home",
      name: "home",
      component: home,
      children: routes,
    },
    {
      // 신청자 리스트
      path: "/applicantList",
      name: "applicantList",
      component: applicantList,
      children: routes,
    },
    {
      // 현황 Board
      path: "/currentSituationBoard",
      name: "currentSituationBoard",
      component: currentSituationBoard,
      children: routes,
    },
    {
      // 취소자 리스트
      path: "/cancelerList",
      name: "cancelerList",
      component: cancelerList,
      children: routes,
    },
    {
      // 수강내역
      path: "/courseHistory",
      name: "courseHistory",
      component: courseHistory,
      children: routes,
    },
    {
      // 공지사항 관리
      path: "/noticeManagement",
      name: "noticeManagement",
      component: noticeManagement,
      children: routes,
    },
    {
      // 공지사항 등록, 수정
      path: "/noticeCreate",
      name: "noticeCreate",
      component: noticeCreate,
      children: routes,
    },
    {
      // 수강신청 등록 (1단계)
      path: "/enrolmentStep1",
      name: "enrolmentStep1",
      component: enrolmentStep1,
      children: routes,
    },
    {
      // 수강신청 등록 (2단계)
      path: "/enrolmentStep2",
      name: "enrolmentStep2",
      component: enrolmentStep2,
      children: routes,
    },
    {
      // 수강신청 등록 (3단계 - 1:1)
      path: "/enrolmentStep3_1",
      name: "enrolmentStep3_1",
      component: enrolmentStep3_1,
      children: routes,
    },
    {
      // 수강신청 등록 (3단계 - 그룹)
      path: "/enrolmentStep3_2",
      name: "enrolmentStep3_2",
      component: enrolmentStep3_2,
      children: routes,
    },
    {
      // 부산광역시교육청 과제제출
      path: "/busanSubmission",
      name: "busanSubmission",
      component: busanSubmission,
      children: routes,
    },
    {
      // 부산광역시교육청 과제제출
      path: "/questionPage",
      name: "questionPage",
      component: questionPage,
      children: routes,
    },
  ],
  scrollBehavior(to, from, savedPosition) {
    return { top: 0, behavior: "smooth" };
  },
});

export default router;
