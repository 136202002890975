<template>
  <div>
    <div class="hidden pc:block bg-wrapper">
      <div class="flex items-center justify-center bg py-[76px] max-w-[1920px] mx-auto">
        <p class="text-[40px] font-bold text-neutral-950">공지사항</p>
      </div>
    </div>

    <section class="pt-[55px] pb-[70px] pc:pt-[70px] pc:pb-[140px] pc-con-wrapper1">
      <table class="mb-12 border-t border-b border-solid border-neutral-950 pc:mb-16">
        <thead class="border-b border-solid bg-neutral-50 border-neutral-200">
          <tr>
            <th class="p-6 font-medium text-neutral-950">번호</th>
            <th class="p-6 font-medium text-neutral-950">제목</th>
            <th class="hidden w-48 p-6 font-medium pc:table-cell text-neutral-950">등록일</th>
            <th class="hidden p-6 font-medium pc:table-cell text-neutral-950">조회수</th>
          </tr>
        </thead>
        <tbody v-for="item in noticeInfo.noticeInfoContent.noticeInfoList" :key="item">
          <tr
            class="border-b border-solid border-neutral-200"
            @click="noticeInfo.goNoticeDetailContent(item.idxNotice, noticeInfo.noticeInfoContent.idxLeSiteMain)"
          >
            <td class="p-6 text-neutral-500">{{ item.rowNum }}</td>
            <td class="p-6 text-left cursor-pointer text-neutral-950">
              {{ item.title }}
            </td>
            <td class="hidden p-6 pc:table-cell text-neutral-500">
              {{ item.createDate }}
            </td>
            <td class="hidden p-6 pc:table-cell text-neutral-500">
              {{ item.viewCount }}
            </td>
          </tr>
        </tbody>
      </table>
    </section>
  </div>
</template>
<script>
import { reactive, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import axios from "@lifeeduWeb/plugins/axios.js";
import { useStore } from "vuex";

export default {
  components: {},

  setup() {
    const router = useRouter();
    const route = useRoute();
    const store = useStore();
    const idxLeSiteMain = store.getters.getIdxLeSiteMain;

    const noticeInfo = reactive({
      noticeInfoContent: {
        rowNum: "",
        idxNotice: "",
        idxLeSiteMain: idxLeSiteMain,
        pageNum: 1,
        pageSize: 50,
        viewCount: "",
        title: "",
        content: "",
        noticeInfoList: [],
      },

      noticeList() {
        const params = {
          idxLeSiteMain: idxLeSiteMain,
          pageNum: noticeInfo.noticeInfoContent.pageNum,
          pageSize: noticeInfo.noticeInfoContent.pageSize,
        };
        axios.get(`/user/page/noticeList`, { params }).then(function (res) {
          if (res.data.success === true) {
            noticeInfo.noticeInfoContent.noticeInfoList = res.data.data;
          } else {
            alert(res.data.message);
          }
        });
      },

      goNoticeDetailContent(idxNotice, idxLeSiteMain) {
        router.push({
          path: "/learningSupport/noticeDetail",
          query: {
            idxNotice: idxNotice,
            idxLeSiteMain: idxLeSiteMain,
          },
        });
      },
    });

    onMounted(() => {
      noticeInfo.noticeList();
    });

    return {
      noticeInfo,
      idxLeSiteMain,
    };
  },
};
</script>
<style scoped></style>
