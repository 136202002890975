<template>
  <div>
    <section class="pt-[55px] pb-[70px] pc:py-[140px] pc-con-wrapper1">
      <p class="hidden text-lg font-bold text-center pc:text-5xl text-neutral-950 mb-11 pc:block">로그인</p>
      <div class="px-5 border border-solid py-7 pc:py-11 border-neutral-200 rounded-2xl">
        <div class="max-w-xs mx-auto">
          <ul class="flex flex-col mb-4 gap-11">
            <li class="flex flex-col gap-4">
              <label for="userId" class="block text-sm font-bold pc:text-lg text-neutral-950">아이디</label>
              <input
                type="text"
                id="userId"
                placeholder="아이디를 입력해 주세요."
                class="p-4 text-sm font-bold border border-solid rounded border-neutral-200 text-neutral-950 placeholder:text-neutral-200 placeholder:font-light pc:text-base"
                v-model="registUser.userInfo.id"
              />
            </li>
            <li class="flex flex-col gap-4">
              <label for="userPw" class="block text-sm font-bold pc:text-lg text-neutral-950">비밀번호</label>
              <input
                type="password"
                id="userPw"
                placeholder="비밀번호를 입력해 주세요."
                class="p-4 text-sm font-bold border border-solid rounded border-neutral-200 text-neutral-950 placeholder:text-neutral-200 placeholder:font-light pc:text-base"
                v-model="registUser.userInfo.password"
                @keydown.enter="registUser.userLogin()"
              />
            </li>
          </ul>
          <div class="mb-8 pc:mb-10">
            <label class="text-xs font-light cursor-pointer pc:text-base check-label text-neutral-500"
              >아이디 저장
              <input type="checkbox" v-model="registUser.rememberId" @change="registUser.checkRememberId" />
              <div class="checkmark"></div>
            </label>
          </div>
          <p class="hidden mb-8 text-xs pc:text-base pc:mb-10 text-rose-600">
            * 아이디 또는 비밀번호를 잘못 입력했습니다.<br />입력하신 내용을 다시 확인해주세요.
          </p>
          <p
            class="py-4 text-sm font-bold text-center text-white bg-orange-500 rounded cursor-pointer pc:py-6 pc:text-xl mb-11"
            @click="registUser.userLogin()"
          >
            로그인
          </p>
          <ul class="flex items-center justify-center gap-2">
            <li class="text-sm cursor-pointer pc:text-lg text-neutral-500" @click="registUser.signUser">회원가입</li>
            <li class="text-sm pc:text-lg text-neutral-500">ㅣ</li>
            <li class="text-sm cursor-pointer pc:text-lg text-neutral-500" @click="registUser.findInfo">아이디/비밀번호 찾기</li>
          </ul>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import { reactive, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import axios from "@lifeeduWeb/plugins/axios.js";
import { useStore } from "vuex";

export default {
  components: {},
  setup() {
    const router = useRouter();
    const route = useRoute();
    const store = useStore();
    const idxLeSiteMain = store.getters.getIdxLeSiteMain;

    const registUser = reactive({
      userInfo: {
        id: "",
        password: "",
      },
      rememberId: false,
      idxLeSiteMain: idxLeSiteMain,

      checkRememberId() {
        if (registUser.rememberId) {
          // 아이디를 로컬 스토리지에 저장
          localStorage.setItem("rememberedUserId", registUser.userInfo.id);
        } else {
          // 아이디를 로컬 스토리지에서 제거
          localStorage.removeItem("rememberedUserId");
        }
      },

      userLogin() {
        const id = registUser.userInfo.id;
        const password = registUser.userInfo.password;
        axios.post(`/user/auth/login`, { id, password }).then(function (res) {
          if (res.data.success === true) {
            const accessToken = res.data.data.accessToken;
            store.commit("setAccessToken", accessToken);
            store.commit("setUserId", id);

            router.push({
              path: "/home",
              query: {
                idxLeSiteMain: registUser.idxLeSiteMain,
              },
            });
          } else {
            alert(`아이디 또는 비밀번호를 잘못 입력했습니다. \n입력하신 내용을 다시 확인해주세요.`);
          }
        });
      },

      findInfo() {
        router.push({ path: "/login/findId" });
      },

      signUser() {
        router.push({ path: "/signup/signUp1" });
      },
    });

    onMounted(() => {
      const rememberedUserId = localStorage.getItem("rememberedUserId");
      if (rememberedUserId) {
        registUser.userInfo.id = rememberedUserId;
        registUser.rememberId = true;
      }
    });

    return {
      registUser,
      idxLeSiteMain,
    };
  },
};
</script>
<style scoped></style>
