<template>
  <div id="printContent" class="printContents fixed inset-0 size-full bg-white z-[2] pc:bg-white scroll-custom overflow-auto">
    <div class="flex items-center justify-center h-full min-h-full pc:h-auto pc:p-4">
      <div class="printContents w-full p-5 pb-10 bg-white h-full pc:max-w-[900px] pc:h-auto pc:rounded-2xl">
        <div class="flex items-center justify-between mb-4">
          <div class="group">
            <div
              v-if="!app.isPrint"
              @click="app.print()"
              class="inline-flex items-center gap-1.5 border border-solid border-[#737373] px-2.5 py-1 rounded-md cursor-pointer group-hover:border-[#F97316]"
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="17.818" height="18.161">
                <defs>
                  <clipPath id="a"><path data-name="사각형 7716" fill="none" d="M0 0h17.818v18.161H0z" /></clipPath>
                </defs>
                <g data-name="그룹 18081">
                  <g data-name="그룹 18080" clip-path="url(#a)" class="fill-[#737373] group-hover:fill-[#F97316]">
                    <path
                      data-name="패스 58920"
                      d="M15.319 14.905h-1.385a.678.678 0 1 1 0-1.357h1.385a1.138 1.138 0 0 0 .807-.334 1.133 1.133 0 0 0 .335-.807V8.088a1.142 1.142 0 0 0-1.142-1.142H2.5a1.139 1.139 0 0 0-.445.09 1.151 1.151 0 0 0-.607.609 1.13 1.13 0 0 0-.089.444v4.318a1.133 1.133 0 0 0 .089.444 1.172 1.172 0 0 0 .246.365 1.146 1.146 0 0 0 .361.243 1.133 1.133 0 0 0 .445.09h1.384a.678.678 0 0 1 0 1.357H2.5a2.5 2.5 0 0 1-2.5-2.5V8.088a2.5 2.5 0 0 1 2.5-2.5h12.819a2.5 2.5 0 0 1 2.5 2.5v4.318a2.5 2.5 0 0 1-2.5 2.5"
                    />
                    <path
                      data-name="패스 58921"
                      d="M13.071 18.162H4.748a.678.678 0 0 1-.677-.679v-6.151a.678.678 0 0 1 .678-.678h8.322a.678.678 0 0 1 .678.678v6.151a.678.678 0 0 1-.678.678m-7.642-1.357h6.962v-4.793H5.427Z"
                    />
                    <path
                      data-name="패스 58922"
                      d="M13.071 6.415a.678.678 0 0 1-.678-.678v-4.38H5.428v4.38a.678.678 0 1 1-1.357 0V.678A.678.678 0 0 1 4.749 0h8.322a.678.678 0 0 1 .678.678v5.059a.678.678 0 0 1-.678.678"
                    />
                  </g>
                </g>
              </svg>
              <p class="text-lg text-[#737373] group-hover:text-[#F97316]">Print</p>
            </div>
          </div>
          <p class="pc:hidden"></p>
          <img
            v-if="!app.isPrint"
            @click="app.close"
            src="@/lifeeduWeb/assets/images/common/close.png"
            alt="icon"
            class="w-4 cursor-pointer"
          />
        </div>

        <div class="pb-1">
          <p class="mb-3 text-xl font-bold text-center pc:text-2xl pc:mb-2.5">
            {{ app.questionInfo.bookName }}<br />{{ app.questionInfo.round }}회차 / 총
            {{ app.questionInfo.questionDataOptionList.length }}문항
          </p>

          <p class="text-sm text-[#737373] text-center mb-7 pc:text-lg pc:mb-12">
            한번 제출한 과제는 수정이 불가하니, 꼭! 신중하게 풀어주세요!
          </p>

          <ul class="space-y-[30px] pb-10 mb-10 border-b border-solid border-[#707070] pc:space-y-10">
            <div
              v-for="(question, index) in app.questionInfo.questionDataOptionList"
              :key="question.idxLeSugangQuestionUserDataOption"
            >
              <li v-if="question.type === '객관식'" class="pc:px-[88px]">
                <div class="printContents flex items-center gap-3.5 px-4 py-2.5 bg-[#F4F4F5] rounded-md mb-3 pc:p-3 pc:mb-5">
                  <div
                    :class="{ correct: question.isCorrect === 'Y', incorrect: question.isCorrect === 'N' }"
                    class="printContents relative flex items-center justify-center bg-white rounded-md size-9"
                  >
                    <p class="text-sm text-[#737373] text-center pc:text-xl">
                      {{ index + 1 < 10 ? "0" + (index + 1) : index + 1 }}
                    </p>
                  </div>
                  <p class="text-sm text-[#737373] font-medium pc:text-xl">
                    <span v-html="question.title"> </span>
                  </p>
                </div>

                <p class="mb-5 text-lg font-bold text-center pc:px-[60px] pc:text-2xl pc:text-left pc:mb-7">
                  <span v-html="question.subTitle"></span>
                  <!-- My dog has <span>_</span> eyes and small feet. -->
                </p>

                <ul class="flex flex-col px-5 gap-2.5 pc:px-[60px]">
                  <li>
                    <label
                      :class="{
                        'radio-incorrect': question.isCorrect === 'N' && question.userAnswer === 'a',
                        'radio-correct':
                          (question.isCorrect === 'Y' && question.userAnswer === 'a') ||
                          (question.isCorrect === 'N' && question.correctAnswer === 'a'),
                      }"
                      class="radio-label px-2.5 py-1.5 rounded-full"
                    >
                      <input
                        v-model="question.userAnswer"
                        :value="'a'"
                        :checked="question.userAnswer === 'a'"
                        :name="question.idxLeSugangQuestionUserDataOption"
                        :disabled="app.isResult"
                        type="radio"
                      />
                      <div class="radiomark"></div>
                      a. {{ question.contentA }}
                    </label>
                  </li>
                  <li>
                    <label
                      :class="{
                        'radio-incorrect': question.isCorrect === 'N' && question.userAnswer === 'b',
                        'radio-correct':
                          (question.isCorrect === 'Y' && question.userAnswer === 'b') ||
                          (question.isCorrect === 'N' && question.correctAnswer === 'b'),
                      }"
                      class="radio-label px-2.5 py-1.5 rounded-full"
                    >
                      <input
                        v-model="question.userAnswer"
                        :value="'b'"
                        :checked="question.userAnswer === 'b'"
                        :disabled="app.isResult"
                        type="radio"
                        :name="question.idxLeSugangQuestionUserDataOption"
                      />
                      <div class="radiomark"></div>
                      b. {{ question.contentB }}
                    </label>
                  </li>
                  <li>
                    <label
                      :class="{
                        'radio-incorrect': question.isCorrect === 'N' && question.userAnswer === 'c',
                        'radio-correct':
                          (question.isCorrect === 'Y' && question.userAnswer === 'c') ||
                          (question.isCorrect === 'N' && question.correctAnswer === 'c'),
                      }"
                      class="radio-label px-2.5 py-1.5 rounded-full"
                    >
                      <input
                        v-model="question.userAnswer"
                        :value="'c'"
                        :checked="question.userAnswer === 'c'"
                        :disabled="app.isResult"
                        type="radio"
                        :name="question.idxLeSugangQuestionUserDataOption"
                      />
                      <div class="radiomark"></div>
                      c. {{ question.contentC }}
                    </label>
                  </li>
                  <li>
                    <label
                      :class="{
                        'radio-incorrect': question.isCorrect === 'N' && question.userAnswer === 'd',
                        'radio-correct':
                          (question.isCorrect === 'Y' && question.userAnswer === 'd') ||
                          (question.isCorrect === 'N' && question.correctAnswer === 'd'),
                      }"
                      class="radio-label px-2.5 py-1.5 rounded-full"
                    >
                      <input
                        v-model="question.userAnswer"
                        :value="'d'"
                        :checked="question.userAnswer === 'd'"
                        :disabled="app.isResult"
                        type="radio"
                        :name="question.idxLeSugangQuestionUserDataOption"
                      />
                      <div class="radiomark"></div>
                      d. {{ question.contentD }}
                    </label>
                  </li>
                </ul>

                <div
                  v-if="app.isResult"
                  class="mt-5 p-[18px] pc:px-[62px] pc:py-6 border border-solid border-[#E5E5E5] rounded-md space-y-2.5 pc:mt-7 pc:space-y-4"
                >
                  <p class="font-medium pc:text-lg">정답해설</p>
                  <p class="text-sm text-[#737373] font-medium pc:text-base">
                    {{ question.correctAnswerExplanation }}
                  </p>
                </div>
              </li>

              <li v-if="question.type === '객관식(이미지)'" class="pc:px-[88px]">
                <div class="printContents flex items-center gap-3.5 px-4 py-2.5 bg-[#F4F4F5] rounded-md mb-3 pc:p-3 pc:mb-5">
                  <div
                    :class="{ correct: question.isCorrect === 'Y', incorrect: question.isCorrect === 'N' }"
                    class="printContents relative flex items-center justify-center bg-white rounded-md size-9"
                  >
                    <p class="text-sm text-[#737373] text-center pc:text-xl">
                      {{ index + 1 < 10 ? "0" + (index + 1) : index + 1 }}
                    </p>
                  </div>
                  <p class="text-sm text-[#737373] font-medium pc:text-xl">
                    <span v-html="question.title"> </span>
                  </p>
                </div>

                <div class="px-5 mb-5 overflow-hidden rounded-md pc:px-[60px]">
                  <img :src="question.image" alt="img" class="pc:max-w-[326px]" />
                </div>

                <ul class="flex flex-col px-5 gap-2.5 pc:px-[60px]">
                  <li>
                    <label
                      :class="{
                        'radio-incorrect': question.isCorrect === 'N' && question.userAnswer === 'a',
                        'radio-correct':
                          (question.isCorrect === 'Y' && question.userAnswer === 'a') ||
                          (question.isCorrect === 'N' && question.correctAnswer === 'a'),
                      }"
                      class="radio-label px-2.5 py-1.5 rounded-full"
                    >
                      <input
                        v-model="question.userAnswer"
                        :value="'a'"
                        :disabled="app.isResult"
                        :name="question.idxLeSugangQuestionUserDataOption"
                        type="radio"
                      />
                      <div class="radiomark"></div>
                      a. {{ question.contentA }}
                    </label>
                  </li>
                  <li>
                    <label
                      :class="{
                        'radio-incorrect': question.isCorrect === 'N' && question.userAnswer === 'b',
                        'radio-correct':
                          (question.isCorrect === 'Y' && question.userAnswer === 'b') ||
                          (question.isCorrect === 'N' && question.correctAnswer === 'b'),
                      }"
                      class="radio-label px-2.5 py-1.5 rounded-full"
                    >
                      <input
                        type="radio"
                        v-model="question.userAnswer"
                        :disabled="app.isResult"
                        :value="'b'"
                        :name="question.idxLeSugangQuestionUserDataOption"
                      />
                      <div class="radiomark"></div>
                      b. {{ question.contentB }}
                    </label>
                  </li>
                  <li>
                    <label
                      :class="{
                        'radio-incorrect': question.isCorrect === 'N' && question.userAnswer === 'c',
                        'radio-correct':
                          (question.isCorrect === 'Y' && question.userAnswer === 'c') ||
                          (question.isCorrect === 'N' && question.correctAnswer === 'c'),
                      }"
                      class="radio-label px-2.5 py-1.5 rounded-full"
                    >
                      <input
                        type="radio"
                        v-model="question.userAnswer"
                        :value="'c'"
                        :disabled="app.isResult"
                        :name="question.idxLeSugangQuestionUserDataOption"
                      />
                      <div class="radiomark"></div>
                      c. {{ question.contentC }}
                    </label>
                  </li>
                  <li>
                    <label
                      :class="{
                        'radio-incorrect': question.isCorrect === 'N' && question.userAnswer === 'd',
                        'radio-correct':
                          (question.isCorrect === 'Y' && question.userAnswer === 'd') ||
                          (question.isCorrect === 'N' && question.correctAnswer === 'd'),
                      }"
                      class="radio-label px-2.5 py-1.5 rounded-full"
                    >
                      <input
                        type="radio"
                        v-model="question.userAnswer"
                        :disabled="app.isResult"
                        :value="'d'"
                        :name="question.idxLeSugangQuestionUserDataOption"
                      />
                      <div class="radiomark"></div>
                      d. {{ question.contentD }}
                    </label>
                  </li>
                </ul>

                <div
                  v-if="app.isResult"
                  class="mt-5 p-[18px] pc:px-[62px] pc:py-6 border border-solid border-[#E5E5E5] rounded-md space-y-2.5 pc:mt-7 pc:space-y-4"
                >
                  <p class="font-medium pc:text-lg">정답해설</p>
                  <p class="text-sm text-[#737373] font-medium pc:text-base">
                    {{ question.correctAnswerExplanation }}
                  </p>
                </div>
              </li>

              <li v-if="question.type === '주관식'" class="pc:px-[88px]">
                <div class="printContents flex items-center gap-3.5 px-4 py-2.5 bg-[#F4F4F5] rounded-md mb-3 pc:p-3 pc:mb-5">
                  <div
                    :class="{ correct: question.isCorrect === 'Y', incorrect: question.isCorrect === 'N' }"
                    class="printContents relative flex items-center justify-center bg-white rounded-md size-9"
                  >
                    <p class="text-sm text-[#737373] text-center pc:text-xl">
                      {{ index + 1 < 10 ? "0" + (index + 1) : index + 1 }}
                    </p>
                  </div>
                  <p class="text-sm text-[#737373] font-medium pc:text-xl">
                    <span v-html="question.title"> </span>
                  </p>
                </div>

                <p class="mb-5 text-lg font-bold text-center pc:px-[60px] pc:text-2xl pc:text-left pc:mb-7">
                  <span v-html="question.subTitle"></span>

                  <!-- Flora / her / is / favorite / pink / dress / wearing -->
                </p>

                <div class="px-5 pc:px-[60px]">
                  <textarea
                    v-if="!app.isPrint"
                    :disabled="app.isResult"
                    v-model="question.userAnswer"
                    name=""
                    id=""
                    class="block w-full min-h-[100px] p-5 border border-solid border-[#737373] rounded-md resize-none text-lg font-medium pc:text-xl"
                  ></textarea>
                  <div
                    v-else
                    class="block w-full min-h-[100px] p-5 border border-solid border-[#737373] rounded-md resize-none text-lg font-medium pc:text-xl"
                  >
                    {{ question.correctAnswer }}
                  </div>
                </div>

                <div
                  v-if="app.isResult"
                  class="mt-5 p-[18px] pc:px-[62px] pc:py-6 border border-solid border-[#E5E5E5] rounded-md space-y-2.5 pc:mt-7 pc:space-y-4"
                >
                  <p class="font-medium pc:text-lg">정답해설</p>
                  <p class="text-sm text-[#737373] font-medium pc:text-base">
                    {{ question.correctAnswerExplanation }}
                  </p>
                </div>
              </li>
            </div>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { reactive, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import axios from "@lifeeduAdmin/plugins/axios.js";
import "@lifeeduWeb/assets/css/tailwind.css";

const router = useRouter();
const route = useRoute();
const store = useStore();

const app = reactive({
  isResult: false,
  isPrint: false,
  params: {
    round: route.query.round,
    submissionStatus: route.query.check,
    idxCtmMember: route.query.idxCtmMember,
    userName: route.query.userName,
  },
  questionInfo: { questionDataOptionList: [] },
  close: () => {
    window.close();
  },
  getQuestionList: async () => {
    const result = await axios.get(`/admin/question/exam/${app.params.idxCtmMember}/${app.params.round}`);
    const { success, message, data } = result.data;

    if (!success) {
      alert(message);
      window.close();
      return;
    }
    app.questionInfo = {
      ...data,
      questionDataOptionList: data.questionDataOptionList.map((item) => {
        return { ...item, userAnswer: "" };
      }),
    };
  },
  getResultList: async () => {
    const result = await axios.get(`/admin/question/result/${app.params.idxCtmMember}/${app.params.round}`);

    const { success, message, data } = result.data;

    if (!success) {
      alert(message);
      return;
    }
    app.questionInfo = data;
  },
  print: () => {
    app.isPrint = true;
    setTimeout(() => {
      const printContents = document.getElementById("printContent").innerHTML;
      const originalContents = document.body.innerHTML;

      window.onbeforeprint = () => {
        document.title = `부산광역시교육청 NALDA 화상영어 자기주도학습 ${app.params.round}회차 ${app.params.userName}`;
        document.body.innerHTML = printContents;
      };

      window.onafterprint = () => {
        app.isPrint = false;
        window.location.reload();
        document.body.innerHTML = originalContents;
      };
      window.print();
    }, 500);
  },
});

onMounted(() => {
  console.log(app.params.idxCtmMember);
  if (app.params.submissionStatus === "true") {
    app.isResult = true;
    app.getResultList();
  } else {
    app.isResult = false;
    app.getQuestionList();
  }
});
</script>

<style lang="scss" scoped>
.radio-label {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  font-size: 18px;
  font-weight: 500;
  cursor: pointer;

  @media screen and (min-width: 1200px) {
    font-size: 24px;
  }
}
.radio-label input {
  display: none;
}
.radio-label .radiomark {
  flex: none;
  width: 24px;
  height: 24px;
  background: url(@/lifeeduWeb/assets/images/selfLearning/radio-off.png) no-repeat center / cover;
  border-radius: 100%;
  -webkit-print-color-adjust: exact;
}
// 기본 체크 된 거
.radio-label input:checked ~ .radiomark {
  background: url(@/lifeeduWeb/assets/images/selfLearning/radio-on.png) no-repeat center / cover;
  -webkit-print-color-adjust: exact;
}

// 채점완료 화면에서 정답인 문제에 체크 된 거
.radio-label {
  &.radio-correct {
    background-color: #e1f6ff;
    -webkit-print-color-adjust: exact;
  }
}
.radio-label.radio-correct input:checked ~ .radiomark {
  background: url(@/lifeeduWeb/assets/images/selfLearning/radio-correct.png) no-repeat center / cover;
  -webkit-print-color-adjust: exact;
}

// 채점완료 화면에서 오답인 문제에 정답 체크 된 거
.radio-label {
  &.radio-incorrect {
    background-color: #ffe8ed;
    -webkit-print-color-adjust: exact;
  }
}
.radio-label.radio-incorrect input:checked ~ .radiomark {
  background: url(@/lifeeduWeb/assets/images/selfLearning/radio-incorrect.png) no-repeat center / cover;
  -webkit-print-color-adjust: exact;
}

// 채점된 화면에서 정답에 동그라미 표시
.correct {
  &::after {
    content: "";
    position: absolute;
    width: 40px;
    height: 40px;
    background: url(@/lifeeduWeb/assets/images/selfLearning/correct.png) no-repeat center / cover;

    -webkit-print-color-adjust: exact;
    @media screen and (min-width: 1200px) {
      width: 50px;
      height: 50px;
    }
  }
}

// 채점된 화면에서 오답에 작대기 표시
.incorrect {
  &::after {
    content: "";
    position: absolute;
    width: 40px;
    height: 40px;
    background: url(@/lifeeduWeb/assets/images/selfLearning/incorrect.png) no-repeat center / cover;
    -webkit-print-color-adjust: exact;

    @media screen and (min-width: 1200px) {
      width: 50px;
      height: 50px;
    }
  }
}

.scroll-custom {
  &::-webkit-scrollbar {
    display: none;
  }
}

.printContents {
  -webkit-print-color-adjust: exact;
}
</style>
