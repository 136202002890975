<template>
  <div>
    <div class="hidden pc:block bg-wrapper">
      <div class="flex items-center justify-center bg py-[76px] max-w-[1920px] mx-auto">
        <p class="text-[40px] font-bold text-neutral-950">성인 과정</p>
      </div>
    </div>

    <section class="pt-[55px] pb-[70px] pc:pt-[70px] pc:pb-[140px]">
      <div>
        <div class="flex items-center justify-between p-2 bg-[#323740] pc:hidden show-list">
          <div class="w-3"></div>
          <p class="text-sm font-bold text-white">과정 리스트 보기</p>
          <div class="w-3">
            <img src="@lifeeduWeb/assets/images/courseIntroduce/adult/arrow.png" alt="icon" class="transition-all show-arrow" />
          </div>
        </div>
        <ul class="flex pc:flex-col pc:max-w-[1200px] pc:mx-auto lang-list">
          <li class="w-[40%] bg-[#ececec] pc:w-full pc:bg-white">
            <ul class="gap-10 pc:flex pc:border-b border-solid border-[#f1f1f1]">
              <li
                class="py-3.5 font-medium text-center text-[#fb6930] cursor-pointer"
                @click="goPage('/courseIntroduce/adultEng/PageAll')"
              >
                영어
              </li>
              <li class="py-3.5 font-medium text-center cursor-pointer" @click="goPage('/courseIntroduce/adultChi/pageAll')">
                중국어
              </li>
              <li class="py-3.5 font-medium text-center cursor-pointer" @click="goPage('/courseIntroduce/adultJap/pageAll')">
                일본어
              </li>
              <li class="py-3.5 font-medium text-center cursor-pointer" @click="goPage('/courseIntroduce/adultEtc')">
                제2외국어
              </li>
            </ul>
          </li>
          <li class="w-[60%] pc:w-full">
            <ul class="divide-y divide-solid divide-[#e9e9e9] pc:flex pc:divide-y-0 gap-9 sub-list">
              <li
                class="py-3.5 font-medium text-center cursor-pointer text-[#666666]"
                @click="goPage('/courseIntroduce/adultEng/PageAll')"
              >
                전체 커리큘럼 맵
              </li>
              <li
                class="py-3.5 font-medium text-center cursor-pointer text-[#666666]"
                @click="goPage('/courseIntroduce/adultEng/page1')"
              >
                일반회화
              </li>
              <li
                class="py-3.5 font-medium text-center text-[#fb6930] cursor-pointer"
                @click="goPage('/courseIntroduce/adultEng/page2')"
              >
                비즈니스회화
              </li>
              <li
                class="py-3.5 font-medium text-center cursor-pointer text-[#666666]"
                @click="goPage('/courseIntroduce/adultEng/page3')"
              >
                토론회화
              </li>
              <li
                class="py-3.5 font-medium text-center cursor-pointer text-[#666666]"
                @click="goPage('/courseIntroduce/adultEng/page4')"
              >
                시험·인터뷰대비
              </li>
              <li
                class="py-3.5 font-medium text-center cursor-pointer text-[#666666]"
                @click="goPage('/courseIntroduce/adultEng/page5')"
              >
                로이터뉴스
              </li>
              <li
                class="py-3.5 font-medium text-center cursor-pointer text-[#666666]"
                @click="goPage('/courseIntroduce/adultEng/page6')"
              >
                Global Topic
              </li>
            </ul>
          </li>
        </ul>
        <div class="pc-con-wrapper5">
          <div class="flex flex-col items-center bg2 py-10 pc:py-[90px] px-5 mb-7 pc:mb-11">
            <p class="text-white font-bold text-center text-[35px] mb-2">비즈니스회화</p>
            <div class="w-[72px] h-[2px] bg-[#fb6930] mb-6"></div>
            <p class="text-center text-white pc:text-2xl">
              비즈니스 업무 환경에서 필요한 커뮤니케이션 능력을 향상 시키고,<br />다양한 상황을 통해 비즈니스 실무 능력을 마스터
              합니다.
            </p>
          </div>
          <div class="px-5 pc-con-wrapper4">
            <ul class="grid grid-cols-2 gap-5 tablet:grid-cols-3 pc:grid-cols-4 pc:gap-10">
              <li>
                <div class="relative mb-2.5">
                  <img src="@lifeeduWeb/assets/images/courseIntroduce/adult/eng/bg2-book01.png" alt="img" />
                  <p class="absolute right-0 px-3 py-1 text-white bottom-5 bg-black/60">중급</p>
                </div>
                <p
                  class="inline-block cursor-pointer text-sm px-3.5 font-medium text-[#fb6930] mb-1 border border-solid border-[fb6930] rounded-2xl"
                  @click="
                    goSite(
                      'https://carrotenglish.kr/guide/media_view/book_media_study/49?_gl=1*1enl8o1*_ga*MTMxNDQwMTgzMS4xNjk4MjIxMjM4*_ga_R79H2W39ZD*MTcwMjQ0MTk3NS4yOC4xLjE3MDI0NDM2MTcuNTkuMC4w'
                    )
                  "
                >
                  미리보기
                </p>
                <p class="text-[#111111] font-medium mb-1.5">Pre-Biz Basic 1</p>
                <p class="text-sm text-[#535353] mb-1">
                  다양한 비즈니스 상황에서 발생할 수 있는 기본 Topic 학습하는 기초 비즈니스 회화 과정
                </p>
                <ul class="flex flex-wrap gap-1">
                  <li class="text-sm py-px px-2 bg-[#ececec] rounded-2xl">#비즈니스</li>
                  <li class="text-sm py-px px-2 bg-[#ececec] rounded-2xl">#표현학습</li>
                  <li class="text-sm py-px px-2 bg-[#ececec] rounded-2xl">#롤플레잉</li>
                </ul>
              </li>
              <li>
                <div class="relative mb-2.5">
                  <img src="@lifeeduWeb/assets/images/courseIntroduce/adult/eng/bg2-book02.png" alt="img" />
                  <p class="absolute right-0 px-3 py-1 text-white bottom-5 bg-black/60">중급</p>
                </div>
                <p
                  class="inline-block cursor-pointer text-sm px-3.5 font-medium text-[#fb6930] mb-1 border border-solid border-[fb6930] rounded-2xl"
                  @click="
                    goSite(
                      'https://carrotenglish.kr/guide/media_view/book_media_study/55?_gl=1*1enl8o1*_ga*MTMxNDQwMTgzMS4xNjk4MjIxMjM4*_ga_R79H2W39ZD*MTcwMjQ0MTk3NS4yOC4xLjE3MDI0NDM2MTcuNTkuMC4w'
                    )
                  "
                >
                  미리보기
                </p>
                <p class="text-[#111111] font-medium mb-1.5">Pre-Biz Basic 2</p>
                <p class="text-sm text-[#535353] mb-1">
                  다양한 비즈니스 상황에서 발생할 수 있는 기본 Topic 학습하는 기초 비즈니스 회화 과정
                </p>
                <ul class="flex flex-wrap gap-1">
                  <li class="text-sm py-px px-2 bg-[#ececec] rounded-2xl">#비즈니스</li>
                  <li class="text-sm py-px px-2 bg-[#ececec] rounded-2xl">#표현학습</li>
                  <li class="text-sm py-px px-2 bg-[#ececec] rounded-2xl">#롤플레잉</li>
                </ul>
              </li>
              <li>
                <div class="relative mb-2.5">
                  <img src="@lifeeduWeb/assets/images/courseIntroduce/adult/eng/bg2-book03.png" alt="img" />
                  <p class="absolute right-0 px-3 py-1 text-white bottom-5 bg-black/60">중급</p>
                </div>
                <p
                  class="inline-block cursor-pointer text-sm px-3.5 font-medium text-[#fb6930] mb-1 border border-solid border-[fb6930] rounded-2xl"
                  @click="
                    goSite(
                      'https://carrotenglish.kr/guide/media_view/book_media_study/33?_gl=1*7gdkjz*_ga*MTMxNDQwMTgzMS4xNjk4MjIxMjM4*_ga_R79H2W39ZD*MTcwMjQ0MTk3NS4yOC4xLjE3MDI0NDM2MTcuNTkuMC4w'
                    )
                  "
                >
                  미리보기
                </p>
                <p class="text-[#111111] font-medium mb-1.5">Business Basic 1</p>
                <p class="text-sm text-[#535353] mb-1">
                  비즈니스 환경에서 공통적으로 사용되는 주요 표현을 학습하여 비즈니스 실무 커뮤니케이션 능력을 훈련하는 과정
                </p>
                <ul class="flex flex-wrap gap-1">
                  <li class="text-sm py-px px-2 bg-[#ececec] rounded-2xl">#중급</li>
                  <li class="text-sm py-px px-2 bg-[#ececec] rounded-2xl">#회사생활</li>
                  <li class="text-sm py-px px-2 bg-[#ececec] rounded-2xl">#실무영어</li>
                </ul>
              </li>
              <li>
                <div class="relative mb-2.5">
                  <img src="@lifeeduWeb/assets/images/courseIntroduce/adult/eng/bg2-book04.png" alt="img" />
                  <p class="absolute right-0 px-3 py-1 text-white bottom-5 bg-black/60">중급</p>
                </div>
                <p
                  class="inline-block cursor-pointer text-sm px-3.5 font-medium text-[#fb6930] mb-1 border border-solid border-[fb6930] rounded-2xl"
                  @click="
                    goSite(
                      'https://carrotenglish.kr/guide/media_view/book_media_study/30?_gl=1*1qda9fx*_ga*MTMxNDQwMTgzMS4xNjk4MjIxMjM4*_ga_R79H2W39ZD*MTcwMjQ0MTk3NS4yOC4xLjE3MDI0NDE5NzcuNTguMC4w'
                    )
                  "
                >
                  미리보기
                </p>
                <p class="text-[#111111] font-medium mb-1.5">Business Basic 2</p>
                <p class="text-sm text-[#535353] mb-1">
                  비즈니스 환경에서 공통적으로 사용되는 주요 표현을 학습하여 비즈니스 실무 커뮤니케이션 능력을 훈련하는 과정
                </p>
                <ul class="flex flex-wrap gap-1">
                  <li class="text-sm py-px px-2 bg-[#ececec] rounded-2xl">#중급</li>
                  <li class="text-sm py-px px-2 bg-[#ececec] rounded-2xl">#회사생활</li>
                  <li class="text-sm py-px px-2 bg-[#ececec] rounded-2xl">#실무영어</li>
                </ul>
              </li>
              <li>
                <div class="relative mb-2.5">
                  <img src="@lifeeduWeb/assets/images/courseIntroduce/adult/eng/bg2-book05.png" alt="img" />
                  <p class="absolute right-0 px-3 py-1 text-white bottom-5 bg-black/60">고급</p>
                </div>
                <p
                  class="inline-block cursor-pointer text-sm px-3.5 font-medium text-[#fb6930] mb-1 border border-solid border-[fb6930] rounded-2xl"
                  @click="
                    goSite(
                      'https://carrotenglish.kr/guide/media_view/book_media_study/39?_gl=1*bm23cm*_ga*MTMxNDQwMTgzMS4xNjk4MjIxMjM4*_ga_R79H2W39ZD*MTcwMjQ0MTk3NS4yOC4xLjE3MDI0NDI1MjYuNjAuMC4w'
                    )
                  "
                >
                  미리보기
                </p>
                <p class="text-[#111111] font-medium mb-1.5">Business Practice 1</p>
                <p class="text-sm text-[#535353] mb-1">
                  비즈니스 환경에서 공통적으로 사용되는 주요 표현을 학습하여 비즈니스 실무 커뮤니케이션 능력을 훈련하는 과정
                </p>
                <ul class="flex flex-wrap gap-1">
                  <li class="text-sm py-px px-2 bg-[#ececec] rounded-2xl">#비즈커뮤니케이션</li>
                </ul>
              </li>
              <li>
                <div class="relative mb-2.5">
                  <img src="@lifeeduWeb/assets/images/courseIntroduce/adult/eng/bg2-book06.png" alt="img" />
                  <p class="absolute right-0 px-3 py-1 text-white bottom-5 bg-black/60">고급</p>
                </div>
                <p
                  class="inline-block cursor-pointer text-sm px-3.5 font-medium text-[#fb6930] mb-1 border border-solid border-[fb6930] rounded-2xl"
                  @click="
                    goSite(
                      'https://carrotenglish.kr/guide/media_view/book_media_study/126?_gl=1*nvdn6v*_ga*MTMxNDQwMTgzMS4xNjk4MjIxMjM4*_ga_R79H2W39ZD*MTcwMjQ0MTk3NS4yOC4xLjE3MDI0NDM4MjAuNjAuMC4w'
                    )
                  "
                >
                  미리보기
                </p>
                <p class="text-[#111111] font-medium mb-1.5">Business Practice 2</p>
                <p class="text-sm text-[#535353] mb-1">
                  비즈니스 환경에서 공통적으로 사용되는 주요 표현을 학습하여 비즈니스 실무 커뮤니케이션 능력을 훈련하는 과정
                </p>
                <ul class="flex flex-wrap gap-1">
                  <li class="text-sm py-px px-2 bg-[#ececec] rounded-2xl">#비즈커뮤니케이션</li>
                </ul>
              </li>
              <li>
                <div class="relative mb-2.5">
                  <img src="@lifeeduWeb/assets/images/courseIntroduce/adult/eng/bg2-book07.png" alt="img" />
                  <p class="absolute right-0 px-3 py-1 text-white bottom-5 bg-black/60">초급~중급</p>
                </div>
                <p
                  class="inline-block cursor-pointer text-sm px-3.5 font-medium text-[#fb6930] mb-1 border border-solid border-[fb6930] rounded-2xl"
                  @click="goSite('https://kr.object.ncloudstorage.com/mailpad/b2b_pdf/EN02_Online%20Business%20Small%20Talk.pdf')"
                >
                  미리보기
                </p>
                <p class="text-[#111111] font-medium mb-1.5">Business Small Talk</p>
                <p class="text-sm text-[#535353] mb-1">비즈니스 상황에서의 Small talk 표현들을 학습하는 과정</p>
                <ul class="flex flex-wrap gap-1">
                  <li class="text-sm py-px px-2 bg-[#ececec] rounded-2xl">#비즈니스테마</li>
                  <li class="text-sm py-px px-2 bg-[#ececec] rounded-2xl">#상황별</li>
                  <li class="text-sm py-px px-2 bg-[#ececec] rounded-2xl">#스몰톡</li>
                </ul>
              </li>
              <li>
                <div class="relative mb-2.5">
                  <img src="@lifeeduWeb/assets/images/courseIntroduce/adult/eng/bg2-book08.png" alt="img" />
                  <p class="absolute right-0 px-3 py-1 text-white bottom-5 bg-black/60">초급~중급</p>
                </div>
                <p
                  class="inline-block cursor-pointer text-sm px-3.5 font-medium text-[#fb6930] mb-1 border border-solid border-[fb6930] rounded-2xl"
                  @click="goSite('https://kr.object.ncloudstorage.com/mailpad/b2b_pdf/EN03_Online%20Business%20Trip.pdf')"
                >
                  미리보기
                </p>
                <p class="text-[#111111] font-medium mb-1.5">Business Trip</p>
                <p class="text-sm text-[#535353] mb-1">
                  비즈니스 출장을 위해 단기간 내 어학능력 향상과 어휘력, 표현을 향상시킬 수 있는 과정
                </p>
                <ul class="flex flex-wrap gap-1">
                  <li class="text-sm py-px px-2 bg-[#ececec] rounded-2xl">#비즈니스테마</li>
                  <li class="text-sm py-px px-2 bg-[#ececec] rounded-2xl">#상황별</li>
                  <li class="text-sm py-px px-2 bg-[#ececec] rounded-2xl">#출장</li>
                </ul>
              </li>
              <li>
                <div class="relative mb-2.5">
                  <img src="@lifeeduWeb/assets/images/courseIntroduce/adult/eng/bg2-book09.png" alt="img" />
                  <p class="absolute right-0 px-3 py-1 text-white bottom-5 bg-black/60">중급~고급</p>
                </div>
                <p
                  class="inline-block cursor-pointer text-sm px-3.5 font-medium text-[#fb6930] mb-1 border border-solid border-[fb6930] rounded-2xl"
                  @click="goSite('https://kr.object.ncloudstorage.com/mailpad/b2b_pdf/EN04_Online%20Business%20Meeting.pdf')"
                >
                  미리보기
                </p>
                <p class="text-[#111111] font-medium mb-1.5">Business Meeting</p>
                <p class="text-sm text-[#535353] mb-1">비즈니스 영어 미팅을 위한 효과적인 미팅 프로세스 스킬 학습 과정</p>
                <ul class="flex flex-wrap gap-1">
                  <li class="text-sm py-px px-2 bg-[#ececec] rounded-2xl">#비즈니스테마</li>
                  <li class="text-sm py-px px-2 bg-[#ececec] rounded-2xl">#상황별</li>
                  <li class="text-sm py-px px-2 bg-[#ececec] rounded-2xl">#미팅</li>
                </ul>
              </li>
              <li>
                <div class="relative mb-2.5">
                  <img src="@lifeeduWeb/assets/images/courseIntroduce/adult/eng/bg2-book10.png" alt="img" />
                  <p class="absolute right-0 px-3 py-1 text-white bottom-5 bg-black/60">중급~고급</p>
                </div>
                <p
                  class="inline-block cursor-pointer text-sm px-3.5 font-medium text-[#fb6930] mb-1 border border-solid border-[fb6930] rounded-2xl"
                  @click="goSite('https://kr.object.ncloudstorage.com/mailpad/b2b_pdf/EN05_Online%20Business%20Presentation.pdf')"
                >
                  미리보기
                </p>
                <p class="text-[#111111] font-medium mb-1.5">Business Presentation</p>
                <p class="text-sm text-[#535353] mb-1">
                  영어 프레젠테이션을 준비하는 단계별 배경지식, 주요표현 및 필수 규칙 학습 과정
                </p>
                <ul class="flex flex-wrap gap-1">
                  <li class="text-sm py-px px-2 bg-[#ececec] rounded-2xl">#비즈니스테마</li>
                  <li class="text-sm py-px px-2 bg-[#ececec] rounded-2xl">#상황별</li>
                  <li class="text-sm py-px px-2 bg-[#ececec] rounded-2xl">#프레젠테이션</li>
                </ul>
              </li>
              <li>
                <div class="relative mb-2.5">
                  <img src="@lifeeduWeb/assets/images/courseIntroduce/adult/eng/bg2-book11.png" alt="img" />
                  <p class="absolute right-0 px-3 py-1 text-white bottom-5 bg-black/60">중급~고급</p>
                </div>
                <p
                  class="inline-block cursor-pointer text-sm px-3.5 font-medium text-[#fb6930] mb-1 border border-solid border-[fb6930] rounded-2xl"
                  @click="goSite('https://kr.object.ncloudstorage.com/mailpad/b2b_pdf/EN06_Virtual%20Meeting%20English.pdf')"
                >
                  미리보기
                </p>
                <p class="text-[#111111] font-medium mb-1.5">Virtual Meeting English</p>
                <p class="text-sm text-[#535353] mb-1">
                  효과적인 온라인 미팅을 위해 알아두어야 할 화상미팅, 컨퍼런스 콜 필수 표현 학습 및 현업과의 연계 위한 연습 과정
                </p>
                <ul class="flex flex-wrap gap-1">
                  <li class="text-sm py-px px-2 bg-[#ececec] rounded-2xl">#비즈니스테마</li>
                  <li class="text-sm py-px px-2 bg-[#ececec] rounded-2xl">#비대면</li>
                  <li class="text-sm py-px px-2 bg-[#ececec] rounded-2xl">#미팅</li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import { ref, reactive, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import axios from "@lifeeduWeb/plugins/axios.js";
import { useStore } from "vuex";

export default {
  components: {},

  setup() {
    const router = useRouter();
    const route = useRoute();
    const store = useStore();

    const goPage = (url) => {
      location.href = url;
    };

    const goSite = (url) => {
      window.open(url, "_blank");
    };

    onMounted(() => {
      const showListButton = document.querySelector(".show-list");
      const arrowIcon = showListButton.querySelector(".show-arrow");
      const listToToggle = document.querySelector(".lang-list");
      const countryListItems = document.querySelectorAll(".sub-list li");

      showListButton.addEventListener("click", function () {
        if (listToToggle.style.display === "none") {
          listToToggle.style.display = "flex";
        } else {
          listToToggle.style.display = "none";
        }

        arrowIcon.classList.toggle("rotate-180");
      });

      countryListItems.forEach(function (item) {
        item.addEventListener("click", function () {
          listToToggle.style.display = "none";
          arrowIcon.classList.remove("rotate-180");
        });
      });
    });

    return {
      goPage,
      goSite,
    };
  },
};
</script>
<style lang="scss" scoped>
.bg2 {
  background: url(@lifeeduWeb/assets/images/courseIntroduce/adult/eng/bg2.png) no-repeat center / 1920px;
}
</style>
