<template>
    <div
        class="flex items-center justify-center px-4 py-3 bg-white border-t border-gray-200 sm:px-6"
    >
        <div class="items-center hidden sm:flex sm:items-center">
            <!-- <div>
            <p class="text-sm text-gray-700">
              Showing
              <span class="font-medium">1</span>
              to
              <span class="font-medium">10</span>
              of
              <span class="font-medium">97</span>
              results
            </p>
          </div> -->
            <div>
                <nav
                    class="inline-flex items-center -space-x-px rounded-md isolate"
                    aria-label="Pagination"
                >
                    <span
                        @click="bPage.clickNextPrev(-1)"
                        class="relative inline-flex items-center px-2 py-2 text-gray-400 cursor-pointer rounded-l-md ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                    >
                        <span class="sr-only">Previous</span>
                        <svg
                            class="w-5 h-5"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                            aria-hidden="true"
                        >
                            <path
                                fill-rule="evenodd"
                                d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z"
                                clip-rule="evenodd"
                            />
                        </svg>
                    </span>
                    <!-- Current: "z-10 bg-indigo-600 text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600", Default: "text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:outline-offset-0" -->
                    <span v-for="(item, i) in bPage.pagingList" v-bind:key="i">
                        <span
                            v-if="item == bPage.currentPage"
                            class="relative z-10 inline-flex items-center px-4 py-2 text-sm font-semibold cursor-pointer focus:z-20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
                            @click="bPage.pagingClick(item)"
                            >{{ item }}</span
                        >

                        <span
                            v-else
                            class="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 cursor-pointer ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                            @click="bPage.pagingClick(item)"
                            >{{ item }}</span
                        >
                    </span>
                    <span
                        @click="bPage.clickNextPrev(1)"
                        class="relative inline-flex items-center px-2 py-2 text-gray-400 cursor-pointer rounded-r-md ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                    >
                        <span class="sr-only">Next</span>
                        <svg
                            class="w-5 h-5"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                            aria-hidden="true"
                        >
                            <path
                                fill-rule="evenodd"
                                d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                                clip-rule="evenodd"
                            />
                        </svg>
                    </span>
                </nav>
            </div>
        </div>
    </div>
</template>
<script>
import { watch, reactive, onMounted } from "vue";

export default {
    name: "BagicPaging",
    components: {},
    props: ["totalCnt", "currentPage", "pageSize"],
    setup(props, { emit }) {
        const bPage = reactive({
            totalRowCnt: 0,
            pageSize: 1,
            limit: 11,
            totalPageCnt: 1, //총 페이지 수
            currentPage: 1, //현재 페이지 수
            pageMinNum: 1, //보여줄 페이지들 중에 최소 페이지 번호
            pageMaxNum: 10, //보여줄 페이지들 중에 최대 페이지 번호
            pagingList: [], //보여줄 페이지 번호 목록

            pagingClick(value) {
                if (value >= 1 && value <= bPage.totalPageCnt) {
                    bPage.currentPage = value;
                    emit("paging-click", bPage.currentPage);
                }
                // bPage.currentPage = value;
                // emit("paging-click", value);
            },
            clickNextPrev(value) {
                let targetPage = bPage.currentPage + value;
                //pagingList 에 이동하고자 하는 페이지가 존재하는 경우.
                if (bPage.pagingList.includes(targetPage)) {
                    bPage.currentPage = targetPage;
                    if (bPage.currentPage < 1) bPage.currentPage = 1;
                    if (bPage.currentPage > bPage.totalPageCnt)
                        bPage.currentPage = bPage.totalPageCnt;
                    emit("paging-click", bPage.currentPage);
                } else {
                    //pagingList 에 이동하고자 하는 페이지가 존재하지 않는 경우.
                    if (value >= 1 && bPage.pageMaxNum < bPage.totalPageCnt) {
                        //value 가 1이고 pageMaxNum 값이 totalPageCnt 보다 작은경우, 페이지 목록에서 첫번째 것을 제거하고, 맨 마지막에 값을 추가한다.
                        bPage.pagingList.shift();
                        bPage.pagingList.push(targetPage);
                        bPage.pageMaxNum = Math.max.apply(
                            null,
                            bPage.pagingList
                        );
                        bPage.pageMinNum = Math.min.apply(
                            null,
                            bPage.pagingList
                        );
                        bPage.currentPage = targetPage;
                    }
                    if (value <= -1 && bPage.pageMinNum > 1) {
                        //value 가 1이고 pageMaxNum 값이 totalPageCnt 보다 작은경우, 페이지 목록에서 첫번째 것을 제거하고, 맨 마지막에 값을 추가한다.
                        bPage.pagingList.pop();
                        bPage.pagingList.unshift(targetPage);
                        bPage.pageMaxNum = Math.max.apply(
                            null,
                            bPage.pagingList
                        );
                        bPage.pageMinNum = Math.min.apply(
                            null,
                            bPage.pagingList
                        );

                        bPage.currentPage = targetPage;
                        emit("paging-click", bPage.currentPage);
                    }
                }
            },
            makePagingList() {
                bPage.pagingList = [];
                for (let i = bPage.pageMinNum; i <= bPage.pageMaxNum; i++) {
                    bPage.pagingList.push(i);
                }
            },
            calNumList() {
                //페이징의 노출되는 숫자를 목록을 계산한다.
                //limit : 페이징 제한 수
                bPage.totalPageCnt =
                    bPage.totalRowCnt > 0
                        ? Math.ceil(bPage.totalRowCnt / bPage.pageSize)
                        : 1;

                //totalPageCnt 가 최대 노출 페이지 수보다 작거나 같은경우
                if (bPage.totalPageCnt <= bPage.limit) {
                    bPage.pageMinNum = 1;
                    bPage.pageMaxNum = bPage.totalPageCnt;
                }

                //totalPageCnt 가 최대 노출 페이지 수보다 큰경우 전체 15, 리미트 11, 현재 12
                if (bPage.totalPageCnt > bPage.limit) {
                    let leftSize = (bPage.limit - 1) / 2;
                    if (bPage.currentPage - leftSize > bPage.pageMinNum) {
                        bPage.pageMinNum = bPage.currentPage - leftSize;
                        bPage.pageMaxNum = bPage.currentPage + leftSize;
                    } else {
                        //페이지 최소 값은 유지. 최대값만 조정.
                        bPage.pageMaxNum = bPage.currentPage + leftSize;
                    }
                }

                //페이지 최소 최대값 제한.
                if (bPage.pageMaxNum > bPage.totalPageCnt)
                    bPage.pageMaxNum = bPage.totalPageCnt;
                if (bPage.pageMinNum < 1) bPage.pageMinNum = 1;

                //전체 페이지 개수 : totalCnt / pageSize

                bPage.makePagingList();
            },
        });
        onMounted(() => {
            //키 목록을 가져온다.
            console.log("페이징");
            console.log(props);
            if (props.totalCnt > 0) {
                bPage.totalRowCnt = props.totalCnt;
            }
            if (props.currentPage > 0) {
                bPage.currentPage = props.currentPage;
            }
            if (props.pageSize > 0) {
                bPage.pageSize = props.pageSize;
            }
            bPage.calNumList();
        });

        watch(
            () => props,
            (cur, prev) => {
                //가상돔이 반영된 후 데이터가 바인딩되도록 처리한다.
                bPage.pageSize = cur.pageSize;
                bPage.totalRowCnt = cur.totalCnt;
                bPage.calNumList();
                console.log("paging.totalCnt : " + cur);
            }
        );

        watch(
            () => props.pageSize,
            (cur, prev) => {
                bPage.pageSize = cur;
                bPage.calNumList();
            }
        );
        watch(
            () => props.totalCnt,
            (cur, prev) => {
                bPage.totalRowCnt = cur;
                bPage.calNumList();
            }
        );
        watch(
            () => props.currentPage,
            (cur, prev) => {
                bPage.currentPage = cur;
                bPage.calNumList();
            }
        );

        return {
            bPage,
        };
    },
};
</script>
