<template>
  <div class="mb-5">
    <p class="pb-2 mb-2 text-lg font-semibold border-b-2 border-solid border-slate-800">수업 횟수 / 시간</p>
    <table class="mb-2">
      <thead>
        <tr>
          <th>
            <span class="font-bold">*</span>
            수업 횟수
          </th>
          <th>
            <span class="font-bold">*</span>
            총 수업 횟수
          </th>
          <th>
            <span class="font-bold">*</span>
            수업 시간(분)
          </th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in registSugang.sugangGroupTimeInfo" :key="index">
          <td>
            <div class="flex items-center justify-center gap-2">
              <p>주</p>
              <select
                v-model="registSugang.sugangGroupTimeInfo[index].numberOfClass"
                class="border border-slate-800 border-solid rounded w-full max-w-[100px]"
              >
                <option value="0" selected>선택</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="5">5</option>
              </select>
              <p>회</p>
            </div>
          </td>
          <td>
            <div class="flex items-center justify-center gap-2">
              <p>총</p>
              <input
                type="text"
                class="border border-slate-800 border-solid rounded w-full max-w-[50px]"
                v-model="registSugang.sugangGroupTimeInfo[index].totalNumberOfClass"
              />
              <p>회</p>
            </div>
          </td>
          <td>
            <input
              type="text"
              class="border border-solid rounded border-slate-800"
              v-model="registSugang.sugangGroupTimeInfo[index].lessonTime"
            />
          </td>
          <td>
            <p
              class="p-2 border border-solid rounded cursor-pointer text-slate-800 border-slate-800"
              @click="registSugang.deleteRows(index)"
            >
              삭제
            </p>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="flex justify-end">
      <p class="p-2 border border-solid rounded cursor-pointer text-slate-800 border-slate-800" @click="registSugang.addRows">
        추가입력
      </p>
    </div>
  </div>
</template>

<script>
import { onMounted, reactive, ref, computed, watch, watchEffect } from "vue";

class BaseOneTimeObject {
  constructor() {
    this.idxLeSugangGroupTimeInfo = 0;
    this.idxLeSugang = 0;
    this.numberOfClass = "";
    this.lessonTime = "";
    this.classPrice = "";
    this.staffIdx = 0;
  }
}

export default {
  name: "RegistGroupClassEduPay",
  components: {},
  props: {
    sugangGroupTimeInfo: {
      type: Object,
    },
  },
  setup(props, { emit }) {
    const registSugang = reactive({
      checkedLanguageList: [],
      currentLanguage: -1,
      currentClassListIdx: -1,

      sugangGroupTimeInfo: props.sugangGroupTimeInfo,
      addRows: () => {
        let bo = new BaseOneTimeObject();
        console.log("registSugang.sugangGroupTimeInfo", registSugang.sugangGroupTimeInfo);
        registSugang.sugangGroupTimeInfo.push(bo);
        return registSugang.sugangGroupTimeInfo.length;
      },
      deleteRows: (index) => {
        if (index > -1) {
          registSugang.sugangGroupTimeInfo.splice(index, 1);
        }
      },
    });

    onMounted(() => {
      registSugang.addRows();
    });

    watch(
      () => props.sugangGroupTimeInfo,
      (cur, prev) => {
        registSugang.sugangGroupTimeInfo = cur;
      }
    );

    watch(
      () => registSugang.sugangGroupTimeInfo,
      (cur, prev) => {
        console.log(cur);
        // 이벤트를 통해 부모로 데이터셋을 전달.
        emit("set-edupay-data", cur); // 일단 주석처리.
      }
    );

    return {
      registSugang,
    };
  },
};
</script>

<style></style>
