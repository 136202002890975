<template>

</template>

<script>
import { onMounted, computed } from "vue";
import router from "@lifeeduWeb/router";
import { _axios } from "@lifeeduWeb/plugins/axios";
import { reactive } from "@vue/reactivity";
import { useRoute } from "vue-router";

export default {
    name: "index",
    components: {
    },
    setup() {
        onMounted(() => {
            
        });
        return {        };
    },
};
</script>
<style>
</style>
