<template>
  <div>
    <div class="hidden pc:block bg-wrapper">
      <div class="flex items-center justify-center bg py-[76px] max-w-[1920px] mx-auto">
        <p class="text-[40px] font-bold text-neutral-950">FAQ</p>
      </div>
    </div>

    <section class="pt-[55px] pb-[70px] pc:pt-[70px] pc:pb-[140px]">
      <div class="flex justify-center mb-16 border-b border-solid border-neutral-200 pc:mb-36">
        <ul class="flex gap-5 px-5 overflow-x-auto pc:gap-11 flex-nowrap">
          <li
            class="flex-none pb-2 font-medium cursor-pointer pc:text-lg"
            :class="{
              'border-b-2 border-orange-500 border-solid text-neutral-950 pc:pb-7': activeTab === 0,
            }"
            @click="activeTab = 0"
          >
            홈페이지
          </li>
          <li
            class="flex-none pb-2 font-medium cursor-pointer pc:text-lg"
            :class="{
              'border-b-2 border-orange-500 border-solid text-neutral-950 pc:pb-7': activeTab === 1,
            }"
            @click="activeTab = 1"
          >
            교재
          </li>
          <li
            class="flex-none pb-2 font-medium cursor-pointer pc:text-lg"
            :class="{
              'border-b-2 border-orange-500 border-solid text-neutral-950 pc:pb-7': activeTab === 2,
            }"
            @click="activeTab = 2"
          >
            수강신청
          </li>
          <li
            class="flex-none pb-2 font-medium cursor-pointer pc:text-lg"
            :class="{
              'border-b-2 border-orange-500 border-solid text-neutral-950 pc:pb-7': activeTab === 3,
            }"
            @click="activeTab = 3"
          >
            수업관련
          </li>
          <!-- <li
            class="flex-none pb-2 font-medium cursor-pointer pc:text-lg"
            :class="{
              'border-b-2 border-orange-500 border-solid text-neutral-950 pc:pb-7': activeTab === 4,
            }"
            @click="activeTab = 4"
          >
            수강취소
          </li> -->
        </ul>
      </div>

      <!-- <div class="pc-con-wrapper1">
        <ul class="border-t border-b border-solid border-r-neutral-200">
          <li
            v-for="(item, index) in filteredFaqContent"
            :key="index"
            class="p-5 border-b border-solid cursor-pointer border-neutral-200 last-of-type:border-b-0"
            :class="item.isOpen ? 'bg-neutral-50' : 'bg-white'"
            @click="toggleAccordion(index)"
          >
            <div class="flex items-center justify-between cursor-pointer click">
              <div class="flex gap-1">
                <p class="text-xl font-bold text-neutral-950">Q.</p>
                <p class="text-xl font-bold text-neutral-950">
                  {{ item.title }}
                </p>
              </div>
              <div>
                <img
                  :src="
                    item.isOpen
                      ? require('@lifeeduWeb/assets/images/common/arrow-up.png')
                      : require('@lifeeduWeb/assets/images/common/arrow-down.png')
                  "
                  alt="icon"
                  class="w-3.5 pc:w-4"
                />
              </div>
            </div>
            <div class="pt-5 whitespace-pre-line" v-show="item.isOpen">
              {{ item.content }}
            </div>
          </li>
        </ul>
      </div> -->
      <div class="pc-con-wrapper1">
        <ul class="border-t border-b border-solid border-r-neutral-200">
          <li
            v-for="(item, index) in filteredFaqContent"
            :key="index"
            class="p-5 border-b border-solid cursor-pointer border-neutral-200 last-of-type:border-b-0"
            :class="item.isOpen ? 'bg-neutral-50' : 'bg-white'"
            @click="toggleAccordion(index)"
          >
            <div class="flex items-center justify-between cursor-pointer click">
              <div class="flex gap-1">
                <p class="text-xl font-bold text-neutral-950 pc:text-[24px]">Q.</p>
                <p class="text-xl font-bold text-neutral-950 pc:text-[24px]">{{ item.title }}</p>
              </div>
              <div>
                <img
                  :src="
                    item.isOpen
                      ? require('@lifeeduWeb/assets/images/common/arrow-up.png')
                      : require('@lifeeduWeb/assets/images/common/arrow-down.png')
                  "
                  alt="icon"
                  class="w-3.5 pc:w-4"
                />
              </div>
            </div>
            <div class="pt-6 pc:pt-[30px] whitespace-pre-line" v-show="item.isOpen">
              <p class="text-lg pc:text-xl" v-html="faqDetailContentList.renderHyperlinks(item.content)"></p>
            </div>
          </li>
        </ul>
      </div>
    </section>
  </div>
</template>

<script>
import { ref, reactive } from "vue";

export default {
  computed: {
    filteredFaqContent() {
      if (this.activeTab === 0) {
        return this.faqDetailContentList.homePageContentList;
      } else if (this.activeTab === 1) {
        return this.faqDetailContentList.bookContentList;
      } else if (this.activeTab === 2) {
        return this.faqDetailContentList.enrolmentList;
      } else if (this.activeTab === 3) {
        return this.faqDetailContentList.classRelatedList;
      } else if (this.activeTab === 4) {
        return this.faqDetailContentList.cancelClassList;
      }
    },
  },

  setup() {
    const activeTab = ref(0);

    const faqDetailContentList = reactive({
      homePageContentList: [
        {
          title: `개인정보(이메일/전화번호)변경 희망합니다.`,
          content: `마이페이지 회원정보수정에서 변경 가능합니다.`,
          isOpen: false,
        },
      ],
      bookContentList: [
        {
          title: "e-book확인 방법이 궁금합니다.",
          content: `▶ PC 확인 방법
                        [나의학습방]에서 [교재명]을 클릭하시면 확인 및 출력이 가능합니다.

                        ▶ 모바일 확인 방법
                        수업 20분 전 알림톡의 '미리 교재를 확인해 보세요'를 클릭하시면 교재를 바로 확인하실 수 있습니다.`,

          isOpen: false,
        },
        {
          title: "교재 구입 희망합니다.",
          content: `교재구입을 희망하시는 경우 개별 문의를 통해 안내 받으실수 있습니다.`,
          isOpen: false,
        },
        {
          title: "과정 및 교재 변경 가능한가요? ",
          content: `1:1 수업은 개별문의를 통해 변경 가능합니다. 그룹 수업은 변경이 어려우니 이점 참고 부탁드립니다.`,
          isOpen: false,
        },
        {
          title: "교재는 어떻게 배정되나요?",
          content: `수강신청 시 선택하신 과정 中 맞는 레벨로 배정됩니다. 
                    레벨테스트 희망 시 별도 체크해주시면 안내드립니다.`,
          isOpen: false,
        },
      ],
      enrolmentList: [
        {
          title: "수업은 어떻게 신청하나요?",
          content: `수강신청 기간에 '수강신청바로가기' 버튼을 클릭하여 신청이 가능하며,
                    수업 희망시간 및 과정을 선택하고 교육비 결제까지 진행되면 수강신청이 완료됩니다.`,
          isOpen: false,
        },
        {
          title: "레벨을 어느정도로 선택해야 할지 모르겠습니다.",
          content: `[과정소개]
          에서 레벨 및 교재를 확인 후 선택해주세요!`,
          isOpen: false,
        },
        {
          title: "수업 가능시간은 언제인가요?",
          content: `[그룹수업] 
          평일 오후 18시 ~ 오후 23시
                    [1:1 수업] 
                    평일 오전 6시 ~ 자정 0시
                    ※ 원어민강사와의 수업은 오전 9시 ~ 오후 18시까지는 수업이 진행되지 않습니다.`,
          isOpen: false,
        },
        {
          title: "입문레벨도 수강 가능 한가요?",
          content: `알파벳 단계부터 수업 진행 가능합니다.`,
          isOpen: false,
        },
        {
          title: "신청인원 부족으로 강좌 미개설시 어떻게 진행되나요?",
          content: `해당 수업시간 신청인원 부족으로 강좌가 미개설될 경우 자동으로 신청 및 결제가 취소되며, 홈페이지 內 추가 신청 기간에 다시 신청하실 수 있습니다.
                    클래스별 학습자 구성은 수강신청 및 결제 완료 순이니 참고해 주세요!`,
          isOpen: false,
        },
        {
          title: "수강 조건이 궁금합니다.(거주지, 관내소속 학교 재학)",
          content: `해당 관내 거주, 관내소속 학교 재학 시, 직장 재직 시 수강 가능합니다. 수강 기한 중 다른 지역으로 이동 시 수강이 제한됩니다.`,
          isOpen: false,
        },
      ],
      classRelatedList: [
        {
          title: "수업시간 및 강사변경이 가능한가요?",
          content: `[그룹수업] 
                    수업이 시작되고 난 이후 변경은 불가합니다.
                    [1:1 수업]
                    1:1문의를 통해 변경 가능합니다.`,
          isOpen: false,
        },
        {
          title: "수업 연기 가능한가요?",
          content: `[그룹수업]
                    개인적인 사유로 수업 연기 및 변경은 불가합니다. 본인 수업 스케줄에 맞춰 꼭 출석부탁드립니다.
                    [1:1 수업] 1:1문의를 통해 가능합니다.
                    ※ 개별적인 컴퓨터 고장이나 인터넷 장애 시에도 휴강이나 결강에 따른 보강이 되지 않으니 이점 꼭 숙지하시기 바랍니다.`,
          isOpen: false,
        },
        {
          title: "수업을 오랫동안 못 듣게 될 경우, 어떻게 할 수 있나요?",
          content: `[그룹수업]
                    전체 수강생 스케줄 변경 제외하고 개인적인 스케줄 변경은 불가하므로 신청하신 과정은 종료시까지 참석해 주셔야 하며, 수업 시작 후 과정중단은 불가합니다.
                    [1:1 수업] 
                    2주이상 참여가 어려울 경우 수업은 홀딩되며 해당 수업은 교육기간 내에 보강 진행 됩니다.`,
          isOpen: false,
        },
        {
          title: "화상 수업 준비사항 문의 합니다.",
          content: `앱스토어 혹은 플레이스토어에서 Zoom을 검색하여 설치 가능합니다. 아래의 안내 URL을 참고해 주세요!
                    https://www.carrotenglish.kr/phone/guide_virtual`,
          isOpen: false,
        },
        {
          title: "수업 입장은 어떻게 진행하나요?",
          content: `[나의학습방] → [수업스케줄 확인하기] → [화상방 입장하기] 클릭
                    ※수업 정시에 알림톡을 통해 보내드리는 입장 링크를 클릭하시면 화상방에 입장하실 수 있습니다. 아래의 안내 URL을 참고해 주세요!
                    https://www.carrotenglish.kr/phone/guide_virtual`,
          isOpen: false,
        },
        {
          title: "해외에서도 진행 가능 한가요?",
          content: `해외에서도 모바일 또는 PC화상수업 진행이 가능합니다. 알림톡은 수신 불가하실수 있는 점 참고 부탁드립니다.
                    ※ 거주하시는 지역에 따라 인터넷 환경이 다를 수 있으니 수업 시작 전, 환경테스트를 해보시는 것을 추천드립니다.`,
          isOpen: false,
        },
      ],
      // cancelClassList: [
      //   {
      //     title: "환불 규정 문의 합니다.",
      //     content: `제 13 조(수강취소 및 환불)
      //               환불의 규정은 아래와 같으며 이는 평생교육 사업법에 의거하여 규정되었습니다.

      //               ① 전화강의 서비스 이용료 징수기간이 1개월 이내인 경우
      //               1) 수업시작 전 - 기납입한 이용료 전액
      //               2) 총 수업시간의 1/3이 경과하기 전 - 기납입한 이용료의 2/3에 해당되는 금액
      //               3) 총 수업시간의 1/2이 경과하기 전 - 기납입한 이용료의 1/2에 해당되는 금액
      //               4) 총 수업시간의 1/2이 경과한 후 - 반환하지 아니함

      //               ② 전화강의 서비스 이용료 징수기간이 1개월을 초과하는 경우
      //               1) 수업시작 전 - 기납입한 이용료 전액
      //               2) 수업시작 후 - 환불사유가 발생한 그 달의 환불대상 이용료
      //               (이용료 징수기간이 1개월 이내인 경우에 준하여 산출된 이용료)와 나머지 달의 이용료 전액을 합산한 금액 )
      //               단, 장기수강에 따른 할인율이 적용된 경우, 환불사유 적용시점에서
      //               수업이 완료된 개월에 대해 할인액을 재적용하여 환불전 적용된 할인액과의 차액을 환불금액에서 차감합니다.

      //               단, 재적용되는 할인액은 신청한 상품의 수강신청페이지상에서 완료된 개월에 준한 할인율을 기준으로 합니다.

      //               네이버페이를 통한 주문건의 경우, 전화강의 서비스 이용료 지불 후 29일을 초과하여 환불 시
      //               결제수수료 및 상품 구입 시 지급받은 네이버페이 포인트를 위약금으로 정합니다.

      //               환불금액은 환불 신청 후 일주일 내에 고객님이 요청한 통장으로 입금이 되며,
      //               카드로 결제한 고객분은 교재금액 및 종료된 수업횟수의 수강료를
      //               당근영어 통장계좌(우리: 1005-404-222447, ㈜캐럿솔루션즈)로 입금 또는 카드 재결제 완료 후 승인취소가 가능합니다.`,
      //     isOpen: false,
      //   },
      // ],

      renderHyperlinks(content) {
        return content.replace(
          /(https:\/\/www\.carrotenglish\.kr\/phone\/guide_virtual)/g,
          '<a href="$1" target="_blank">$1</a>'
        );
      },
    });

    const toggleAccordion = (index) => {
      if (activeTab.value === 0) {
        faqDetailContentList.homePageContentList[index].isOpen = !faqDetailContentList.homePageContentList[index].isOpen;
      } else if (activeTab.value === 1) {
        faqDetailContentList.bookContentList[index].isOpen = !faqDetailContentList.bookContentList[index].isOpen;
      } else if (activeTab.value === 2) {
        faqDetailContentList.enrolmentList[index].isOpen = !faqDetailContentList.enrolmentList[index].isOpen;
      } else if (activeTab.value === 3) {
        faqDetailContentList.classRelatedList[index].isOpen = !faqDetailContentList.classRelatedList[index].isOpen;
      } else if (activeTab.value === 4) {
        faqDetailContentList.cancelClassList[index].isOpen = !faqDetailContentList.cancelClassList[index].isOpen;
      }
    };

    return {
      activeTab,
      faqDetailContentList,
      toggleAccordion,
    };
  },
};
</script>
<style scoped></style>
