<template>
  <div>
    <section class="pt-[55px] pb-[70px] pc:py-[140px] pc-con-wrapper1">
      <div>
        <img
          src="@lifeeduWeb/assets/images/signUp/icon.png"
          alt="icon"
          class="max-w-[185px] pc:max-w-[284px] mx-auto mb-20 pc:mb-32"
        />
        <p class="text-xl font-bold text-center pc:text-4xl mb-7 pc:mb-11">회원가입을 진심으로 환영합니다.</p>
        <ul class="grid grid-cols-2 gap-2.5 pc:gap-5 pc:max-w-md mx-auto max-w-xs">
          <li
            class="py-4 text-sm font-bold text-center border border-solid rounded cursor-pointer text-neutral-500 pc:py-6 pc:text-xl border-neutral-500"
            @click="backHome()"
          >
            메인 페이지로 이동
          </li>
          <li
            class="py-4 text-sm font-bold text-center text-white bg-orange-500 rounded cursor-pointer pc:py-6 pc:text-xl"
            @click="goEnrolment()"
          >
            수강신청 바로가기
          </li>
        </ul>
      </div>
    </section>
  </div>
</template>
<script>
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import axios from "@lifeeduWeb/plugins/axios.js";

export default {
  components: {},
  setup() {
    const router = useRouter();
    const route = useRoute();
    const store = useStore();
    const idxLeSiteMain = store.getters.getIdxLeSiteMain;

    const backHome = () => {
      router.push({
        path: "/home",
        query: {
          idxLeSiteMain: idxLeSiteMain,
        },
      });
    };

    const goEnrolment = () => {
      const token = store.getters.getAccessToken;
      const params = {
        token: token,
      };
      axios.get(`/user/auth/token`, { params }).then(function (res) {
        if (res.data.success === true) {
          router.push({
            path: "/enrolment/newEnrolment",
            query: { idxLeSiteMain },
          });
        } else {
          alert("로그인이 필요한 서비스 입니다.");
          router.push({
            path: "/login/login",
          });
        }
      });
    };

    return {
      backHome,
      goEnrolment,
    };
  },
};
</script>
<style scoped></style>
