<template>
  <div>
    <div class="hidden pc:block bg-wrapper">
      <div class="flex items-center justify-center bg py-[76px] max-w-[1920px] mx-auto">
        <p class="text-[40px] font-bold text-neutral-950">수강절차 안내</p>
      </div>
    </div>

    <section class="pt-[55px] pb-[70px] pc:py-[140px] pc-con-wrapper1">
      <p class="text-xl font-bold text-center text-neutral-950 pc:text-4xl mb-7 pc:mb-11">수강신청 Step</p>
      <div class="mx-auto">
        <ul class="flex flex-col items-center justify-center max-w-lg gap-4 mx-auto pc:flex-row pc:flex-wrap pc:max-w-[722px]">
          <li class="w-full pc:w-48 pc:grow pc:self-stretch">
            <div
              class="flex items-center p-5 border border-solid pc:pt-[42px] pc:px-[34px] gap-7 border-neutral-200 rounded-xl pc:flex-col pc:items-start pc:h-[240px]"
            >
              <div class="flex items-center w-11 h-11">
                <img src="@/lifeeduWeb/assets/images/enrolment/step/step1.png" alt="icon" />
              </div>
              <div class="flex items-center gap-5 pc:flex-col pc:gap-3 pc:items-start">
                <p class="font-light text-orange-500 pc:text-lg">Step.1</p>
                <p class="font-bold text-neutral-950 pc:text-xl">회원가입</p>
              </div>
            </div>
          </li>
          <li>
            <div class="w-5 pc:w-[30px] pc:-rotate-90">
              <img src="@/lifeeduWeb/assets/images/enrolment/step/step-arrow.png" alt="icon" />
            </div>
          </li>
          <li class="w-full pc:w-48 pc:grow pc:self-stretch">
            <div
              class="flex items-center p-5 border border-solid pc:pt-[42px] pc:px-[34px] gap-7 border-neutral-200 rounded-xl pc:flex-col pc:items-start pc:h-[240px]"
            >
              <div class="flex items-center w-11 h-11">
                <img src="@/lifeeduWeb/assets/images/enrolment/step/step2.png" alt="icon" />
              </div>
              <div class="flex items-center gap-5 pc:flex-col pc:gap-3 pc:items-start">
                <p class="font-light text-orange-500 pc:text-lg">Step.2</p>
                <p class="font-bold text-neutral-950 pc:text-xl">수강신청</p>
              </div>
            </div>
          </li>
          <li>
            <div class="w-5 pc:w-[30px] pc:-rotate-90">
              <img src="@/lifeeduWeb/assets/images/enrolment/step/step-arrow.png" alt="icon" />
            </div>
          </li>
          <li class="w-full pc:w-48 pc:grow pc:self-stretch">
            <div
              class="flex items-center p-5 border border-solid pc:pt-[42px] pc:px-[34px] gap-7 border-neutral-200 rounded-xl pc:flex-col pc:items-start pc:h-[240px]"
            >
              <div class="flex items-center w-11 h-11">
                <img src="@/lifeeduWeb/assets/images/enrolment/step/step3.png" alt="icon" />
              </div>
              <div class="flex items-center gap-5 pc:flex-col pc:gap-3 pc:items-start">
                <p class="font-light text-orange-500 pc:text-lg">Step.3</p>
                <p class="font-bold text-neutral-950 pc:text-xl">결제</p>
              </div>
            </div>
          </li>
          <li class="pc:hidden">
            <div class="w-5 pc:w-[30px] pc:-rotate-90">
              <img src="@/lifeeduWeb/assets/images/enrolment/step/step-arrow.png" alt="icon" />
            </div>
          </li>
          <li class="w-full pc:w-48 pc:grow pc:self-stretch">
            <div
              class="flex items-center p-5 border border-solid pc:pt-[42px] pc:px-[34px] gap-7 border-neutral-200 rounded-xl pc:flex-col pc:items-start pc:h-[240px]"
            >
              <div class="flex items-center w-11 h-11">
                <img src="@/lifeeduWeb/assets/images/enrolment/step/step4.png" alt="icon" />
              </div>
              <div class="flex items-center gap-5 pc:flex-col pc:gap-3 pc:items-start">
                <p class="font-light text-orange-500 pc:text-lg">Step.4</p>
                <p class="font-bold text-neutral-950 pc:text-xl">레벨테스트<br />수업 배정</p>
              </div>
            </div>
          </li>
          <li>
            <div class="w-5 pc:w-[30px] pc:-rotate-90">
              <img src="@/lifeeduWeb/assets/images/enrolment/step/step-arrow.png" alt="icon" />
            </div>
          </li>
          <li class="w-full pc:w-48 pc:grow pc:self-stretch">
            <div
              class="flex items-center p-5 border border-solid pc:pt-[42px] pc:px-[34px] gap-7 border-neutral-200 rounded-xl pc:flex-col pc:items-start pc:h-[240px]"
            >
              <div class="flex items-center w-11 h-11">
                <img src="@/lifeeduWeb/assets/images/enrolment/step/step5.png" alt="icon" />
              </div>
              <div class="flex items-center gap-5 pc:flex-col pc:gap-3 pc:items-start">
                <p class="font-light text-orange-500 pc:text-lg">Step.5</p>
                <p class="font-bold text-neutral-950 pc:text-xl">수업안내</p>
              </div>
            </div>
          </li>
          <li>
            <div class="w-5 pc:w-[30px] pc:-rotate-90">
              <img src="@/lifeeduWeb/assets/images/enrolment/step/step-arrow.png" alt="icon" />
            </div>
          </li>
          <li class="w-full pc:w-48 pc:grow pc:self-stretch">
            <div
              class="flex items-center p-5 border border-solid pc:pt-[42px] pc:px-[34px] gap-7 border-neutral-200 rounded-xl pc:flex-col pc:items-start pc:h-[240px]"
            >
              <div class="flex items-center w-11 h-11">
                <img src="@/lifeeduWeb/assets/images/enrolment/step/step6.png" alt="icon" />
              </div>
              <div class="flex items-center gap-5 pc:flex-col pc:gap-3 pc:items-start">
                <p class="font-light text-orange-500 pc:text-lg">Step.6</p>
                <p class="font-bold text-neutral-950 pc:text-xl">수업시작</p>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </section>
  </div>
</template>
<script>
import { reactive, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import axios from "@lifeeduWeb/plugins/axios.js";
import { useStore } from "vuex";

export default {
  components: {},
  setup() {
    const router = useRouter();
    const route = useRoute();
    const store = useStore();

    onMounted(() => {});

    return {};
  },
};
</script>
<style scoped></style>
