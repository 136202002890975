<template>
  <div>
    <div class="hidden pc:block bg-wrapper">
      <div class="flex items-center justify-center bg py-[76px] max-w-[1920px] mx-auto">
        <p class="text-[40px] font-bold text-neutral-950">성인 과정</p>
      </div>
    </div>

    <section class="pt-[55px] pb-[70px] pc:pt-[70px] pc:pb-[140px]">
      <div>
        <div class="flex items-center justify-between p-2 bg-[#323740] pc:hidden show-list">
          <div class="w-3"></div>
          <p class="text-sm font-bold text-white">과정 리스트 보기</p>
          <div class="w-3">
            <img src="@lifeeduWeb/assets/images/courseIntroduce/adult/arrow.png" alt="icon" class="transition-all show-arrow" />
          </div>
        </div>
        <ul class="flex pc:flex-col pc:max-w-[1200px] pc:mx-auto lang-list">
          <li class="w-[40%] bg-[#ececec] pc:w-full pc:bg-white">
            <ul class="gap-10 pc:flex pc:border-b border-solid border-[#f1f1f1]">
              <li
                class="py-3.5 font-medium text-center text-[#fb6930] cursor-pointer"
                @click="goPage('/courseIntroduce/adultEng/PageAll')"
              >
                영어
              </li>
              <li class="py-3.5 font-medium text-center cursor-pointer" @click="goPage('/courseIntroduce/adultChi/pageAll')">
                중국어
              </li>
              <li class="py-3.5 font-medium text-center cursor-pointer" @click="goPage('/courseIntroduce/adultJap/pageAll')">
                일본어
              </li>
              <li class="py-3.5 font-medium text-center cursor-pointer" @click="goPage('/courseIntroduce/adultEtc')">
                제2외국어
              </li>
            </ul>
          </li>
          <li class="w-[60%] pc:w-full">
            <ul class="divide-y divide-solid divide-[#e9e9e9] pc:flex pc:divide-y-0 gap-9 sub-list">
              <li
                class="py-3.5 font-medium text-center cursor-pointer text-[#666666]"
                @click="goPage('/courseIntroduce/adultEng/PageAll')"
              >
                전체 커리큘럼 맵
              </li>
              <li
                class="py-3.5 font-medium text-center cursor-pointer text-[#666666]"
                @click="goPage('/courseIntroduce/adultEng/page1')"
              >
                일반회화
              </li>
              <li
                class="py-3.5 font-medium text-center cursor-pointer text-[#666666]"
                @click="goPage('/courseIntroduce/adultEng/page2')"
              >
                비즈니스회화
              </li>
              <li
                class="py-3.5 font-medium text-center cursor-pointer text-[#666666]"
                @click="goPage('/courseIntroduce/adultEng/page3')"
              >
                토론회화
              </li>
              <li
                class="py-3.5 font-medium text-center text-[#fb6930] cursor-pointer"
                @click="goPage('/courseIntroduce/adultEng/page4')"
              >
                시험·인터뷰대비
              </li>
              <li
                class="py-3.5 font-medium text-center cursor-pointer text-[#666666]"
                @click="goPage('/courseIntroduce/adultEng/page5')"
              >
                로이터뉴스
              </li>
              <li
                class="py-3.5 font-medium text-center cursor-pointer text-[#666666]"
                @click="goPage('/courseIntroduce/adultEng/page6')"
              >
                Global Topic
              </li>
            </ul>
          </li>
        </ul>
        <div class="pc-con-wrapper5">
          <div class="flex flex-col items-center bg4 py-10 pc:py-[90px] px-5 mb-7 pc:mb-11">
            <p class="text-white font-bold text-center text-[35px] mb-2">시험·인터뷰대비</p>
            <div class="w-[72px] h-[2px] bg-[#fb6930] mb-6"></div>
            <p class="text-center text-white pc:text-2xl">
              시험대비 3개월 완성 과정, 어휘와 핵심문장 패턴을 익히고<br />1:1 수업을 통해 실전 감각과 시험 전략을 키웁니다.
            </p>
          </div>
          <div class="px-5 pc-con-wrapper4">
            <ul class="grid grid-cols-2 gap-5 tablet:grid-cols-3 pc:grid-cols-4 pc:gap-10">
              <li>
                <div class="relative mb-2.5">
                  <img src="@lifeeduWeb/assets/images/courseIntroduce/adult/eng/bg4-book01.png" alt="img" />
                  <p class="absolute right-0 px-3 py-1 text-white bottom-5 bg-black/60">초급~중급</p>
                </div>
                <p
                  class="inline-block cursor-pointer text-sm px-3.5 font-medium text-[#fb6930] mb-1 border border-solid border-[fb6930] rounded-2xl"
                  @click="
                    goSite(
                      'https://carrotenglish.kr/guide/media_view/book_media_study/28?_gl=1*9blxtw*_ga*MTMxNDQwMTgzMS4xNjk4MjIxMjM4*_ga_R79H2W39ZD*MTcwMjQ0MTk3NS4yOC4xLjE3MDI0NDQyODguNTkuMC4w'
                    )
                  "
                >
                  미리보기
                </p>
                <p class="text-[#111111] font-medium mb-1.5">OPIc IM 완전정복</p>
                <p class="text-sm text-[#535353] mb-1">
                  전문 강사님과 OPIC 예상문제를 중심으로 집중 학습을 통한 단기적 성적 향상을 목표로 하는 과정
                </p>
                <ul class="flex flex-wrap gap-1">
                  <li class="text-sm py-px px-2 bg-[#ececec] rounded-2xl">#IM레벨</li>
                  <li class="text-sm py-px px-2 bg-[#ececec] rounded-2xl">#토픽별</li>
                  <li class="text-sm py-px px-2 bg-[#ececec] rounded-2xl">#완전정복</li>
                </ul>
              </li>
              <li>
                <div class="relative mb-2.5">
                  <img src="@lifeeduWeb/assets/images/courseIntroduce/adult/eng/bg4-book02.png" alt="img" />
                  <p class="absolute right-0 px-3 py-1 text-white bottom-5 bg-black/60">중급~고급</p>
                </div>
                <p
                  class="inline-block cursor-pointer text-sm px-3.5 font-medium text-[#fb6930] mb-1 border border-solid border-[fb6930] rounded-2xl"
                  @click="goSite('https://kr.object.ncloudstorage.com/mailpad/b2b_pdf/EN08_Online%20OPIc%20Advanced.pdf')"
                >
                  미리보기
                </p>
                <p class="text-[#111111] font-medium mb-1.5">OPIc IH 완전정복</p>
                <p class="text-sm text-[#535353] mb-1">
                  전문 강사님과 OPIC 예상문제를 중심으로 집중 학습을 통한 단기적 성적 향상을 목표로 하는 과정
                </p>
                <ul class="flex flex-wrap gap-1">
                  <li class="text-sm py-px px-2 bg-[#ececec] rounded-2xl">#IH레벨</li>
                  <li class="text-sm py-px px-2 bg-[#ececec] rounded-2xl">#토픽별</li>
                  <li class="text-sm py-px px-2 bg-[#ececec] rounded-2xl">#완전정복</li>
                </ul>
              </li>
              <li>
                <div class="relative mb-2.5">
                  <img src="@lifeeduWeb/assets/images/courseIntroduce/adult/eng/bg4-book03.png" alt="img" />
                  <p class="absolute right-0 px-3 py-1 text-white bottom-5 bg-black/60">초급~중급</p>
                </div>
                <p
                  class="inline-block cursor-pointer text-sm px-3.5 font-medium text-[#fb6930] mb-1 border border-solid border-[fb6930] rounded-2xl"
                  @click="
                    goSite('https://kr.object.ncloudstorage.com/mailpad/b2b_pdf/EN09_Online%20TOEIC%20Speaking%20Level%205.pdf')
                  "
                >
                  미리보기
                </p>
                <p class="text-[#111111] font-medium mb-1.5">Dynamic Discussion</p>
                <p class="text-sm text-[#535353] mb-1">
                  전문 강사님과 TOEIC SPEAKING 예상문제를 중심으로 집중 학습을 통한 단기적 성적 향상을 목표로 하는 과정
                </p>
                <ul class="flex flex-wrap gap-1">
                  <li class="text-sm py-px px-2 bg-[#ececec] rounded-2xl">#토스초심자</li>
                  <li class="text-sm py-px px-2 bg-[#ececec] rounded-2xl">#유형별</li>
                  <li class="text-sm py-px px-2 bg-[#ececec] rounded-2xl">#완전정복</li>
                </ul>
              </li>
              <li>
                <div class="relative mb-2.5">
                  <img src="@lifeeduWeb/assets/images/courseIntroduce/adult/eng/bg4-book04.png" alt="img" />
                  <p class="absolute right-0 px-3 py-1 text-white bottom-5 bg-black/60">초급~중급</p>
                </div>
                <p
                  class="inline-block cursor-pointer text-sm px-3.5 font-medium text-[#fb6930] mb-1 border border-solid border-[fb6930] rounded-2xl"
                  @click="
                    goSite('https://kr.object.ncloudstorage.com/mailpad/b2b_pdf/EN10_Online%20TOEIC%20Speaking%20Level%207.pdf')
                  "
                >
                  미리보기
                </p>
                <p class="text-[#111111] font-medium mb-1.5">토익스피킹 Lv.7 완전정복</p>
                <p class="text-sm text-[#535353] mb-1">
                  전문 강사님과 TOEIC SPEAKING 예상문제를 중심으로 집중 학습을 통한 단기적 성적 향상을 목표로 하는 과정
                </p>
                <ul class="flex flex-wrap gap-1">
                  <li class="text-sm py-px px-2 bg-[#ececec] rounded-2xl">#토스초심자</li>
                  <li class="text-sm py-px px-2 bg-[#ececec] rounded-2xl">#유형별</li>
                  <li class="text-sm py-px px-2 bg-[#ececec] rounded-2xl">#완전정복</li>
                </ul>
              </li>
              <li>
                <div class="relative mb-2.5">
                  <img src="@lifeeduWeb/assets/images/courseIntroduce/adult/eng/bg4-book05.png" alt="img" />
                  <p class="absolute right-0 px-3 py-1 text-white bottom-5 bg-black/60">입문~초급</p>
                </div>
                <p
                  class="inline-block cursor-pointer text-sm px-3.5 font-medium text-[#fb6930] mb-1 border border-solid border-[fb6930] rounded-2xl"
                  @click="goSite('https://kr.object.ncloudstorage.com/mailpad/b2b_pdf/EN12_Speaking%20Practice%202.pdf')"
                >
                  미리보기
                </p>
                <p class="text-[#111111] font-medium mb-1.5">Speaking Practice 1</p>
                <p class="text-sm text-[#535353] mb-1">
                  전문 강사님과 TOEIC SPEAKING 예상문제를 중심으로 집중 학습을 통한 단기적 성적 향상을 목표로 하는 과정
                </p>
                <ul class="flex flex-wrap gap-1">
                  <li class="text-sm py-px px-2 bg-[#ececec] rounded-2xl">#스피킹</li>
                  <li class="text-sm py-px px-2 bg-[#ececec] rounded-2xl">#시험대비</li>
                  <li class="text-sm py-px px-2 bg-[#ececec] rounded-2xl">#문장연습</li>
                </ul>
              </li>
              <li>
                <div class="relative mb-2.5">
                  <img src="@lifeeduWeb/assets/images/courseIntroduce/adult/eng/bg4-book06.png" alt="img" />
                  <p class="absolute right-0 px-3 py-1 text-white bottom-5 bg-black/60">입문~초급</p>
                </div>
                <p
                  class="inline-block cursor-pointer text-sm px-3.5 font-medium text-[#fb6930] mb-1 border border-solid border-[fb6930] rounded-2xl"
                  @click="goSite('https://kr.object.ncloudstorage.com/mailpad/b2b_pdf/EN12_Speaking%20Practice%202.pdf')"
                >
                  미리보기
                </p>
                <p class="text-[#111111] font-medium mb-1.5">Job 인터뷰 완벽대비 2</p>
                <p class="text-sm text-[#535353] mb-1">
                  전문 강사님과 TOEIC SPEAKING 예상문제를 중심으로 집중 학습을 통한 단기적 성적 향상을 목표로 하는 과정
                </p>
                <ul class="flex flex-wrap gap-1">
                  <li class="text-sm py-px px-2 bg-[#ececec] rounded-2xl">#스피킹</li>
                  <li class="text-sm py-px px-2 bg-[#ececec] rounded-2xl">#시험대비</li>
                  <li class="text-sm py-px px-2 bg-[#ececec] rounded-2xl">#문장연습</li>
                </ul>
              </li>
              <li>
                <div class="relative mb-2.5">
                  <img src="@lifeeduWeb/assets/images/courseIntroduce/adult/eng/bg4-book07.png" alt="img" />
                  <p class="absolute right-0 px-3 py-1 text-white bottom-5 bg-black/60">전 레벨</p>
                </div>
                <p
                  class="inline-block cursor-pointer text-sm px-3.5 font-medium text-[#fb6930] mb-1 border border-solid border-[fb6930] rounded-2xl"
                  @click="goSite('https://kr.object.ncloudstorage.com/mailpad/b2b_pdf/EN13_Power%20job%20interview%201.pdf')"
                >
                  미리보기
                </p>
                <p class="text-[#111111] font-medium mb-1.5">Speaking Practice 2</p>
                <p class="text-sm text-[#535353] mb-1">
                  전문 강사님과 TOEIC SPEAKING 예상문제를 중심으로 집중 학습을 통한 단기적 성적 향상을 목표로 하는 과정
                </p>
                <ul class="flex flex-wrap gap-1">
                  <li class="text-sm py-px px-2 bg-[#ececec] rounded-2xl">#스피킹</li>
                  <li class="text-sm py-px px-2 bg-[#ececec] rounded-2xl">#시험대비</li>
                  <li class="text-sm py-px px-2 bg-[#ececec] rounded-2xl">#문장연습</li>
                </ul>
              </li>
              <li>
                <div class="relative mb-2.5">
                  <img
                    src="@lifeeduWeb/assets/images/courseIntroduce/adult/eng/bg4-book08.png"
                    alt="img"
                    class="https://kr.object.ncloudstorage.com/mailpad/b2b_pdf/EN10_Online%20TOEIC%20Speaking%20Level%207.pdf"
                  />
                  <p class="absolute right-0 px-3 py-1 text-white bottom-5 bg-black/60">전 레벨</p>
                </div>
                <p
                  class="inline-block cursor-pointer text-sm px-3.5 font-medium text-[#fb6930] mb-1 border border-solid border-[fb6930] rounded-2xl"
                  @click="goSite('https://kr.object.ncloudstorage.com/mailpad/b2b_pdf/EN14_Power%20job%20interview%202.pdf')"
                >
                  미리보기
                </p>
                <p class="text-[#111111] font-medium mb-1.5">Job 인터뷰 완벽대비 2</p>
                <p class="text-sm text-[#535353] mb-1">
                  전문 강사님과 TOEIC SPEAKING 예상문제를 중심으로 집중 학습을 통한 단기적 성적 향상을 목표로 하는 과정
                </p>
                <ul class="flex flex-wrap gap-1">
                  <li class="text-sm py-px px-2 bg-[#ececec] rounded-2xl">#스피킹</li>
                  <li class="text-sm py-px px-2 bg-[#ececec] rounded-2xl">#시험대비</li>
                  <li class="text-sm py-px px-2 bg-[#ececec] rounded-2xl">#문장연습</li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import { ref, reactive, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import axios from "@lifeeduWeb/plugins/axios.js";
import { useStore } from "vuex";

export default {
  components: {},

  setup() {
    const router = useRouter();
    const route = useRoute();
    const store = useStore();

    const goPage = (url) => {
      location.href = url;
    };

    const goSite = (url) => {
      window.open(url, "_blank");
    };

    onMounted(() => {
      const showListButton = document.querySelector(".show-list");
      const arrowIcon = showListButton.querySelector(".show-arrow");
      const listToToggle = document.querySelector(".lang-list");
      const countryListItems = document.querySelectorAll(".sub-list li");

      showListButton.addEventListener("click", function () {
        if (listToToggle.style.display === "none") {
          listToToggle.style.display = "flex";
        } else {
          listToToggle.style.display = "none";
        }

        arrowIcon.classList.toggle("rotate-180");
      });

      countryListItems.forEach(function (item) {
        item.addEventListener("click", function () {
          listToToggle.style.display = "none";
          arrowIcon.classList.remove("rotate-180");
        });
      });
    });

    return {
      goPage,
      goSite,
    };
  },
};
</script>
<style lang="scss" scoped>
.bg4 {
  background: url(@lifeeduWeb/assets/images/courseIntroduce/adult/eng/bg4.png) no-repeat center / 1920px;
}
</style>
