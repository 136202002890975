<template>
  <div>
    <div class="hidden pc:block bg-wrapper">
      <div class="flex items-center justify-center bg py-[76px] max-w-[1920px] mx-auto">
        <p class="text-[40px] font-bold text-neutral-950">성인 과정</p>
      </div>
    </div>

    <section class="pt-[55px] pb-[70px] pc:pt-[70px] pc:pb-[140px]">
      <div>
        <div class="flex items-center justify-between p-2 bg-[#323740] pc:hidden show-list">
          <div class="w-3"></div>
          <p class="text-sm font-bold text-white">과정 리스트 보기</p>
          <div class="w-3">
            <img src="@lifeeduWeb/assets/images/courseIntroduce/adult/arrow.png" alt="icon" class="transition-all show-arrow" />
          </div>
        </div>
        <ul class="flex pc:flex-col pc:max-w-[1200px] pc:mx-auto lang-list">
          <li class="w-[40%] bg-[#ececec] pc:w-full pc:bg-white">
            <ul class="gap-10 pc:flex pc:border-b border-solid border-[#f1f1f1]">
              <li
                class="py-3.5 font-medium text-center text-[#fb6930] cursor-pointer"
                @click="goPage('/courseIntroduce/adultEng/PageAll')"
              >
                영어
              </li>
              <li class="py-3.5 font-medium text-center cursor-pointer" @click="goPage('/courseIntroduce/adultChi/pageAll')">
                중국어
              </li>
              <li class="py-3.5 font-medium text-center cursor-pointer" @click="goPage('/courseIntroduce/adultJap/pageAll')">
                일본어
              </li>
              <li class="py-3.5 font-medium text-center cursor-pointer" @click="goPage('/courseIntroduce/adultEtc')">
                제2외국어
              </li>
            </ul>
          </li>
          <li class="w-[60%] pc:w-full">
            <ul class="divide-y divide-solid divide-[#e9e9e9] pc:flex pc:divide-y-0 gap-9 sub-list">
              <li
                class="py-3.5 font-medium text-center cursor-pointer text-[#666666]"
                @click="goPage('/courseIntroduce/adultEng/PageAll')"
              >
                전체 커리큘럼 맵
              </li>
              <li
                class="py-3.5 font-medium text-center text-[#fb6930] cursor-pointer"
                @click="goPage('/courseIntroduce/adultEng/page1')"
              >
                일반회화
              </li>
              <li
                class="py-3.5 font-medium text-center cursor-pointer text-[#666666]"
                @click="goPage('/courseIntroduce/adultEng/page2')"
              >
                비즈니스회화
              </li>
              <li
                class="py-3.5 font-medium text-center cursor-pointer text-[#666666]"
                @click="goPage('/courseIntroduce/adultEng/page3')"
              >
                토론회화
              </li>
              <li
                class="py-3.5 font-medium text-center cursor-pointer text-[#666666]"
                @click="goPage('/courseIntroduce/adultEng/page4')"
              >
                시험·인터뷰대비
              </li>
              <li
                class="py-3.5 font-medium text-center cursor-pointer text-[#666666]"
                @click="goPage('/courseIntroduce/adultEng/page5')"
              >
                로이터뉴스
              </li>
              <li
                class="py-3.5 font-medium text-center cursor-pointer text-[#666666]"
                @click="goPage('/courseIntroduce/adultEng/page6')"
              >
                Global Topic
              </li>
            </ul>
          </li>
        </ul>
        <div class="pc-con-wrapper5">
          <div class="flex flex-col items-center bg1 py-10 pc:py-[90px] px-5 mb-7 pc:mb-11">
            <p class="text-white font-bold text-center text-[35px] mb-2">일반회화</p>
            <div class="w-[72px] h-[2px] bg-[#fb6930] mb-6"></div>
            <p class="text-center text-white pc:text-2xl">
              실제 상황별 표현을 통해 기본기를 다지고<br />회화에 꼭 필요한 필수표현 습득으로 영어 자신감과 재미를 100%
              보장합니다.
            </p>
          </div>
          <div class="px-5 pc-con-wrapper4">
            <ul class="grid grid-cols-2 gap-5 tablet:grid-cols-3 pc:grid-cols-4 pc:gap-10">
              <li>
                <div class="relative mb-2.5">
                  <img src="@lifeeduWeb/assets/images/courseIntroduce/adult/eng/bg1-book01.png" alt="img" />
                  <p class="absolute right-0 px-3 py-1 text-white bottom-5 bg-black/60">입문</p>
                </div>
                <p
                  class="inline-block cursor-pointer text-sm px-3.5 font-medium text-[#fb6930] mb-1 border border-solid border-[fb6930] rounded-2xl"
                  @click="goSite('https://kr.object.ncloudstorage.com/mailpad/b2b_pdf/EN01_Power%20Verb%208%20Collocation.pdf')"
                >
                  미리보기
                </p>
                <p class="text-[#111111] font-medium mb-1.5">Power Verb 8 Collocation</p>
                <p class="text-sm text-[#535353] mb-1">
                  영어에서 가장 많이 쓰이는 동사 8가지를 통해 다양한 영어표현 습득 및 구문 구사 능력 향상시키는 과정
                </p>
                <ul class="flex flex-wrap gap-1">
                  <li class="text-sm py-px px-2 bg-[#ececec] rounded-2xl">#8가지</li>
                  <li class="text-sm py-px px-2 bg-[#ececec] rounded-2xl">#동사활용</li>
                  <li class="text-sm py-px px-2 bg-[#ececec] rounded-2xl">#문장만들기</li>
                </ul>
              </li>
              <li>
                <div class="relative mb-2.5">
                  <img src="@lifeeduWeb/assets/images/courseIntroduce/adult/eng/bg1-book02.png" alt="img" />
                  <p class="absolute right-0 px-3 py-1 text-white bottom-5 bg-black/60">입문~초급</p>
                </div>
                <p
                  class="inline-block cursor-pointer text-sm px-3.5 font-medium text-[#fb6930] mb-1 border border-solid border-[fb6930] rounded-2xl"
                  @click="
                    goSite(
                      'https://carrotenglish.kr/guide/media_view/book_media_study/912?_gl=1*1upjrxz*_ga*MTMxNDQwMTgzMS4xNjk4MjIxMjM4*_ga_R79H2W39ZD*MTcwMjQzNTMwNS4yNy4xLjE3MDI0MzYxNTguNjAuMC4w'
                    )
                  "
                >
                  미리보기
                </p>
                <p class="text-[#111111] font-medium mb-1.5">Pattern English</p>
                <p class="text-sm text-[#535353] mb-1">
                  기본 문장 패턴 반복 학습을 통한 다양한 문장 유형 및 기초 표현 학습하는 과정
                </p>
                <ul class="flex flex-wrap gap-1">
                  <li class="text-sm py-px px-2 bg-[#ececec] rounded-2xl">#왕초보</li>
                  <li class="text-sm py-px px-2 bg-[#ececec] rounded-2xl">#패턴으로</li>
                  <li class="text-sm py-px px-2 bg-[#ececec] rounded-2xl">#문장연습</li>
                </ul>
              </li>
              <li>
                <div class="relative mb-2.5">
                  <img src="@lifeeduWeb/assets/images/courseIntroduce/adult/eng/bg1-book03.png" alt="img" />
                  <p class="absolute right-0 px-3 py-1 text-white bottom-5 bg-black/60">입문</p>
                </div>
                <p
                  class="inline-block cursor-pointer text-sm px-3.5 font-medium text-[#fb6930] mb-1 border border-solid border-[fb6930] rounded-2xl"
                  @click="
                    goSite(
                      'https://carrotenglish.kr/guide/media_view/book_media_study/26?_gl=1*10vysuv*_ga*MTMxNDQwMTgzMS4xNjk4MjIxMjM4*_ga_R79H2W39ZD*MTcwMjQ0MTk3NS4yOC4xLjE3MDI0NDE5NzcuNTguMC4w'
                    )
                  "
                >
                  미리보기
                </p>
                <p class="text-[#111111] font-medium mb-1.5">Essential English</p>
                <p class="text-sm text-[#535353] mb-1">일상생활에서 자주 쓰이는 쉽고 중요한 표현을 배우는 학습 과정</p>
                <ul class="flex flex-wrap gap-1">
                  <li class="text-sm py-px px-2 bg-[#ececec] rounded-2xl">#왕초보</li>
                  <li class="text-sm py-px px-2 bg-[#ececec] rounded-2xl">#첫걸음</li>
                  <li class="text-sm py-px px-2 bg-[#ececec] rounded-2xl">#영어새로고침</li>
                </ul>
              </li>
              <li>
                <div class="relative mb-2.5">
                  <img src="@lifeeduWeb/assets/images/courseIntroduce/adult/eng/bg1-book04.png" alt="img" />
                  <p class="absolute right-0 px-3 py-1 text-white bottom-5 bg-black/60">초급</p>
                </div>
                <p
                  class="inline-block cursor-pointer text-sm px-3.5 font-medium text-[#fb6930] mb-1 border border-solid border-[fb6930] rounded-2xl"
                  @click="
                    goSite(
                      'https://carrotenglish.kr/guide/media_view/book_media_study/30?_gl=1*1qda9fx*_ga*MTMxNDQwMTgzMS4xNjk4MjIxMjM4*_ga_R79H2W39ZD*MTcwMjQ0MTk3NS4yOC4xLjE3MDI0NDE5NzcuNTguMC4w'
                    )
                  "
                >
                  미리보기
                </p>
                <p class="text-[#111111] font-medium mb-1.5">NEW Pre-Get Up to Speed 1</p>
                <p class="text-sm text-[#535353] mb-1">
                  일상생활에서 사용하는 주요 표현을 습득하여 커뮤니케이션 능력을 향상 시키는 과정
                </p>
                <ul class="flex flex-wrap gap-1">
                  <li class="text-sm py-px px-2 bg-[#ececec] rounded-2xl">#일상회화</li>
                  <li class="text-sm py-px px-2 bg-[#ececec] rounded-2xl">#단어</li>
                  <li class="text-sm py-px px-2 bg-[#ececec] rounded-2xl">#표현익히기</li>
                </ul>
              </li>
              <li>
                <div class="relative mb-2.5">
                  <img src="@lifeeduWeb/assets/images/courseIntroduce/adult/eng/bg1-book05.png" alt="img" />
                  <p class="absolute right-0 px-3 py-1 text-white bottom-5 bg-black/60">초급</p>
                </div>
                <p
                  class="inline-block cursor-pointer text-sm px-3.5 font-medium text-[#fb6930] mb-1 border border-solid border-[fb6930] rounded-2xl"
                  @click="
                    goSite(
                      'https://carrotenglish.kr/guide/media_view/book_media_study/39?_gl=1*bm23cm*_ga*MTMxNDQwMTgzMS4xNjk4MjIxMjM4*_ga_R79H2W39ZD*MTcwMjQ0MTk3NS4yOC4xLjE3MDI0NDI1MjYuNjAuMC4w'
                    )
                  "
                >
                  미리보기
                </p>
                <p class="text-[#111111] font-medium mb-1.5">NEW Pre-Get Up to Speed 2</p>
                <p class="text-sm text-[#535353] mb-1">
                  일상생활에서 사용하는 주요 표현을 습득하여 커뮤니케이션 능력을 향상 시키는 과정
                </p>
                <ul class="flex flex-wrap gap-1">
                  <li class="text-sm py-px px-2 bg-[#ececec] rounded-2xl">#일상회화</li>
                  <li class="text-sm py-px px-2 bg-[#ececec] rounded-2xl">#단어</li>
                  <li class="text-sm py-px px-2 bg-[#ececec] rounded-2xl">#표현익히기</li>
                </ul>
              </li>
              <li>
                <div class="relative mb-2.5">
                  <img src="@lifeeduWeb/assets/images/courseIntroduce/adult/eng/bg1-book06.png" alt="img" />
                  <p class="absolute right-0 px-3 py-1 text-white bottom-5 bg-black/60">중급</p>
                </div>
                <p
                  class="inline-block cursor-pointer text-sm px-3.5 font-medium text-[#fb6930] mb-1 border border-solid border-[fb6930] rounded-2xl"
                  @click="
                    goSite(
                      'https://carrotenglish.kr/guide/media_view/book_media_study/38?_gl=1*1b86wvf*_ga*MTMxNDQwMTgzMS4xNjk4MjIxMjM4*_ga_R79H2W39ZD*MTcwMjQ0MTk3NS4yOC4xLjE3MDI0NDI1MjYuNjAuMC4w'
                    )
                  "
                >
                  미리보기
                </p>
                <p class="text-[#111111] font-medium mb-1.5">NEW Get Up to Speed 1</p>
                <p class="text-sm text-[#535353] mb-1">
                  일상생활에서 사용하는 주요 표현을 습득하여 커뮤니케이션 능력을 향상 시키는 과정
                </p>
                <ul class="flex flex-wrap gap-1">
                  <li class="text-sm py-px px-2 bg-[#ececec] rounded-2xl">#일상회화</li>
                  <li class="text-sm py-px px-2 bg-[#ececec] rounded-2xl">#단어</li>
                  <li class="text-sm py-px px-2 bg-[#ececec] rounded-2xl">#표현익히기</li>
                </ul>
              </li>
              <li>
                <div class="relative mb-2.5">
                  <img src="@lifeeduWeb/assets/images/courseIntroduce/adult/eng/bg1-book07.png" alt="img" />
                  <p class="absolute right-0 px-3 py-1 text-white bottom-5 bg-black/60">중급</p>
                </div>
                <p
                  class="inline-block cursor-pointer text-sm px-3.5 font-medium text-[#fb6930] mb-1 border border-solid border-[fb6930] rounded-2xl"
                  @click="
                    goSite(
                      'https://carrotenglish.kr/guide/media_view/book_media_study/51?_gl=1*1b86wvf*_ga*MTMxNDQwMTgzMS4xNjk4MjIxMjM4*_ga_R79H2W39ZD*MTcwMjQ0MTk3NS4yOC4xLjE3MDI0NDI1MjYuNjAuMC4w'
                    )
                  "
                >
                  미리보기
                </p>
                <p class="text-[#111111] font-medium mb-1.5">NEW Get Up to Speed 2</p>
                <p class="text-sm text-[#535353] mb-1">
                  일상생활에서 사용하는 주요 표현을 습득하여 커뮤니케이션 능력을 향상 시키는 과정
                </p>
                <ul class="flex flex-wrap gap-1">
                  <li class="text-sm py-px px-2 bg-[#ececec] rounded-2xl">#일상회화</li>
                  <li class="text-sm py-px px-2 bg-[#ececec] rounded-2xl">#단어</li>
                  <li class="text-sm py-px px-2 bg-[#ececec] rounded-2xl">#표현익히기</li>
                </ul>
              </li>
              <li>
                <div class="relative mb-2.5">
                  <img src="@lifeeduWeb/assets/images/courseIntroduce/adult/eng/bg1-book08.png" alt="img" />
                  <p class="absolute right-0 px-3 py-1 text-white bottom-5 bg-black/60">고급</p>
                </div>
                <p
                  class="inline-block cursor-pointer text-sm px-3.5 font-medium text-[#fb6930] mb-1 border border-solid border-[fb6930] rounded-2xl"
                  @click="
                    goSite(
                      'https://carrotenglish.kr/guide/media_view/book_media_study/536?_gl=1*1b86wvf*_ga*MTMxNDQwMTgzMS4xNjk4MjIxMjM4*_ga_R79H2W39ZD*MTcwMjQ0MTk3NS4yOC4xLjE3MDI0NDI1MjYuNjAuMC4w'
                    )
                  "
                >
                  미리보기
                </p>
                <p class="text-[#111111] font-medium mb-1.5">NEW Get Up to Speed 3</p>
                <p class="text-sm text-[#535353] mb-1">
                  일상생활에서 사용하는 주요 표현을 습득하여 커뮤니케이션 능력을 향상 시키는 과정
                </p>
                <ul class="flex flex-wrap gap-1">
                  <li class="text-sm py-px px-2 bg-[#ececec] rounded-2xl">#일상회화</li>
                  <li class="text-sm py-px px-2 bg-[#ececec] rounded-2xl">#단어</li>
                  <li class="text-sm py-px px-2 bg-[#ececec] rounded-2xl">#표현익히기</li>
                </ul>
              </li>
              <li>
                <div class="relative mb-2.5">
                  <img src="@lifeeduWeb/assets/images/courseIntroduce/adult/eng/bg1-book09.png" alt="img" />
                  <p class="absolute right-0 px-3 py-1 text-white bottom-5 bg-black/60">고급</p>
                </div>
                <p
                  class="inline-block cursor-pointer text-sm px-3.5 font-medium text-[#fb6930] mb-1 border border-solid border-[fb6930] rounded-2xl"
                  @click="
                    goSite(
                      'https://carrotenglish.kr/guide/media_view/book_media_study/157?_gl=1*1l65sfl*_ga*MTMxNDQwMTgzMS4xNjk4MjIxMjM4*_ga_R79H2W39ZD*MTcwMjQ0MTk3NS4yOC4xLjE3MDI0NDI1MjYuNjAuMC4w'
                    )
                  "
                >
                  미리보기
                </p>
                <p class="text-[#111111] font-medium mb-1.5">NEW Get Up to Speed 4</p>
                <p class="text-sm text-[#535353] mb-1">
                  일상생활에서 사용하는 주요 표현을 습득하여 커뮤니케이션 능력을 향상 시키는 과정
                </p>
                <ul class="flex flex-wrap gap-1">
                  <li class="text-sm py-px px-2 bg-[#ececec] rounded-2xl">#일상회화</li>
                  <li class="text-sm py-px px-2 bg-[#ececec] rounded-2xl">#단어</li>
                  <li class="text-sm py-px px-2 bg-[#ececec] rounded-2xl">#표현익히기</li>
                </ul>
              </li>
              <li>
                <div class="relative mb-2.5">
                  <img src="@lifeeduWeb/assets/images/courseIntroduce/adult/eng/bg1-book10.png" alt="img" />
                  <p class="absolute right-0 px-3 py-1 text-white bottom-5 bg-black/60">중급~고급</p>
                </div>
                <p
                  class="inline-block cursor-pointer text-sm px-3.5 font-medium text-[#fb6930] mb-1 border border-solid border-[fb6930] rounded-2xl"
                  @click="
                    goSite(
                      'https://carrotenglish.kr/guide/media_view/book_media_study/870?_gl=1*1l65sfl*_ga*MTMxNDQwMTgzMS4xNjk4MjIxMjM4*_ga_R79H2W39ZD*MTcwMjQ0MTk3NS4yOC4xLjE3MDI0NDI1MjYuNjAuMC4w'
                    )
                  "
                >
                  미리보기
                </p>
                <p class="text-[#111111] font-medium mb-1.5">최신 SNS 트렌디 영어</p>
                <p class="text-sm text-[#535353] mb-1">
                  인스타그램, 페이스북 등 SNS에서 사용할 수 있는 최신 유행 표현을 습득할 수 있는 과정
                </p>
                <ul class="flex flex-wrap gap-1">
                  <li class="text-sm py-px px-2 bg-[#ececec] rounded-2xl">#중급</li>
                  <li class="text-sm py-px px-2 bg-[#ececec] rounded-2xl">#인싸용어</li>
                  <li class="text-sm py-px px-2 bg-[#ececec] rounded-2xl">#최신표현</li>
                </ul>
              </li>
              <li>
                <div class="relative mb-2.5">
                  <img src="@lifeeduWeb/assets/images/courseIntroduce/adult/eng/bg1-book11.png" alt="img" />
                  <p class="absolute right-0 px-3 py-1 text-white bottom-5 bg-black/60">중급~고급</p>
                </div>
                <p
                  class="inline-block cursor-pointer text-sm px-3.5 font-medium text-[#fb6930] mb-1 border border-solid border-[fb6930] rounded-2xl"
                  @click="
                    goSite(
                      'https://carrotenglish.kr/guide/media_view/book_media_study/763?_gl=1*r9hzdr*_ga*MTMxNDQwMTgzMS4xNjk4MjIxMjM4*_ga_R79H2W39ZD*MTcwMjQ0MTk3NS4yOC4xLjE3MDI0NDI1MjYuNjAuMC4w'
                    )
                  "
                >
                  미리보기
                </p>
                <p class="text-[#111111] font-medium mb-1.5">두근두근 여행 영어</p>
                <p class="text-sm text-[#535353] mb-1">해외여행을 하면서 겪게 되는 다양한 상황 속 표현을 학습하는 과정</p>
                <ul class="flex flex-wrap gap-1">
                  <li class="text-sm py-px px-2 bg-[#ececec] rounded-2xl">#테마</li>
                  <li class="text-sm py-px px-2 bg-[#ececec] rounded-2xl">#체크인부터</li>
                  <li class="text-sm py-px px-2 bg-[#ececec] rounded-2xl">#완벽정복</li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import { ref, reactive, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import axios from "@lifeeduWeb/plugins/axios.js";
import { useStore } from "vuex";

export default {
  components: {},

  setup() {
    const router = useRouter();
    const route = useRoute();
    const store = useStore();

    const goPage = (url) => {
      location.href = url;
    };

    const goSite = (url) => {
      window.open(url, "_blank");
    };

    onMounted(() => {
      const showListButton = document.querySelector(".show-list");
      const arrowIcon = showListButton.querySelector(".show-arrow");
      const listToToggle = document.querySelector(".lang-list");
      const countryListItems = document.querySelectorAll(".sub-list li");

      showListButton.addEventListener("click", function () {
        if (listToToggle.style.display === "none") {
          listToToggle.style.display = "flex";
        } else {
          listToToggle.style.display = "none";
        }

        arrowIcon.classList.toggle("rotate-180");
      });

      countryListItems.forEach(function (item) {
        item.addEventListener("click", function () {
          listToToggle.style.display = "none";
          arrowIcon.classList.remove("rotate-180");
        });
      });
    });

    return {
      goPage,
      goSite,
    };
  },
};
</script>
<style lang="scss" scoped>
.bg1 {
  background: url(@lifeeduWeb/assets/images/courseIntroduce/adult/eng/bg1.png) no-repeat center / 1920px;
}
</style>
