<template>
  <ComHeader></ComHeader>
  <div class="wrapper">
    <router-view :key="$route.fullPath" />
  </div>
</template>
<script>
import { onMounted } from "vue";
import ComHeader from "./components/ComHeader.vue";

if (location.origin.includes("stage-lifeedu.carrotenglish.net") || "life-admin.carrotapply.com") {
} else {
  import("@lifeeduAdmin/assets/css/reset.css");
  import("@lifeeduAdmin/assets/css/tailwind.css");
}

export default {
  name: "App",
  components: {
    ComHeader,
  },
  setup() {
    onMounted(() => {});

    return {};
  },
};
</script>
<style></style>
