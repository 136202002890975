<template>
  <div>
    <div class="hidden pc:block bg-wrapper">
      <div class="flex items-center justify-center bg py-[76px] max-w-[1920px] mx-auto">
        <p class="text-[40px] font-bold text-neutral-950">공지사항</p>
      </div>
    </div>

    <section class="pt-[55px] pb-[70px] pc:py-[140px] pc-con-wrapper1">
      <div class="mb-5 border-t border-b border-solid border-neutral-950 pc:mb-7">
        <p
          class="p-5 text-base font-bold border-b border-solid pc:p-6 bg-neutral-50 border-neutral-200 pc:text-lg text-neutral-950"
        >
          {{ noticeDetail.noticeDetailContent.noticeDetailList.title }}
        </p>
        <div class="flex items-center justify-between p-5 border-b border-solid pc:p-6 border-neutral-200">
          <div class="flex items-center gap-2 pc:gap-3">
            <p class="text-sm pc:text-base text-neutral-500">등록일</p>
            <p class="text-sm pc:text-base text-neutral-500">ㅣ</p>
            <p class="text-sm pc:text-base text-neutral-500">
              {{ noticeDetail.noticeDetailContent.noticeDetailList.createDate }}
            </p>
          </div>
          <div class="flex items-center gap-2 pc:gap-3">
            <p class="text-sm pc:text-base text-neutral-500">조회수</p>
            <p class="text-sm pc:text-base text-neutral-500">ㅣ</p>
            <p class="text-sm pc:text-base text-neutral-500">
              {{ noticeDetail.noticeDetailContent.noticeDetailList.viewCount }}
            </p>
          </div>
        </div>
        <div class="p-5 text-sm whitespace-pre-wrap pc:p-6 pc:text-base text-neutral-950">
          <span v-html="noticeDetail.noticeDetailContent.noticeDetailList.content"></span>
        </div>
      </div>
      <div class="flex justify-end">
        <p class="px-5 py-2.5 border border-solid border-neutral-500 cursor-pointer" @click="noticeDetail.goBackList">목록</p>
      </div>
    </section>
  </div>
</template>
<script>
import { reactive, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import axios from "@lifeeduWeb/plugins/axios.js";
import { useStore } from "vuex";

export default {
  components: {},

  setup() {
    const router = useRouter();
    const route = useRoute();
    const store = useStore();
    const idxLeSiteMain = store.getters.getIdxLeSiteMain;

    const noticeDetail = reactive({
      noticeDetailContent: {
        idxNotice: route.query.idxNotice,
        // idxLeSiteMain: route.query.idxLeSiteMain,
        idxLeSiteMain: store.getters.getIdxLeSiteMain,
        noticeDetailList: [],
      },

      notice() {
        const idxNotice = noticeDetail.noticeDetailContent.idxNotice;
        // const idxLeSiteMain = route.query.idxLeSiteMain;
        const idxLeSiteMain = store.getters.getIdxLeSiteMain;

        axios.get(`/user/page/notice?idxNotice=${idxNotice}&idxLeSiteMain=${idxLeSiteMain}`).then(function (res) {
          if (res.data.success === true) {
            noticeDetail.noticeDetailContent.noticeDetailList = res.data.data;
          } else {
            alert(res.data.message);
          }
        });
      },

      goBackList() {
        router.go(-1);
      },
    });

    onMounted(() => {
      noticeDetail.notice();
    });

    return {
      noticeDetail,
      idxLeSiteMain,
    };
  },
};
</script>
<style scoped></style>
