<template>
  <div class="p-4">
    <div class="p-2 mb-5">
      <!-- 수정일 땐 그룹 수강신청 수정 으로 변경  -->
      <p class="text-xl font-semibold">그룹 수강신청 등록</p>
    </div>

    <!-- 일반/지원 선택 -->
    <div class="p-2 flex gap-2">
      <div
        :class="{
          'bg-slate-800 text-white': registSugang.registSugangData.sugangSupType == '일반',
          'text-slate-800 ': registSugang.registSugangData.sugangSupType == '지원',
        }"
        class="p-2 rounded border border-solid border-slate-800"
      >
        일반
      </div>
      <div
        v-if="registSugang.registSugangInfo.isGroupSup == 'Y'"
        :class="{
          'bg-slate-800 text-white': registSugang.registSugangData.sugangSupType == '지원',
          'text-slate-800 ': registSugang.registSugangData.sugangSupType == '일반',
        }"
        class="p-2 rounded border border-solid border-slate-800"
      >
        지원
      </div>
    </div>

    <!-- 그룹 유형, 수강 언어, 레벨 선택 -->
    <div class="p-2 mb-5 rounded shadow-md">
      <GroupClass
        :sugangGroupTypeList="registSugang.registSugangData.sugangGroupTypeList"
        @set-regist-sugang-data="registSugang.setRegistSugangData"
      ></GroupClass>

      <!-- 수업 횟수 / 시간 -->
      <!-- <div class="p-2 mb-5 rounded shadow-md">
        <GroupClassEduPay
          :sugangGroupTimeInfo="registSugang.registSugangData.sugangGroupTimeInfoList"
          @set-edupay-data="registSugang.setEduPayData"
        ></GroupClassEduPay>
      </div> -->

      <div class="flex justify-end">
        <p
          class="p-2 border border-solid rounded cursor-pointer text-slate-800 border-slate-800"
          @click="registSugang.registSugangGroupClass()"
        >
          그룹 임시저장
        </p>
      </div>
    </div>

    <!-- 개월 수 -->
    <div class="p-2 mb-5 rounded shadow-md">
      <p class="pb-2 mb-2 text-lg font-semibold border-b-2 border-solid border-slate-800">개월 수</p>
      <div class="flex items-center gap-2">
        <p class="flex-none font-semibold w-36"><span class="font-bold text-red-500">*</span> 개월 수</p>
        <ul class="flex items-baseline gap-4">
          <li class="flex items-center gap-2">
            <input type="radio" name="rdo1" id="rdo1_0" value="0" v-model="registSugang.registSugangData.classMonth" /><label
              class="cursor-pointer"
              for="rdo1_0"
              >사용 안함</label
            >
          </li>
          <li class="flex items-center gap-2">
            <input type="radio" name="rdo1" id="rdo1_1" value="1" v-model="registSugang.registSugangData.classMonth" /><label
              class="cursor-pointer"
              for="rdo1_1"
              >1개월</label
            >
          </li>
          <li class="flex items-center gap-2">
            <input type="radio" name="rdo1" id="rdo1_2" value="2" v-model="registSugang.registSugangData.classMonth" />
            <label class="cursor-pointer" for="rdo1_2">2개월</label>
          </li>
          <li class="flex items-center gap-2">
            <input type="radio" name="rdo1" id="rdo1_3" value="3" v-model="registSugang.registSugangData.classMonth" />
            <label class="cursor-pointer" for="rdo1_3">3개월</label>
          </li>
          <li class="flex items-center gap-2">
            <input type="radio" name="rdo1" id="rdo1_4" value="4" v-model="registSugang.registSugangData.classMonth" />
            <label class="cursor-pointer" for="rdo1_4">4개월</label>
          </li>
          <li class="flex items-center gap-2">
            <input type="radio" name="rdo1" id="rdo1_5" value="5" v-model="registSugang.registSugangData.classMonth" /><label
              class="cursor-pointer"
              for="rdo1_5"
              >5개월</label
            >
          </li>
          <li class="flex items-center gap-2">
            <input type="radio" name="rdo1" id="rdo1_6" value="6" v-model="registSugang.registSugangData.classMonth" /><label
              class="cursor-pointer"
              for="rdo1_6"
              >6개월</label
            >
          </li>
          <li class="flex items-center gap-2">
            <input type="radio" name="rdo1" id="rdo1_7" value="12" v-model="registSugang.registSugangData.classMonth" />
            <label class="cursor-pointer" for="rdo1_7">12개월</label>
          </li>
        </ul>
      </div>
    </div>

    <!-- 레벨테스트 신청 여부 -->
    <div class="p-2 mb-5 rounded shadow-md">
      <p class="pb-2 mb-2 text-lg font-semibold border-b-2 border-solid border-slate-800">레벨테스트 신청 여부</p>
      <div class="flex items-center gap-2">
        <p class="flex-none font-semibold w-36"><span class="font-bold text-red-500">*</span> 레벨테스트 신청</p>
        <ul class="flex items-baseline gap-4">
          <li class="flex items-center gap-2">
            <input type="radio" name="rdo2" id="rdo2_0" value="Y" v-model="registSugang.registSugangData.isLevelTest" /><label
              class="cursor-pointer"
              for="rdo2_0"
              >사용</label
            >
          </li>
          <li class="flex items-center gap-2">
            <input type="radio" name="rdo2" id="rdo2_1" value="N" v-model="registSugang.registSugangData.isLevelTest" />
            <label class="cursor-pointer" for="rdo2_1">사용 안함</label>
          </li>
        </ul>
      </div>
    </div>

    <!-- 안내문구 입력 -->
    <div class="p-2 mb-5 rounded shadow-md">
      <p class="pb-2 mb-2 text-lg font-semibold border-b-2 border-solid border-slate-800">안내문구 입력</p>
      <li class="flex items-center gap-2 pb-4 border-b border-solid">
        <div class="flex items-center flex-none gap-2 font-semibold w-44">
          <input
            type="checkbox"
            id="numberOfClassText"
            :checked="registSugang.registSugangData.isNumberOfClassText"
            v-model="registSugang.registSugangData.isNumberOfClassText"
            true-value="Y"
            false-value="N"
          />
          <label class="cursor-pointer" for="numberOfClassText">
            <p>수업횟수 / 시간 안내</p>
          </label>
        </div>
        <textarea
          class="w-full max-w-xl p-2 border border-gray-400 border-solid resize-none"
          v-model="registSugang.registSugangData.numberOfClassText"
        ></textarea>
      </li>
      <li class="flex items-center gap-2 pb-4 border-b border-solid">
        <div class="flex items-center flex-none gap-2 font-semibold w-44">
          <input
            type="checkbox"
            name=""
            id="levelChoiceText"
            :checked="registSugang.registSugangData.isLevelChoiceText"
            v-model="registSugang.registSugangData.isLevelChoiceText"
            true-value="Y"
            false-value="N"
          />
          <label class="cursor-pointer" for="levelChoiceText">
            <p>레벨 선택 안내</p>
          </label>
        </div>
        <textarea
          class="w-full max-w-xl p-2 border border-gray-400 border-solid resize-none"
          v-model="registSugang.registSugangData.levelChoiceText"
        ></textarea>
      </li>
    </div>

    <div class="flex justify-between">
      <p class="p-2 border border-solid rounded cursor-pointer border-slate-800" @click="registSugang.goBack()">이전 페이지</p>

      <!-- 지원수강이 존재하는 경우 -->
      <p
        class="p-2 text-white bg-blue-600 border border-solid rounded cursor-pointer"
        @click="registSugang.registSugangGroupAll"
        v-if="registSugang.registSugangData.sugangSupType == '일반' && registSugang.registSugangInfo.isGroupSup == 'Y'"
      >
        저장하고 그룹 지원수강 작성하기
      </p>
      <!-- 그 외 -->
      <p
        class="p-2 text-white bg-blue-600 border border-solid rounded cursor-pointer"
        @click="registSugang.registSugangGroupAll"
        v-else
      >
        완료하기
      </p>
    </div>
  </div>
</template>
<script>
import { onMounted, reactive, watch, ref, computed, watchEffect } from "vue";
import { useRouter, useRoute } from "vue-router";
import axios from "@lifeeduAdmin/plugins/axios.js";
import { useStore } from "vuex";

import GroupClass from "@/lifeeduAdmin/components/RegistGroupClass.vue";
import GroupClassEduPay from "@/lifeeduAdmin/components/RegistGroupClassEduPay.vue";

export default {
  components: {
    GroupClass,
    GroupClassEduPay,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const store = useStore();

    let isApiCallInProgress = false;

    const registSugang = reactive({
      registSugangData: {
        idxLeSugangGroup: 0,
        idxLeSugang: 0,
        classMonth: 0,
        isLevelTest: "",
        isNumberOfClassText: "N",
        isLevelChoiceText: "N",
        numberOfClassText: "",
        levelChoiceText: "",
        isBookBuy: "",
        sugangGroupTimeInfoList: [],
        staffIdx: null,
        sugangGroupTypeList: [],
        sugangSupType: "일반", //일반, 지원 구분하는 값. 기본은 일반
        buttonName: "",
      },
      registSugangInfo: {
        isGroup: "N", //그룹 일반수강 사용여부값.
        isGroupSup: "N", //그룹 지원수강 사용여부값.
        isOne: "N", //1:1 일반수강 사용여부 값,
        isOneSup: "N", //1:1 지원수강 사용여부 값,
      },

      // registParams: {
      //     isOne: computed(() => (isOneValue.value ? "Y" : "N")),
      //     isGroup: computed(() => (isGroupValue.value ? "Y" : "N")),
      // },

      setEduPayData(val) {
        registSugang.sugangGroupTimeInfoList = val;
      },
      setRegistSugangData(val) {
        registSugang.registSugangData.sugangGroupTypeList = val;
      },

      async getSugang() {
        //route.query.idxSugang 값이 존재하면 값을 가져오고, 아니라면 값을 비운다.
        console.log("getSugang");
        if (registSugang.registSugangData.idxLeSugang > 0) {
          const params = {
            idxLeSugang: registSugang.registSugangData.idxLeSugang,
          };
          console.log(params);
          await axios
            .get("/admin/sugang", {
              params: params,
            })
            .then(function (res) {
              if (res.data.success) {
                registSugang.registSugangInfo = { ...registSugang.registSugangInfo, ...res.data.data.sugangInfo };
                registSugang.registMainSugangInfo = res.data.data.siteMainInfo;
                console.log("sugang", registSugang.registSugangInfo);
              } else {
                alert(res.data.message);
                router.push({ path: "/home" });
              }
            });
        }
      },

      // 1:1 수강 언어 선택 및 저장
      async getGroupClass() {
        //route.query.idxSugang 값이 존재하면 값을 가져오고, 아니라면 값을 비운다.
        console.log("getGroupClass");
        if (registSugang.registSugangData.idxLeSugang > 0) {
          const params = {
            idxLeSugang: registSugang.registSugangData.idxLeSugang,
            sugangSupType: registSugang.registSugangData.sugangSupType,
          };
          console.log(params);
          await axios
            .get("/admin/sugangGroup", {
              params: params,
            })
            .then(function (res) {
              if (res.data.success) {
                registSugang.registSugangData = { ...registSugang.registSugangData, ...res.data.data.pop() };

                registSugang.registSugangData.sugangGroupTypeList.forEach((item, index) => {
                  //그룹 타입별 제한여부를 선택한 값이 없으므로 생성해준다.
                  if (item.studentLimit == 0 && item.studentLimitWait == 0) {
                    item.studentLimitType = "Not Limited";
                  } else {
                    item.studentLimitType = "Total";
                  }
                  console.log("Index: " + index + " Value: " + item);
                });
                console.log("sugangGroup Get", registSugang.registSugangData);
              }
            });
        }
      },

      // 수강신청
      async registSugangGroupClass() {
        if (isApiCallInProgress) {
          alert("처리중 입니다. data가 많을 경우 오랜 시간이 걸릴수 있습니다.");
          return;
        }
        isApiCallInProgress = true;
        const params = {
          idxLeSugang: registSugang.registSugangData.idxLeSugang,
          sugangSupType: registSugang.registSugangData.sugangSupType,
          sugangGroupTypeList: registSugang.registSugangData.sugangGroupTypeList,
          buttonName: "",
        };
        try {
          const res = await axios.post("/admin/sugangGroup", params, {});
          if (res.data.success) {
            alert("수강신청 과정 저장 완료");
            registSugang.getSugang();
            // registSugang.getGroupClass();
          } else {
            alert(res.data.message);
          }
        } catch (error) {
          alert("API 통신 중 오류가 발생했습니다.");
        } finally {
          isApiCallInProgress = false;
        }
        // listPage.is_loader = false;
      },

      //   수강신청
      async registSugangGroupAll() {
        if (isApiCallInProgress) {
          alert("처리중 입니다. data가 많을 경우 오랜 시간이 걸릴수 있습니다.");
          return;
        }
        isApiCallInProgress = true;
        const params = registSugang.registSugangData;
        try {
          const res = await axios.post("/admin/sugangGroupAll", params, {});
          if (res.data.success) {
            //일반과 그룹이 함께 존재하는 경우, 일반을 우선 저장하도록 유도해야 오류가 적을 수 있다.
            //일반을 저장하는 경우, 지원이 존재하면 지원으로 이동된다.
            if (registSugang.registSugangData.sugangSupType == "일반" && registSugang.registSugangInfo.isGroupSup == "Y") {
              registSugang.setSugangSupType("지원");
            } else {
              alert("그룹 수강신청을 등록 완료하였습니다.");
              router.push({ path: "/home" });
            }
          } else {
            alert(res.data.message);
          }
        } catch (error) {
          alert("API 통신 중 오류가 발생했습니다.");
        } finally {
          isApiCallInProgress = false;
        }
      },

      setSugangSupType(val) {
        console.log("setSugangSupType :", val);
        router.push({
          name: "enrolmentStep3_2",
          query: {
            idxLeSugang: registSugang.registSugangData.idxLeSugang,
            sugangSupType: val,
          },
        });
      },

      goBack() {
        //이전페이지의 동작은 무조건 histroy.back 로 한다.
        router.go(-1);
      },
    });

    onMounted(() => {
      if (route.query.idxLeSugang) {
        registSugang.registSugangData.idxLeSugang = Number(route.query.idxLeSugang);
        registSugang.registSugangData.sugangSupType = "일반";
        if (route.query.sugangSupType && route.query.sugangSupType == "지원") {
          registSugang.registSugangData.sugangSupType = "지원";
        }

        registSugang.getSugang();
        registSugang.getGroupClass();
      } else {
        alert("잘못된 접근입니다.");
        router.push({ path: "/home" });
      }
    });

    return {
      registSugang,
      isApiCallInProgress,
    };
  },
};
</script>
<style></style>
