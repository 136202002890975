<template>
  <div class="p-4">
    <div class="p-2 mb-5">
      <!-- 수정일 땐 수강신청 수정 으로 변경  -->
      <p class="mb-2 text-xl font-semibold">
        {{ idxLeSugang ? "수강신청 수정" : "수강신청 등록" }}
      </p>
      <p class="flex-none font-semibold">
        <span class="font-bold text-red-500">*</span> 그룹수강 신청 정원은 그룹 수강신청 에서 설정합니다.
      </p>
    </div>
    <div class="p-2 mb-5 rounded shadow-md">
      <p class="pb-2 mb-2 text-lg font-semibold border-b-2 border-solid border-slate-800">수강신청 정보 입력</p>
      <ul class="flex flex-col gap-4">
        <li class="flex items-center gap-2 pb-4 border-b border-solid">
          <p class="flex-none w-40 font-semibold"><span class="font-bold text-red-500">*</span> 과정명</p>
          <input
            v-model="registClass.registParams.sugangName"
            type="text"
            maxlength="50"
            class="w-full max-w-lg border border-solid rounded border-slate-800"
          />
        </li>
        <li class="flex items-center gap-2 pb-4 border-b border-solid">
          <p class="flex-none w-40 font-semibold"><span class="font-bold text-red-500">*</span> 수강신청 기간</p>
          <ul class="flex items-center gap-4">
            <li class="flex gap-4">
              <BagicDatePickerStartDate
                :startDate="registClass.registParams.startDate"
                @date-click="registClass.setStartDate"
                class="border border-solid rounded border-slate-800"
              ></BagicDatePickerStartDate>
              <ul class="flex gap-4">
                <li class="flex items-center gap-2">
                  <select
                    class="border border-slate-800 border-solid rounded w-[80px] cursor-pointer"
                    v-model="registClass.registParams.startDateTime"
                  >
                    <option v-for="hour in registClass.startHours" :key="hour" :value="hour">
                      {{ hour }}
                    </option>
                  </select>
                  <p>시</p>
                </li>
                <li class="flex items-center gap-2">
                  <select
                    class="border border-slate-800 border-solid rounded w-[80px] cursor-pointer"
                    v-model="registClass.registParams.startDateMinute"
                  >
                    <option v-for="minute in registClass.startMinutes" :key="minute" :value="minute">
                      {{ minute }}
                    </option>
                  </select>
                  <p>분</p>
                </li>
              </ul>
            </li>
            <li>~</li>
            <li class="flex gap-4">
              <BagicDatePickerEndDate
                :endDate="registClass.registParams.endDate"
                @date-click="registClass.setEndDate"
                class="border border-solid rounded border-slate-800"
              ></BagicDatePickerEndDate>
              <ul class="flex gap-4">
                <li class="flex items-center gap-2">
                  <select
                    class="border border-slate-800 border-solid rounded w-[80px] cursor-pointer"
                    v-model="registClass.registParams.endDateTime"
                  >
                    <option v-for="hour in registClass.endHours" :key="hour" :value="hour">
                      {{ hour }}
                    </option>
                  </select>
                  <p>시</p>
                </li>
                <li class="flex items-center gap-2">
                  <select
                    class="border border-slate-800 border-solid rounded w-[80px] cursor-pointer"
                    v-model="registClass.registParams.endDateMinute"
                  >
                    <option v-for="minute in registClass.endMinutes" :key="minute" :value="minute">
                      {{ minute }}
                    </option>
                  </select>

                  <p>분</p>
                </li>
              </ul>
            </li>
          </ul>
        </li>
        <li class="flex items-center gap-2 pb-4 border-b border-solid">
          <p class="flex-none w-40 font-semibold"><span class="font-bold text-red-500">*</span> 교육기간</p>
          <ul class="flex items-center gap-4">
            <li class="border border-solid rounded border-slate-800">
              <BagicDatePickerRange
                :eduStartDate="registClass.registParams.eduStartDate"
                :eduEndDate="registClass.registParams.eduEndDate"
                @date-click="registClass.setDate"
              >
              </BagicDatePickerRange>
            </li>
          </ul>
        </li>

        <ul class="flex">
          <li class="w-full flex items-center gap-2 pb-4 border-b border-solid">
            <p class="flex-none w-40 font-semibold"><span class="font-bold text-red-500">*</span> 1:1 지원대상자</p>
            <ul class="flex gap-5">
              <li class="flex items-center gap-1">
                <input
                  v-model="registClass.registParams.isOneSup"
                  value="Y"
                  type="radio"
                  name="supportTarget"
                  id="supportTarget1"
                />
                <label class="cursor-pointer" for="supportTarget1">사용</label>
              </li>
              <li class="flex items-center gap-1">
                <input
                  v-model="registClass.registParams.isOneSup"
                  value="N"
                  type="radio"
                  name="supportTarget"
                  id="supportTarget2"
                />
                <label class="cursor-pointer" for="supportTarget2">사용 안함</label>
              </li>
            </ul>
          </li>

          <li class="w-full flex items-center gap-2 pb-4 border-b border-solid">
            <p class="flex-none w-40 font-semibold"><span class="font-bold text-red-500">*</span> 그룹 지원대상자</p>
            <ul class="flex gap-5">
              <li class="flex items-center gap-1">
                <input
                  v-model="registClass.registParams.isGroupSup"
                  value="Y"
                  type="radio"
                  name="groupSupportTarget"
                  id="groupSupportTarget1"
                />
                <label class="cursor-pointer" for="groupSupportTarget1">사용</label>
              </li>
              <li class="flex items-center gap-1">
                <input
                  v-model="registClass.registParams.isGroupSup"
                  value="N"
                  type="radio"
                  name="groupSupportTarget"
                  id="groupSupportTarget2"
                />
                <label class="cursor-pointer" for="groupSupportTarget2">사용 안함</label>
              </li>
            </ul>
          </li>
        </ul>

        <li class="flex items-center gap-2 pb-4 border-b border-solid">
          <p class="flex-none w-40 font-semibold"><span class="font-bold text-red-500">*</span> 1:1 수강신청 정원(일반)</p>
          <ul class="flex flex-col gap-4">
            <li class="flex items-center gap-2">
              <input
                type="radio"
                name="personnel"
                id="personnel1"
                v-model="registClass.registParams.studentLimitOne"
                :value="0"
                @change="registClass.handleLimitChange"
              />
              <label class="cursor-pointer" for="personnel1">제한 없음</label>
            </li>
            <li class="flex gap-4">
              <div class="flex items-center gap-2">
                <input type="radio" name="personnel" id="personnel2" :checked="registClass.registParams.studentLimitOne !== 0" />
                <label class="cursor-pointer" for="personnel2">과정 전체</label>
              </div>
              <div class="flex items-center gap-2">
                <p>선착순</p>
                <div class="flex items-center gap-2">
                  <input
                    type="text"
                    class="border border-slate-800 border-solid rounded w-full max-w-[50px]"
                    v-model="registClass.registParams.studentLimitOne"
                  />
                  <!-- :disabled="registClass.registParams.studentLimitOne !== '0'" -->
                  <p>명</p>
                </div>
              </div>
              <div class="flex items-center gap-2">
                <p>대기자</p>
                <div class="flex items-center gap-2">
                  <input
                    type="text"
                    class="border border-slate-800 border-solid rounded w-full max-w-[50px]"
                    v-model="registClass.registParams.studentLimitWaitOne"
                  />
                  <!-- :disabled="registClass.registParams.studentLimitWaitOne !== '0'" -->
                  <p>명</p>
                </div>
              </div>
            </li>
            <div>
              <span class="font-bold text-red-500">*</span>
              <span
                >대기자가 없으면
                <span class="text-red-500">0명</span>
                으로 입력 해주세요</span
              >
            </div>
          </ul>
        </li>
        <li v-if="registClass.registParams.isOneSup === 'Y'" class="flex items-center gap-2 pb-4 border-b border-solid">
          <p class="flex-none w-40 font-semibold"><span class="font-bold text-red-500">*</span> 1:1 수강신청 정원(지원)</p>
          <ul class="flex flex-col gap-4">
            <li class="flex items-center gap-2">
              <input
                type="radio"
                name="personnel0"
                id="personnel3"
                v-model="registClass.registParams.studentLimitOneSup"
                :value="0"
                @change="registClass.handleLimitWaitChange"
              />
              <label class="cursor-pointer" for="personnel3">제한 없음</label>
            </li>
            <li class="flex gap-4">
              <div class="flex items-center gap-2">
                <input
                  type="radio"
                  name="personnel0"
                  id="personnel4"
                  :checked="registClass.registParams.studentLimitOneSup !== 0"
                />
                <label class="cursor-pointer" for="personnel4">과정 전체</label>
              </div>
              <div class="flex items-center gap-2">
                <p>선착순</p>
                <div class="flex items-center gap-2">
                  <input
                    type="text"
                    class="border border-slate-800 border-solid rounded w-full max-w-[50px]"
                    v-model="registClass.registParams.studentLimitOneSup"
                  />
                  <p>명</p>
                </div>
              </div>
              <div class="flex items-center gap-2">
                <p>대기자</p>
                <div class="flex items-center gap-2">
                  <input
                    type="text"
                    class="border border-slate-800 border-solid rounded w-full max-w-[50px]"
                    v-model="registClass.registParams.studentLimitWaitOneSup"
                  />
                  <p>명</p>
                </div>
              </div>
            </li>
            <div>
              <span class="font-bold text-red-500">*</span>
              <span>대기자가 없으면 <span class="text-red-500">0명</span> 으로 입력 해주세요</span>
            </div>
          </ul>
        </li>

        <li class="flex items-center gap-2 pb-4 border-b border-solid">
          <p class="flex-none w-40 font-semibold"><span class="font-bold text-red-500">*</span> 일반 버튼명</p>
          <input
            v-model="registClass.registParams.normalButtonName"
            type="text"
            maxlength="50"
            class="w-full max-w-lg border border-solid rounded border-slate-800"
          />
        </li>

        <li
          v-if="registClass.registParams.isOneSup === 'Y' || registClass.registParams.isGroupSup === 'Y'"
          class="flex items-center gap-2 pb-4 border-b border-solid"
        >
          <p class="flex-none w-40 font-semibold"><span class="font-bold text-red-500"></span> 지원 버튼명</p>
          <input
            v-model="registClass.registParams.supButtonName"
            type="text"
            maxlength="50"
            class="w-full max-w-lg border border-solid rounded border-slate-800"
          />
        </li>

        <li class="flex items-center gap-2 pb-4 border-b border-solid">
          <p class="flex-none w-40 font-semibold"><span class="font-bold text-red-500">*</span> 중복신청 기준</p>
          <ul class="flex items-baseline gap-4">
            <li class="flex items-center gap-2">
              <input type="radio" name="duplication" id="duplication1" v-model="registClass.registParams.duplicate" value="Y" />
              <label class="cursor-pointer" for="duplication1">중복신청 가능</label>
            </li>
            <li class="flex items-center gap-2">
              <input type="radio" name="duplication" id="duplication2" v-model="registClass.registParams.duplicate" value="N" />
              <label class="cursor-pointer" for="duplication2">중복신청 불가</label>
            </li>
          </ul>
        </li>
        <li class="flex items-center gap-2 pb-4 border-b border-solid">
          <p class="flex-none w-40 font-semibold"><span class="font-bold text-red-500">*</span> 취소기능 생성여부</p>
          <ul class="flex gap-5">
            <li class="flex items-center gap-1">
              <input v-model="registClass.registParams.isCancelFunction" value="Y" type="radio" name="cancel" id="cancel1" />
              <label class="cursor-pointer" for="cancel1">사용</label>
            </li>
            <li class="flex items-center gap-1">
              <input v-model="registClass.registParams.isCancelFunction" value="N" type="radio" name="cancel" id="cancel2" />
              <label class="cursor-pointer" for="cancel2">사용 안함</label>
            </li>
          </ul>
        </li>
        <li class="flex items-center gap-2">
          <p class="flex-none w-40 font-semibold"><span class="font-bold text-red-500">*</span> HQ 매니저</p>
          <select v-model="registClass.registParams.idxStaff" class="border border-solid rounded cursor-pointer border-slate-800">
            <option value="0">전체</option>
            <option v-for="(item, i) in registClass.staffList" :key="i" :value="item.idx">
              {{ item.ename }} ({{ item.kname }})
            </option>
          </select>
        </li>
      </ul>
    </div>
    <div class="p-2 mb-5 rounded shadow-md">
      <p class="pb-2 mb-2 text-lg font-semibold border-b-2 border-solid border-slate-800">수강신청 유형 선택</p>
      <div class="flex items-center gap-2">
        <p class="flex-none w-40 font-semibold"><span class="font-bold text-red-500">*</span> 수강신청 유형</p>
        <ul class="flex gap-5">
          <li class="flex items-center gap-6">
            <div class="flex items-center gap-1">
              <input
                type="checkbox"
                name="type"
                id="type1"
                true-value="Y"
                false-value="N"
                v-model="registClass.registParams.isOne"
              />
              <label class="cursor-pointer" for="type1">1:1 수업</label>
            </div>
            <div class="flex items-center gap-1">
              <label for="code"><span class="font-bold text-red-500">*</span>1:1 과정코드입력</label>
              <input
                type="text"
                id="code"
                class="border border-solid rounded border-slate-800"
                v-model="registClass.registParams.isOneCode"
                @change="registClass.restrictToNumbers(false)"
              />
              <p class="p-2 text-white bg-blue-600 border border-solid rounded cursor-pointer" @click="registClass.checkCtmCode">
                코드 확인
              </p>
            </div>
          </li>
          <li class="flex items-center gap-6">
            <div class="flex items-center gap-1">
              <input
                type="checkbox"
                name="type"
                id="type2"
                v-model="registClass.registParams.isGroup"
                true-value="Y"
                false-value="N"
              />
              <label class="cursor-pointer" for="type2">그룹 수업</label>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="flex justify-between">
      <p class="p-2 border border-solid rounded cursor-pointer border-slate-800" @click="registClass.goBack">이전 페이지</p>
      <p
        v-if="!registClass.classUpdate"
        class="p-2 text-white bg-blue-600 border border-solid rounded cursor-pointer"
        @click="registClass.registSugang(true)"
      >
        다음 페이지 (저장)
      </p>
      <p
        v-else
        class="p-2 text-white bg-blue-600 border border-solid rounded cursor-pointer"
        @click="registClass.registSugang(false)"
      >
        다음 페이지 (수정)
      </p>
    </div>
  </div>
</template>
<script>
import { onMounted, reactive } from "vue";
import { useRouter, useRoute } from "vue-router";
import axios from "@lifeeduAdmin/plugins/axios.js";
import BagicDatePickerRange from "@/lifeeduAdmin/components/BagicDatePickerRange.vue";
import BagicDatePickerStartDate from "@/lifeeduAdmin/components/BagicDatePickerStartDate.vue";
import BagicDatePickerEndDate from "@/lifeeduAdmin/components/BagicDatePickerEndDate.vue";

export default {
  components: {
    BagicDatePickerRange,
    BagicDatePickerStartDate,
    BagicDatePickerEndDate,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const idxLeSiteMain = route.query.idxLeSiteMain;
    const idxLeSugang = route.query.idxLeSugang;

    const registClass = reactive({
      registParams: {
        // staffIdx: 0,
        idxLeSiteMain: "",
        eduEndDate: "",
        idxStaff: 0,
        idxSugang: "",
        idxLeSugang: "",
        idxCtmB2b: "",
        isOneCode: "",
        eduStartDate: "",
        studentLimitOne: 0,
        studentLimitWaitOne: 0,
        studentLimitOneSup: 0,
        studentLimitWaitOneSup: 0,
        sugangName: "",
        normalButtonName: "",
        supButtonName: "",
        isCancelFunction: "",
        duplicate: "",
        endDate: "",
        startDate: "",
        startDateTime: "0",
        endDateTime: "0",
        startDateMinute: "0",
        endDateMinute: "0",
        isOne: "N",
        isGroup: "N",
        isOneSup: "",
        isGroupSup: "",
      },

      classUpdate: false,

      staffList: [],

      codeCheck: false,

      codeCheckDone: false,

      startHours: Array.from({ length: 24 }, (_, index) => index.toString().padStart(2, "0")),
      startMinutes: Array.from({ length: 6 }, (_, index) => (index * 10).toString().padStart(2, "0")),

      endHours: Array.from({ length: 24 }, (_, index) => index.toString().padStart(2, "0")),
      endMinutes: Array.from({ length: 6 }, (_, index) => (index * 10).toString().padStart(2, "0")),

      handleLimitChange() {
        if (registClass.registParams.studentLimitOne === 0) {
          registClass.registParams.studentLimitOne = 0;
          registClass.registParams.studentLimitWaitOne = 0;
        }
      },

      handleLimitWaitChange() {
        if (registClass.registParams.studentLimitOneSup === 0) {
          registClass.registParams.studentLimitOneSup = 0;
          registClass.registParams.studentLimitWaitOneSup = 0;
        }
      },

      // 수강 조회
      getSugang() {
        const idxLeSugang = route.query.idxLeSugang;

        if (idxLeSugang && idxLeSugang !== "0") {
          const params = {
            idxLeSugang: idxLeSugang,
          };
          axios.get("/admin/sugang", { params }).then(function (res) {
            if (res.data.success) {
              registClass.registParams = res.data.data.sugangInfo;

              const startDateParts = res.data.data.sugangInfo.startDate.split(" ");
              const startTimeParts = startDateParts[1].split(":");
              registClass.registParams.startDateTime = startTimeParts[0];
              registClass.registParams.startDateMinute = startTimeParts[1];

              const endDateParts = res.data.data.sugangInfo.endDate.split(" ");
              const endTimeParts = endDateParts[1].split(":");
              registClass.registParams.endDateTime = endTimeParts[0];
              registClass.registParams.endDateMinute = endTimeParts[1];

              registClass.staffList.idx = res.data.data;
              registClass.classUpdate = true;
            } else {
              // 처리 실패 로직
            }
          });
        }
      },

      // 스태프 조회
      getStaffList() {
        axios.get("/admin/staffList", {}).then(function (res) {
          if (res.data.success) {
            registClass.staffList = res.data.data.companyList;
          } else {
            alert(res.data.message);
          }
        });
      },

      restrictToNumbers(value) {
        localStorage.setItem("codeCheckDone", value.toString());
        registClass.registParams.isOneCode = registClass.registParams.isOneCode.replace(/[^0-9]/g, "");
      },

      // 1:1 과정 코드 확인
      checkCtmCode() {
        const isOneCode = registClass.registParams.isOneCode;
        if (!isOneCode) {
          alert("1:1 과정코드를 입력하세요.");
          return;
        }
        axios.get(`/admin/ctmCode?idxCtmB2b=${isOneCode}`, {}).then(function (res) {
          if (res.data.success) {
            alert("코드가 존재 합니다.");
            registClass.codeCheck = true;
            localStorage.setItem("codeCheckDone", "true");
          } else {
            alert("코드가 존재하지 않습니다.");
            registClass.codeCheck = false;
          }
          // listPage.is_loader = false;
        });
      },

      // 수강 등록
      registSugang() {
        registClass.registParams.idxLeSiteMain = route.query.idxLeSiteMain;

        const codeCheckDone = localStorage.getItem("codeCheckDone");

        if (!registClass.registParams.sugangName) {
          alert("과정명을 입력하세요.");
          return;
        }

        if (!registClass.registParams.startDate || !registClass.registParams.endDate) {
          alert("수강신청 기간을 입력하세요.");
          return;
        }

        if (registClass.registParams.endDate < registClass.registParams.startDate) {
          alert("시작일을 종료일보다 과거로 설정해주세요");
          return;
        }

        if (
          !registClass.registParams.startDateTime ||
          registClass.registParams.startDateTime === "0" ||
          !registClass.registParams.startDateMinute ||
          registClass.registParams.startDateMinute === "0"
        ) {
          alert("시작시간을 입력하세요.");
          return;
        }

        if (
          !registClass.registParams.endDateTime ||
          registClass.registParams.endDateTime === "0" ||
          !registClass.registParams.endDateMinute ||
          registClass.registParams.endDateMinute === "0"
        ) {
          alert("종료시간을 입력하세요.");
          return;
        }

        if (!registClass.registParams.eduStartDate || !registClass.registParams.eduEndDate) {
          alert("교육기간을 입력하세요.");
          return;
        }

        if (!registClass.registParams.normalButtonName) {
          alert("일반 버튼명을 입력해주세요.");
          return;
        }

        if (!registClass.registParams.duplicate) {
          alert("중복 신청 기준을 선택하세요.");
          return;
        }

        if (!registClass.registParams.isCancelFunction) {
          alert("취소기능 생성여부를 선택하세요.");
          return;
        }

        if (!registClass.registParams.idxStaff) {
          alert("HQ 매니저를 선택하세요.");
          return;
        }

        if (registClass.registParams.isOne === "N" && registClass.registParams.isGroup === "N") {
          alert("수강신청 유형을 선택하세요.");
          return;
        }

        if (codeCheckDone === "true") {
          // router.push({
          //   path: "/enrolmentStep3_1",
          //   query: { idxLeSugang: idxLeSugang },
          // });
        } else if (registClass.registParams.isOne === "Y" && !registClass.codeCheck) {
          alert("1:1 수업 과정코드를 확인하세요.");
          return;
        }
        // else if (registClass.registParams.isGroup === "Y") {
        //   if (!registClass.codeCheck) {
        //     alert("그룹 수업 과정코드를 확인하세요.");
        //     return;
        //   }
        // }

        registClass.registParams.startDate = `${registClass.registParams.startDate.split(" ")[0]} ${
          registClass.registParams.startDateTime
        }:${registClass.registParams.startDateMinute}`;
        registClass.registParams.endDate = `${registClass.registParams.endDate.split(" ")[0]} ${
          registClass.registParams.endDateTime
        }:${registClass.registParams.endDateMinute}`;

        const params = registClass.registParams;

        axios.post("/admin/sugang", params, {}).then(function (res) {
          if (res.data.success) {
            const idxLeSugang = res.data.data.idxLeSugang;
            if (registClass.classUpdate) {
              localStorage.setItem("codeCheckDone", "false");
              alert("수정되었습니다.");
              if (registClass.registParams.isOne === "Y" && registClass.registParams.isGroup === "Y") {
                router.push({
                  path: "/enrolmentStep3_1",
                  query: { idxLeSugang: idxLeSugang },
                });
              } else if (registClass.registParams.isOne === "Y") {
                router.push({
                  path: "/enrolmentStep3_1",
                  query: { idxLeSugang: idxLeSugang },
                });
              } else if (registClass.registParams.isGroup === "Y") {
                router.push({
                  path: "/enrolmentStep3_2",
                  query: { idxLeSugang: idxLeSugang },
                });
              }
            } else if (idxLeSugang) {
              localStorage.setItem("codeCheckDone", "false");
              alert("수강신청 등록 완료");
              if (registClass.registParams.isOne === "Y" && registClass.registParams.isGroup === "Y") {
                router.push({
                  path: "/enrolmentStep3_1",
                  query: { idxLeSugang: idxLeSugang },
                });
              } else if (registClass.registParams.isOne === "Y") {
                router.push({
                  path: "/enrolmentStep3_1",
                  query: { idxLeSugang: idxLeSugang },
                });
              } else if (registClass.registParams.isGroup === "Y") {
                router.push({
                  path: "/enrolmentStep3_2",
                  query: { idxLeSugang: idxLeSugang },
                });
              }
            }
          } else {
            alert(res.data.message);
          }
          // listPage.is_loader = false;
        });
      },

      setStartDate(dateArr) {
        registClass.registParams.startDate = dateArr[0];
      },
      setEndDate(dateArr) {
        registClass.registParams.endDate = dateArr[0];
      },

      setDate(dateArr) {
        registClass.registParams.eduStartDate = dateArr[0];
        registClass.registParams.eduEndDate = dateArr[1];
      },

      goBack() {
        if (idxLeSiteMain) {
          router.push({
            path: `/enrolmentStep1`,
            query: {
              idxLeSiteMain: idxLeSiteMain,
            },
          });
        } else if (!idxLeSugang) {
          window.history.back();
        } else {
          router.push({
            path: `/enrolmentStep1`,
            query: {
              idxLeSiteMain: registClass.registParams.idxLeSiteMain,
            },
          });
        }
      },
    });

    onMounted(() => {
      // 1:1, 그룹 과정코드 로컬스토라지에서 제어 하는 부분
      const params = new URLSearchParams(window.location.search);
      const idxLeSugang = params.get("idxLeSugang");
      const idxLeSiteMain = params.get("idxLeSiteMain");
      // codeCheckDone 값을 설정합니다.
      if (idxLeSugang) {
        localStorage.setItem("codeCheckDone", "true");
      } else if (idxLeSiteMain) {
        localStorage.setItem("codeCheckDone", "false");
      }
      registClass.getStaffList();
      registClass.getSugang();
    });

    return {
      registClass,
      idxLeSiteMain,
      idxLeSugang,
    };
  },
};
</script>
<style></style>
