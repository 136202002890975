<template>
  <div class="p-4">
    <div class="p-2 mb-5">
      <!-- 수정일 땐 수강신청 홈페이지 수정 으로 변경  -->
      <p v-if="regist.param.idxLeSiteMain" class="mb-2 text-xl font-semibold">수강신청 홈페이지 등록</p>
      <p v-else class="mb-2 text-xl font-semibold">수강신청 홈페이지 수정</p>
      <p>[ <span class="font-bold text-red-500">*</span> ] 표시는 필수 입력사항입니다.</p>
    </div>
    <div class="p-2 mb-5 rounded shadow-md">
      <p class="pb-2 mb-2 text-lg font-semibold border-b-2 border-solid border-slate-800">기관 정보</p>
      <ul class="flex flex-col gap-4">
        <li class="flex items-center gap-2">
          <p class="flex-none font-semibold w-36"><span class="font-bold text-red-500">*</span> 법인구분</p>
          <select v-model="regist.param.corporation" class="border border-solid rounded border-slate-800">
            <option value="0" selected>법인구분</option>
            <option value="carrot global">Carrot Global</option>
            <option value="carrot edu">Carrot Edu</option>
            <option value="carrot solutions">Carrot Solutions</option>
          </select>
        </li>
        <li class="flex items-center gap-2">
          <p class="flex-none font-semibold w-36"><span class="font-bold text-red-500">*</span> Company</p>
          <div class="flex items-center gap-4 rounded">
            <BagicCompanySelector :kname="regist.param.kname" @company-click="regist.setCompany" />
          </div>
        </li>
        <li class="flex items-center gap-2">
          <p class="flex-none font-semibold w-36"><span class="font-bold text-red-500">*</span> Company 로고</p>
          <div class="flex-grow">
            <input type="file" name="companyLogo" class="mb-2" @change="(event) => regist.fileChange(event, 'companyLogo')" />
            <div v-if="regist.param.companyLogo" class="flex items-center gap-3">
              <input
                class="text-blue-500 cursor-pointer"
                readonly
                type="text"
                :value="regist.setImageFileName(regist.param.companyLogo)"
                @click="imgDownload(regist.param.companyLogo)"
              />
              <!-- v-model="regist.param.companyLogo" -->
              <button
                class="block p-2 border rounded text-slate-500 border-slate-300 hover:bg-slate-100"
                @click="regist.deleteImage('companyLogo')"
              >
                Delete
              </button>
            </div>
            <p>※ jpg, png 파일만 등록 가능</p>
            <p>※ 해당 이미지 크기는 KB(킬로바이트)만 업로드 가능</p>
            <p>※ 권장 업로드 사이즈 : 150px * 50px</p>
          </div>
        </li>
      </ul>
    </div>
    <div class="p-2 mb-5 rounded shadow-md">
      <p class="pb-2 mb-2 text-lg font-semibold border-b-2 border-solid border-slate-800">홈페이지 메인 이미지</p>
      <ul>
        <li class="flex items-center gap-2">
          <p class="flex-none font-semibold w-36">
            <span class="font-bold text-slate-500"><span class="font-bold text-red-500">*</span></span> 메인 이미지
          </p>
          <div>
            <input type="file" name="mainImg" class="mb-2" @change="(event) => regist.fileChange(event, 'mainImg')" />
            <div v-if="regist.param.mainImg" class="flex items-center gap-3">
              <input
                class="text-blue-500 cursor-pointer"
                readonly
                type="text"
                :value="regist.setImageFileName(regist.param.mainImg)"
                @click="imgDownload(regist.param.mainImg)"
              />
              <button
                class="block p-2 border rounded text-slate-500 border-slate-300 hover:bg-slate-100"
                @click="regist.deleteImage('mainImg')"
              >
                Delete
              </button>
            </div>
            <p>※ jpg, png 파일만 등록 가능</p>
            <p>※ 해당 이미지 크기는 KB(킬로바이트)만 업로드 가능</p>
            <p>※ 미등록 시, 기본 이미지로 노출됩니다.</p>
            <p>※ 권장 업로드 사이즈 : 850px * 850px</p>
          </div>
        </li>
      </ul>
    </div>
    <div class="p-2 mb-5 rounded shadow-md">
      <p class="pb-2 mb-2 text-lg font-semibold border-b-2 border-solid border-slate-800">홈페이지 팝업 등록</p>
      <ul class="flex flex-wrap gap-14">
        <li class="flex items-center gap-2">
          <p class="flex-none font-semibold w-36"><span class="font-bold text-slate-800"></span> 팝업 등록1</p>
          <div>
            <input type="file" name="popupImg1" class="mb-2" @change="(event) => regist.fileChange(event, 'popupImg1')" />
            <div v-if="regist.param.popupImg1" class="flex items-center gap-3">
              <input
                readonly
                class="text-blue-500 cursor-pointer"
                type="text"
                :value="regist.setImageFileName(regist.param.popupImg1)"
                @click="imgDownload(regist.param.popupImg1)"
              />
              <button
                class="block p-2 border rounded text-slate-500 border-slate-300 hover:bg-slate-100"
                @click="regist.deleteImage('popupImg1')"
              >
                Delete
              </button>
            </div>
            <p>※ jpg, png 파일만 등록 가능</p>
            <p>※ 해당 이미지 크기는 KB(킬로바이트)만 업로드 가능</p>
          </div>
        </li>
        <li class="flex items-center gap-2">
          <p class="flex-none font-semibold w-36"><span class="font-bold text-slate-800"></span> 팝업 등록2</p>
          <div>
            <input type="file" name="popupImg2" class="mb-2" @change="(event) => regist.fileChange(event, 'popupImg2')" />
            <div v-if="regist.param.popupImg2" class="flex items-center gap-3">
              <input
                readonly
                class="text-blue-500 cursor-pointer"
                type="text"
                :value="regist.setImageFileName(regist.param.popupImg2)"
                @click="imgDownload(regist.param.popupImg2)"
              />
              <button
                class="block p-2 border rounded text-slate-500 border-slate-300 hover:bg-slate-100"
                @click="regist.deleteImage('popupImg2')"
              >
                Delete
              </button>
            </div>
            <p>※ jpg, png 파일만 등록 가능</p>
            <p>※ 해당 이미지 크기는 KB(킬로바이트)만 업로드 가능</p>
          </div>
        </li>
        <li class="flex items-center gap-2">
          <p class="flex-none font-semibold w-36"><span class="font-bold text-slate-800"></span> 팝업 등록3</p>
          <div>
            <input type="file" name="popupImg3" class="mb-2" @change="(event) => regist.fileChange(event, 'popupImg3')" />
            <div v-if="regist.param.popupImg3" class="flex items-center gap-3">
              <input
                readonly
                class="text-blue-500 cursor-pointer"
                type="text"
                :value="regist.setImageFileName(regist.param.popupImg3)"
                @click="imgDownload(regist.param.popupImg3)"
              />
              <button
                class="block p-2 border rounded text-slate-500 border-slate-300 hover:bg-slate-100"
                @click="regist.deleteImage('popupImg3')"
              >
                Delete
              </button>
            </div>
            <p>※ jpg, png 파일만 등록 가능</p>
            <p>※ 해당 이미지 크기는 KB(킬로바이트)만 업로드 가능</p>
          </div>
        </li>
        <li class="flex items-center gap-2">
          <p class="flex-none font-semibold w-36"><span class="font-bold text-slate-800"></span> 팝업 등록4</p>
          <div>
            <input type="file" name="popupImg4" class="mb-2" @change="(event) => regist.fileChange(event, 'popupImg4')" />
            <div v-if="regist.param.popupImg4" class="flex items-center gap-3">
              <input
                readonly
                class="text-blue-500 cursor-pointer"
                type="text"
                :value="regist.setImageFileName(regist.param.popupImg4)"
                @click="imgDownload(regist.param.popupImg4)"
              />
              <button
                class="block p-2 border rounded text-slate-500 border-slate-300 hover:bg-slate-100"
                @click="regist.deleteImage('popupImg4')"
              >
                Delete
              </button>
            </div>
            <p>※ jpg, png 파일만 등록 가능</p>
            <p>※ 해당 이미지 크기는 KB(킬로바이트)만 업로드 가능</p>
          </div>
        </li>
        <li class="flex items-center gap-2">
          <p class="flex-none font-semibold w-36"><span class="font-bold text-slate-800"></span> 팝업 등록5</p>
          <div>
            <input type="file" name="popupImg5" class="mb-2" @change="(event) => regist.fileChange(event, 'popupImg5')" />
            <div v-if="regist.param.popupImg5" class="flex items-center gap-3">
              <input
                readonly
                class="text-blue-500 cursor-pointer"
                type="text"
                :value="regist.setImageFileName(regist.param.popupImg5)"
                @click="imgDownload(regist.param.popupImg5)"
              />
              <button
                class="block p-2 border rounded text-slate-500 border-slate-300 hover:bg-slate-100"
                @click="regist.deleteImage('popupImg5')"
              >
                Delete
              </button>
            </div>
            <p>※ jpg, png 파일만 등록 가능</p>
            <p>※ 해당 이미지 크기는 KB(킬로바이트)만 업로드 가능</p>
          </div>
        </li>
      </ul>
    </div>
    <!-- 수강신청 바로가기 버튼 -->
    <div class="p-2 mb-5 rounded shadow-md">
      <div class="mb-2">
        <div class="flex gap-4 pb-2 border-b-2 border-slate-800 border-solid">
          <p class="text-lg font-semibold">수강신청 바로가기 버튼</p>
        </div>
      </div>
      <div class="flex gap-4">
        <li>
          사용함
          <input type="radio" v-model="regist.param.isButton" value="Y" />
        </li>
        <li>
          사용안함
          <input type="radio" v-model="regist.param.isButton" value="N" />
        </li>
      </div>
      <ul class="flex flex-col gap-4">
        <li class="flex items-center gap-2 pb-4 border-b border-solid">
          <p class="flex-none font-semibold w-36"><span class="font-bold text-slate-500"></span> Button 1</p>
          <ul class="flex flex-col flex-grow gap-2">
            <li class="flex items-center gap-2">
              <p class="flex-none w-14">버튼명 :</p>
              <input
                v-model="regist.param.button1Name"
                type="text"
                placeholder="Ex) Jnior 수강신청 바로가기"
                class="w-full max-w-lg border border-slate-300 border-solid rounded"
              />
            </li>
            <li class="flex items-center gap-2">
              <p class="flex-none w-14">링크 :</p>
              <input
                v-model="regist.param.button1Link"
                type="text"
                placeholder="Ex) carrotjunior.kr"
                class="w-full max-w-lg border border-slate-300 border-solid rounded"
              />
            </li>
          </ul>
        </li>
        <li class="flex items-center gap-2 pb-4 border-b border-solid">
          <p class="flex-none font-semibold w-36"><span class="font-bold text-slate-500"></span> Button 2</p>
          <ul class="flex flex-col flex-grow gap-2">
            <li class="flex items-center gap-2">
              <p class="flex-none w-14">버튼명 :</p>
              <input
                v-model="regist.param.button2Name"
                type="text"
                placeholder="Ex) Jnior 수강신청 바로가기"
                class="w-full max-w-lg border border-slate-300 border-solid rounded"
              />
            </li>
            <li class="flex items-center gap-2">
              <p class="flex-none w-14">링크 :</p>
              <input
                v-model="regist.param.button2Link"
                type="text"
                placeholder="Ex) carrotjunior.kr"
                class="w-full max-w-lg border border-slate-300 border-solid rounded"
              />
            </li>
          </ul>
        </li>
        <li class="flex items-center gap-2 pb-4 border-b border-solid">
          <p class="flex-none font-semibold w-36"><span class="font-bold text-slate-500"></span> Button 3</p>
          <ul class="flex flex-col flex-grow gap-2">
            <li class="flex items-center gap-2">
              <p class="flex-none w-14">버튼명 :</p>
              <input
                v-model="regist.param.button3Name"
                type="text"
                placeholder="Ex) Jnior 수강신청 바로가기"
                class="w-full max-w-lg border border-slate-300 border-solid rounded"
              />
            </li>
            <li class="flex items-center gap-2">
              <p class="flex-none w-14">링크 :</p>
              <input
                v-model="regist.param.button3Link"
                type="text"
                placeholder="Ex) carrotjunior.kr"
                class="w-full max-w-lg border border-slate-300 border-solid rounded"
              />
            </li>
          </ul>
        </li>
        <li class="flex items-center gap-2">
          <p class="flex-none font-semibold w-36"><span class="font-bold text-slate-500"></span> Button 4</p>
          <ul class="flex flex-col flex-grow gap-2">
            <li class="flex items-center gap-2">
              <p class="flex-none w-14">버튼명 :</p>
              <input
                v-model="regist.param.button4Name"
                type="text"
                placeholder="Ex) Jnior 수강신청 바로가기"
                class="w-full max-w-lg border border-slate-300 border-solid rounded"
              />
            </li>
            <li class="flex items-center gap-2">
              <p class="flex-none w-14">링크 :</p>
              <input
                v-model="regist.param.button4Link"
                type="text"
                placeholder="Ex) carrotjunior.kr"
                class="w-full max-w-lg border border-slate-300 border-solid rounded"
              />
            </li>
          </ul>
        </li>
      </ul>
    </div>
    <div class="p-2 mb-5 rounded shadow-md">
      <p class="pb-2 mb-2 text-lg font-semibold border-b-2 border-solid border-slate-800">
        <span class="font-bold text-slate-500">*</span>메인페이지 컨텐츠 선택
      </p>
      <ul class="flex gap-5">
        <li class="flex items-center gap-1">
          <input v-model="regist.param.mainContent" type="radio" name="content" id="content1" value="junior" />
          <label class="cursor-pointer" for="content1">주니어 수강신청</label>
        </li>

        <li class="flex items-center gap-1">
          <input v-model="regist.param.mainContent" type="radio" name="content" id="content2" value="adult" />
          <label for="content2" class="cursor-pointer">주니어 + 성인(20세 이상) 수강신청</label>
        </li>
      </ul>
    </div>
    <div v-if="!regist.classUpdate" class="flex justify-end">
      <p class="p-2 text-white bg-blue-600 border border-solid rounded cursor-pointer" @click="regist.classAddHomePage(true)">
        다음 페이지 (저장)
      </p>
    </div>
    <div v-if="regist.classUpdate" class="flex justify-end">
      <p class="p-2 text-white bg-blue-600 border border-solid rounded cursor-pointer" @click="regist.classAddHomePage(false)">
        다음 페이지 (수정)
      </p>
    </div>
  </div>
</template>
<script>
import { onMounted, reactive } from "vue";
import { useRouter, useRoute } from "vue-router";
import axios from "@lifeeduAdmin/plugins/axios.js";
import BagicCompanySelector from "@/lifeeduAdmin/components/BagicCompanySelector.vue";

export default {
  components: {
    BagicCompanySelector,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();

    const idxLeSiteMain = route.query.idxLeSiteMain || "0";

    const regist = reactive({
      param: {
        corporation: "0", //회사명 (문자열)
        kname: "", //회사 이름
        idxCompany: "", // 회사 인덱스 (정수)
        companyLogo: "", //로고 경로 (문자열)
        mainImg: "", //이미지 경로 (문자열)
        popupImg1: "", //팝업 경로1 (문자열)
        popupImg2: "", //팝업 경로2 (문자열)
        popupImg3: "", //팝업 경로3 (문자열)
        popupImg4: "", //팝업 경로4 (문자열)
        popupImg5: "", //팝업 경로5 (문자열)
        button1Name: "", //버튼1 이름 (문자열)
        button1Link: "", //버튼1 경로 (문자열)
        button2Name: "", //버튼2 이름 (문자열)
        button2Link: "", //버튼2 경로 (문자열)
        button3Name: "", //버튼3 이름 (문자열)
        button3Link: "", //버튼3 경로 (문자열)
        button4Name: "", //버튼4 이름 (문자열)
        button4Link: "", //버튼4 경로 (문자열)
        mainContent: "", //메인페이지 컨텐츠 (문자열)
        idxLeSiteMain: idxLeSiteMain, //idxLeSiteMain값 (정수)
        idxLeSugang: "",
        isButton: "",
      },

      classUpdate: false,

      fileChange: async (event, fieldName) => {
        const inputField = event.target;
        const file = inputField.files[0]; // 선택된 파일
        if (file) {
          try {
            const res = await regist.uploadFile(file);
            regist.param[fieldName] = res.data.data.uploadUrl;
            console.log(res.data);
          } catch (err) {
            console.log(err);
          }
        }
      },

      uploadFile: async (file) => {
        const formData = new FormData();
        formData.append("file", file);

        try {
          const res = await axios.post(`/admin/upload`, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          });
          return res;
        } catch (err) {
          throw err;
        }
      },

      deleteImage(fieldName) {
        regist.param[fieldName] = "";
        const inputField = document.querySelector(`input[name="${fieldName}"]`);
        inputField.value = "";
      },

      setImageFileName(url) {
        if (url) {
          const parts = url.split("/");
          return parts[parts.length - 1];
        }
        return "";
      },

      setCompany(companyArr) {
        regist.param.idxCompany = companyArr.idxCompany;
      },

      // 수강 홈페이지 조회
      getSugangHomePage() {
        const idxLeSiteMain = route.query.idxLeSiteMain;

        if (idxLeSiteMain && idxLeSiteMain !== "0") {
          const params = {
            idxLeSiteMain: idxLeSiteMain,
          };
          axios.get(`/admin/sugangHomePage`, { params }).then(function (res) {
            if (res.data.success) {
              regist.param = res.data.data;
              regist.param.isButton = res.data.data.isButton;
              regist.classUpdate = true;
            } else {
              // 처리 실패 시의 동작
            }
          });
        }
      },

      // 수강 홈페이지 등록
      classAddHomePage() {
        // const params = regist.param;

        // 필수 입력값 체크
        if (!regist.param.corporation || regist.param.corporation === "0") {
          alert("법인구분을 선택해주세요.");
          return;
        }

        if (!regist.param.idxCompany) {
          alert("Company를 선택해주세요.");
          return;
        }

        if (!regist.param.companyLogo) {
          alert("Company 로고를 등록 해주세요.");
          return;
        }

        if (!regist.param.mainImg) {
          alert("메인 이미지를 등록 해주세요.");
          return;
        }

        if (!regist.param.mainContent) {
          alert("메인페이지 컨텐츠를 선택해주세요.");
          return;
        }

        const params = {
          ...route.query,
          ...regist.param,
          isButton: regist.param.isButton,
        };

        axios.post(`/admin/sugangHomePage`, params, {}).then(function (res) {
          if (res.data.success) {
            regist.param.idxLeSiteMain = res.data.data.idxLeSiteMain;
            regist.param.idxLeSugang = res.data.data.idxLeSugang;

            if (regist.classUpdate && !regist.param.idxLeSugang) {
              alert("수정되었습니다.");
              router.push({
                name: "enrolmentStep2",
                query: {
                  idxLeSiteMain: route.query.idxLeSiteMain,
                },
              });
            } else if (!regist.classUpdate && !regist.param.idxLeSugang) {
              alert("등록되었습니다.");
              router.push({
                name: "enrolmentStep2",
                query: {
                  idxLeSiteMain: regist.param.idxLeSiteMain,
                },
              });
            } else {
              alert("수정되었습니다.");
              router.push({
                name: "enrolmentStep2",
                query: {
                  idxLeSugang: regist.param.idxLeSugang,
                },
              });
            }

            window.scrollTo({ top: 0, behavior: "smooth" });
          }
        });
      },
    });

    const imgDownload = (url) => {
      window.open(url, "_blank");
    };

    onMounted(() => {
      regist.getSugangHomePage();
    });

    return {
      regist,
      idxLeSiteMain,
      imgDownload,
    };
  },
};
</script>
<style></style>
