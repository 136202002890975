import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";

export default createStore({
  state: {
    accessToken: "",
    staffInfo: {
      idx: 0,
      email: "",
      kname: "",
      ename: "",
      idxTeam: 0,
      idxOffice: 0,
      inOffice: "",
    },
    searchParams: {},
  },
  getters: {
    getAccessToken(state) {
      return state.accessToken;
    },
  },
  mutations: {
    setAccessToken(state, userInfo) {
      state.accessToken = userInfo.accessToken;
      state.staffInfo = userInfo.staffInfo;
    },
  },
  actions: {},
  plugins: [
    createPersistedState({
      key: "LifeEduAdminVuex",
    }),
  ],
});
