<template></template>
<script>
import { reactive, onMounted, computed } from "vue";
import { useRouter, useRoute } from "vue-router";
import axios from "@lifeeduAdmin/plugins/axios.js";
import { useStore } from "vuex";

export default {
    components: {},
    setup() {
        const router = useRouter();
        const route = useRoute();
        const store = useStore();
        const login = reactive({
            params: {
                email: "",
            },
            hqLogin: () => {
                login.params = {
                    email: route.query.email,
                };
                axios
                    .post("/admin/auth/hq_login?email=" + route.query.email, {
                        params: login.params,
                    })
                    .then((res) => {
                        if (res.data.success) {
                            store.commit("setAccessToken", res.data.data);
                            //메인페이지로 이동한다.
                            router.push({
                                path: "/home",
                            });
                        } else {
                            //alert('로그인에 실패하였습니다.');
                        }
                    });
            },
        });
        onMounted(() => {
            login.hqLogin();
        });
        return { login };
    },
};
</script>
