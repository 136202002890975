<template>
  <div>
    <div class="hidden pc:block bg-wrapper">
      <div class="flex items-center justify-center bg py-[76px] max-w-[1920px] mx-auto">
        <p class="text-[40px] font-bold text-neutral-950">성인 과정</p>
      </div>
    </div>

    <section class="pt-[55px] pb-[70px] pc:pt-[70px] pc:pb-[140px]">
      <div>
        <div class="flex items-center justify-between p-2 bg-[#323740] pc:hidden show-list">
          <div class="w-3"></div>
          <p class="text-sm font-bold text-white">과정 리스트 보기</p>
          <div class="w-3">
            <img src="@lifeeduWeb/assets/images/courseIntroduce/adult/arrow.png" alt="icon" class="transition-all show-arrow" />
          </div>
        </div>
        <ul class="flex pc:flex-col pc:max-w-[1200px] pc:mx-auto lang-list">
          <li class="w-[40%] bg-[#ececec] pc:w-full pc:bg-white">
            <ul class="gap-10 pc:flex pc:border-b border-solid border-[#f1f1f1]">
              <li class="py-3.5 font-medium text-center cursor-pointer" @click="goPage('/courseIntroduce/adultEng/PageAll')">
                영어
              </li>
              <li class="py-3.5 font-medium text-center cursor-pointer" @click="goPage('/courseIntroduce/adultChi/pageAll')">
                중국어
              </li>
              <li
                class="py-3.5 font-medium text-center text-[#fb6930] cursor-pointer"
                @click="goPage('/courseIntroduce/adultJap/pageAll')"
              >
                일본어
              </li>
              <li class="py-3.5 font-medium text-center cursor-pointer" @click="goPage('/courseIntroduce/adultEtc')">
                제2외국어
              </li>
            </ul>
          </li>
          <li class="w-[60%] pc:w-full">
            <ul class="divide-y divide-solid divide-[#e9e9e9] pc:flex pc:divide-y-0 gap-9 sub-list">
              <li
                class="py-3.5 font-medium text-center text-[#fb6930] cursor-pointer"
                @click="goPage('/courseIntroduce/adultJap/PageAll')"
              >
                전체 커리큘럼 맵
              </li>
              <li
                class="py-3.5 font-medium text-center cursor-pointer text-[#666666]"
                @click="goPage('/courseIntroduce/adultJap/page1')"
              >
                일반회화
              </li>
              <li
                class="py-3.5 font-medium text-center cursor-pointer text-[#666666]"
                @click="goPage('/courseIntroduce/adultJap/page2')"
              >
                비즈니스회화
              </li>
              <li
                class="py-3.5 font-medium text-center cursor-pointer text-[#666666]"
                @click="goPage('/courseIntroduce/adultJap/page3')"
              >
                토론회화
              </li>
              <li
                class="py-3.5 font-medium text-center cursor-pointer text-[#666666]"
                @click="goPage('/courseIntroduce/adultJap/page4')"
              >
                테마과정
              </li>
            </ul>
          </li>
        </ul>
        <div class="pc-con-wrapper5">
          <div class="bg2">
            <div class="justify-between p-8 pc:flex pc:max-w-[1200px] pc:mx-auto pc:px-0">
              <p class="text-3xl font-bold text-center text-white mb-7 pc:pt-9 pc:mb-0 pc:text-left">
                현재 일본어 레벨이<br />궁금하신가요?
              </p>
              <div class="pc:hidden">
                <table class="text-xs mb-2.5">
                  <thead class="bg-[#fb6930] text-white">
                    <tr class="h-9">
                      <th class="border-r border-white border-solid">레벨</th>
                      <th class="border-r border-white border-solid">입문</th>
                      <th class="border-r border-white border-solid">초급</th>
                      <th class="border-r border-white border-solid">중급</th>
                      <th>고급</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr class="bg-[#ebebeb] h-9">
                      <td class="border-r border-white border-solid">SPAC^ test</td>
                      <td class="border-r border-white border-solid">0</td>
                      <td class="border-r border-white border-solid">1~24</td>
                      <td class="border-r border-white border-solid">25~51</td>
                      <td>52~80</td>
                    </tr>
                    <tr class="bg-white h-9">
                      <td class="border-r border-white border-solid">회화 수준</td>
                      <td class="border-r border-white border-solid">일본어를 처음 배우는 단계</td>
                      <td class="border-r border-white border-solid">기본적인 어휘를 사용하여 간단한 표현</td>
                      <td class="border-r border-white border-solid">기본적인 문장 구조를 알고 의사소통 가능</td>
                      <td>익숙하지 않은 주제로 원어민과 자연스럽게 대화</td>
                    </tr>
                  </tbody>
                </table>
                <ul class="text-xs text-right text-white">
                  <li>*OPIC : 입문 0 / 초급 NL~NM / 중급 NH~IM2 / 고급 IM3~AL</li>
                  <li>*JLPT : 입문 0 / 초급 N5 / 중급 N3~4 / 고급 N1~2</li>
                </ul>
              </div>
              <img src="@lifeeduWeb/assets/images/courseIntroduce/adult/jap/level.png" alt="img" class="hidden pc:block w-min" />
            </div>
          </div>
          <div>
            <p class="text-xl font-bold text-center text-[#232323] py-5 pc:text-3xl pc:py-9">Curriculum Map</p>
            <div class="px-5 pc:hidden">
              <table class="text-xs table-fixed map">
                <thead>
                  <tr class="h-10 bg-[#2a2a2a] text-white">
                    <th width="70px" class="border-r border-white border-solid">카테고리</th>
                    <th colspan="2" class="border-r border-white border-solid">입문</th>
                    <th colspan="4" class="border-r border-white border-solid">초급</th>
                    <th colspan="4" class="border-r border-white border-solid">중급</th>
                    <th colspan="4">고급</th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="tr_01">
                    <td rowspan="10" class="col_01">일반회화</td>
                    <td colspan="3" class="col_02">당당한 일본어 입문편</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr class="tr_02">
                    <td></td>
                    <td></td>
                    <td colspan="3" class="col_02">당당한 일본어 기초편</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr class="tr_03">
                    <td></td>
                    <td></td>
                    <td colspan="3" class="col_02">일본어뱅크 NEW 다이스키 1</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr class="tr_04">
                    <td></td>
                    <td></td>
                    <td></td>
                    <td colspan="3" class="col_02">일본어뱅크 NEW 다이스키 2</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr class="tr_05">
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td colspan="3" class="col_02">일본어뱅크 NEW 다이스키 3</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr class="tr_06">
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td colspan="3" class="col_02">일본어뱅크 NEW 다이스키 4</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr class="tr_07">
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td colspan="3" class="col_02">일본어뱅크 NEW 다이스키 5</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr class="tr_08">
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td colspan="3" class="col_02">일본어뱅크 NEW 다이스키 6</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr class="tr_09">
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td colspan="3" class="col_02">일본어뱅크 NEW 다이스키 7</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr class="tr_10">
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td colspan="3" class="col_02">일본어뱅크 NEW 다이스키 8</td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <!--  -->
                  <tr class="tr_10">
                    <td rowspan="7" class="col_01">비즈니스회화</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td colspan="4" class="col_03">당당한 비즈니스 일본어 Basics편</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr class="tr_11">
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td colspan="4" class="col_03">당당한 비즈니스 일본어 면접편</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr class="tr_12">
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td colspan="4" class="col_03">당당한 비즈니스 일본어 미팅편</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr class="tr_13">
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td colspan="4" class="col_03">당당한 비즈니스 일본어 출장편</td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr class="tr_14">
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td colspan="4" class="col_03">당당한 비즈니스 일본어 이메일편</td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr class="tr_15">
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td colspan="4" class="col_03">당당한 비즈니스 일본어 PPT</td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr class="tr_16">
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td colspan="5" class="col_03">일본어뱅크 NEW 스타일 비즈니스 일본어 1~2</td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>

                  <!--  -->
                  <tr class="tr_15">
                    <td rowspan="2" class="col_01">토론회화</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td colspan="6" class="col_04">밸런스게임 일본어: 당신이라면 어느쪽?</td>
                  </tr>
                  <tr class="tr_16">
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td colspan="6" class="col_04">일본어 토픽 회화</td>
                    <td></td>
                    <td></td>
                  </tr>
                  <!--  -->
                  <tr class="tr_18">
                    <td rowspan="3" class="col_01">테마과정</td>
                    <td></td>
                    <td></td>
                    <td colspan="5" class="col_05">일본어뱅크 톡톡 일본어 여행회화</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr class="tr_19">
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td colspan="3" class="col_05">뉴일본어뱅크 프리토킹 1</td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr class="tr_20">
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td colspan="3" class="col_05">뉴일본어뱅크 프리토킹 2</td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div class="justify-center hidden pc:flex">
              <img src="@lifeeduWeb/assets/images/courseIntroduce/adult/chi/map.png" alt="img" class="w-min" />
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import { reactive, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import axios from "@lifeeduWeb/plugins/axios.js";
import { useStore } from "vuex";

export default {
  components: {},

  setup() {
    const router = useRouter();
    const route = useRoute();
    const store = useStore();

    const goPage = (url) => {
      location.href = url;
    };

    onMounted(() => {
      const showListButton = document.querySelector(".show-list");
      const arrowIcon = showListButton.querySelector(".show-arrow");
      const listToToggle = document.querySelector(".lang-list");
      const countryListItems = document.querySelectorAll(".sub-list li");

      showListButton.addEventListener("click", function () {
        if (listToToggle.style.display === "none") {
          listToToggle.style.display = "flex";
        } else {
          listToToggle.style.display = "none";
        }

        arrowIcon.classList.toggle("rotate-180");
      });

      countryListItems.forEach(function (item) {
        item.addEventListener("click", function () {
          listToToggle.style.display = "none";
          arrowIcon.classList.remove("rotate-180");
        });
      });
    });

    return {
      goPage,
    };
  },
};
</script>
<style lang="scss" scoped>
.bg2 {
  background: url(@lifeeduWeb/assets/images/courseIntroduce/adult/chi/bg-all.png) no-repeat center / 1920px;
}

.map {
  tr {
    height: 40px;
  }

  td {
    border: 1px solid #a8a8a8;
  }

  .col_02 {
    background-color: #e2eefa;
    color: #336185;
  }

  .col_03 {
    background-color: #ffeedc;
    color: #f1740f;
  }

  .col_04 {
    background-color: #ffe4eb;
    color: #e46284;
  }

  .col_05 {
    background-color: #e0f9f3;
    color: #30987f;
  }
}
</style>
