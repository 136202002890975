<template>
  <div class="pt-[55px] pb-[70px] pc:py-[140px] pc-con-wrapper1">
    <p class="text-xl font-bold text-center text-orange-500 mb-7 pc:text-4xl pc:mb-11">
      수강 신청이 정상적으로<br class="pc:hidden" />
      완료되었습니다.
    </p>
    <ul class="flex flex-col gap-14 pc:gap-36 mb-14 pc:mb-24">
      <li>
        <p class="mb-5 text-lg font-bold pc:text-2xl pc:mb-4">회원 정보</p>
        <ul class="grid grid-cols-1 border-t border-b border-solid divide-y border-neutral-950 divide-solid divide-neutral-200">
          <!-- 1:1 -->
          <li v-if="regDatailInfo.regDateInfoList.sugangLecType === '1:1'" class="flex items-center">
            <div
              class="flex-none w-24 pc:w-60 px-2.5 py-3 pc:px-9 pc:py-4 self-stretch flex justify-center items-center bg-neutral-50"
            >
              <p class="text-sm font-bold text-center text-neutral-950 pc:text-lg">회원 이름</p>
            </div>
            <div class="grow pl-2.5 py-3 pc:pl-9 pc:py-4 flex flex-col gap-2.5 pc:flex-row pc:gap-9 pc:items-center">
              <p class="text-sm font-medium pc:text-lg text-neutral-500">
                {{ regDatailInfo.regDateInfoList.memberName }}
              </p>
            </div>
          </li>

          <!-- 그룹 -->
          <li v-else class="flex items-center">
            <div
              class="flex-none w-24 pc:w-60 px-2.5 py-3 pc:px-9 pc:py-4 self-stretch flex justify-center items-center bg-neutral-50"
            >
              <p class="text-sm font-bold text-center text-neutral-950 pc:text-lg">회원 이름(실명)</p>
            </div>
            <div class="grow pl-2.5 py-3 pc:pl-9 pc:py-4 flex flex-col gap-2.5 pc:flex-row pc:gap-9 pc:items-center">
              <p class="text-sm font-medium pc:text-lg text-neutral-500">
                {{ regDatailInfo.regDateInfoList.memberName }}
              </p>
            </div>
          </li>
          <li class="flex items-center">
            <div
              class="flex-none w-24 pc:w-60 px-2.5 py-3 pc:px-9 pc:py-4 self-stretch flex justify-center items-center bg-neutral-50"
            >
              <p class="text-sm font-bold text-center text-neutral-950 pc:text-lg">영어이름<br class="pc:hidden" />(수업이름)</p>
            </div>
            <div class="grow pl-2.5 py-3 pc:pl-9 pc:py-4 flex flex-col gap-2.5 pc:flex-row pc:gap-9 pc:items-center">
              <p class="text-sm font-medium pc:text-lg text-neutral-500">
                {{ regDatailInfo.regDateInfoList.memberEname }}
              </p>
            </div>
          </li>
          <li class="flex items-center">
            <div
              class="flex-none w-24 pc:w-60 px-2.5 py-3 pc:px-9 pc:py-4 self-stretch flex justify-center items-center bg-neutral-50"
            >
              <p class="text-sm font-bold text-center text-neutral-950 pc:text-lg">휴대전화</p>
            </div>
            <div class="grow pl-2.5 py-3 pc:pl-9 pc:py-4 flex flex-col gap-2.5 pc:flex-row pc:gap-9 pc:items-center">
              <p class="text-sm font-medium pc:text-lg text-neutral-500">
                {{ regDatailInfo.regDateInfoList.memberMobile }}
              </p>
            </div>
          </li>
          <li class="flex items-center">
            <div
              class="flex-none w-24 pc:w-60 px-2.5 py-3 pc:px-9 pc:py-4 self-stretch flex justify-center items-center bg-neutral-50"
            >
              <p class="text-sm font-bold text-center text-neutral-950 pc:text-lg">주소</p>
            </div>
            <div class="grow pl-2.5 py-3 pc:pl-9 pc:py-4 flex flex-col gap-2.5 pc:flex-row pc:gap-9 pc:items-center">
              <p class="text-sm font-medium pc:text-lg text-neutral-500">
                {{ regDatailInfo.regDateInfoList.memberPostCode }}<br />{{ regDatailInfo.regDateInfoList.memberAddress }}
                {{ regDatailInfo.regDateInfoList.memberAddressSub }}
              </p>
            </div>
          </li>
        </ul>
      </li>
      <li>
        <p class="mb-5 text-lg font-bold pc:text-2xl pc:mb-4">수강 정보</p>
        <ul class="grid grid-cols-1 border-t border-b border-solid divide-y border-neutral-950 divide-solid divide-neutral-200">
          <!-- 1:1 -->
          <li v-if="regDatailInfo.regDateInfoList.sugangLecType === '1:1'" class="flex items-center">
            <div
              class="flex-none w-24 pc:w-60 px-2.5 py-3 pc:px-9 pc:py-4 self-stretch flex justify-center items-center bg-neutral-50"
            >
              <p class="text-sm font-bold text-center text-neutral-950 pc:text-lg">수업 스케줄</p>
            </div>
            <div class="grow pl-2.5 py-3 pc:pl-9 pc:py-4 flex flex-col gap-2.5 pc:flex-row pc:gap-9 pc:items-center">
              <p class="text-sm font-medium pc:text-lg text-neutral-500">
                {{ regDatailInfo.regDateInfoList.sugangAdult }}
                과정 /
                {{ regDatailInfo.regDateInfoList.sugangLecType }}
              </p>
            </div>
          </li>

          <!-- 그룹 -->
          <li v-else class="flex items-center">
            <div
              class="flex-none w-24 pc:w-60 px-2.5 py-3 pc:px-9 pc:py-4 self-stretch flex justify-center items-center bg-neutral-50"
            >
              <p class="text-sm font-bold text-center text-neutral-950 pc:text-lg">수업 스케줄</p>
            </div>
            <div class="grow pl-2.5 py-3 pc:pl-9 pc:py-4 flex flex-col gap-2.5 pc:flex-row pc:gap-9 pc:items-center">
              <p class="text-sm font-medium pc:text-lg text-neutral-500">
                {{ regDatailInfo.regDateInfoList.sugangAdult }}
                과정 /
                {{ regDatailInfo.regDateInfoList.sugangType }}
              </p>
            </div>
          </li>

          <!-- 1:1 -->
          <li v-if="regDatailInfo.regDateInfoList.sugangLecType === '1:1'" class="flex items-center">
            <div
              class="flex-none w-24 pc:w-60 px-2.5 py-3 pc:px-9 pc:py-4 self-stretch flex justify-center items-center bg-neutral-50"
            >
              <p class="text-sm font-bold text-center text-neutral-950 pc:text-lg">수업 유형</p>
            </div>
            <div class="grow pl-2.5 py-3 pc:pl-9 pc:py-4 flex flex-col gap-2.5 pc:flex-row pc:gap-9 pc:items-center">
              <p class="text-sm font-medium pc:text-lg text-neutral-500">
                {{ regDatailInfo.regDateInfoList.sugangType }}
              </p>
            </div>
          </li>

          <!-- 그룹 -->
          <li v-else class="flex items-center">
            <div
              class="flex-none w-24 pc:w-60 px-2.5 py-3 pc:px-9 pc:py-4 self-stretch flex justify-center items-center bg-neutral-50"
            >
              <p class="text-sm font-bold text-center text-neutral-950 pc:text-lg">그룹</p>
            </div>
            <div class="grow pl-2.5 py-3 pc:pl-9 pc:py-4 flex flex-col gap-2.5 pc:flex-row pc:gap-9 pc:items-center">
              <p class="text-sm font-medium pc:text-lg text-neutral-500">
                {{ regDatailInfo.regDateInfoList.sugangLecType }}
              </p>
            </div>
          </li>
          <li class="flex items-center">
            <div
              class="flex-none w-24 pc:w-60 px-2.5 py-3 pc:px-9 pc:py-4 self-stretch flex justify-center items-center bg-neutral-50"
            >
              <p class="text-sm font-bold text-center text-neutral-950 pc:text-lg">언어</p>
            </div>
            <div class="grow pl-2.5 py-3 pc:pl-9 pc:py-4 flex flex-col gap-2.5 pc:flex-row pc:gap-9 pc:items-center">
              <p class="text-sm font-medium pc:text-lg text-neutral-500">
                {{ regDatailInfo.regDateInfoList.sugangLang }}
              </p>
            </div>
          </li>
          <li class="flex items-center">
            <div
              class="flex-none w-24 pc:w-60 px-2.5 py-3 pc:px-9 pc:py-4 self-stretch flex justify-center items-center bg-neutral-50"
            >
              <p class="text-sm font-bold text-center text-neutral-950 pc:text-lg">수업 기간/횟수</p>
            </div>
            <div class="grow pl-2.5 py-3 pc:pl-9 pc:py-4 flex flex-col gap-2.5 pc:flex-row pc:gap-9 pc:items-center">
              <p
                v-if="regDatailInfo.regDateInfoList.sugangClassMonth === 0"
                class="text-sm font-medium pc:text-lg text-neutral-500"
              >
                주{{ regDatailInfo.regDateInfoList.sugangNumberOfClass }} 회
                {{ regDatailInfo.regDateInfoList.sugangLessonTime }}분
              </p>

              <p v-else class="text-sm font-medium pc:text-lg text-neutral-500">
                {{ regDatailInfo.regDateInfoList.sugangClassMonth }}개월 주{{
                  regDatailInfo.regDateInfoList.sugangNumberOfClass
                }}
                회 {{ regDatailInfo.regDateInfoList.sugangLessonTime }}분
              </p>
            </div>
          </li>
          <li class="flex items-center">
            <div
              class="flex-none w-24 pc:w-60 px-2.5 py-3 pc:px-9 pc:py-4 self-stretch flex justify-center items-center bg-neutral-50"
            >
              <p class="text-sm font-bold text-center text-neutral-950 pc:text-lg">레벨</p>
            </div>
            <div class="grow pl-2.5 py-3 pc:pl-9 pc:py-4 flex flex-col gap-2.5 pc:flex-row pc:gap-9 pc:items-center">
              <p class="text-sm font-medium pc:text-lg text-neutral-500">
                {{ regDatailInfo.regDateInfoList.sugangLevel }}
              </p>
            </div>
          </li>
          <li class="flex items-center">
            <div
              class="flex-none w-24 pc:w-60 px-2.5 py-3 pc:px-9 pc:py-4 self-stretch flex justify-center items-center bg-neutral-50"
            >
              <p class="text-sm font-bold text-center text-neutral-950 pc:text-lg">수업</p>
            </div>
            <div class="grow pl-2.5 py-3 pc:pl-9 pc:py-4 flex flex-col gap-2.5 pc:flex-row pc:gap-9 pc:items-center">
              <p class="text-sm font-medium pc:text-lg text-neutral-500">
                {{ regDatailInfo.regDateInfoList.sugangClassName }}
              </p>
            </div>
          </li>

          <!-- 1:1 -->
          <li v-if="regDatailInfo.regDateInfoList.sugangLecType === '1:1'" class="flex items-center">
            <div
              class="flex-none w-24 pc:w-60 px-2.5 py-3 pc:px-9 pc:py-4 self-stretch flex justify-center items-center bg-neutral-50"
            >
              <p class="text-sm font-bold text-center text-neutral-950 pc:text-lg">수업 횟수</p>
            </div>
            <div class="grow pl-2.5 py-3 pc:pl-9 pc:py-4 flex flex-col gap-2.5 pc:flex-row pc:gap-9 pc:items-center">
              <p class="text-sm font-medium pc:text-lg text-neutral-500">
                주
                {{ regDatailInfo.regDateInfoList.sugangNumberOfClass }}회 {{ regDatailInfo.regDateInfoList.sugangLessonTime }}분
              </p>
            </div>
          </li>

          <!-- 그룹 -->
          <li v-else class="flex items-center">
            <div
              class="flex-none w-24 pc:w-60 px-2.5 py-3 pc:px-9 pc:py-4 self-stretch flex justify-center items-center bg-neutral-50"
            >
              <p class="text-sm font-bold text-center text-neutral-950 pc:text-lg">수업시작시간</p>
            </div>
            <div class="grow pl-2.5 py-3 pc:pl-9 pc:py-4 flex flex-col gap-2.5 pc:flex-row pc:gap-9 pc:items-center">
              <p class="text-sm font-medium pc:text-lg text-neutral-500">
                {{ regDatailInfo.regDateInfoList.sugangTime }}
              </p>
            </div>
          </li>
          <li v-if="regDatailInfo.regDateInfoList.sugangLecType === '1:1'" class="flex items-center">
            <div
              class="flex-none w-24 pc:w-60 px-2.5 py-3 pc:px-9 pc:py-4 self-stretch flex justify-center items-center bg-neutral-50"
            >
              <p class="text-sm font-bold text-center text-neutral-950 pc:text-lg">희망 수업시간</p>
            </div>
            <div class="grow pl-2.5 py-3 pc:pl-9 pc:py-4 flex flex-col gap-2.5 pc:flex-row pc:gap-9 pc:items-center">
              <p class="text-sm font-medium pc:text-lg text-neutral-500">
                {{ regDatailInfo.regDateInfoList.sugangHopeTime1 }},
                {{ regDatailInfo.regDateInfoList.sugangHopeTime2 }}
              </p>
            </div>
          </li>
          <li class="flex items-center">
            <div
              class="flex-none w-24 pc:w-60 px-2.5 py-3 pc:px-9 pc:py-4 self-stretch flex justify-center items-center bg-neutral-50"
            >
              <p class="text-sm font-bold text-center text-neutral-950 pc:text-lg">교육비</p>
            </div>
            <div class="grow pl-2.5 py-3 pc:pl-9 pc:py-4 flex flex-col gap-2.5 pc:flex-row pc:gap-9 pc:items-center">
              <p class="text-sm font-medium pc:text-lg text-neutral-500">{{ regDatailInfo.regDateInfoList.classPay }}원</p>
            </div>
          </li>
          <li class="flex items-center">
            <div
              class="flex-none w-24 pc:w-60 px-2.5 py-3 pc:px-9 pc:py-4 self-stretch flex justify-center items-center bg-neutral-50"
            >
              <p class="text-sm font-bold text-center text-neutral-950 pc:text-lg">교재비</p>
            </div>
            <div class="grow pl-2.5 py-3 pc:pl-9 pc:py-4 flex flex-col gap-2.5 pc:flex-row pc:gap-9 pc:items-center">
              <p class="text-sm font-medium pc:text-lg text-neutral-500">{{ regDatailInfo.regDateInfoList.bookPay }}원</p>
            </div>
          </li>
          <li class="flex items-center">
            <div
              class="flex-none w-24 pc:w-60 px-2.5 py-3 pc:px-9 pc:py-4 self-stretch flex justify-center items-center bg-neutral-50"
            >
              <p class="text-sm font-bold text-center text-neutral-950 pc:text-lg">총 결제금액</p>
            </div>
            <div class="grow pl-2.5 py-3 pc:pl-9 pc:py-4 flex flex-col gap-2.5 pc:flex-row pc:gap-9 pc:items-center">
              <p class="text-sm font-medium pc:text-lg text-neutral-500">{{ regDatailInfo.regDateInfoList.totalPay }}원</p>
            </div>
          </li>
        </ul>
      </li>
      <li>
        <p class="mb-5 text-lg font-bold pc:text-2xl pc:mb-4">교재 배송지 정보</p>
        <ul class="grid grid-cols-1 border-t border-b border-solid divide-y border-neutral-950 divide-solid divide-neutral-200">
          <li class="flex items-center">
            <div
              class="flex-none w-24 pc:w-60 px-2.5 py-3 pc:px-9 pc:py-4 self-stretch flex justify-center items-center bg-neutral-50"
            >
              <p class="text-sm font-bold text-center text-neutral-950 pc:text-lg">받는 사람</p>
            </div>
            <div class="grow pl-2.5 py-3 pc:pl-9 pc:py-4 flex flex-col gap-2.5 pc:flex-row pc:gap-9 pc:items-center">
              <p class="text-sm font-medium pc:text-lg text-neutral-500">
                {{ regDatailInfo.regDateInfoList.bookMemberName }}
              </p>
            </div>
          </li>
          <li class="flex items-center">
            <div
              class="flex-none w-24 pc:w-60 px-2.5 py-3 pc:px-9 pc:py-4 self-stretch flex justify-center items-center bg-neutral-50"
            >
              <p class="text-sm font-bold text-center text-neutral-950 pc:text-lg">휴대전화</p>
            </div>
            <div class="grow pl-2.5 py-3 pc:pl-9 pc:py-4 flex flex-col gap-2.5 pc:flex-row pc:gap-9 pc:items-center">
              <p class="text-sm font-medium pc:text-lg text-neutral-500">
                {{ regDatailInfo.regDateInfoList.bookMemberMobile }}
              </p>
            </div>
          </li>
          <li class="flex items-center">
            <div
              class="flex-none w-24 pc:w-60 px-2.5 py-3 pc:px-9 pc:py-4 self-stretch flex justify-center items-center bg-neutral-50"
            >
              <p class="text-sm font-bold text-center text-neutral-950 pc:text-lg">주소</p>
            </div>
            <div class="grow pl-2.5 py-3 pc:pl-9 pc:py-4 flex flex-col gap-2.5 pc:flex-row pc:gap-9 pc:items-center">
              <p class="text-sm font-medium pc:text-lg text-neutral-500">
                {{ regDatailInfo.regDateInfoList.bookMemberPostCode }}<br />{{ regDatailInfo.regDateInfoList.bookMemberAddress }}
                {{ regDatailInfo.regDateInfoList.bookMemberAddressSub }}
              </p>
            </div>
          </li>
        </ul>
      </li>
    </ul>

    <p
      class="max-w-xs py-4 mx-auto text-sm font-bold text-center text-white bg-orange-500 rounded cursor-pointer pc:py-6 pc:text-xl"
      @click="regDatailInfo.goToMain()"
    >
      메인 페이지로 이동
    </p>
  </div>
</template>
<script>
import { reactive, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import axios from "@lifeeduWeb/plugins/axios.js";
import { useStore } from "vuex";
export default {
  components: {},
  setup({ emit }) {
    const router = useRouter();
    const route = useRoute();
    const store = useStore();
    const idxLeSiteMain = store.getters.getIdxLeSiteMain; // store 에 저장된 idxLeSiteMain 값
    const idxLeSugangPayInfo = route.query.idxLeSugangPayInfo; // 수강신청 완료 전 페이지에서 넘겨 받은 query값

    const regDatailInfo = reactive({
      regDateInfoList: [], // 수강신청 정보 리스트
      idxLeSugangPayInfo: idxLeSugangPayInfo,

      // 결제 이후 저장된 값을 노출 하기 위해 세션에 저장해놓은 값들
      payWay: sessionStorage.getItem("payWay"), // 결제 방법
      memberEmail: sessionStorage.getItem("memberEmail"), // 신청자 이메일
      totalPay: sessionStorage.getItem("totalPay"), // 합계 금액
      memberName: sessionStorage.getItem("memberName"), // 신청자 이름
      memberEname: sessionStorage.getItem("memberEname"), // 신청자 영문 이름
      memberMobile: sessionStorage.getItem("memberMobile"), // 신청자 핸드폰 번호
      memberPostCode: sessionStorage.getItem("memberPostCode"), // 신청자 우편번호
      memberAddress: sessionStorage.getItem("memberAddress"), // 신청자 주소1
      memberAddressSub: sessionStorage.getItem("memberAddressSub"), // 신청자 상세 주소
      bookMemberName: sessionStorage.getItem("bookMemberName"), // 배송지 이름
      bookMemberMobile: sessionStorage.getItem("bookMemberMobile"), // 배송지 핸드폰 번호
      bookMemberPostCode: sessionStorage.getItem("bookMemberPostCode"), // 배송지 우편 번호
      bookMemberAddress: sessionStorage.getItem("bookMemberAddress"), // 배송지 주소1
      bookMemberAddressSub: sessionStorage.getItem("bookMemberAddressSub"), // 배송지 상세 주소

      // 결제후 체크 함수
      getConfirm() {
        const impUid = route.query.imp_uid;
        const merchantUid = route.query.merchant_uid;
        const params = {
          payWay: regDatailInfo.payWay,
          memberEmail: regDatailInfo.memberEmail,
          merchantUid: merchantUid,
          impUid: impUid, //결제 idx (정수)
          totalPay: regDatailInfo.totalPay, //최종 금액 (정수)
          memberName: regDatailInfo.memberName, //회원 이름 (문자열)
          memberEname: regDatailInfo.memberEname, //회원 영어 이름 (문자열)
          memberMobile: regDatailInfo.memberMobile, //회원 휴대전화 (문자열)
          memberPostCode: regDatailInfo.memberPostCode, //회원 우편번호 (문자열)
          memberAddress: regDatailInfo.memberAddress, //회원 주소 (문자열)
          memberAddressSub: regDatailInfo.memberAddressSub, //회원 추가 주소 (문자열)
          bookMemberName: regDatailInfo.bookMemberName, //배송지 받는사람 이름 (문자열)
          bookMemberMobile: regDatailInfo.bookMemberMobile, //배송지 받는사람 휴대전화 (문자열)
          bookMemberPostCode: regDatailInfo.bookMemberPostCode, //배송지 우편번호 (문자열)
          bookMemberAddress: regDatailInfo.bookMemberAddress, //배송지 주소 (문자열)
          bookMemberAddressSub: regDatailInfo.bookMemberAddressSub, //배송지 추가 주소 (문자열)
        };
        axios.post(`/user/sugang/pay/confirm`, params).then(function (res) {
          if (res.data.success === true) {
            sessionStorage.clear();
            window.history.replaceState(null, "", window.location.pathname);
            regDatailInfo.getRegisterDetail();
          } else {
            alert(res.data.message);
          }
        });
      },

      // 수업 신청 내역 상세보기 함수
      getRegisterDetail() {
        const params = {
          idxLeSugangPayInfo: regDatailInfo.idxLeSugangPayInfo,
        };
        axios.get(`/user/sugang/class/registerDetail`, { params }).then(function (res) {
          if (res.data.success === true) {
            regDatailInfo.regDateInfoList = res.data.data;
          } else {
            alert(res.data.message);
          }
        });
      },

      // 메인 페이지 이동 query 값으로 idxLeSiteMain 값을 물고 이동 (메인 페이지 에서 로고 팝업 등 idxLeSiteMain 값을 필요로 하기 때문)
      goToMain() {
        router.push({
          path: "/home",
          query: {
            idxLeSiteMain: idxLeSiteMain,
          },
        });
      },
    });
    onMounted(() => {
      regDatailInfo.getConfirm();
      const payWay = sessionStorage.getItem("payWay");
      const memberEmail = sessionStorage.getItem("memberEmail");
      const totalPay = sessionStorage.getItem("totalPay");
      const memberName = sessionStorage.getItem("memberName");
      const memberEname = sessionStorage.getItem("memberEname");
      const memberMobile = sessionStorage.getItem("memberMobile");
      const memberPostCode = sessionStorage.getItem("memberPostCode");
      const memberAddress = sessionStorage.getItem("memberAddress");
      const memberAddressSub = sessionStorage.getItem("memberAddressSub");
      const bookMemberName = sessionStorage.getItem("bookMemberName");
      const bookMemberMobile = sessionStorage.getItem("bookMemberMobile");
      const bookMemberPostCode = sessionStorage.getItem("bookMemberPostCode");
      const bookMemberAddress = sessionStorage.getItem("bookMemberAddress");
      const bookMemberAddressSub = sessionStorage.getItem("bookMemberAddressSub");
    });

    return {
      regDatailInfo,
      idxLeSugangPayInfo,
    };
  },
};
</script>
<style></style>
