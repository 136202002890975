<template>
  <div class="p-4">
    <div class="p-4">
      <div class="p-2 flex gap-2">
        <div
          @click="regist.handleComon()"
          :class="{
            'bg-slate-800 text-white border border-solid border-slate-800': regist.is_show_common,
          }"
          class="p-2 rounded cursor-pointer text-slate-800 border border-solid border-slate-800"
        >
          일반
        </div>
        <div
          v-if="regist.isGroupSup == 'Y'"
          @click="regist.handleSupport()"
          :class="{
            'bg-slate-800 text-white border border-solid border-slate-800': regist.is_show_support,
          }"
          class="p-2 rounded cursor-pointer text-slate-800 border border-solid border-slate-800"
        >
          지원
        </div>
      </div>
    </div>
    <!-- 안내문구 기입 -->
    <!-- <div class="p-2 mb-5">
      <p class="mb-2 text-xl font-semibold">평생교육 홈페이지 관리자 안내</p>
      <ul class="flex flex-col gap-2">
        <li>- <span class="text-red-500">[Compnay] </span>클릭 시, 홈페이지 수정페이지로 이동합니다.</li>
      </ul>
    </div> -->
    <div class="p-2 mb-5 rounded shadow-md">
      <div class="flex justify-between">
        <p class="pb-2 mb-2 text-lg font-semibold border-b-2 border-solid border-slate-800">그룹 유형</p>
        <p class="p-2 text-red-600 border border-red-600 border-solid rounded cursor-pointer" @click="regist.handleLevelCheck()">
          신청 레벨 확인
        </p>
      </div>
      <ul class="flex gap-2">
        <li
          v-for="item in regist.sugangInfo.sugangGroupTypeList"
          :key="item.idxLeSugangGroupType"
          class="flex gap-2 p-2 bg-slate-800 text-white cursor-pointer"
          @click="regist.handleSugangLang(item.idxLeSugangGroupType)"
        >
          {{ item.groupType }}
        </li>
      </ul>
    </div>

    <div class="p-2 mb-5 rounded shadow-md">
      <p class="pb-2 mb-2 text-lg font-semibold border-b-2 border-solid border-slate-800">시간</p>
      <ul class="flex gap-2">
        <li
          v-for="time in regist.sugangInfo.sugangGroupTimeInfoList"
          :key="time.idxLeSugangGroupTimeInfo"
          class="p-2 bg-slate-800 text-white cursor-pointer"
          @click="regist.updateTime(time.idxLeSugangGroupTimeInfo)"
        >
          주{{ time.numberOfClass }}회 {{ time.lessonTime }}분
        </li>
      </ul>
    </div>

    <div class="p-2 mb-5 rounded shadow-md">
      <p class="pb-2 mb-2 text-lg font-semibold border-b-2 border-solid border-slate-800">언어</p>
      <ul class="flex gap-2">
        <li
          v-for="lang in regist.selectedLangList"
          :key="lang.idxLeSugangGroupLang"
          class="p-2 bg-slate-800 text-white cursor-pointer"
          @click="regist.updateLanguageLevels(lang.idxLeSugangGroupLang)"
        >
          {{ lang.language }}
        </li>
      </ul>
    </div>

    <div class="p-2 mb-5 rounded shadow-md">
      <p class="pb-2 mb-2 text-lg font-semibold border-b-2 border-solid border-slate-800">레벨</p>
      <ul class="flex gap-2 overflow-auto">
        <li
          v-for="level in regist.selectedLanguageLevels"
          :key="level.idxLeSugangGroupLevel"
          class="p-2 bg-slate-800 text-white cursor-pointer"
          @click="regist.sugangGroupDetailRoom(level)"
        >
          {{ level.levelName }}
        </li>
      </ul>
    </div>

    <div class="p-2 rounded shadow-md overflow-auto h-[500px]">
      <table>
        <thead>
          <!-- 과정 시간대 -->
          <tr>
            <!-- 횟수 -->
          </tr>
        </thead>
        <tbody>
          <tr>
            <!-- 컨텐츠 부분 -->
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <!-- 신청 레벨 확인 팝업 -->
  <div v-if="regist.is_ShowLevelPop">
    <div class="fixed inset-0 w-full h-full bg-black/80 z-[10] overflow-y-auto">
      <div class="flex flex-col justify-center items-center p-5 w-[900px] mx-auto">
        <div class="w-full flex fl justify-end p-2 bg-white">
          <p class="cursor-pointer text-xl font-bold underline" @click="regist.handleCloseLevelCheck()">닫기</p>
        </div>
        <img src="@/lifeeduAdmin/assets/images/levelcheck.png" />
      </div>
    </div>
  </div>
</template>
<script>
import { onMounted, reactive } from "vue";
import { useRouter, useRoute } from "vue-router";
import axios from "@lifeeduAdmin/plugins/axios.js";

export default {
  setup() {
    const router = useRouter();
    const route = useRoute();

    const regist = reactive({
      is_ShowLevelPop: false,

      isGroupSup: "",
      is_show_common: true,
      is_show_support: false,

      sugangInfo: {
        sugangGroupTypeList: [],
        sugangGroupTimeInfoList: [],
        sugangGroupLangList: [],
        sugangGroupLevelList: [],
        selectedLangList: [],
      },

      sugangSupType: "",

      // 수강 홈페이지 조회
      // getSugangHomePage() {
      //   const params = {
      //     idxLeSugang: route.query.idxLeSugang,
      //     sugangSupType: regist.sugangSupType,
      //   };

      //   axios.get(`/admin/sugangGroupDetail`, { params }).then(function (res) {
      //     if (res.data.success) {
      //       if (regist.sugangSupType == "일반") {
      //         regist.sugangInfo = res.data.data[0];
      //       } else {
      //         regist.sugangInfo = res.data.data[1];
      //       }
      //     } else {
      //       // 처리 실패 시의 동작
      //     }
      //   });
      // },

      getSugangHomePage() {
        const params = {
          idxLeSugang: route.query.idxLeSugang,
          sugangSupType: regist.sugangSupType,
        };

        axios
          .get(`/admin/sugangGroupDetail`, { params })
          .then(function (res) {
            if (res.data.success) {
              if (res.data.data.length > 0) {
                // 데이터가 있는지 확인
                if (regist.sugangSupType === "일반" || regist.sugangSupType === "지원") {
                  regist.sugangInfo = res.data.data[0];
                }
              } else {
                // 데이터가 없는 경우 처리
                alert("등록된 수업이 없습니다.");
              }
            } else {
              // 처리 실패 시의 동작
            }
          })
          .catch(function (error) {
            console.error("Error fetching data:", error);
            // 에러 처리
          });
      },

      getSugang() {
        const idxLeSugang = route.query.idxLeSugang;

        if (idxLeSugang && idxLeSugang !== "0") {
          const params = {
            idxLeSugang: idxLeSugang,
          };
          axios.get("/admin/sugang", { params }).then(function (res) {
            if (res.data.success) {
              regist.isGroupSup = res.data.data.sugangInfo.isGroupSup;
            } else {
              // 처리 실패 로직
            }
          });
        }
      },

      handleLevelCheck() {
        regist.is_ShowLevelPop = true;
      },

      handleCloseLevelCheck() {
        regist.is_ShowLevelPop = false;
      },

      handleSugangLang(idxLeSugangGroupType) {
        console.log("idxLeSugangGroupType:", idxLeSugangGroupType);
        const selectedGroup = regist.sugangInfo.sugangGroupTypeList.find(
          (group) => group.idxLeSugangGroupType === idxLeSugangGroupType
        );
        if (selectedGroup) {
          regist.sugangInfo.sugangGroupTimeInfoList = selectedGroup.sugangGroupTimeInfoList;
        }
      },

      updateTime(idxLeSugangGroupTimeInfo) {
        // 선택된 시간에 해당하는 언어 목록을 찾기
        const selectedTime = regist.sugangInfo.sugangGroupTimeInfoList.find(
          (time) => time.idxLeSugangGroupTimeInfo === idxLeSugangGroupTimeInfo
        );
        // 언어 목록이 존재하면
        if (selectedTime && selectedTime.sugangGroupLangList) {
          // 선택된 언어 목록을 설정
          regist.selectedLangList = selectedTime.sugangGroupLangList;
        } else {
          // 선택된 시간에 해당하는 언어 목록이 없으면 빈 배열로 설정
          regist.selectedLangList = [];
        }
      },

      updateLanguageLevels(idxLeSugangGroupLang) {
        // 선택된 언어에 해당하는 레벨 목록을 찾기
        const selectedLanguage = regist.selectedLangList.find((lang) => lang.idxLeSugangGroupLang === idxLeSugangGroupLang);
        // 레벨 목록이 존재하면
        if (selectedLanguage && selectedLanguage.sugangGroupLevelList) {
          // 선택된 레벨 목록을 설정
          regist.selectedLanguageLevels = selectedLanguage.sugangGroupLevelList;
        } else {
          // 선택된 언어에 해당하는 레벨 목록이 없으면 빈 배열로 설정
          regist.selectedLanguageLevels = [];
        }
      },

      // 그룹 룸 상세 조회
      sugangGroupDetailRoom(level) {
        const idxLeSugangGroupLevel = level.idxLeSugangGroupLevel;
        const params = {
          idxLeSugangGroupLevel: idxLeSugangGroupLevel,
        };

        console.log("idxLeSugangGroupLevel", idxLeSugangGroupLevel);

        axios.get(`/admin/sugangGroupDetailRoom`, { params }).then(function (res) {
          if (res.data.success) {
            const data = res.data.data.sugangGroupClassList;
            const tableBody = document.querySelector("tbody");
            tableBody.innerHTML = "";

            // Determine the maximum length of timeInfoList among all timeInfo objects
            const maxTimeInfoListLength = Math.max(
              ...data.map((classInfo) => classInfo.timeList.map((timeInfo) => timeInfo.timeInfoList.length)).flat()
            );

            // Generate the table header HTML
            let timeHeaderHTML = `<tr>
                            <th class="p-2 bg-slate-800 text-white" rowspan="2">과정/시간대</th>`;

            data[0].timeList.forEach((timeInfo) => {
              const [hours, minutes] = timeInfo.time.split(":").map(Number);
              let newMinutes = (minutes + 30) % 60;
              let newHours = hours + Math.floor((minutes + 30) / 60);
              if (newHours === 24) {
                newHours = 0;
              }
              const newTime = `${newHours.toString().padStart(2, "0")}:${newMinutes.toString().padStart(2, "0")}`;
              timeHeaderHTML += `<th class="p-2 bg-slate-800 text-white" colspan="${maxTimeInfoListLength * 2}">${
                timeInfo.time
              } ~ ${newTime}</th>`;
            });

            timeHeaderHTML += `</tr>
                        <tr>`;

            // Generate the table header cells for each weekTime dynamically
            data[0].timeList.forEach((timeInfo) => {
              timeInfo.timeInfoList.forEach((weekInfo) => {
                timeHeaderHTML += `<th colspan="2">${weekInfo.weekTime}</th>`;
              });
            });

            timeHeaderHTML += `</tr>`;
            tableBody.innerHTML += timeHeaderHTML;

            // Generate the table body HTML
            data.forEach((classInfo) => {
              let classNameHTML = `<tr>
                                <td>${classInfo.className}</td>`;
              classInfo.timeList.forEach((timeInfo) => {
                timeInfo.timeInfoList.forEach((weekInfo) => {
                  const totalCount = weekInfo.totalCount;
                  const closeCount = weekInfo.closeCount;
                  const openCount = weekInfo.openCount;
                  const countHTML = `<td colspan="2">
                                            <ul class=" ">
                                                <li class="flex flex-col gap-1">
                                                    <p class="p-1 rounded text-white bg-[#49cc90]">총 ${totalCount}개</p>
                                                    <p class="p-2 text-red-700 border border-red-700 border-solid rounded">마감 ${closeCount}개</p>
                                                    <p class="p-2 text-blue-300 border-2 border-blue-300 border-solid rounded">신청가능 ${openCount}개</p>
                                                </li>
                                            </ul>
                                        </td>`;
                  classNameHTML += countHTML;
                });
              });
              classNameHTML += `</tr>`;
              tableBody.innerHTML += classNameHTML;
            });
          }
        });
      },

      handleComon() {
        regist.is_show_common = true;
        regist.is_show_support = false;
        regist.sugangSupType = "일반";
        // regist.registSugangData.sugangOneLangList = registSugang.sugangOneLangListCommon;
        // registSugang.registSugangData.sugangOneTimeInfoList = registSugang.sugangOneTimeInfoListCommon;
        regist.getSugangHomePage();
      },

      handleSupport() {
        regist.is_show_common = false;
        regist.is_show_support = true;
        regist.sugangSupType = "지원";
        // registSugang.registSugangData.sugangOneLangList = registSugang.sugangOneLangListSupport;
        // registSugang.registSugangData.sugangOneTimeInfoList = registSugang.sugangOneTimeInfoListSupport;
        regist.getSugangHomePage();
      },
    });

    onMounted(() => {
      regist.getSugang();
    });

    return {
      regist,
    };
  },
};
</script>
<style></style>
