<template>
    <BagicEditor etype="elearning" v-model="page.params.contents" class="w-100per h-300px"></BagicEditor>
</template>

<script>
import { reactive, onMounted, computed } from "vue";
import { useRouter, useRoute } from 'vue-router';
import axios from '@lifeeduAdmin/plugins/axios.js'
import { useStore } from 'vuex'

import BagicEditor from '@lifeeduAdmin/components/BagicEditor.vue'

export default {
    name: "index",
    components: {
        BagicEditor,
    },
    setup() {
    const router = useRouter();
    const route = useRoute();
    const store = useStore();
    const page = reactive({
        params: {
            contents:'',
        },
    });
    onMounted(() => {
    });
    return { page }
  },
};
</script>
<style>
</style>
