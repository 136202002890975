<template>
  <section class="pt-[55px] pb-[70px] pc:py-[140px] pc-con-wrapper1">
    <div>
      <p class="text-xl font-bold text-center mb-7 pc:text-4xl pc:mb-11">
        {{ classPayment.classPaymentData.classPaymentDataList.memberName }} 님의 기존 수강 내역입니다.
      </p>
      <div>
        <ul class="flex flex-col border-t border-b border-solid pc:hidden border-neutral-950 mb-14">
          <li class="flex border-b border-solid border-neutral-200">
            <p class="text-sm font-bold py-7 flex-none w-[90px] bg-neutral-50 text-center">수강기간</p>
            <p class="pl-10 text-sm font-medium py-7 grow">
              {{ classPayment.classPaymentData.classPaymentDataList.sdate }} ~
              {{ classPayment.classPaymentData.classPaymentDataList.edate }}
            </p>
          </li>
          <li class="flex border-b border-solid border-neutral-200">
            <p class="text-sm font-bold py-7 flex-none w-[90px] bg-neutral-50 text-center">스케줄</p>
            <p class="pl-10 text-sm font-medium py-7 grow">
              {{ classPayment.classPaymentData.classPaymentDataList.sugangLecType }}
            </p>
          </li>
          <li class="flex border-b border-solid border-neutral-200">
            <p class="text-sm font-bold py-7 flex-none w-[90px] bg-neutral-50 text-center">언어</p>
            <p class="pl-10 text-sm font-medium py-7 grow">
              {{ classPayment.classPaymentData.classPaymentDataList.sugangLang }}
            </p>
          </li>
          <li class="flex border-b border-solid border-neutral-200">
            <p class="text-sm font-bold py-7 flex-none w-[90px] bg-neutral-50 text-center">레벨</p>
            <p class="pl-10 text-sm font-medium py-7 grow">
              {{ classPayment.classPaymentData.classPaymentDataList.sugangLevel }}
            </p>
          </li>
          <li class="flex border-b border-solid border-neutral-200">
            <p class="text-sm font-bold py-7 flex-none w-[90px] bg-neutral-50 text-center">수업</p>
            <p class="pl-10 text-sm font-medium py-7 grow">
              {{ classPayment.classPaymentData.classPaymentDataList.sugangAdult }}
              {{ classPayment.classPaymentData.classPaymentDataList.sugangClassName }}
            </p>
          </li>
          <li class="flex border-b border-solid border-neutral-200">
            <p class="text-sm font-bold py-7 flex-none w-[90px] bg-neutral-50 text-center">교재</p>
            <p class="pl-10 text-sm font-medium py-7 grow">
              {{ classPayment.classPaymentData.classPaymentDataList.idxBook1 }}
            </p>
          </li>
          <li class="flex border-b border-solid border-neutral-200">
            <p class="text-sm font-bold py-7 flex-none w-[90px] bg-neutral-50 text-center">수업 횟수</p>
            <p
              class="pl-10 text-sm font-medium py-7 grow"
              v-if="classPayment.classPaymentData.classPaymentDataList.sugangClassMonth === 0"
            >
              주{{ classPayment.classPaymentData.classPaymentDataList.sugangNumberOfClass }}회
              {{ classPayment.classPaymentData.classPaymentDataList.sugangLessonTime }}분
            </p>
            <p v-else class="pl-10 text-sm font-medium py-7 grow">
              {{ classPayment.classPaymentData.classPaymentDataList.sugangClassMonth }}개월 주
              {{ classPayment.classPaymentData.classPaymentDataList.sugangNumberOfClass }}회
              {{ classPayment.classPaymentData.classPaymentDataList.sugangLessonTime }}분
            </p>
          </li>
          <li class="flex border-b border-solid border-neutral-200">
            <p class="text-sm font-bold py-7 flex-none w-[90px] bg-neutral-50 text-center">수업 시간</p>
            <p class="pl-10 text-sm font-medium py-7 grow">{{ classPayment.classPaymentData.classPaymentDataList.stime }}</p>
          </li>
          <li class="flex border-b border-solid border-neutral-200">
            <p class="text-sm font-bold py-7 flex-none w-[90px] bg-neutral-50 text-center">레벨테스트</p>
            <p class="pl-10 text-sm font-medium py-7 grow">
              {{ classPayment.classPaymentData.classPaymentDataList.isLevelTest === "Y" ? "신청함" : "신청 안함" }}
            </p>
          </li>
        </ul>
        <table class="hidden overflow-hidden border-t border-b border-solid pc:table mb-11">
          <thead class="bg-neutral-50">
            <tr class="border-b border-solid border-neutral-200">
              <th class="text-lg border-r border-solid p-7 border-neutral-200">수강기간</th>
              <th class="text-lg border-r border-solid p-7 border-neutral-200">스케줄</th>
              <th class="text-lg border-r border-solid p-7 border-neutral-200">언어</th>
              <th class="text-lg border-r border-solid p-7 border-neutral-200">레벨</th>
              <th class="text-lg border-r border-solid p-7 border-neutral-200">수업</th>
              <th class="text-lg border-r border-solid p-7 border-neutral-200">교재</th>
              <th class="text-lg border-r border-solid p-7 border-neutral-200">수업 횟수</th>
              <th class="text-lg border-r border-solid p-7 border-neutral-200">수업 시간</th>
              <th class="text-lg p-7">레벨테스트</th>
            </tr>
          </thead>
          <tbody>
            <tr class="border-b border-solid border-neutral-200 last-of-type:border-none">
              <td class="px-2.5 py-3.5 border-r border-solid border-neutral-200">
                <p class="text-lg font-medium text-neutral-950">
                  {{ classPayment.classPaymentData.classPaymentDataList.sdate }}<br />~
                  {{ classPayment.classPaymentData.classPaymentDataList.edate }}
                </p>
              </td>
              <td class="px-2.5 py-3.5 border-r border-solid border-neutral-200">
                <p class="text-lg font-medium text-neutral-950">
                  {{ classPayment.classPaymentData.classPaymentDataList.sugangLecType }}
                </p>
              </td>
              <td class="px-2.5 py-3.5 border-r border-solid border-neutral-200">
                <p class="text-lg font-medium text-neutral-950">
                  {{ classPayment.classPaymentData.classPaymentDataList.sugangLang }}
                </p>
              </td>
              <td class="px-2.5 py-3.5 border-r border-solid border-neutral-200">
                <p class="text-lg font-medium text-neutral-950">
                  {{ classPayment.classPaymentData.classPaymentDataList.sugangLevel }}
                </p>
              </td>
              <td class="px-2.5 py-3.5 border-r border-solid border-neutral-200">
                <p class="text-lg font-medium text-neutral-950">
                  {{ classPayment.classPaymentData.classPaymentDataList.sugangAdult }}
                  {{ classPayment.classPaymentData.classPaymentDataList.sugangClassName }}
                </p>
              </td>
              <td class="px-2.5 py-3.5 border-r border-solid border-neutral-200">
                <p class="text-lg font-medium text-neutral-950">
                  {{ classPayment.classPaymentData.classPaymentDataList.idxBook1 }}
                </p>
              </td>
              <td class="px-2.5 py-3.5 border-r border-solid border-neutral-200">
                <p
                  class="text-lg font-medium text-neutral-950"
                  v-if="classPayment.classPaymentData.classPaymentDataList.sugangClassMonth === 0"
                >
                  주{{ classPayment.classPaymentData.classPaymentDataList.sugangNumberOfClass }}회
                  {{ classPayment.classPaymentData.classPaymentDataList.sugangLessonTime }}분
                </p>
                <p v-else class="text-lg font-medium text-neutral-950">
                  {{ classPayment.classPaymentData.classPaymentDataList.sugangClassMonth }}개월<br />주
                  {{ classPayment.classPaymentData.classPaymentDataList.sugangNumberOfClass }}회
                  {{ classPayment.classPaymentData.classPaymentDataList.sugangLessonTime }}분
                </p>
              </td>
              <td class="px-2.5 py-3.5 border-r border-solid border-neutral-200">
                <p class="text-lg font-medium text-neutral-950">
                  {{ classPayment.classPaymentData.classPaymentDataList.stime }}
                </p>
              </td>
              <td class="px-2.5 py-3.5">
                <p class="text-lg font-medium text-neutral-950">
                  {{ classPayment.classPaymentData.classPaymentDataList.isLevelTest === "Y" ? "신청함" : "신청 안함" }}
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <!-- 1:1의 경우 -->
      <ul
        v-if="classPayment.classPaymentData.classPaymentDataList.sugangLecType === '1:1'"
        class="grid grid-cols-2 gap-2.5 pc:gap-5 pc:max-w-md mx-auto max-w-xs"
      >
        <li
          class="flex items-center justify-center border border-solid rounded cursor-pointer border-neutral-500"
          @click="classPayment.getRegisterCheckCencle"
        >
          <p class="py-4 text-sm font-bold text-center text-neutral-500 pc:text-xl pc:py-6">처음부터 신청하기</p>
        </li>

        <li class="flex items-center justify-center bg-orange-500 rounded cursor-pointer" @click="classPayment.onClassApplyNext">
          <p class="py-4 text-sm font-bold text-center text-white pc:text-xl pc:py-6">동일수업 신청하기</p>
        </li>
      </ul>

      <!-- 그룹의 경우 -->
      <p
        v-else
        class="max-w-xs py-4 mx-auto text-sm font-bold text-center text-white bg-orange-500 rounded cursor-pointer pc:py-6 pc:text-xl"
        @click="classPayment.getRegisterCheckCencle"
      >
        수강신청 바로가기
      </p>

      <!-- 성인 -->
      <div v-if="classPayment.classPaymentData.classPaymentDataList.sugangAdult === '성인'" class="pt-16">
        <img src="@lifeeduWeb/assets/images/enrolment/foeverClass-adult.png" />
      </div>

      <!-- 주니어 -->
      <div v-if="classPayment.classPaymentData.classPaymentDataList.sugangAdult === '주니어'" class="pt-16">
        <img src="@lifeeduWeb/assets/images/enrolment/foeverClass-junior.png" />
      </div>
    </div>
  </section>
</template>
<script>
import { reactive, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import axios from "@lifeeduWeb/plugins/axios.js";
import { useStore } from "vuex";
export default {
  components: {},

  setup(props, { emit }) {
    const router = useRouter();
    const route = useRoute();
    const store = useStore();
    const idxLeSiteMain = store.getters.getIdxLeSiteMain; // store 에 저장된 idxLeSiteMain 값

    const classPayment = reactive({
      classPaymentData: {
        classPaymentDataList: [], // 결제 리스트
        idxLeSiteMain: idxLeSiteMain,
        idxLeSugangPayInfo: route.query.idxLeSugangPayInfo, // 수강결제정보
        idxInfoSugangOneLevel: [], // 1:1 수업 수강레벨 리스트
        isMobilePc: "", // 모바일 PC 구분
      },

      // 마지막 수강 내역 확인 함수
      getLast() {
        const params = {
          idxLeSiteMain: idxLeSiteMain,
        };
        axios.get(`/user/sugang/class/last`, { params }).then(function (res) {
          if (res.data.success === true) {
            classPayment.classPaymentData.classPaymentDataList = res.data.data.userSugangPayInfo;

            classPayment.classPaymentData.idxInfoSugangOneLevel = res.data.data.idxInfoSugangOneLevel;

            classPayment.classPaymentData.isMobilePc = res.data.data.isMobilePc;
          } else {
            alert(res.data.message);
          }
        });
      },

      // 기존수강신청 내역 날리고 처음부터 신청 하기
      getRegisterCheckCencle() {
        router.push({ path: "/enrolment/newEnrolmentClass" });
      },

      // 기존에 신청 했던 동일한 수업 신청 함수
      onClassApplyNext() {
        const idxLeSugangOneLevel = classPayment.classPaymentData.idxInfoSugangOneLevel?.idxLeSugangOneLevel ?? "";
        const idxLeSugangOneTimeInfo = classPayment.classPaymentData.idxInfoSugangOneLevel?.idxLeSugangOneTimeInfo ?? "";
        const isBookBuy = classPayment.classPaymentData.classPaymentDataList?.isBookBuy ?? "";
        const isLevelTest = classPayment.classPaymentData.classPaymentDataList?.isLevelTest ?? "";
        const isMobilePc = classPayment.classPaymentData.isMobilePc ?? "";
        const sugangHopeTime1 = classPayment.classPaymentData.classPaymentDataList?.sugangHopeTime1 ?? "";
        const sugangHopeTime2 = classPayment.classPaymentData.classPaymentDataList?.sugangHopeTime2 ?? "";

        const params = {
          idxLeSugangOneLevel,
          idxLeSugangOneTimeInfo,
          isBookBuy,
          isLevelTest,
          isMobilePc,
          sugangHopeTime1,
          sugangHopeTime2,
        };

        axios.post(`/user/sugang/class/registerOne`, params).then(function (res) {
          if (res.data.success === true) {
            const idxLeSugangPayInfo = res.data.data.idxLeSugangPayInfo;
            router.push({
              path: `/enrolment/checkDetail`,
              query: {
                idxLeSugangPayInfo: idxLeSugangPayInfo,
              },
            });
          } else {
            alert(res.data.message);
          }
        });
      },
    });
    onMounted(() => {
      classPayment.getLast();
    });

    return {
      classPayment,
      idxLeSiteMain,
    };
  },
};
</script>
<style></style>
