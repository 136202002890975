<template>
    <div class="flex items-center gap-3">
        <input
            type="search"
            class="block p-2 border border-solid rounded border-slate-800 w-80"
            list="list-data"
            v-model="companySelector.resultSet.kname"
        />
        <!-- <button
            type="button"
            class="block p-2 text-red-500 border border-red-300 rounded hover:bg-red-100"
            @click="companySelector.getCompanyList"
        >
            search
        </button> -->
        <datalist id="list-data">
            <option
                v-for="item in companySelector.companyList"
                :value="item.kname"
                :key="item.idxCompany"
            >
                {{ item.kname }}
            </option>
        </datalist>
    </div>
</template>
<script>
import { watch, reactive, onMounted, ref } from "vue";
import axios from "@lifeeduAdmin/plugins/axios.js";

export default {
    name: "BagicCompanySelector",
    components: {},
    props: ["kname"],
    setup(props, { emit }) {
        const companySelector = reactive({
            companyList: [],
            resultSet: {
                kname: "",
                idxCompany: 0,
            },

            getCompanyList() {
                //회사 목록을 조회한다.
                let searchParams = {
                    searchKeyword: companySelector.resultSet.kname,
                };
                axios
                    .get("/admin/company", {
                        params: searchParams,
                    })
                    .then(function (res) {
                        if (res.data.success) {
                            companySelector.companyList =
                                res.data.data.companyList;
                        }
                    });
            },
        });

        watch(
            () => companySelector.resultSet.kname,
            (cur, prev) => {
                let companyItem = {};
                companyItem = companySelector.companyList.filter(function (
                    item
                ) {
                    return item["kname"] === cur;
                });
                if (companyItem.length > 0) {
                    companySelector.resultSet.idxCompany =
                        companyItem[0].idxCompany;
                    //회사명이 동일한 것을 찾았다면 idx 값을 리턴해준다.
                    emit("company-click", companySelector.resultSet);
                } else {
                    companySelector.getCompanyList();
                }
            }
        );

        watch(
            () => props.kname,
            (nVal, oVal) => {
                companySelector.resultSet.kname = props.kname;
            }
        );

        onMounted(() => {
            companySelector.resultSet.kname = props.kname;
        });

        return {
            companySelector,
        };
    },
};
</script>
