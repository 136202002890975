<template>
  <div>
    <div class="hidden pc:block bg-wrapper">
      <div class="flex items-center justify-center bg py-[76px] max-w-[1920px] mx-auto">
        <p class="text-[40px] font-bold text-neutral-950">수강신청 내역확인</p>
      </div>
    </div>

    <section
      v-if="!registrationDetail.registrationDetailList.length > 0"
      class="pt-[55px] pb-[70px] pc:py-[250px] pc-con-wrapper1 flex flex-col items-center gap-7"
    >
      <img src="@lifeeduWeb/assets/images/myClass/warn.png" alt="icon" class="w-10" />
      <p class="text-xl font-bold text-center pc:text-4xl">수강 신청 내역이 없습니다.</p>
    </section>

    <section v-else class="pt-[55px] pb-[70px] pc:py-[140px] pc-con-wrapper1">
      <p
        class="text-xl font-bold text-center mb-7 pc:text-4xl pc:mb-11"
        v-if="registrationDetail.registrationDetailList.length > 0"
      >
        {{ registrationDetail.registrationDetailList[0].memberName }}
        님의 수강신청 내역입니다.
      </p>
      <div class="mb-5 pc:mb-4">
        <table class="hidden overflow-hidden border-t border-b border-solid pc:table">
          <thead class="bg-neutral-50">
            <tr class="border-b border-solid border-neutral-200">
              <th class="p-3 text-lg border-r border-solid border-neutral-200">No.</th>
              <th class="p-3 text-lg border-r border-solid border-neutral-200">수강신청일</th>
              <th class="p-3 text-lg border-r border-solid border-neutral-200">유형</th>
              <th class="p-3 text-lg border-r border-solid border-neutral-200">레벨</th>
              <th class="p-3 text-lg border-r border-solid border-neutral-200">수업</th>
              <th class="p-3 text-lg border-r border-solid border-neutral-200">수업안내</th>
              <th class="p-3 text-lg border-r border-solid border-neutral-200">교재소개</th>
              <th class="p-3 text-lg border-r border-solid border-neutral-200">수업시간</th>
              <th class="p-3 text-lg border-r border-solid border-neutral-200">수업정보</th>
              <th class="p-3 text-lg border-r border-solid border-neutral-200">결제금액</th>
              <th class="p-3 text-lg">수강취소</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item, index) in registrationDetail.registrationDetailList"
              :key="index"
              class="border-b border-solid border-neutral-200 last-of-type:border-none"
            >
              <td class="p-3 border-r border-solid border-neutral-200">
                <p class="font-medium text-neutral-950">
                  {{ item.rowNum }}
                </p>
              </td>
              <td class="p-3 border-r border-solid border-neutral-200">
                <p class="font-medium text-neutral-950">
                  {{ item.createDate }}
                </p>
              </td>
              <td class="p-3 border-r border-solid border-neutral-200">
                <p class="font-medium text-neutral-950">
                  {{ item.sugangLecType }}
                </p>
              </td>
              <td class="p-3 border-r border-solid border-neutral-200">
                <p class="font-medium text-neutral-950">
                  {{ item.sugangLevel }}
                </p>
              </td>
              <td class="p-3 border-r border-solid border-neutral-200">
                <p class="font-medium text-neutral-950">
                  {{ item.sugangAdult }}
                  {{ item.sugangLang }}
                  {{ item.sugangClassName }}
                </p>
              </td>
              <td class="p-3 border-r border-solid border-neutral-200">
                <p
                  v-if="item.classInfo && Object.keys(item.classInfo).length > 0"
                  class="font-medium text-orange-500 underline cursor-pointer"
                  @click="registrationDetail.classIntroduction(item.classInfo)"
                >
                  확인
                </p>
              </td>
              <td class="p-3 border-r border-solid border-neutral-200">
                <p
                  v-if="item.bookInfo && Object.keys(item.bookInfo).length > 0"
                  class="font-medium text-orange-500 underline cursor-pointer"
                  @click="registrationDetail.bookIntroduction(item.bookInfo)"
                >
                  확인
                </p>
              </td>
              <td class="p-3 border-r border-solid border-neutral-200">
                <p class="font-medium text-neutral-950">
                  {{ item.sugangLecType === "1:1" && item.sdate === "배정 전" ? item.sdate : item.stime }}
                </p>
              </td>
              <td class="p-3 border-r border-solid border-neutral-200">
                <p class="font-medium text-neutral-950">주 {{ item.sugangNumberOfClass }}회 {{ item.sugangLessonTime }}분</p>
              </td>
              <td class="p-3 border-r border-solid border-neutral-200">
                <p class="font-medium text-neutral-950">{{ item.totalPay }}원</p>
              </td>
              <td class="p-3">
                <p v-if="item.cancelStatus === '취소불가'" class="font-medium text-neutral-950">
                  {{ item.cancelStatus }}
                </p>
                <p
                  @click="registrationDetail.getCancle(item)"
                  v-else
                  class="font-medium text-orange-500 underline cursor-pointer"
                >
                  {{ item.cancelStatus }}
                </p>
              </td>
            </tr>
          </tbody>
        </table>
        <!-- MOBILE -->
        <div class="flex flex-col gap-[70px] pc:hidden">
          <ul
            v-for="(item, index) in registrationDetail.registrationDetailList"
            :key="index"
            class="grid grid-cols-1 border-t border-b border-solid divide-y divide-solid divide-neutral-200"
          >
            <li class="flex items-center">
              <p class="text-sm font-bold text-neutral-950 flex-none w-[89px] px-3.5 py-6 bg-neutral-50 text-center">No.</p>
              <p class="py-6 text-sm font-medium pl-11 grow text-neutral-950">{{ item.rowNum }}</p>
            </li>
            <li class="flex items-center">
              <p class="text-sm font-bold text-neutral-950 flex-none w-[89px] px-3.5 py-6 bg-neutral-50 text-center">수강기간</p>
              <p class="py-6 text-sm font-medium pl-11 grow text-neutral-950">{{ item.createDate }}</p>
            </li>
            <li class="flex items-center">
              <p class="text-sm font-bold text-neutral-950 flex-none w-[89px] px-3.5 py-6 bg-neutral-50 text-center">유형</p>
              <p class="py-6 text-sm font-medium pl-11 grow text-neutral-950">{{ item.sugangLecType }}</p>
            </li>
            <li class="flex items-center">
              <p class="text-sm font-bold text-neutral-950 flex-none w-[89px] px-3.5 py-6 bg-neutral-50 text-center">레벨</p>
              <p class="py-6 text-sm font-medium pl-11 grow text-neutral-950">{{ item.sugangLevel }}</p>
            </li>
            <li class="flex items-center">
              <p class="text-sm font-bold text-neutral-950 flex-none w-[89px] px-3.5 py-6 bg-neutral-50 text-center">수업</p>
              <p class="py-6 text-sm font-medium pl-11 grow text-neutral-950">
                {{ item.sugangAdult }}
                {{ item.sugangLang }}
                {{ item.sugangClassName }}
              </p>
            </li>

            <li class="flex items-center">
              <p class="text-sm font-bold text-neutral-950 flex-none w-[89px] px-3.5 py-6 bg-neutral-50 text-center">수업안내</p>
              <p
                v-if="item.classInfo && Object.keys(item.classInfo).length > 0"
                class="py-6 text-sm font-medium text-orange-500 underline cursor-pointer pl-11 grow text-neutral-950"
                @click="registrationDetail.classIntroduction(item.classInfo)"
              >
                확인
              </p>
            </li>

            <li class="flex items-center">
              <p class="text-sm font-bold text-neutral-950 flex-none w-[89px] px-3.5 py-6 bg-neutral-50 text-center">교재소개</p>
              <p
                v-if="item.bookInfo && Object.keys(item.bookInfo).length > 0"
                class="py-6 text-sm font-medium text-orange-500 underline cursor-pointer pl-11 grow text-neutral-950"
                @click="registrationDetail.bookIntroduction(item.bookInfo)"
              >
                확인
              </p>
            </li>

            <li class="flex items-center">
              <p class="text-sm font-bold text-neutral-950 flex-none w-[89px] px-3.5 py-6 bg-neutral-50 text-center">교재</p>
              <p class="py-6 text-sm font-medium pl-11 grow text-neutral-950">{{ item.idxBook1 }}</p>
            </li>
            <li class="flex items-center">
              <p class="text-sm font-bold text-neutral-950 flex-none w-[89px] px-3.5 py-6 bg-neutral-50 text-center">수업시간</p>
              <p class="py-6 text-sm font-medium pl-11 grow text-neutral-950">
                {{ item.sugangLecType === "1:1" && item.sdate === "배정 전" ? item.sdate : item.stime }}
              </p>
            </li>
            <li class="flex items-center">
              <p class="text-sm font-bold text-neutral-950 flex-none w-[89px] px-3.5 py-6 bg-neutral-50 text-center">수업정보</p>
              <p class="py-6 text-sm font-medium pl-11 grow text-neutral-950">
                주 {{ item.sugangNumberOfClass }}회 {{ item.sugangLessonTime }}분
              </p>
            </li>
            <li class="flex items-center">
              <p class="text-sm font-bold text-neutral-950 flex-none w-[89px] px-3.5 py-6 bg-neutral-50 text-center">결제금액</p>
              <p class="py-6 text-sm font-medium pl-11 grow text-neutral-950">{{ item.totalPay }}원</p>
            </li>

            <li class="flex items-center">
              <p class="text-sm font-bold text-neutral-950 flex-none w-[89px] px-3.5 py-6 bg-neutral-50 text-center">수강취소</p>
              <p v-if="item.cancelStatus === '취소불가'" class="py-6 text-sm font-medium pl-11 grow text-neutral-950">
                {{ item.cancelStatus }}
              </p>

              <p
                v-else
                class="py-6 text-sm font-medium text-orange-500 underline cursor-pointer pl-11 grow text-neutral-950"
                @click="registrationDetail.getCancle(item)"
              >
                {{ item.cancelStatus }}
              </p>
            </li>
          </ul>
        </div>
      </div>
      <p v-if="registrationDetail.isCancleFunction === 'Y'" class="text-sm text-neutral-500 pc:text-lg">
        * 수강취소는
        <span class="text-violet-500">수강신청 마감일 "1일" 전까지</span>
        가능합니다.<br class="pc:hidden" />
        이후 수강취소를 원하시면 당근영어로 문의 부탁드립니다.
      </p>
      <p v-if="registrationDetail.isCancleFunction === 'N'" class="text-sm text-neutral-500 pc:text-lg">
        *수강신청 후
        <span class="text-violet-500">취소는 불가합니다.</span><br class="pc:hidden" />
        수강취소를 원하시면 당근영어로 문의 부탁드립니다.
      </p>
    </section>
  </div>
</template>
<script>
import { reactive, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import axios from "@lifeeduWeb/plugins/axios.js";
import { useStore } from "vuex";

export default {
  components: {},

  setup() {
    const router = useRouter();
    const route = useRoute();
    const store = useStore();
    const idxLeSiteMain = store.getters.getIdxLeSiteMain; // store 에 저장된 idxLeSiteMain

    const registrationDetail = reactive({
      registrationDetailList: [], // 수강신청 내역 리스트
      idxLeSugangPayInfo: "", // 수강결제 정보
      isCancleFunction: "", // 수강취소 문구

      idxLeSiteMain: idxLeSiteMain,

      // 수업 신청 내역 조회
      getRegisterList() {
        const params = {
          idxLeSiteMain: idxLeSiteMain,
        };
        axios.get(`/user/sugang/class/registerList`, { params }).then(function (res) {
          if (res.data.success === true) {
            registrationDetail.registrationDetailList = res.data.data;
            // registrationDetail.idxLeSugangPayInfo = res.data.data.idxLeSugangPayInfo;
          } else {
            alert(res.data.message);
          }
        });
      },

      // 수강 취소 버튼
      getCancle(item) {
        const idxLeSugangPayInfo = item.idxLeSugangPayInfo;

        axios.post(`/user/sugang/class/cancle?idxLeSugangPayInfo=${idxLeSugangPayInfo}`).then(function (res) {
          if (res.data.success === true) {
            alert("수강이 취소 되었습니다.");
            registrationDetail.getRegisterList();
          } else if (res.data.success === false) {
            alert(res.data.message);
          }
        });
      },

      // 교육 안내 버튼
      classIntroduction(relativePath) {
        if (relativePath) {
          // const absolutePath = `https://${relativePath}`;
          const absolutePath = relativePath;
          window.open(absolutePath, "_blank");
        }
      },

      // 교재 안내 버튼
      bookIntroduction(relativePath) {
        if (relativePath) {
          // const absolutePath = `https://${relativePath}`;
          const absolutePath = relativePath;
          window.open(absolutePath, "_blank");
        }
      },
    });

    onMounted(() => {
      registrationDetail.isCancleFunction = store.state.isCancleFunction;
      registrationDetail.getRegisterList();
    });

    return {
      registrationDetail,
      idxLeSiteMain,
    };
  },
};
</script>
<style scoped></style>
