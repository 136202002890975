<template>
  <div>
    <div class="hidden pc:block bg-wrapper">
      <div class="flex items-center justify-center bg py-[76px] max-w-[1920px] mx-auto">
        <p class="text-[40px] font-bold text-neutral-950">성인 과정</p>
      </div>
    </div>

    <section class="pt-[55px] pb-[70px] pc:pt-[70px] pc:pb-[140px]">
      <div>
        <div class="flex items-center justify-between p-2 bg-[#323740] pc:hidden show-list">
          <div class="w-3"></div>
          <p class="text-sm font-bold text-white">과정 리스트 보기</p>
          <div class="w-3">
            <img src="@lifeeduWeb/assets/images/courseIntroduce/adult/arrow.png" alt="icon" class="transition-all show-arrow" />
          </div>
        </div>
        <ul class="flex pc:flex-col pc:max-w-[1200px] pc:mx-auto lang-list">
          <li class="w-[40%] bg-[#ececec] pc:w-full pc:bg-white">
            <ul class="gap-10 pc:flex pc:border-b border-solid border-[#f1f1f1]">
              <li
                class="py-3.5 font-medium text-center text-[#fb6930] cursor-pointer"
                @click="goPage('/courseIntroduce/adultEng/PageAll')"
              >
                영어
              </li>
              <li class="py-3.5 font-medium text-center cursor-pointer" @click="goPage('/courseIntroduce/adultChi/pageAll')">
                중국어
              </li>
              <li class="py-3.5 font-medium text-center cursor-pointer" @click="goPage('/courseIntroduce/adultJap/pageAll')">
                일본어
              </li>
              <li class="py-3.5 font-medium text-center cursor-pointer" @click="goPage('/courseIntroduce/adultEtc')">
                제2외국어
              </li>
            </ul>
          </li>
          <li class="w-[60%] pc:w-full">
            <ul class="divide-y divide-solid divide-[#e9e9e9] pc:flex pc:divide-y-0 gap-9 sub-list">
              <li
                class="py-3.5 font-medium text-center text-[#fb6930] cursor-pointer"
                @click="goPage('/courseIntroduce/adultEng/PageAll')"
              >
                전체 커리큘럼 맵
              </li>
              <li
                class="py-3.5 font-medium text-center cursor-pointer text-[#666666]"
                @click="goPage('/courseIntroduce/adultEng/page1')"
              >
                일반회화
              </li>
              <li
                class="py-3.5 font-medium text-center cursor-pointer text-[#666666]"
                @click="goPage('/courseIntroduce/adultEng/page2')"
              >
                비즈니스회화
              </li>
              <li
                class="py-3.5 font-medium text-center cursor-pointer text-[#666666]"
                @click="goPage('/courseIntroduce/adultEng/page3')"
              >
                토론회화
              </li>
              <li
                class="py-3.5 font-medium text-center cursor-pointer text-[#666666]"
                @click="goPage('/courseIntroduce/adultEng/page4')"
              >
                시험·인터뷰대비
              </li>
              <li
                class="py-3.5 font-medium text-center cursor-pointer text-[#666666]"
                @click="goPage('/courseIntroduce/adultEng/page5')"
              >
                로이터뉴스
              </li>
              <li
                class="py-3.5 font-medium text-center cursor-pointer text-[#666666]"
                @click="goPage('/courseIntroduce/adultEng/page6')"
              >
                Global Topic
              </li>
            </ul>
          </li>
        </ul>
        <div class="pc-con-wrapper5">
          <div class="bg2">
            <div class="justify-between p-8 pc:flex pc:max-w-[1200px] pc:mx-auto pc:px-0">
              <p class="text-3xl font-bold text-center text-white mb-7 pc:pt-9 pc:mb-0 pc:text-left">
                현재 영어 레벨이<br />궁금하신가요?
              </p>
              <div class="pc:hidden">
                <table class="text-xs mb-2.5">
                  <thead class="bg-[#fb6930] text-white">
                    <tr class="h-9">
                      <th class="border-r border-white border-solid">레벨</th>
                      <th class="border-r border-white border-solid">입문</th>
                      <th class="border-r border-white border-solid">초급</th>
                      <th class="border-r border-white border-solid">중급</th>
                      <th>고급</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr class="bg-[#ebebeb] h-9">
                      <td class="border-r border-white border-solid">SPAC^ test</td>
                      <td class="border-r border-white border-solid">0~19</td>
                      <td class="border-r border-white border-solid">20~39</td>
                      <td class="border-r border-white border-solid">40~56</td>
                      <td>57~80</td>
                    </tr>
                    <tr class="bg-white h-9">
                      <td class="border-r border-white border-solid">회화 수준</td>
                      <td class="border-r border-white border-solid">기본적인 의사소통 간단한 문장</td>
                      <td class="border-r border-white border-solid">긴 문장 구성</td>
                      <td class="border-r border-white border-solid">체계적으로 유창하게 대화</td>
                      <td>다양한 주제로 원어민과 자유롭게 대화</td>
                    </tr>
                  </tbody>
                </table>
                <ul class="text-xs text-right text-white">
                  <li>*OPIC : 입문 NL / 초급 NM~NH / 중급 IL~IM3 / 고급 IH~AL</li>
                  <li>*Toeic Speacking : 입문 Lv. 1 / 초급 Lv. 2~3 / 중급 Lv. 4~6 / 고급 Lv. 7~8</li>
                </ul>
              </div>
              <img src="@lifeeduWeb/assets/images/courseIntroduce/adult/eng/level.png" alt="img" class="hidden pc:block w-min" />
            </div>
          </div>
          <div>
            <p class="text-xl font-bold text-center text-[#232323] py-5 pc:text-3xl pc:py-9">Curriculum Map</p>
            <div class="px-5 pc:hidden">
              <table class="text-xs table-fixed map">
                <thead>
                  <tr class="h-10 bg-[#2a2a2a] text-white">
                    <th width="70px" class="border-r border-white border-solid">카테고리</th>
                    <th colspan="2" class="border-r border-white border-solid">입문</th>
                    <th colspan="4" class="border-r border-white border-solid">초급</th>
                    <th colspan="4" class="border-r border-white border-solid">중급</th>
                    <th colspan="4">고급</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td rowspan="9" class="col_01">일반회화</td>
                    <td colspan="2" class="col_02">Power Verb 8</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td colspan="2" class="col_02">Essential English</td>
                    <td colspan="2" class="col_02">Pattern English</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td></td>
                    <td></td>
                    <td colspan="3" class="col_02">Pre Get Up to Speed 1-2</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td colspan="3" class="col_02">New Get up to Speed 1</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td colspan="3" class="col_02">New Get up to Speed 2</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td colspan="3" class="col_02">New Get up to Speed 3</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td colspan="3" class="col_02">New Get up to Speed 4</td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td colspan="3" class="col_02">두근두근 여행영어</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td colspan="3" class="col_02">최신 SNS 트렌디 영어</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td rowspan="5" class="col_01">비즈니스회화</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td colspan="3" class="col_03">Pre Biz Basics 1-2</td>
                    <td colspan="3" class="col_03">Business Basics 1-2</td>
                    <td colspan="3" class="col_03">Business Practice 1-2</td>
                    <td></td>
                  </tr>
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td colspan="3" class="col_03">Business Small talk</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td colspan="3" class="col_03">Business Trip</td>
                    <td colspan="3" class="col_03">Business Meeting</td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td colspan="3" class="col_03">Business Presentation</td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td colspan="3" class="col_03">Virtual Meeting English</td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td rowspan="3" class="col_01">토론회화</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td colspan="4" class="col_04">Active Discussion 1</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td colspan="4" class="col_04">Active Discussion 2</td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td colspan="4" class="col_04">Dynamic Discussion</td>
                  </tr>
                  <tr>
                    <td rowspan="4" class="col_01">시험/인터뷰<br />대비과정</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td colspan="3" class="col_05">OPIc IM 완전정복</td>
                    <td colspan="3" class="col_05">OPIc IH 완전정복</td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td colspan="3" class="col_05">토익스피킹 Lv.5 완전정복</td>
                    <td colspan="3" class="col_05">토익스피킹 Lv.7 완전정복</td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td></td>
                    <td></td>
                    <td colspan="4" class="col_05">Speaking Practice 1-2</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td colspan="4" class="col_05">Job 인터뷰 완벽대비 1</td>
                    <td colspan="4" class="col_05">Job 인터뷰 완벽대비 2</td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td rowspan="2" class="col_01">로이터뉴스</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td colspan="6" class="col_06">로이터통신 뉴스 (Reuters News)</td>
                  </tr>
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td colspan="6" class="col_06">Biz/Finance 로이터통신 뉴스</td>
                  </tr>
                  <tr>
                    <td rowspan="6" class="col_01">Global<br />Topic</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td colspan="6" class="col_07">Daily 토픽회화</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td colspan="4" class="col_07">글로벌 Insight 토론_Basic</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td colspan="4" class="col_07">글로벌 Insight 토론_Intermediate</td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td colspan="4" class="col_07">글로벌 Insight 토론_Advanced</td>
                  </tr>
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td colspan="4" class="col_07">TED-Ed 영어</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div class="justify-center hidden pc:flex">
              <img src="@lifeeduWeb/assets/images/courseIntroduce/adult/eng/map.png" alt="img" class="w-min" />
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import { reactive, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import axios from "@lifeeduWeb/plugins/axios.js";
import { useStore } from "vuex";

export default {
  components: {},

  setup() {
    const router = useRouter();
    const route = useRoute();
    const store = useStore();

    const goPage = (url) => {
      location.href = url;
    };

    onMounted(() => {
      const showListButton = document.querySelector(".show-list");
      const arrowIcon = showListButton.querySelector(".show-arrow");
      const listToToggle = document.querySelector(".lang-list");
      const countryListItems = document.querySelectorAll(".sub-list li");

      showListButton.addEventListener("click", function () {
        if (listToToggle.style.display === "none") {
          listToToggle.style.display = "flex";
        } else {
          listToToggle.style.display = "none";
        }

        arrowIcon.classList.toggle("rotate-180");
      });

      countryListItems.forEach(function (item) {
        item.addEventListener("click", function () {
          listToToggle.style.display = "none";
          arrowIcon.classList.remove("rotate-180");
        });
      });
    });

    return {
      goPage,
    };
  },
};
</script>
<style lang="scss" scoped>
.bg2 {
  background: url(@lifeeduWeb/assets/images/courseIntroduce/adult/eng/bg-all.png) no-repeat center / 1920px;
}

.map {
  tr {
    height: 40px;
  }

  td {
    border: 1px solid #a8a8a8;
  }

  .col_01 {
    background-color: #ebebeb;
    color: #111;
  }

  .col_02 {
    background-color: #e2eefa;
    color: #336185;
  }

  .col_03 {
    background-color: #ffeedc;
    color: #f1740f;
  }

  .col_04 {
    background-color: #ffe4eb;
    color: #e46284;
  }

  .col_05 {
    background-color: #e0f9f3;
    color: #30987f;
  }

  .col_06 {
    background-color: #e8e8fb;
    color: #6363d6;
  }

  .col_07 {
    background-color: #ffe1db;
    color: #e9684c;
  }
}
</style>
