<template>
  <div>
    <!-- 그룹 유형 선택 -->
    <div class="mb-5">
      <p class="pb-2 mb-5 text-lg font-semibold border-b-2 border-solid border-slate-800">그룹 유형 선택</p>
      <ul class="flex flex-wrap gap-10">
        <li v-for="(item, index) in registSugang.groupTypeList" :key="index" class="space-y-4">
          <div class="flex items-center gap-4">
            <div class="flex items-center gap-px">
              <input
                type="checkbox"
                :id="item.groupType"
                v-model="item.checked"
                @change="registSugang.groupTypeChecked(item.checked, item.groupType)"
              />
              <label class="cursor-pointer" :for="item.groupType">{{ item.groupType }}</label>
            </div>
            <ul class="flex gap-2">
              <li class="flex items-center gap-px">
                <input
                  type="radio"
                  :id="item.groupType + '_studentLimitType1'"
                  v-model="item.studentLimitType"
                  value="Not Limited"
                  @change="registSugang.studentLimitTypeChecked(item)"
                />
                <label class="cursor-pointer" :for="item.groupType + '_studentLimitType1'">제한 없음</label>
              </li>
              <li class="flex items-center gap-px">
                <input
                  type="radio"
                  :id="item.groupType + '_studentLimitType2'"
                  v-model="item.studentLimitType"
                  value="Total"
                  @change="registSugang.studentLimitTypeChecked(item)"
                />
                <label class="cursor-pointer" :for="item.groupType + '_studentLimitType2'">과정 전체</label>
              </li>
            </ul>
          </div>
          <div class="flex gap-4" v-if="item.studentLimitType == 'Total'">
            <div class="flex items-center gap-2">
              <p>선착순</p>
              <div class="flex items-center gap-2">
                <input
                  type="text"
                  class="border border-slate-800 border-solid rounded w-full max-w-[50px]"
                  v-model="item.studentLimit"
                  @change="registSugang.studentLimitInput(item.studentLimit, item.groupType)"
                />
                <p>명</p>
              </div>
            </div>
            <div class="flex items-center gap-2">
              <p>대기자</p>
              <div class="flex items-center gap-2">
                <input
                  type="text"
                  class="border border-slate-800 border-solid rounded w-full max-w-[50px]"
                  v-model="item.studentLimitWait"
                  @change="registSugang.studentLimitWaitInput(item.studentLimitWait, item.groupType)"
                />
                <p>명</p>
              </div>
            </div>
          </div>
          <div v-if="item.studentLimitType == 'Total'">
            <span class="font-bold text-red-500">*</span>
            <span>대기자가 없으면 <span class="text-red-500">0명</span> 으로 입력 해주세요</span>
          </div>
          <div class="flex items-center gap-1">
            <label for="code"><span class="font-bold text-red-500">*</span>과정코드입력</label>
            <input
              type="text"
              :id="'code_' + index"
              class="border border-solid rounded border-slate-800 max-w-[100px]"
              v-model="item.isGroupCode"
            />
            <p
              class="p-2 text-white bg-blue-600 border border-solid rounded cursor-pointer"
              @click="registSugang.checkCtmGroupCode(item.isGroupCode, item.groupType)"
            >
              코드 확인
            </p>
          </div>
        </li>
      </ul>
    </div>

    <!-- 그룹 유형 선택 
    <div class="mb-5">
      <p class="pb-2 mb-2 text-lg font-semibold border-b-2 border-solid border-slate-800">그룹 유형 선택</p>
      <ul class="flex flex-wrap gap-4">
        <li class="flex items-center gap-1" v-for="(item, index) in registSugang.groupTypeList" :key="index">
          <input
            type="checkbox"
            @change="registSugang.groupTypeChecked(item.checked, item.groupType)"
            :id="item.groupType"
            v-model="item.checked"
          />
          <label class="cursor-pointer" :for="item.groupType">{{ item.groupType }}</label>
        </li>
      </ul>
    </div>
    -->
    <!-- 수업횟수/시간 선택 -->
    <div class="mb-5">
      <p class="pb-2 mb-2 text-lg font-semibold border-b-2 border-solid border-slate-800">수업 횟수 / 시간</p>
      <table class="mb-2">
        <thead>
          <tr>
            <th>
              <span class="font-bold">*</span>
              수업 횟수
            </th>
            <th>
              <span class="font-bold">*</span>
              총 수업 횟수
            </th>
            <th>
              <span class="font-bold">*</span>
              수업 시간(분)
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in registSugang.groupTimeInfoList" :key="index">
            <td>
              <div class="flex items-center justify-center gap-2">
                <p>주</p>
                <select
                  class="border border-slate-800 border-solid rounded w-full max-w-[100px]"
                  v-model="item.numberOfClass"
                  @change="registSugang.changeNumberOfClass(item.numberOfClass, index)"
                >
                  <option value="0" selected>선택</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="5">5</option>
                </select>
                <p>회</p>
              </div>
            </td>
            <td>
              <div class="flex items-center justify-center gap-2">
                <p>총</p>
                <input
                  type="text"
                  class="border border-slate-800 border-solid rounded w-full max-w-[50px]"
                  v-model="item.totalNumberOfClass"
                  @change="registSugang.changeTotalNumberOfClass(item.totalNumberOfClass, index)"
                />
                <p>회</p>
              </div>
            </td>
            <td>
              <input
                type="text"
                class="border border-solid rounded border-slate-800"
                v-model="item.lessonTime"
                @change="registSugang.changeLessonTime(item.lessonTime, index)"
              />
            </td>
            <td>
              <p
                class="p-2 border border-solid rounded cursor-pointer text-slate-800 border-slate-800"
                @click="registSugang.removeGroupTimeInfo(index, item)"
              >
                삭제
              </p>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="flex justify-end">
        <p
          class="p-2 border border-solid rounded cursor-pointer text-slate-800 border-slate-800"
          @click="registSugang.addGroupTimeInfo"
        >
          추가입력
        </p>
      </div>
    </div>
    <!-- 수강 언어 선택 -->
    <div class="mb-5">
      <p class="pb-2 mb-2 text-lg font-semibold border-b-2 border-solid border-slate-800">수강 언어 선택</p>
      <ul class="flex flex-wrap gap-4">
        <li v-for="(item, index) in registSugang.languageList" :key="index" class="flex items-center gap-1">
          <input
            type="checkbox"
            @change="registSugang.languageChecked(item.checked, item.lang)"
            :id="item.lang"
            v-model="item.checked"
          />
          <label class="cursor-pointer" :for="item.lang">{{ item.lang }}</label>
        </li>
      </ul>
    </div>
    <!-- 레벨 선택 -->
    <div class="mb-5">
      <p class="pb-2 mb-2 text-lg font-semibold border-b-2 border-solid border-slate-800">레벨 선택</p>
      <ul class="grid grid-cols-7 gap-4">
        <li v-for="(item, index) in registSugang.levelList" :key="index" class="flex items-center gap-1">
          <input
            type="checkbox"
            @change="registSugang.levelChecked(item.checked, item.levelName)"
            :id="item.levelName"
            v-model="item.checked"
          />
          <label class="cursor-pointer" :for="item.levelName">{{ item.levelName }}</label>
        </li>
      </ul>
    </div>
    <!-- 수업 등록 -->
    <div class="p-2">
      <div class="">
        <p class="pb-2 mb-2 text-lg font-semibold border-b-2 border-solid border-slate-800">수업 등록</p>
        <p class="mb-2 text-red-500">
          * [그룹] [언어] [레벨] 선택에 따른 수업 등록입니다.<br />
          * 하단의 [그룹] [언어] [레벨] 을 선택하면 개별 수업들의 입력 화면이 나타납니다.
        </p>
        <ul class="flex gap-4 p-1 mb-2 rounded bg-slate-800">
          <template v-if="registSugang.sugangGroupTypeList">
            <li
              v-for="(item, index) in registSugang.sugangGroupTypeList"
              :key="index"
              class="p-2 text-white cursor-pointer"
              :class="{
                'border-b-4 border-solid border-white': registSugang.currentGroupType == index,
              }"
              @click="registSugang.setCurrentGroupType(index)"
            >
              {{ item.groupType }}
            </li>
          </template>
        </ul>
        <ul class="flex gap-4 p-1 mb-2 rounded bg-slate-800">
          <template v-if="registSugang.sugangGroupTypeList[0]">
            <li
              v-for="(item, index) in registSugang.sugangGroupTypeList[0].sugangGroupTimeInfoList"
              :key="index"
              class="p-2 text-white cursor-pointer"
              :class="{
                'border-b-4 border-solid border-white': registSugang.currentGroupTimeInfo == index,
              }"
              @click="registSugang.setCurrentGroupTimeInfo(index)"
            >
              주 {{ item.numberOfClass }}회/총 {{ item.totalNumberOfClass }}회/{{ item.lessonTime }}분
            </li>
          </template>
        </ul>
        <ul class="flex gap-4 p-1 mb-2 rounded bg-slate-800">
          <template v-if="registSugang.sugangGroupTypeList[0]?.sugangGroupTimeInfoList[0]">
            <li
              v-for="(item, index) in registSugang.sugangGroupTypeList[0].sugangGroupTimeInfoList[0].sugangGroupLangList"
              :key="index"
              class="p-2 text-white cursor-pointer"
              :class="{
                'border-b-4 border-solid border-white': registSugang.currentLanguage == index,
              }"
              @click="registSugang.setCurrentLanguage(index)"
            >
              {{ item.language }}
            </li>
          </template>
        </ul>

        <ul class="flex gap-4 p-1 mb-2 rounded bg-slate-800 overflow-auto">
          <template
            v-if="registSugang.sugangGroupTypeList[0]?.sugangGroupTimeInfoList[0]?.sugangGroupLangList[0]?.sugangGroupLevelList"
          >
            <li
              v-for="(item, index) in registSugang.sugangGroupTypeList[0].sugangGroupTimeInfoList[0].sugangGroupLangList[0]
                .sugangGroupLevelList"
              :key="index"
              class="p-2 text-white cursor-pointer"
              :class="{
                'border-b-4 border-solid border-white': registSugang.currentLevel == index,
              }"
              @click="registSugang.setCurrentLevel(index)"
            >
              {{ item.levelName }}
            </li>
          </template>
        </ul>
      </div>
    </div>

    <!-- [그룹] [언어] [레벨] 선택에 따른 수업 등록 / 타입, 언어, 레벨이 모두 선택되어야 함.-->
    <div class="">
      <div class="flex items-center justify-between mb-2">
        <ul
          class="flex gap-2"
          v-if="
            registSugang.currentGroupType > -1 &&
            registSugang.currentGroupTimeInfo > -1 &&
            registSugang.currentLanguage > -1 &&
            registSugang.currentLevel > -1
          "
        >
          <li
            class="p-2 border border-solid rounded cursor-pointer text-slate-800 border-slate-800"
            @click="registSugang.addRow()"
          >
            수업 추가
          </li>
          <li
            class="p-2 border border-solid rounded cursor-pointer text-slate-800 border-slate-800"
            @click="registSugang.deleteRows()"
          >
            선택수업 삭제
          </li>
        </ul>
      </div>
      <table
        class="mb-5"
        v-if="
          registSugang.currentGroupType > -1 &&
          registSugang.currentGroupTimeInfo > -1 &&
          registSugang.currentLanguage > -1 &&
          registSugang.currentLevel > -1
        "
      >
        <thead>
          <tr>
            <th></th>
            <th>그룹유형</th>
            <th>언어</th>
            <th>레벨</th>
            <th>수업</th>
            <th>수업 안내</th>
            <th>교재 소개</th>
            <th>교재비</th>
            <th>교육비</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(item, classIndex) in registSugang.sugangGroupTypeList[registSugang.currentGroupType].sugangGroupTimeInfoList[
              registSugang.currentGroupTimeInfo
            ].sugangGroupLangList[registSugang.currentLanguage].sugangGroupLevelList[registSugang.currentLevel]
              .sugangGroupClassList"
            :key="classIndex"
          >
            <td>
              <input type="radio" :value="classIndex" v-model="registSugang.currentClassListIdx" />
            </td>
            <td>
              {{ registSugang.sugangGroupTypeList[registSugang.currentGroupType].groupType }}
            </td>
            <td>
              {{
                registSugang.sugangGroupTypeList[registSugang.currentGroupType].sugangGroupTimeInfoList[
                  registSugang.currentGroupTimeInfo
                ].sugangGroupLangList[registSugang.currentLanguage].language
              }}
            </td>
            <td>
              {{
                registSugang.sugangGroupTypeList[registSugang.currentGroupType].sugangGroupTimeInfoList[
                  registSugang.currentGroupTimeInfo
                ].sugangGroupLangList[registSugang.currentLanguage].sugangGroupLevelList[registSugang.currentLevel].levelName
              }}
            </td>
            <td>
              <select
                v-model="item.className"
                name=""
                id=""
                class="w-full text-center border border-solid rounded cursor-pointer border-slate-800 max-w-[120px]"
                @change="registSugang.checkDuplicateSelection(item.className, classIndex)"
              >
                <option v-if="!(language === '중국어' || language === '일본어')">데일리토픽</option>
                <option v-if="!(language === '중국어' || language === '일본어')">로이터</option>
                <option v-if="!(language === '중국어' || language === '일본어')">리딩북앤톡</option>
                <option v-if="!(language === '중국어' || language === '일본어')">인터뷰</option>
                <option>일반회화</option>
                <option v-if="!(language === '중국어' || language === '일본어')">커리어</option>
                <option v-if="!(language === '중국어' || language === '일본어')">테드</option>
                <option v-if="!(language === '중국어' || language === '일본어')">토론</option>
                <option v-if="!(language === '중국어' || language === '일본어')">파닉스</option>
              </select>
            </td>
            <td>
              <input
                type="text"
                class="border border-solid rounded border-slate-800 max-w-[150px]"
                v-model="item.classInfo"
                placeholder="수업 안내(URL)"
              />
            </td>
            <td>
              <input
                type="text"
                class="border border-solid rounded border-slate-800 max-w-[150px]"
                v-model="item.bookInfo"
                placeholder="교재 소개(URL)"
              />
            </td>
            <td>
              <input type="text" class="border border-solid rounded border-slate-800 max-w-[150px]" v-model="item.bookPrice" />
            </td>
            <td>
              <input type="text" class="border border-solid rounded border-slate-800 max-w-[150px]" v-model="item.classPrice" />
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- 그룹 개수 설정 -->
    <div class="mb-5">
      <p class="mb-2 text-red-500">* 각 시간마다 생성할 그룹 개수를 설정해주세요.</p>
      <ul class="gap-4 mb-5">
        <li>
          <table
            v-if="
              registSugang.currentGroupType > -1 &&
              registSugang.currentGroupTimeInfo > -1 &&
              registSugang.currentLanguage > -1 &&
              registSugang.currentLevel > -1
            "
            class="w-auto"
          >
            <thead>
              <tr>
                <th class="w-[220px]">수업시작시간</th>
                <th
                  v-for="(item, classIndex) in registSugang.sugangGroupTypeList[registSugang.currentGroupType]
                    .sugangGroupTimeInfoList[registSugang.currentGroupTimeInfo].sugangGroupLangList[registSugang.currentLanguage]
                    .sugangGroupLevelList[registSugang.currentLevel].sugangGroupClassList"
                  :key="classIndex"
                >
                  {{ item.className }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in registSugang.BaseClassRoomTimeList" :key="index">
                <td class="text-sm">
                  {{ item.startTime }} ~
                  {{ item.endTime }}
                </td>
                <td
                  v-for="(item, classIndex) in registSugang.sugangGroupTypeList[registSugang.currentGroupType]
                    .sugangGroupTimeInfoList[registSugang.currentGroupTimeInfo].sugangGroupLangList[registSugang.currentLanguage]
                    .sugangGroupLevelList[registSugang.currentLevel].sugangGroupClassList"
                  :key="classIndex"
                >
                  <input
                    type="text"
                    class="border border-slate-800 border-solid rounded w-full max-w-[80px]"
                    v-model="item.sugangGroupClassRoomList[index].roomSize"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { onMounted, reactive, watch } from "vue";
import axios from "@lifeeduAdmin/plugins/axios.js";

class BaseClassRoomListObject {
  constructor() {
    this.idxLeSugangGroupClass = 0;
    this.idxLeSugangGroupClassRoom = 0;
    this.idxLeSugangGroupTimeInfo = "";
    this.roomSize = 0;
    this.time = "";
    this.startTime = "";
    this.endTime = "";
    this.staffIdx = 0;
  }
}

class BaseClassListObject {
  constructor() {
    this.idxLeSugangGroupClass = 0;
    this.idxLeSugangGroupLevel = 0;
    this.className = "";
    this.classInfo = "";
    this.classPrice = 0;
    this.bookInfo = "";
    this.bookPrice = 0;
    this.staffIdx = 0;
    this.sugangGroupClassRoomList = new Array();
  }
}

class BaseLevelListObject {
  constructor() {
    this.idxLeSugangGroupLang = 0;
    this.idxLeSugangGroupLevel = 0;
    this.levelName = "";
    this.sugangGroupClassList = new Array();
    this.staffIdx = 0;
  }
}

class BaseLangListObject {
  constructor() {
    this.idxLeSugangGroupLang = 0;
    this.idxLeSugangGroupTimeInfo = 0;
    this.idxLeSugangGroupType = 0;
    this.language = "";
    this.sugangGroupLevelList = new Array();
    this.staffIdx = 0;
  }
}

class BaseTimeInfoListObject {
  constructor() {
    this.idxLeSugangGroupTimeInfo = 0;
    this.idxLeSugangGroupType = 0;
    this.lessonTime = 0;
    this.numberOfClass = 0;
    this.sugangGroupLangList = new Array();
    this.staffIdx = 0;
    this.totalNumberOfClass = 0;
  }
}

class BaseTypeListObject {
  constructor() {
    this.idxLeSugangGroupType = 0;
    this.idxLeSugangGroup = 0;
    this.groupType = "";
    this.studentLimitType = "Not Limited";
    this.studentLimit = 0;
    this.studentLimitWait = 0;
    this.sugangGroupTimeInfoList = new Array();
    this.staffIdx = 0;
  }
}

export default {
  name: "GroupClass",
  components: {},
  props: {
    sugangGroupTypeList: {
      type: Object,
    },
  },
  setup(props, { emit }) {
    const registSugang = reactive({
      groupTypeList: [
        {
          groupType: "1:2",
          checked: false,
          studentLimitType: "Not Limited",
          studentLimit: 0,
          studentLimitWait: 0,
          isGroupCode: "",
        },
        {
          groupType: "1:3",
          checked: false,
          studentLimitType: "Not Limited",
          studentLimit: 0,
          studentLimitWait: 0,
          isGroupCode: "",
        },
        {
          groupType: "1:4",
          checked: false,
          studentLimitType: "Not Limited",
          studentLimit: 0,
          studentLimitWait: 0,
          isGroupCode: "",
        },
        {
          groupType: "1:5",
          checked: false,
          studentLimitType: "Not Limited",
          studentLimit: 0,
          studentLimitWait: 0,
          isGroupCode: "",
        },
      ],
      groupTimeInfoList: [],
      languageList: [
        {
          lang: "영어(원어민)",
          checked: false,
        },
        {
          lang: "영어(필리핀)",
          checked: false,
        },
        {
          lang: "중국어",
          checked: false,
        },
        {
          lang: "일본어",
          checked: false,
        },
      ],
      levelList: [
        {
          levelName: "초등(저학년) 입문1",
          checked: false,
        },
        {
          levelName: "초등(저학년) 입문2",
          checked: false,
        },
        {
          levelName: "초등(저학년) 초급1",
          checked: false,
        },
        {
          levelName: "초등(저학년) 초급2",
          checked: false,
        },
        {
          levelName: "초등(저학년) 중급1",
          checked: false,
        },
        {
          levelName: "초등(저학년) 중급2",
          checked: false,
        },
        {
          levelName: "초등(저학년) 고급1",
          checked: false,
        },
        {
          levelName: "초등(저학년) 고급2",
          checked: false,
        },
        {
          levelName: "초등(저학년) 고급3",
          checked: false,
        },
        {
          levelName: "초등(저학년) 고급4",
          checked: false,
        },

        {
          levelName: "초등(고학년) 입문1",
          checked: false,
        },
        {
          levelName: "초등(고학년) 입문2",
          checked: false,
        },
        {
          levelName: "초등(고학년) 초급1",
          checked: false,
        },
        {
          levelName: "초등(고학년) 초급2",
          checked: false,
        },
        {
          levelName: "초등(고학년) 중급1",
          checked: false,
        },
        {
          levelName: "초등(고학년) 중급2",
          checked: false,
        },
        {
          levelName: "초등(고학년) 고급1",
          checked: false,
        },
        {
          levelName: "초등(고학년) 고급2",
          checked: false,
        },
        {
          levelName: "초등(고학년) 고급3",
          checked: false,
        },
        {
          levelName: "초등(고학년) 고급4",
          checked: false,
        },

        {
          levelName: "중등 입문1",
          checked: false,
        },
        {
          levelName: "중등 입문2",
          checked: false,
        },
        {
          levelName: "중등 초급1",
          checked: false,
        },
        {
          levelName: "중등 초급2",
          checked: false,
        },
        {
          levelName: "중등 중급1",
          checked: false,
        },
        {
          levelName: "중등 중급2",
          checked: false,
        },
        {
          levelName: "중등 고급1",
          checked: false,
        },
        {
          levelName: "중등 고급2",
          checked: false,
        },
        {
          levelName: "중등 고급3",
          checked: false,
        },
        {
          levelName: "중등 고급4",
          checked: false,
        },

        {
          levelName: "고등 입문1",
          checked: false,
        },
        {
          levelName: "고등 입문2",
          checked: false,
        },
        {
          levelName: "고등 초급1",
          checked: false,
        },
        {
          levelName: "고등 초급2",
          checked: false,
        },
        {
          levelName: "고등 중급1",
          checked: false,
        },
        {
          levelName: "고등 중급2",
          checked: false,
        },
        {
          levelName: "고등 고급1",
          checked: false,
        },
        {
          levelName: "고등 고급2",
          checked: false,
        },
        {
          levelName: "고등 고급3",
          checked: false,
        },
        {
          levelName: "고등 고급4",
          checked: false,
        },
      ],
      BaseClassRoomTimeList: [],
      checkedLanguageList: [],

      currentGroupType: -1,
      currentGroupTimeInfo: -1,
      currentLanguage: -1,
      currentLevel: -1,
      currentClassListIdx: -1,

      sugangGroupTypeList: props.sugangGroupTypeList,

      checkDuplicateSelection: (selectedOption, classIndex) => {
        // Get the array of selected options from your data
        const selectedOptions = registSugang.sugangGroupTypeList[registSugang.currentGroupType].sugangGroupTimeInfoList[
          registSugang.currentGroupTimeInfo
        ].sugangGroupLangList[registSugang.currentLanguage].sugangGroupLevelList[
          registSugang.currentLevel
        ].sugangGroupClassList.map((item) => item.className);

        // Count the occurrences of the selected option
        const selectedCount = selectedOptions.filter((option) => option === selectedOption).length;

        // Check if the option is selected more than once
        if (selectedCount > 1) {
          alert("해당 옵션은 이미 선택되었습니다.");
          // Reset the selected option to an empty string
          registSugang.sugangGroupTypeList[registSugang.currentGroupType].sugangGroupTimeInfoList[
            registSugang.currentGroupTimeInfo
          ].sugangGroupLangList[registSugang.currentLanguage].sugangGroupLevelList[
            registSugang.currentLevel
          ].sugangGroupClassList[classIndex].className = "";
        }
      },

      setTimeInterval: () => {
        let timeList = Array();
        let date1 = new Date(2023, 1, 1, 5, 30, 0); //기준 날짜를 지정.
        let date2 = new Date(2023, 1, 1, 6, 0, 0); //기준 날짜를 지정.
        const interval = 30;

        //6시부터 23시까지 반복한다. 36회
        for (let i = 0; i < 36; i++) {
          date1.setMinutes(date1.getMinutes() + interval); //현재
          let sTime = ("0" + date1.getHours()).slice(-2) + ":" + ("0" + date1.getMinutes()).slice(-2);
          date2.setMinutes(date2.getMinutes() + interval); //30분 뒤
          let eTime = ("0" + date2.getHours()).slice(-2) + ":" + ("0" + date2.getMinutes()).slice(-2);
          let TimeObj = new BaseClassRoomListObject();
          TimeObj.time = sTime;
          TimeObj.startTime = sTime;
          TimeObj.endTime = eTime;
          timeList.push(TimeObj);
        }
        return timeList;
      },

      //선택된 그룹(타입), 언어, 레벨 값을 가져오고
      //이를 토대로 리스트를 재구성한다.

      groupTypeChecked: (checkedTF, groupType) => {
        console.log("groupTypeChecked", checkedTF, groupType);
        if (checkedTF) {
          //그룹유형이 추가된 경우에는 sugangGroupTypeList 에 오브젝트를 추가해주고,
          registSugang.addBaseGroupTypeList(groupType);
        } else {
          //그룹유형이 제거된 경우에는 sugangGroupTypeList 에 오브젝트를 제거한다.
          if (confirm("선택을 해제하면 설정된 그룹유형의 수업이 제거됩니다. 선택을 해제하시겠습니까?")) {
            //해제한다.
            registSugang.removeGroupTypeList(groupType);
          } else {
            //해제하지 않는다.
            checkedTF = true;
            registSugang.groupTypeList.forEach((item) => {
              if (item.groupType == groupType) item.checked = true;
            });
            console.log("sugangGroupTypeList", registSugang.sugangGroupTypeList);
          }
        }
      },

      studentLimitTypeChecked: (groupTypeItem) => {
        //입력한 값을 sugangGroupTypeList 와 맞춰준다.
        console.log("studentLimitTypeChecked", groupTypeItem.studentLimitType, groupTypeItem.groupType);
        if (groupTypeItem.studentLimitType == "Not Limited") {
          //제한없음인 경우, 선착순 대기자 수를 0명으로 변경한다.
          groupTypeItem.studentLimit = 0;
          groupTypeItem.studentLimitWait = 0;
        }
        registSugang.sugangGroupTypeList.forEach((item) => {
          if (item.groupType == groupTypeItem.groupType) {
            item.studentLimitType = groupTypeItem.studentLimitType;
            item.studentLimit = groupTypeItem.studentLimit;
            item.studentLimitWait = groupTypeItem.studentLimitWait;
          }
        });
        console.log("sugangGroupTypeList", registSugang.sugangGroupTypeList);
      },

      studentLimitInput: (studentLimit, groupType) => {
        //입력한 값을 sugangGroupTypeList 와 맞춰준다.
        console.log("studentLimitInput", studentLimit, groupType);
        registSugang.sugangGroupTypeList.forEach((item) => {
          if (item.groupType == groupType) item.studentLimit = studentLimit;
        });
        console.log("sugangGroupTypeList", registSugang.sugangGroupTypeList);
      },

      studentLimitWaitInput: (studentLimitWait, groupType) => {
        //입력한 값을 sugangGroupTypeList 와 맞춰준다.
        console.log("studentLimitWaitInput", studentLimitWait, groupType);
        registSugang.sugangGroupTypeList.forEach((item) => {
          if (item.groupType == groupType) item.studentLimitWait = studentLimitWait;
        });
        console.log("sugangGroupTypeList", registSugang.sugangGroupTypeList);
      },

      checkCtmGroupCode(isGroupCode, groupType) {
        if (!isGroupCode) {
          alert("그룹 과정코드를 입력하세요.");
          return;
        }
        axios.get(`/admin/ctmCode?idxCtmB2b=${isGroupCode}`).then((res) => {
          if (res.data.success) {
            alert("코드가 존재합니다.");
            registSugang.sugangGroupTypeList.forEach((item) => {
              if (item.groupType == groupType) {
                item.isGroupCode = isGroupCode;
              }
            });
          } else {
            alert("코드가 존재하지 않습니다.");
          }
        });
      },

      addBaseGroupTypeList: (groupType) => {
        let bo = new BaseTypeListObject();
        bo.groupType = groupType;

        // 현재 입력되어있는 수업 횟수를 추가
        registSugang.groupTimeInfoList.forEach((item) => {
          let TimeInfoBo = new BaseTimeInfoListObject();
          TimeInfoBo.idxLeSugangGroupType = bo.idxLeSugangGroupType;
          TimeInfoBo.lessonTime = item.lessonTime; // 기존의 lessonTime 값을 복사
          TimeInfoBo.numberOfClass = item.numberOfClass; // 기존의 numberOfClass 값을 복사
          TimeInfoBo.totalNumberOfClass = item.totalNumberOfClass; // 기존의 totalNumberOfClass 값을 복사
          registSugang.languageList.forEach((langItem) => {
            if (langItem.checked == true) {
              let langBo = new BaseLangListObject();
              langBo.idxLeSugangGroupType = bo.idxLeSugangGroupType;
              langBo.language = langItem.lang;
              registSugang.levelList.forEach((level) => {
                if (level.checked) {
                  let LevelBo = new BaseLevelListObject();
                  LevelBo.idxLeSugangGroupLang = 0;
                  LevelBo.idxLeSugangGroupLevel = 0;
                  LevelBo.levelName = level.levelName;
                  langBo.sugangGroupLevelList.push(LevelBo);
                }
              });
              TimeInfoBo.sugangGroupLangList.push(langBo);
            }
          });
          bo.sugangGroupTimeInfoList.push(TimeInfoBo);
        });

        registSugang.sugangGroupTypeList.push(bo);
        console.log("addBaseGroupTypeList", registSugang.sugangGroupTypeList);
      },

      removeGroupTypeList: (groupType) => {
        let filterIdx = -1;
        let list = registSugang.sugangGroupTypeList.filter((o, index) => {
          if (o.groupType !== groupType) {
            return o;
          } else {
            filterIdx = index;
          }
        });
        registSugang.sugangGroupTypeList = list;
        //삭제시에 인덱스를 받고, 현재 인덱스 값이 이보다 같거나 크다면 -1을 해주고, 작으면 그대로 둔다.
        if (registSugang.currentGroupType >= filterIdx) registSugang.currentGroupType = registSugang.currentGroupType - 1;
        if (registSugang.sugangGroupTypeList.length == 0) registSugang.currentGroupType = -1;

        console.log("remove sugangGroupTypeList groupType", groupType);
        console.log("remove sugangGroupTypeList list", registSugang.sugangGroupTypeList);
      },

      changeLessonTime: (lessonTime, index) => {
        //수업횟수/시간에서 lessonTime 값을 수정하는 경우, registSugang.sugangGroupTypeList 를 순회하며 해당 인덱스의 값을 수정해주어야 한다.
        registSugang.sugangGroupTypeList.forEach((groupType) => {
          groupType.sugangGroupTimeInfoList.forEach((item, idx) => {
            if (idx == index) item.lessonTime = lessonTime;
          });
        });
      },
      changeTotalNumberOfClass: (totalNumberOfClass, index) => {
        //수업횟수/시간에서 totalNumberOfClass 값을 수정하는 경우, registSugang.sugangGroupTypeList 를 순회하며 해당 인덱스의 값을 수정해주어야 한다.
        registSugang.sugangGroupTypeList.forEach((groupType) => {
          groupType.sugangGroupTimeInfoList.forEach((item, idx) => {
            if (idx == index) item.totalNumberOfClass = totalNumberOfClass;
          });
        });
      },
      changeNumberOfClass: (numberOfClass, index) => {
        //수업횟수/시간에서 numberOfClass 값을 수정하는 경우, registSugang.sugangGroupTypeList 를 순회하며 해당 인덱스의 값을 수정해주어야 한다.
        registSugang.sugangGroupTypeList.forEach((groupType) => {
          groupType.sugangGroupTimeInfoList.forEach((item, idx) => {
            if (idx == index) item.numberOfClass = numberOfClass;
          });
        });
      },

      addGroupTimeInfo: () => {
        //화면에 보여지는 부분을 위해 추가해준다.
        let bo = new BaseTimeInfoListObject();
        registSugang.groupTimeInfoList.push(bo);
        //존재하는 트리구조에 맞추어 추가해준다.
        //registSugang.sugangGroupTypeList
        console.log("addGroupTimeInfo");
        registSugang.sugangGroupTypeList.forEach((groupType) => {
          console.log("groupType ", groupType);
          let bo = new BaseTimeInfoListObject();
          bo.idxLeSugangGroupType = groupType.idxLeSugangGroupType;

          //현재 선택되어있는 언어를 추가한다.
          console.log("registSugang ", registSugang);
          registSugang.languageList.forEach((item) => {
            console.log("languageList ", item);
            if (item.checked == true) {
              let langBo = new BaseLangListObject();
              langBo.idxLeSugangGroupType = bo.idxLeSugangGroupType;
              langBo.language = item.lang;
              //현재 선택되어있는 레벨을 추가한다.
              registSugang.levelList.forEach((level) => {
                console.log("levelList ", level);
                if (level.checked) {
                  let LevelBo = new BaseLevelListObject();
                  LevelBo.idxLeSugangGroupLang = 0;
                  LevelBo.idxLeSugangGroupLevel = 0;
                  LevelBo.levelName = level.levelName;
                  langBo.sugangGroupLevelList.push(LevelBo);
                }
              });
              bo.sugangGroupLangList.push(langBo);
            }
          });
          //클래스는 초기 빈 배열이다.
          groupType.sugangGroupTimeInfoList.push(bo);
        });
        console.log("addBaseGroupTypeList", registSugang.sugangGroupTypeList);
      },

      removeGroupTimeInfo: (index, item) => {
        //수업횟수/시간에서 삭제하는 경우, registSugang.sugangGroupTypeList 를 순회하며 해당 인덱스의 오브젝트를 제거해주어야 한다.
        registSugang.sugangGroupTypeList.forEach((groupType) => {
          groupType.sugangGroupTimeInfoList.splice(index, 1);
        });
        registSugang.groupTimeInfoList.splice(index, 1);

        //삭제시에 인덱스를 받고, 현재 인덱스 값이 이보다 같거나 크다면 -1을 해주고, 작으면 그대로 둔다.
        if (registSugang.currentGroupTimeInfo >= index) registSugang.currentGroupType = registSugang.currentGroupType - 1;
        if (registSugang.groupTimeInfoList.length == 0) registSugang.currentGroupType = -1;
      },

      languageChecked: (checkedTF, lang) => {
        console.log("languageChecked", checkedTF, lang);

        if (checkedTF) {
          //언어가 추가된 경우에는 sugangOneLangList 에 오브젝트를 추가해주고,
          registSugang.addBaseLangList(lang);
        } else {
          //언어가 제거된 경우에는 sugangOneLangList 에 오브젝트를 제거한다.
          if (confirm("선택을 해제하면 설정된 수업이 제거됩니다. 선택을 해제하시겠습니까?")) {
            //해제한다.
            registSugang.removeLangList(lang);
          } else {
            //해제하지 않는다.
            checkedTF = true;
            registSugang.languageList.forEach((item) => {
              if (item.lang == lang) item.checked = true;
            });
          }
        }
      },

      addBaseLangList: (lang) => {
        //모든 선택된 타입마다 언어를 추가해준다.
        console.log("registSugang.sugangGroupTypeList", registSugang.sugangGroupTypeList);
        registSugang.sugangGroupTypeList.forEach((groupTypeList) => {
          groupTypeList.sugangGroupTimeInfoList.forEach((timeInfoList) => {
            ////////////////
            let bo = new BaseLangListObject();
            bo.idxLeSugangGroupType = timeInfoList.idxLeSugangGroupType;
            bo.language = lang;

            //다른 선택된 레벨이 있으면 언어도 추가해준다.
            registSugang.levelList.forEach((level) => {
              if (level.checked) {
                let LevelBo = new BaseLevelListObject();
                LevelBo.idxLeSugangGroupLang = 0;
                LevelBo.idxLeSugangGroupLevel = 0;
                LevelBo.levelName = level.levelName;
                bo.sugangGroupLevelList.push(LevelBo);
              }
            });

            timeInfoList.sugangGroupLangList.push(bo);
            ////////////////
          });
        });
        console.log("addBaseLangList", registSugang.sugangGroupTypeList);
      },

      removeLangList: (lang) => {
        //모든 선택된 타입마다 언어를 제거해준다.
        registSugang.sugangGroupTypeList.forEach((groupTypeList) => {
          groupTypeList.sugangGroupTimeInfoList.forEach((timeInfoList) => {
            let filterIdx = -1;
            let list = timeInfoList.sugangGroupLangList.filter((o, index) => {
              if (o.language !== lang) {
                filterIdx = index;
                return o;
              }
            });
            timeInfoList.sugangGroupLangList = list;
            //삭제시에 인덱스를 받고, 현재 인덱스 값이 이보다 같거나 크다면 -1을 해주고, 작으면 그대로 둔다.
            if (registSugang.currentLanguage >= filterIdx) registSugang.currentLanguage = registSugang.currentLanguage - 1;
            if (timeInfoList.sugangGroupLangList.length == 0) registSugang.currentLanguage = -1;
          });
        });
        console.log("removeLangList lang", lang);
        console.log("removeLangList list", registSugang);
      },

      levelChecked: (checkedTF, levelName) => {
        console.log("levelChecked", checkedTF, levelName);

        if (checkedTF) {
          //언어가 추가된 경우에는 sugangOneLangList 에 오브젝트를 추가해주고,
          registSugang.addBaselevelList(levelName);
        } else {
          //언어가 제거된 경우에는 sugangOneLangList 에 오브젝트를 제거한다.
          if (confirm("선택을 해제하면 설정된 수업이 제거됩니다. 선택을 해제하시겠습니까?")) {
            //해제한다.
            registSugang.removeLevelList(levelName);
          } else {
            //해제하지 않는다.
            checkedTF = true;
            registSugang.levelList.forEach((item) => {
              if (item.levelName == levelName) item.checked = true;
            });
          }
        }
      },

      addBaselevelList: (levelName) => {
        //모든 선택된 타입의 언어마다 레벨를 추가해준다.
        registSugang.sugangGroupTypeList.forEach((groupTypeList) => {
          groupTypeList.sugangGroupTimeInfoList.forEach((timeInfoList) => {
            timeInfoList.sugangGroupLangList.forEach((langList) => {
              let bo = new BaseLevelListObject();
              bo.idxLeSugangGroupLang = langList.idxLeSugangGroupLang;
              bo.idxLeSugangGroupLevel = 0;
              bo.levelName = levelName;
              langList.sugangGroupLevelList.push(bo);
            });
          });
        });
        console.log("addBaselevelList", registSugang.sugangGroupTypeList);
      },

      removeLevelList: (levelName) => {
        let filterIdx = -1;
        let list = registSugang.sugangGroupTypeList.filter((o, index) => {
          if (o.levelName !== levelName) {
            return o;
          } else {
            filterIdx = index;
          }
        });
        registSugang.sugangGroupTypeList = list;
        //삭제시에 인덱스를 받고, 현재 인덱스 값이 이보다 같거나 크다면 -1을 해주고, 작으면 그대로 둔다.
        if (registSugang.currentLevel >= filterIdx) registSugang.currentLevel = registSugang.currentLevel - 1;
        if (registSugang.sugangGroupTypeList.length == 0) registSugang.currentLevel = -1;

        //모든 선택된 타입의 언어마다 레벨를 제거해준다.
        registSugang.sugangGroupTypeList.forEach((groupTypeList) => {
          groupTypeList.sugangGroupTimeInfoList.forEach((timeInfoList) => {
            timeInfoList.sugangGroupLangList.forEach((langList) => {
              let list = langList.sugangGroupLevelList.filter((o) => o.levelName !== levelName);
              langList.sugangGroupLevelList = list;
              if (langList.sugangGroupLevelList.length == 0) registSugang.currentLevel = -1;
            });
          });
        });
        console.log("removeLangList lang", levelName);
        console.log("removeLangList list", registSugang.sugangGroupTypeList);
      },

      setCurrentGroupType: (val) => {
        registSugang.currentGroupType = val;
      },
      setCurrentGroupTimeInfo: (val) => {
        registSugang.currentGroupTimeInfo = val;
      },

      setCurrentLanguage: (val) => {
        registSugang.currentLanguage = val;
      },

      setCurrentLevel: (val) => {
        registSugang.currentLevel = val;
      },

      getSugangOneLangList: (lang) => {
        let resultList = registSugang.sugangOneLangList.find(function whatClass(item) {
          if (item.language == lang) return true;
        });
        return resultList.sugangOneClassList;
      },

      addRow: () => {
        let bo = new BaseClassListObject();
        //클래스를 추가할 때에는 수업을 함께 추가해준다.
        let timeList = registSugang.setTimeInterval();
        bo.sugangGroupClassRoomList = timeList;

        bo.idxLeSugangGroupLevel =
          registSugang.sugangGroupTypeList[registSugang.currentGroupType].sugangGroupTimeInfoList[
            registSugang.currentGroupTimeInfo
          ].sugangGroupLangList[registSugang.currentLanguage].sugangGroupLevelList[
            registSugang.currentLevel
          ].idxLeSugangGroupLevel;

        registSugang.sugangGroupTypeList[registSugang.currentGroupType].sugangGroupTimeInfoList[
          registSugang.currentGroupTimeInfo
        ].sugangGroupLangList[registSugang.currentLanguage].sugangGroupLevelList[
          registSugang.currentLevel
        ].sugangGroupClassList.push(bo);

        console.log(
          registSugang.sugangGroupTypeList[registSugang.currentGroupType].sugangGroupTimeInfoList[
            registSugang.currentGroupTimeInfo
          ].sugangGroupLangList[registSugang.currentLanguage].sugangGroupLevelList[registSugang.currentLevel].sugangGroupClassList
        );
      },

      deleteRows: () => {
        if (registSugang.currentClassListIdx > -1) {
          registSugang.sugangGroupTypeList[registSugang.currentGroupType].sugangGroupTimeInfoList[
            registSugang.currentGroupTimeInfo
          ].sugangGroupLangList[registSugang.currentLanguage].sugangGroupLevelList[
            registSugang.currentLevel
          ].sugangGroupClassList.splice(registSugang.currentClassListIdx, 1);
        } else {
          alert("삭제할 수업을 선택해주세요");
        }
        console.log(registSugang.currentClassListIdx);
      },
      init: () => {
        registSugang.BaseClassRoomTimeList = registSugang.setTimeInterval();
        console.log("BaseClassRoomTimeList", registSugang.BaseClassRoomTimeList);
        //그룹유형, 수강언어, 레벨 리스트들의 선택값을 체크해준다.
        console.log("registSugang.sugangGroupTypeList", registSugang.sugangGroupTypeList);
        if (props.sugangGroupTypeList) {
          registSugang.sugangGroupTypeList = props.sugangGroupTypeList;
          registSugang.sugangGroupTypeList.forEach((groupListItem, index) => {
            //groupListItem.groupType 값이 groupTypeList 에 존재한다면 true로 변경한다.
            registSugang.groupTypeList.forEach((item) => {
              if (item.groupType == groupListItem.groupType) {
                item.checked = true;
                item.isGroupCode = groupListItem.isGroupCode;
                item.studentLimitType = groupListItem.studentLimitType;
                item.studentLimit = groupListItem.studentLimit;
                item.studentLimitWait = groupListItem.studentLimitWait;
              }
            });
            console.log("registSugang.groupTypeList", registSugang.groupTypeList);
            //sugangGroupTimeInfo 를 별도로 내려주지 않으므로 함께 생성해준다.

            groupListItem.sugangGroupTimeInfoList.forEach((groupTimeInfoItem, index) => {
              //퍼스트 차일드를 순회할 때 수업횟수.시간 정보를 가져와서 중복은 제외하고 화면에 보여지는 부분을 설정한다.
              if (
                registSugang.groupTimeInfoList.findIndex(
                  (item) =>
                    item.lessonTime === groupTimeInfoItem.lessonTime &&
                    item.numberOfClass === groupTimeInfoItem.numberOfClass &&
                    item.totalNumberOfClass === groupTimeInfoItem.totalNumberOfClass
                ) < 0
              ) {
                registSugang.groupTimeInfoList = [...registSugang.groupTimeInfoList, ...[groupTimeInfoItem]];
              }
              groupTimeInfoItem.sugangGroupLangList.forEach((langListItem, index) => {
                //langListItem.language 값이 language 에 존재한다면 true로 변경한다.
                registSugang.languageList.forEach((item) => {
                  if (item.lang == langListItem.language) {
                    item.checked = true;
                  }
                });

                langListItem.sugangGroupLevelList.forEach((levelListItem, index) => {
                  //levelListItem.levelName 값이 levelList 에 존재한다면 true로 변경한다.
                  registSugang.levelList.forEach((item) => {
                    if (item.levelName == levelListItem.levelName) {
                      item.checked = true;
                    }
                  });
                  //levelListItem 이 존재한다면 sugangGroupClassList[n].sugangGroupClassRoomList[n] 의 모든 시간대별 값이 존재해야 한다.
                  //timeList = registSugang.setTimeInterval();
                  levelListItem.sugangGroupClassList.forEach((classListItem, index) => {
                    let timeList = registSugang.setTimeInterval();
                    let map = new Map();
                    timeList.forEach((item) => map.set(item.time, item));
                    classListItem.sugangGroupClassRoomList.forEach((item) =>
                      map.set(item.time, {
                        ...map.get(item.time),
                        ...item,
                      })
                    );
                    classListItem.sugangGroupClassRoomList = Array.from(map.values());
                    console.log(classListItem.sugangGroupClassRoomList);
                  });
                });
              });
            });
            console.log(registSugang.groupTimeInfoList);
          });
        }
      },
    });

    onMounted(() => {
      //키 목록을 가져온다.
      registSugang.init();
    });

    watch(
      () => props.sugangGroupTypeList,
      (cur, prev) => {
        console.log("props.sugangGroupTypeList");
        registSugang.sugangGroupTypeList = cur;
        registSugang.init();
      }
    );

    watch(
      () => registSugang.sugangGroupTypeList,
      (cur, prev) => {
        console.log("set-regist-sugang-data", cur);
        // 이벤트를 통해 부모로 데이터셋을 전달.
        emit("set-regist-sugang-data", cur); // 일단 주석처리.
      }
    );

    return {
      registSugang,
    };
  },
};
</script>

<style></style>
