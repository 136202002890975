<template>
  <editor
    v-model="comp.content"
    @change="comp.update"
    :automatic_uploads="true"
    :api-key="comp.tinykey"
    :init="comp.init"
    id="bagic_editor"
    class="reum-editor"
  ></editor>
</template>

<script>
// @ is an alias to /src
import { onMounted, reactive, watch } from "vue";
import Editor from "@tinymce/tinymce-vue";
import axios from "@lifeeduAdmin/plugins/axios.js";

export default {
  name: "BagicEditor",
  components: {
    Editor,
  },
  props: {
    modelValue: {
      type: String,
      default: "",
    },
    etype: {
      type: String,
      default: "temp",
    },
  },
  emits: ["update:modelValue"],
  setup(props, { emit }) {
    const comp = reactive({
      tinykey: "npm2kdqn3rdepr2bmmeqrtrkeucbh7yu3jb0jjhbu2bq1au6",
      etype: "",
      content: "",

      init: {
        selector: "#bagic_editor", // change this value according to your HTML
        menubar: false,
        plugins:
          "preview importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap pagebreak nonbreaking anchor insertdatetime advlist lists wordcount help charmap  emoticons",
        // quickbars 이미지 업로드 퀵바
        // editimage_cors_hosts: ['picsum.photos'],
        // menubar: 'file edit view insert format tools table help',
        toolbar:
          "undo redo | bold italic underline strikethrough | fontfamily fontsize blocks | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample | ltr rtl",
        // toolbar_sticky: true,
        // toolbar_sticky_offset: isSmallScreen ? 102 : 108,
        // autosave_ask_before_unload: true,
        // autosave_interval: '30s',
        // autosave_prefix: '{path}{query}-{id}-',
        // autosave_restore_when_empty: false,
        // autosave_retention: '2m',
        // image_advtab: true,
        image_title: true,
        automatic_uploads: true,
        file_picker_types: "image",
        file_picker_callback: function (cb) {
          let input = document.createElement("input");
          input.setAttribute("type", "file");
          input.setAttribute("accept", "image/*");
          input.onchange = function () {
            let file = this.files[0];

            if (file) {
              var formData = new FormData();
              formData.append("file", file);
              formData.append("etype", comp.etype);
              axios
                .post("/admin/upload", formData, {
                  // headers: {
                  //     "Content-Type": "multipart/form-data",
                  // },
                })
                .then((res) => {
                  if (res.data.success) {
                    const { data } = res.data;
                    const { uploadUrl, fileName } = data;
                    cb(uploadUrl, {
                      title: fileName,
                    });
                  } else {
                    alert(res.data.err_msg);
                  }
                });
            }
          };

          input.click();
        },
      },

      update: () => {
        emit("update:modelValue", comp.content);
      },
    });

    watch(
      () => props.modelValue,
      (nVal, oVal) => {
        if (nVal != oVal) {
          comp.content = nVal;
        }
      }
    );

    onMounted(() => {
      comp.content = props.modelValue;
      comp.etype = props.etype;
    });

    return { comp };
  },
};
</script>
<style lang="scss" scoped>
.reum-editor {
  width: 100%;
  height: 100%;
}
</style>
