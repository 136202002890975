<template>
  <div>
    <section class="pt-[55px] pb-[70px] pc:py-[140px] pc-con-wrapper1">
      <p class="hidden text-lg font-bold text-center pc:text-5xl text-neutral-950 mb-11 pc:block">아이디/비밀번호 찾기</p>
      <div class="px-5 border border-solid py-7 pc:py-11 border-neutral-200 rounded-2xl">
        <div class="max-w-xs mx-auto">
          <ul class="flex flex-col mb-7 pc:mb-11 gap-11">
            <li class="flex flex-col gap-4">
              <label for="userName" class="block text-sm font-bold pc:text-lg text-neutral-950">회원 이름</label>
              <input
                type="text"
                id="userName"
                placeholder="회원 이름을 입력해 주세요."
                class="p-4 text-sm font-bold border border-solid rounded border-neutral-200 text-neutral-950 placeholder:text-neutral-200 placeholder:font-light pc:text-base"
                v-model="findIdInfo.userInfo.userName"
              />
            </li>
            <li class="flex flex-col gap-4">
              <label for="userPhone" class="block text-sm font-bold pc:text-lg text-neutral-950">휴대폰 번호</label>
              <input
                type="tel"
                id="userPhone"
                placeholder="휴대폰 번호를 입력해 주세요."
                class="p-4 text-sm font-bold border border-solid rounded border-neutral-200 text-neutral-950 placeholder:text-neutral-200 placeholder:font-light pc:text-base"
                v-model="findIdInfo.userInfo.userMobile"
                @keydown.enter="findIdInfo.getFindMemberId()"
                @input="findIdInfo.formatMobile"
              />
            </li>
          </ul>
          <p
            class="py-4 text-sm font-bold text-center text-white bg-orange-500 rounded cursor-pointer pc:py-6 pc:text-xl mb-11"
            @click="findIdInfo.getFindMemberId()"
          >
            아이디 찾기
          </p>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import { reactive, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import axios from "@lifeeduWeb/plugins/axios.js";
import { useStore } from "vuex";

export default {
  components: {},
  setup() {
    const router = useRouter();
    const route = useRoute();
    const store = useStore();

    const findIdInfo = reactive({
      userInfo: {
        userName: "",
        userMobile: "",
        userEmail: "",
      },

      getFindMemberId() {
        const params = {
          userName: findIdInfo.userInfo.userName,
          userMobile: findIdInfo.userInfo.userMobile,
        };
        axios.get(`/user/auth/getFindMemberId`, { params }).then(function (res) {
          if (res.data.success === true) {
            findIdInfo.userInfo.userEmail = res.data.data.userEmail;
            router.push({
              name: "findPw",
              query: { userEmail: res.data.data.userEmail },
            });
          } else {
            alert("존재하지 않는 회원정보 입니다. 다시 확인해 주세요.");
          }
        });
      },

      formatMobile() {
        let input = findIdInfo.userInfo.userMobile;

        // 정규식을 사용하여 숫자만 추출
        let numericValue = input.replace(/\D/g, "");

        // 하이픈 추가
        let formattedValue = numericValue.replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3");

        // 최대 길이를 초과하지 않도록 조정
        findIdInfo.userInfo.userMobile = formattedValue.slice(0, 13);
      },
    });

    return {
      findIdInfo,
    };
  },
};
</script>
<style scoped></style>
