<template>
  <div class="top-0 left-0 absolute w-full h-full flex items-center justify-center bg-black bg-opacity-10 z-[2]">
    <span class="loader"></span>
  </div>
</template>

<style scoped>
.loader {
  width: 48px;
  height: 48px;
  border: 5px solid #c4c4c4;
  border-bottom-color: #396cf1;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
