<template>
    <div class="flex items-center w-64 gap-3">
        <vue-tailwind-datepicker
            i18n="ko"
            v-model="datePicker.dateValue"
            :formatter="formatter"
            :placeholder="datePicker.placeholder"
            input-classes="cursor-pointer "
            readonly
            overlay
            as-single
            class="rounded"
        >
        </vue-tailwind-datepicker>
    </div>
</template>
<script>
import { watch, reactive, onMounted, ref } from "vue";

export default {
    name: "BagicDatePickerEndDate",
    components: {},
    props: ["endDate"],
    setup(props, { emit }) {
        const formatter = {
            date: "YYYY-MM-DD",
            month: "MM",
        };

        const datePicker = reactive({
            dateValue: ref([]),
            placeholder: "기간을 선택해주세요",

            setPlaceholderDate(endDate) {
                if (endDate != "") {
                    const [datePart, timePart] = endDate.split(" ");
                    datePicker.placeholder = datePart;
                    // datePicker.placeholder = endDate;
                } else {
                    datePicker.placeholder = "기간을 선택해주세요";
                }
            },
        });
        onMounted(() => {
            //placeholder 를 통해 초기값을 설정한다.
            datePicker.setPlaceholderDate(props.endDate);
        });
        watch(
            () => datePicker.dateValue,
            (nVal, oVal) => {
                if (nVal.length > 0) {
                    // 날짜값이 선택되었으므로 커스텀 이벤트를 발생시킨다.
                    emit("date-click", datePicker.dateValue);
                } else {
                }
            }
        );
        watch(
            () => props.endDate,
            (nVal, oVal) => {
                //props.endDate 값이 바뀌었다면 placeholder 값을 변경한다.
                datePicker.setPlaceholderDate(props.endDate);
            }
        );

        return {
            datePicker,
            formatter,
        };
    },
};
</script>
