<template>
  <!-- table -->
  <table
    class="w-full mb-4 border-collapse border-spacing-0"
    v-if="Array.isArray(bTable.tableHeaderKeys) && bTable.tableHeaderKeys.length > 0"
  >
    <thead>
      <tr>
        <!-- <th v-if="enableCheckBox === true" class="p-1 font-bold text-center bg-red-500 border border-gray-700"> -->
        <th
          v-if="enableCheckBox === true && bTable.tableList.length > 0"
          class="p-1 font-bold text-center border border-gray-700"
        >
          <input type="checkbox" class="cursor-pointer" v-model="allSelected" />
        </th>
        <th class="p-1 font-bold text-center border border-gray-700" v-for="(item, i) in bTable.tableHeaderKeys" :key="i">
          <!-- v-bind:key="i" -->
          {{ bTable.tableHeader[item] }}
        </th>
      </tr>
    </thead>
    <tbody v-if="Array.isArray(bTable.tableList) && bTable.tableList.length > 0">
      <tr v-for="(item, i) in bTable.tableList" v-bind:key="i">
        <td v-if="enableCheckBox === true" class="p-1 text-center border border-gray-700">
          <input
            type="checkbox"
            class="cursor-pointer"
            :value="item[bTable.checkParamName]"
            v-model="bTable.checkBoxList"
            @change="bTable.checkBoxClick()"
          />
        </td>
        <td
          v-for="(keyname, i) in bTable.tableHeaderKeys"
          v-bind:key="i"
          class="p-1 text-center border border-gray-700"
          :class="{
            'cursor-pointer underline': keyname === bTable.detailRowName,
          }"
          @click="bTable.detailClick(keyname, bTable.detailRowName, item[bTable.detailParamName])"
        >
          <p v-html="item[keyname]"></p>
        </td>
      </tr>
    </tbody>
    <tbody v-else>
      <td
        class="p-1 text-center border border-gray-700"
        :colspan="enableCheckBox === true ? bTable.tableHeaderKeys.length + 1 : bTable.tableHeaderKeys.length"
      >
        조회된 결과가 없습니다.
      </td>
    </tbody>
  </table>
  <div class="items-center justify-center text-center">
    <BagicPaging
      :totalCnt="bTable.totalRows"
      :currentPage="bTable.currentPage"
      :pageSize="bTable.pageSize"
      @paging-click="bTable.movePage"
    ></BagicPaging>
  </div>
</template>
<script>
import { watch, reactive, onMounted, computed } from "vue";
import BagicPaging from "@lifeeduAdmin/components/BagicPaging.vue";

export default {
  name: "BagicTable",
  emits: ["pagingClick", "checkClick"],
  components: {
    BagicPaging,
  },
  props: [
    "header",
    "list",
    "pageSize",
    "totalRows",
    "currentPage",
    "detailRowName",
    "detailParamName",
    "enableCheckBox",
    "checkParamName",
  ],
  setup(props, { emit }) {
    const bTable = reactive({
      tableHeaderKeys: [],
      tableHeader: [],
      tableList: [],
      currentPage: 1,
      totalRows: 1,
      pageSize: 1,
      detailRowName: "",
      detailParamName: "",
      enableCheckBox: false,
      checkParamName: "",
      checkBoxList: [],

      movePage(value) {
        bTable.currentPage = value;
        emit("paging-click", bTable.currentPage);
      },
      detailClick(keyNameCurrent, keyNameTarget, value) {
        if (keyNameCurrent === "supDocument" && value) {
          window.open(value, "_blank");
        } else if (keyNameCurrent === keyNameTarget) {
          emit("detail-click", value);
        }
      },
      checkBoxClick() {
        emit("check-click", bTable.checkBoxList);
        console.log(bTable.checkBoxList);
      },
    });
    const allSelected = computed({
      get() {
        return bTable.tableList.length === bTable.checkBoxList.length;
      },
      set(value) {
        if (value) {
          bTable.tableList.forEach(function (row) {
            bTable.checkBoxList.push(row[bTable.checkParamName]);
          });
        } else {
          bTable.checkBoxList = [];
        }
        emit("check-click", bTable.checkBoxList);
        //console.log(bTable.checkBoxList);
      },
    });

    onMounted(() => {
      //키 목록을 가져온다.
      if (Object.keys(props.header).length > 0) {
        bTable.tableHeader = props.header;
        bTable.tableHeaderKeys = Object.keys(props.header);
        bTable.tableList = props.list;
        bTable.totalRows = props.totalRows;
        bTable.pageSize = props.pageSize;
        bTable.detailRowName = props.detailRowName;
        bTable.detailParamName = props.detailParamName;
        bTable.enableCheckBox = props.enableCheckBox;
        bTable.checkParamName = props.checkParamName;
        console.log("마운트");
        // console.log(bTable.tableHeader);
      }
    });

    watch(
      () => props.header,
      (cur, prev) => {
        bTable.tableHeader = cur;
        bTable.tableHeaderKeys = Object.keys(cur);
      }
    );
    watch(
      () => props.list,
      (cur, prev) => {
        bTable.tableList = cur;
        bTable.checkBoxList = [];
      }
    );
    watch(
      () => props.pageSize,
      (cur, prev) => {
        bTable.pageSize = cur;
      }
    );
    watch(
      () => props.totalRows,
      (cur, prev) => {
        bTable.totalRows = cur;
        console.log("bTable.totalRows : ", bTable.totalRows);
      }
    );

    return {
      close,
      bTable,
      allSelected,
    };
  },
};
</script>
