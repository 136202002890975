<template>
  <div>
    <div class="mb-5">
      <p class="pb-2 mb-2 text-lg font-semibold border-b-2 border-solid border-slate-800">{{ adultText }} 수강 언어 선택</p>
      <ul class="flex flex-wrap gap-4">
        <li v-for="(item, index) in registSugang.languageList" :key="index" class="flex items-center gap-1">
          <input
            type="checkbox"
            @change="registSugang.languageChecked(item.checked, item.lang)"
            :id="adult + '_' + item.lang"
            v-model="item.checked"
          />
          <label class="cursor-pointer" :for="adult + '_' + item.lang">{{ item.lang }}</label>
        </li>
      </ul>
    </div>
    <div class="mb-5">
      <p class="pb-2 mb-2 text-lg font-semibold border-b-2 border-solid border-slate-800">{{ adultText }} 수업 등록</p>
      <ul class="flex gap-4 p-1 mb-5 rounded bg-slate-800">
        <template v-for="(item, index) in registSugang.sugangOneLangList" :key="index">
          <li
            class="p-2 text-white cursor-pointer"
            :class="{
              'border-b-4 border-solid border-white': cur.currentLanguage == index,
            }"
            @click="registSugang.setCurrentLanguage(index)"
            v-if="item.adult === adult"
          >
            {{ item.language }}
          </li>
        </template>
      </ul>
      <div
        class="mb-5"
        v-if="
          cur.currentLanguage > -1 &&
          registSugang.sugangOneLangList[cur.currentLanguage] !== undefined &&
          registSugang.sugangOneLangList[cur.currentLanguage].sugangOneClassList !== undefined
        "
      >
        <ul class="flex justify-end gap-2 mb-2">
          <li
            class="p-2 border border-solid rounded cursor-pointer text-slate-800 border-slate-800"
            @click="registSugang.addRow()"
          >
            추가
          </li>
          <li
            class="p-2 border border-solid rounded cursor-pointer text-slate-800 border-slate-800"
            @click="registSugang.deleteRows()"
          >
            삭제
          </li>
        </ul>
        <table>
          <thead>
            <tr>
              <th></th>
              <th>수업</th>
              <th>레벨선택</th>
              <th>수업 안내</th>
              <th>교재 소개</th>
              <th>교재비</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item, classIndex) in registSugang.sugangOneLangList[cur.currentLanguage].sugangOneClassList"
              :key="classIndex"
            >
              <td>
                <input type="radio" :value="classIndex" v-model="cur.currentClassListIdx" />
              </td>
              <td>
                <select
                  v-if="['중국어', '일본어'].includes(registSugang.sugangOneLangList[cur.currentLanguage]?.language)"
                  v-model="item.className"
                  name=""
                  id=""
                  class="w-full text-center border border-solid rounded cursor-pointer border-slate-800"
                  @change="registSugang.checkDuplicateSelection(item.className, classIndex)"
                >
                  <option>일반회화</option>
                </select>

                <select
                  v-else
                  v-model="item.className"
                  name=""
                  id=""
                  class="w-full text-center border border-solid rounded cursor-pointer border-slate-800"
                  @change="registSugang.checkDuplicateSelection(item.className, classIndex)"
                >
                  <option>데일리토픽</option>
                  <option>로이터</option>
                  <option>리딩북앤톡</option>
                  <option>매거진</option>
                  <option>인터뷰</option>
                  <option>일반회화</option>
                  <option>커리어</option>
                  <option>테드</option>
                  <option>토론</option>
                  <option>파닉스</option>
                </select>
              </td>
              <td>
                <ul class="flex justify-center gap-2">
                  <li v-for="(level, index) in registSugang.levelList" :key="index">
                    <input
                      type="checkbox"
                      :checked="registSugang.inLevelList(item.sugangOneLevelList, level)"
                      :id="adult + '_' + classIndex + '_' + level.levelName"
                      @change="registSugang.levelChecked(item.sugangOneLevelList, classIndex, $event, level)"
                    />
                    <label class="cursor-pointer" :for="adult + '_' + classIndex + '_' + level.levelName">{{
                      level.levelName
                    }}</label>
                  </li>
                </ul>
              </td>
              <td>
                <input
                  type="text"
                  class="w-full border border-solid rounded border-slate-800"
                  v-model="item.classInfo"
                  placeholder="수업 안내(URL)"
                />
              </td>
              <td>
                <input
                  type="text"
                  class="w-full border border-solid rounded border-slate-800"
                  v-model="item.bookInfo"
                  placeholder="교재 소개(URL)"
                />
              </td>
              <td>
                <input
                  type="number"
                  class="w-full border border-solid rounded border-slate-800"
                  v-model="item.bookPrice"
                  step="100"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, reactive, ref, computed, watch, watchEffect } from "vue";

class BaseLevelListObject {
  constructor() {
    this.idxLeSugangOneLevel = 0;
    this.idxLeSugangOneClass = 0;
    this.levelName = "";
    this.staffIdx = 0;
    this.checked = false;
  }
}

class BaseClassListObject {
  constructor() {
    this.idxLeSugangOneClass = 0;
    this.idxLeSugangOneLang = 0;
    this.className = "";
    this.classInfo = "";
    this.bookInfo = "";
    this.bookPrice = 0;
    this.sugangOneLevelList = new Array();
    this.staffIdx = 0;
  }
}

class BaseLangListObject {
  constructor(adultType) {
    this.idxLeSugangOneLang = 0;
    this.idxLeSugangOne = 0;
    this.language = "";
    this.adult = adultType;
    this.sugangOneClassList = new Array();
    this.staffIdx = 0;
  }
}

export default {
  name: "JuniorClass",
  components: {},
  props: {
    sugangOneLangList: {
      type: Object,
    },
    adult: {
      type: String,
    },
    adultText: {
      type: String,
    },
  },
  setup(props, { emit }) {
    const cur = reactive({
      currentLanguage: -1,
      currentClassListIdx: -1,
    });
    const registSugang = reactive({
      languageList: [
        {
          lang: "영어(원어민)",
          checked: false,
        },
        {
          lang: "영어(필리핀)",
          checked: false,
        },
        {
          lang: "중국어",
          checked: false,
        },
        {
          lang: "일본어",
          checked: false,
        },
      ],
      levelList: [
        {
          levelName: "입문1",
          idxLeSugangOneLevel: 0,
          idxLeSugangOneClass: 0,
          checked: false,
          staffIdx: 0,
        },
        {
          levelName: "입문2",
          idxLeSugangOneLevel: 0,
          idxLeSugangOneClass: 0,
          checked: false,
          staffIdx: 0,
        },
        {
          levelName: "초급1",
          idxLeSugangOneLevel: 0,
          idxLeSugangOneClass: 0,
          checked: false,
          staffIdx: 0,
        },
        {
          levelName: "초급2",
          idxLeSugangOneLevel: 0,
          idxLeSugangOneClass: 0,
          checked: false,
          staffIdx: 0,
        },
        {
          levelName: "중급1",
          idxLeSugangOneLevel: 0,
          idxLeSugangOneClass: 0,
          checked: false,
          staffIdx: 0,
        },
        {
          levelName: "중급2",
          idxLeSugangOneLevel: 0,
          idxLeSugangOneClass: 0,
          checked: false,
          staffIdx: 0,
        },
        {
          levelName: "고급1",
          idxLeSugangOneLevel: 0,
          idxLeSugangOneClass: 0,
          checked: false,
          staffIdx: 0,
        },
        {
          levelName: "고급2",
          idxLeSugangOneLevel: 0,
          idxLeSugangOneClass: 0,
          checked: false,
          staffIdx: 0,
        },
        {
          levelName: "고급3",
          idxLeSugangOneLevel: 0,
          idxLeSugangOneClass: 0,
          checked: false,
          staffIdx: 0,
        },
        {
          levelName: "고급4",
          idxLeSugangOneLevel: 0,
          idxLeSugangOneClass: 0,
          checked: false,
          staffIdx: 0,
        },
      ],

      sugangOneLangList: props.sugangOneLangList,

      checkDuplicateSelection: (selectedOption, classIndex) => {
        const existingOptions = registSugang.sugangOneLangList[cur.currentLanguage].sugangOneClassList.map(
          (item) => item.className
        );
        const selectedCount = existingOptions.filter((option) => option === selectedOption).length;

        if (selectedCount > 1) {
          alert("해당 옵션은 이미 선택되었습니다.");
          registSugang.sugangOneLangList[cur.currentLanguage].sugangOneClassList[classIndex].className = "";
        }
      },

      languageChecked: (checkedTF, lang) => {
        console.log("languageChecked", checkedTF, lang);

        if (checkedTF) {
          //언어가 추가된 경우에는 sugangOneLangList 에 오브젝트를 추가해주고,
          registSugang.addBaseLangList(lang);
        } else {
          //언어가 제거된 경우에는 sugangOneLangList 에 오브젝트를 제거한다.
          if (confirm("선택을 해제하면 설정된 수업이 제거됩니다. 선택을 해제하시겠습니까?")) {
            //해제한다.
            registSugang.removeLangList(lang);
          } else {
            //해제하지 않는다.
            checkedTF = true;
            registSugang.languageList.forEach((item) => {
              if (item.lang == lang) item.checked = true;
            });
          }
        }
      },

      addBaseLangList: (lang) => {
        let bo = new BaseLangListObject(props.adult);
        bo.language = lang;
        registSugang.sugangOneLangList.push(bo);
        emit("set-regist-sugang-junior-data", registSugang.sugangOneLangList);
        console.log("addBaseLangList", registSugang.sugangOneLangList);
        return registSugang.sugangOneLangList.length;
      },

      removeLangList: (lang) => {
        let list = registSugang.sugangOneLangList.filter((o) => o.language !== lang);
        registSugang.sugangOneLangList = list;
        emit("set-regist-sugang-junior-data", registSugang.sugangOneLangList);
        console.log("removeLangList lang", lang);
        console.log("removeLangList list", registSugang.sugangOneLangList);
      },

      setCurrentLanguage: (index) => {
        console.log("setCurrentLanguage", index);
        cur.currentLanguage = index;
        //테이블의 레벨리스트값.....은??
      },

      inLevelList: (levelListObj, levelObj) => {
        let result = false;
        levelListObj.forEach((o) => {
          if (o.levelName === levelObj.levelName) {
            result = true;
          }
        });
        return result;
      },

      addBaseLevelList: (sugangOneLevelList, classIndex, levelObj) => {
        let bo = new BaseLevelListObject();
        bo.idxLeSugangOneLevel = 0;
        bo.levelName = levelObj.levelName;
        bo.checked = levelObj.checked;

        sugangOneLevelList.push(bo);
        console.log(sugangOneLevelList);
      },
      removeLevelList: (sugangOneLevelList, classIndex, levelObj) => {
        let list = sugangOneLevelList.filter((o) => o.levelName !== levelObj.levelName);
        sugangOneLevelList = list;
        console.log("removeLevelList levelObj", levelObj);
        console.log("removeLevelList list", sugangOneLevelList);
      },

      levelChecked: (sugangOneLevelList, classIndex, checkBoxEvent, levelObj) => {
        //registSugang.sugangOneLangList[idxLeSugangOneLang].sugangOneClassList
        //checkBoxEvent.target.checked === true : 체크상태로 변경되었다.
        console.log("sugangOneLevelList", sugangOneLevelList);
        console.log("classIndex", classIndex);
        console.log("checkBoxEvent", checkBoxEvent);
        console.log("levelObj", levelObj);

        if (checkBoxEvent.target.checked) {
          //레벨이 추가된 경우 sugangOneClassList 에 오브젝트를 추가해주고,
          registSugang.addBaseLevelList(sugangOneLevelList, classIndex, levelObj);
        } else {
          //언어가 제거된 경우에는 sugangOneLangList 에 오브젝트를 제거한다.
          if (confirm("선택을 해제하면 이미 신청자가 존재하는 경우 데이터가 유실됩니다. 선택을 해제하시겠습니까?")) {
            //해제한다.
            registSugang.removeLevelList(sugangOneLevelList, classIndex, levelObj);
          } else {
            //해제하지 않는다.
            checkBoxEvent.target.checked = true;
            // registSugang.languageList.forEach(item => {
            //     if(item.lang == lang) item.checked = true;
            // });
          }
        }
        console.log("levelChecked list", registSugang.sugangOneLangList);
      },

      getSugangOneLangList: (lang) => {
        let resultList = registSugang.sugangOneLangList.find(function whatClass(item) {
          if (item.language == lang) return true;
        });
        return resultList.sugangOneClassList;
      },

      addRow: () => {
        let bo = new BaseClassListObject();
        bo.idxLeSugangOne = registSugang.sugangOneLangList[cur.currentLanguage].idxLeSugangOne;
        bo.idxLeSugangOneLang = registSugang.sugangOneLangList[cur.currentLanguage].idxLeSugangOneLang;
        registSugang.sugangOneLangList[cur.currentLanguage].sugangOneClassList.push(bo);
        console.log(registSugang.sugangOneLangList[cur.currentLanguage].sugangOneClassList);
        return registSugang.sugangOneLangList[cur.currentLanguage].sugangOneClassList.length;
      },

      deleteRows: () => {
        if (cur.currentClassListIdx > -1) {
          registSugang.sugangOneLangList[cur.currentLanguage].sugangOneClassList.splice(cur.currentClassListIdx, 1);
        } else {
          alert("삭제할 수업을 선택해주세요");
        }
        console.log(cur.currentClassListIdx);
      },
      init: () => {
        if (Object.keys(props.sugangOneLangList).length > 0) {
          registSugang.sugangOneLangList = props.sugangOneLangList;
          registSugang.sugangOneLangList.forEach((element, index) => {
            //element.language 값이 languageList 에 존재한다면 true로 변경한다.
            if (element.adult === props.adult) {
              console.log(element.language);
              registSugang.languageList.forEach((item) => {
                if (item.lang == element.language) {
                  item.checked = true;
                }
              });
            }
          });
        }
      },
    });

    onMounted(() => {
      //키 목록을 가져온다.
      registSugang.init();
    });

    watch(
      () => props.sugangOneLangList,
      (cur, prev) => {
        registSugang.sugangOneLangList = cur;
        registSugang.init();
      }
    );

    watch(
      () => registSugang.sugangOneLangList,
      (cur, prev) => {
        console.log("set-regist-sugang-data", cur);
        // 이벤트를 통해 부모로 데이터셋을 전달.
        emit("set-regist-sugang-junior-data", cur); // 일단 주석처리.
      }
    );

    return {
      registSugang,
      cur,
    };
  },
};
</script>

<style></style>
