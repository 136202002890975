<template>
  <div>
    <div class="hidden pc:block bg-wrapper">
      <div class="flex items-center justify-center bg py-[76px] max-w-[1920px] mx-auto">
        <p class="text-[40px] font-bold text-neutral-950">자기주도학습</p>
      </div>
    </div>

    <section class="py-14 pc:py-[140px] pc-con-wrapper1">
      <p class="mb-5 text-lg font-bold pc:text-2xl pc:mb-4">※ 확인해주세요!</p>

      <ul class="p-5 border border-solid border-[#E7E7E7] rounded-md text-sm text-[#737373] mb-14 pc:p-6 pc:text-lg pc:mb-[70px]">
        <li class="flex items-baseline gap-2">
          <p class="w-2 pc:w-2.5">1.</p>
          <p>자기주도학습은 학습자의 수업이 배정된 이후 가능합니다.</p>
        </li>
        <li class="flex items-baseline gap-2">
          <p class="w-2 pc:w-2.5">2.</p>
          <p>안내문 수신 이후, 해당 페이지를 확인해주세요.</p>
        </li>
      </ul>

      <!-- 모바일 -->
      <div class="border-y border-solid border-[#0A0A0A] overflow-hidden pc:hidden">
        <table>
          <tr
            v-for="(learningInfo, index) in app.learningList"
            :key="learningInfo.round"
            class="border-b border-solid border-[#E5E5E5]"
          >
            <td
              :class="{
                'bg-[#FAFAFA]': learningInfo.isSubmit === 'Y',
                'bg-[#FFF7ED]': app.selectedRound === learningInfo.round,
                'bg-white': learningInfo.isSubmit === 'N' && app.selectedRound !== learningInfo.round,
              }"
              class="px-5 py-4"
              @click="learningInfo.bookName !== '-' ? app.getCheckTriedQuestion(learningInfo.round) : () => {}"
            >
              <div class="flex items-center justify-between gap-5 cursor-pointer">
                <div class="flex items-center gap-5">
                  <div class="flex justify-center items-center size-9 bg-white border border-solid border-[#707070] rounded-md">
                    <p class="text-sm text-[#737373] font-medium">
                      {{ learningInfo.isSubmit }}
                    </p>
                  </div>
                  <div class="space-y-1.5">
                    <p class="text-sm text-[#160F0D] text-left font-bold">{{ learningInfo.round }}회차</p>
                    <p class="text-sm text-[#737373] text-left font-medium">
                      제출기한 : {{ app.learnType === "A" ? dateTypeA[index].endDate : dateTypeB[index].endDate }}
                    </p>
                  </div>
                </div>
                <div class="w-2">
                  <img v-if="learningInfo.bookName !== '-'" src="@/lifeeduWeb/assets/images/selfLearning/arrow.png" alt="icon" />
                  <div v-else>-</div>
                </div>
              </div>
            </td>
          </tr>
        </table>
      </div>

      <!-- pc -->
      <div class="hidden pc:block border-y border-solid border-[#0A0A0A]">
        <table>
          <thead>
            <tr>
              <td
                class="w-[100px] py-6 border-r border-b border-solid border-[#E5E5E5] text-lg text-[#160F0D] text-center font-bold"
              >
                회차
              </td>
              <td
                class="w-[230px] py-6 border-r border-b border-solid border-[#E5E5E5] text-lg text-[#160F0D] text-center font-bold"
              >
                수업일자/요일
              </td>
              <td
                class="w-[230px] py-6 border-r border-b border-solid border-[#E5E5E5] text-lg text-[#160F0D] text-center font-bold"
              >
                자기주도학습 제출 기한
              </td>
              <td class="py-6 border-r border-b border-solid border-[#E5E5E5] text-lg text-[#160F0D] text-center font-bold">
                수업교재
              </td>
              <td
                class="w-[140px] py-6 border-r border-b border-solid border-[#E5E5E5] text-lg text-[#160F0D] text-center font-bold"
              >
                문제풀기/보기
              </td>
              <td class="w-[100px] py-6 border-b border-solid border-[#E5E5E5] text-lg text-[#160F0D] text-center font-bold">
                제출여부
              </td>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(learningInfo, index) in app.learningList"
              :key="learningInfo.round"
              :class="{
                'bg-[#FAFAFA]': learningInfo.isSubmit === 'Y',
                'bg-[#FFF7ED]': app.selectedRound === learningInfo.round,
                'bg-white': learningInfo.isSubmit === 'N' && app.selectedRound !== learningInfo.round,
              }"
            >
              <td class="w-[100px] py-6 border-r border-b border-solid border-[#E5E5E5] text-lg text-[#737373] text-center">
                {{ learningInfo.round }}회
              </td>
              <td class="py-6 border-r border-b border-solid border-[#E5E5E5] text-lg text-[#160F0D] text-center">
                {{ app.learnType === "A" ? dateTypeA[index].startDate : dateTypeB[index].startDate }}
              </td>
              <td class="py-6 border-r border-b border-solid border-[#E5E5E5] text-lg text-[#160F0D] text-center">
                {{ app.learnType === "A" ? dateTypeA[index].endDate : dateTypeB[index].endDate }}
              </td>
              <td class="py-6 border-r border-b border-solid border-[#E5E5E5] text-lg text-[#160F0D] text-center">
                <p class="line-clamp-1">
                  {{ learningInfo.bookName }}
                </p>
              </td>
              <td class="w-[140px] py-6 border-r border-b border-solid border-[#E5E5E5] text-lg text-[#737373] text-center">
                <p
                  @click="learningInfo.bookName !== '-' ? app.getCheckTriedQuestion(learningInfo.round) : () => {}"
                  :class="{
                    underline: learningInfo.bookName !== '-',
                    'text-[#F97316]': app.selectedRound === learningInfo.round,
                  }"
                  class="cursor-pointer"
                >
                  {{ learningInfo.bookName === "-" ? "-" : "View" }}
                </p>
              </td>
              <td class="w-[100px] py-6 border-b border-solid border-[#E5E5E5] text-lg text-[#737373] text-center">
                {{ learningInfo.isSubmit }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <!-- 문제 화면 -->
    </section>
  </div>
</template>
<script setup>
import { reactive, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import axios from "@lifeeduWeb/plugins/axios.js";

const router = useRouter();
const route = useRoute();
const store = useStore();
const idxLeSiteMain = store.state.idxLeSiteMain;

//월목 학습자
const dateTypeA = [
  { startDate: "2024-10-17 (Thu)", endDate: "2024-10-21 (Mon)" },
  { startDate: "2024-10-21 (Mon)", endDate: "2024-10-24 (Thu)" },
  { startDate: "2024-10-24 (Thu)", endDate: "2024-10-28 (Mon)" },
  { startDate: "2024-10-28 (Mon)", endDate: "2024-10-31 (Thu)" },
  { startDate: "2024-10-31 (Thu)", endDate: "2024-11-04 (Mon)" },
  { startDate: "2024-11-04 (Mon)", endDate: "2024-11-07 (Thu)" },
  { startDate: "2024-11-07 (Thu)", endDate: "2024-11-11 (Mon)" },
  { startDate: "2024-11-11 (Mon)", endDate: "2024-11-14 (Thu)" },
  { startDate: "2024-11-14 (Thu)", endDate: "2024-11-18 (Mon)" },
  { startDate: "2024-11-18 (Mon)", endDate: "2024-11-21 (Thu)" },
  { startDate: "2024-11-21 (Thu)", endDate: "2024-11-25 (Mon)" },
  { startDate: "2024-11-25 (Mon)", endDate: "2024-11-28 (Thu)" },
  { startDate: "2024-11-28 (Thu)", endDate: "2024-12-02 (Mon)" },
  { startDate: "2024-12-02 (Mon)", endDate: "2024-12-05 (Thu)" },
  { startDate: "2024-12-05 (Thu)", endDate: "2024-12-09 (Mon)" },
  { startDate: "2024-12-09 (Mon)", endDate: "2024-12-12 (Thu)" },
  { startDate: "2024-12-12 (Thu)", endDate: "2024-12-16 (Mon)" },
  { startDate: "2024-12-16 (Mon)", endDate: "2024-12-19 (Thu)" },
  { startDate: "2024-12-19 (Thu)", endDate: "2024-12-23 (Mon)" },
  { startDate: "2024-12-23 (Mon)", endDate: "2024-12-26 (Thu)" },
  { startDate: "2024-12-26 (Thu)", endDate: "2024-12-30 (Mon)" },
  { startDate: "2024-12-30 (Mon)", endDate: "2025-01-02 (Thu)" },
  { startDate: "2025-01-02 (Thu)", endDate: "2025-01-06 (Mon)" },
  { startDate: "2025-01-06 (Mon)", endDate: "2025-01-09 (Thu)" },
];

//화금 학습자
const dateTypeB = [
  { startDate: "2024-10-18 (Fri)", endDate: "2024-10-22 (Tue)" },
  { startDate: "2024-10-22 (Tue)", endDate: "2024-10-25 (Fri)" },
  { startDate: "2024-10-25 (Fri)", endDate: "2024-10-29 (Tue)" },
  { startDate: "2024-10-29 (Tue)", endDate: "2024-11-01 (Fri)" },
  { startDate: "2024-11-01 (Fri)", endDate: "2024-11-05 (Tue)" },
  { startDate: "2024-11-05 (Tue)", endDate: "2024-11-08 (Fri)" },
  { startDate: "2024-11-08 (Fri)", endDate: "2024-11-12 (Tue)" },
  { startDate: "2024-11-12 (Tue)", endDate: "2024-11-15 (Fri)" },
  { startDate: "2024-11-15 (Fri)", endDate: "2024-11-19 (Tue)" },
  { startDate: "2024-11-19 (Tue)", endDate: "2024-11-22 (Fri)" },
  { startDate: "2024-11-22 (Fri)", endDate: "2024-11-26 (Tue)" },
  { startDate: "2024-11-26 (Tue)", endDate: "2024-11-29 (Fri)" },
  { startDate: "2024-11-29 (Fri)", endDate: "2024-12-03 (Tue)" },
  { startDate: "2024-12-03 (Tue)", endDate: "2024-12-06 (Fri)" },
  { startDate: "2024-12-06 (Fri)", endDate: "2024-12-10 (Tue)" },
  { startDate: "2024-12-10 (Tue)", endDate: "2024-12-13 (Fri)" },
  { startDate: "2024-12-13 (Fri)", endDate: "2024-12-17 (Tue)" },
  { startDate: "2024-12-17 (Tue)", endDate: "2024-12-20 (Fri)" },
  { startDate: "2024-12-20 (Fri)", endDate: "2024-12-24 (Tue)" },
  { startDate: "2024-12-24 (Tue)", endDate: "2024-12-27 (Fri)" },
  { startDate: "2024-12-27 (Fri)", endDate: "2024-12-31 (Tue)" },
  { startDate: "2024-12-31 (Tue)", endDate: "2025-01-03 (Fri)" },
  { startDate: "2025-01-03 (Fri)", endDate: "2025-01-07 (Tue)" },
  { startDate: "2025-01-07 (Tue)", endDate: "2025-01-10 (Fri)" },
];

const app = reactive({
  learningList: [],
  learnType: "",
  userInfo: {},
  selectedRound: -1,
  getLearningList: async () => {
    const result = await axios.get(`/user/question`);
    const { success, message, data } = result.data;

    if (!success) {
      alert(message);
      return;
    }

    app.learningList = data.userQuestionList;
    app.learnType = data.userType;
  },

  getCheckTriedQuestion: async (round) => {
    app.selectedRound = round;
    const result = await axios.get(`/user/question/check/${round}`);
    const { success, message, data } = result.data;

    if (!success) {
      alert(message);
      return;
    }
    setTimeout(() => {
      window.open(
        `${window.location.origin}/questionPage?round=${round}&check=${data}&userName=${app.userInfo.kname}`,
        "_blank",
        "width=800, height=896, resizable=no, menubar=no, toolbar=no, location=no, status=no"
      );
    });
  },
  getUserInfo: async () => {
    const result = await axios.get("/user/sugang/member");
    const { success, message, data } = result.data;

    if (!success) {
      alert(message);
      return;
    }
    app.userInfo = data;
  },
});

onMounted(() => {
  if (idxLeSiteMain != 52) {
    alert("부산광역시학습자만 가능합니다");
    router.go(-1);
  }
  app.getLearningList();
  app.getUserInfo();
});
</script>
<style lang="scss" scoped></style>
